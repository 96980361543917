import { CloseOutlined, DeleteOutlined, FileOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Modal, Space, Typography, Select, DatePicker, Checkbox, Popover, message, Pagination } from "antd";
import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import axiosInstance from '../api/axios';
import { useLaboratoryId } from '../hooks/useLaboratoryId';
import { extractExcelHeaders, extractExcelRowsCount, importReagents, extractExcelData } from '../api/import';

const { Link } = Typography;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table-tbody > tr > td {
    height: 55px;
    padding: 8px;
  }
  
  .ant-table-body {
    height: 260px !important;
    overflow-y: auto !important;
  }
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 14px;
  margin-top: 8px;
  display: block;
  text-decoration: underline !important;
  text-underline-offset: 2px;
`;

interface DataType {
    key: React.Key;
    number: number;
    receipt: {
        date: dayjs.Dayjs;
        name: string;
        batch: string;
        manufacturer: string;
        supplier: string;
        frequencyClass: string;
        expiryDate: dayjs.Dayjs;
        expiryNotification: string;
        containerSize: number;
        containerUnit: string;
        containerCount: number;
        documents: boolean;
        admission: boolean;
    };
    storage: {
        isPrecursor: boolean;
        location: string;
        conditions: string;
    };
    accounting: {
        openedShelfLife: string;
        used: number;
        remaining: number;
        notes: string;
    };
    custom_columns?: Record<string, any>;
}

interface CustomColumn {
    title: string;
    key: string;
    type: string;
}

interface ImportTableProps {
    onCancel: () => void;
    open: boolean;
    selectedReagents: any[];
    fileName?: string;
    file?: File | null;
    headerRows?: number;
}

const manufacturerOptions = [
    'Производитель 1',
    'Производитель 2',
    'Производитель 3',
    'Другой'
].map(item => ({ label: item, value: item }));

const supplierOptions = [
    'Поставщик 1',
    'Поставщик 2',
    'Поставщик 3',
    'Другой'
].map(item => ({ label: item, value: item }));

const frequencyClassOptions = [
    'A',
    'B',
    'C',
    'D'
].map(item => ({ label: item, value: item }));

const notificationOptions = [
    'За 30 дней',
    'За 60 дней',
    'За 90 дней',
    'Другое'
].map(item => ({ label: item, value: item }));

const containerUnitOptions = [
    'мл',
    'л',
    'кг',
    'г',
    'Другое'
].map(item => ({ label: item, value: item }));

const locationOptions = [
    'Склад A',
    'Склад B',
    'Склад C',
    'Другое'
].map(item => ({ label: item, value: item }));

const ColumnMappingPopover = styled.div`
  width: 250px;
`;

const ColumnTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  
  .title-content {
    display: flex;
    flex-direction: column;
  }
  
  .edit-icon {
    color: #8c8c8c;
    font-size: 14px;
    margin-left: auto;
  }
  
  &:hover {
    color: #1890ff;
    
    .edit-icon {
      color: #1890ff;
    }
  }
`;

// Мемоизированные компоненты ячеек
const CellInput = memo(({ value, onChange }: { value: string, onChange: (value: string) => void }) => (
    <Input value={value} onChange={(e) => onChange(e.target.value)} />
));

const CellNumberInput = memo(({ value, onChange }: { value: number, onChange: (value: number) => void }) => (
    <Input type="number" value={value} onChange={(e) => onChange(Number(e.target.value))} />
));

const CellSelect = memo(({ value, onChange, options }: { value: string, onChange: (value: string) => void, options: { label: string, value: string }[] }) => (
    <Select
        value={value}
        onChange={onChange}
        options={options}
        style={{ width: '100%' }}
        showSearch
        allowClear
    />
));

const CellDatePicker = memo(({ value, onChange }: { value: dayjs.Dayjs | null, onChange: (value: dayjs.Dayjs | null) => void }) => (
    <DatePicker
        value={value}
        onChange={onChange}
        format="DD.MM.YYYY"
        style={{ width: '100%' }}
    />
));

const CellCheckbox = memo(({ checked, onChange }: { checked: boolean, onChange: (checked: boolean) => void }) => (
    <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} />
));

const ImportTable: React.FC<ImportTableProps> = ({ onCancel, open, fileName = "файл", file, headerRows = 1 }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [columnMapping, setColumnMapping] = useState<Record<string, string>>({});
    const [customColumns, setCustomColumns] = useState<CustomColumn[]>([]);
    const [excelHeaders, setExcelHeaders] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const labId = useLaboratoryId();

    const loadCustomColumns = useCallback(async () => {
        if (!labId) return;
        
        try {
            const response = await axiosInstance.get(`/api/custom-columns/reagent`, {
                params: {
                    laboratory_id: labId
                }
            });
            
            if (response.status === 200) {
                setCustomColumns(response.data.map((col: any) => ({ 
                    title: col.title, 
                    key: col.key,
                    type: col.type || 'text'
                })));
            }
        } catch (error) {
            console.error('Ошибка при загрузке пользовательских колонок:', error);
        }
    }, [labId]);

    const loadExcelData = useCallback(async () => {
        if (!file || !headerRows) return;
        
        try {
            setIsLoading(true);
            
            const headers = await extractExcelHeaders(file, headerRows);
            setExcelHeaders(headers);
            
            const rowsCount = await extractExcelRowsCount(file, headerRows);
            
            const emptyRows: DataType[] = Array.from({ length: rowsCount }, (_, index) => ({
                key: `${index + 1}`,
                number: index + 1,
                receipt: {
                    date: dayjs(),
                    name: '',
                    batch: '',
                    manufacturer: '',
                    supplier: '',
                    frequencyClass: '',
                    expiryDate: dayjs(),
                    expiryNotification: '',
                    containerSize: 0,
                    containerUnit: '',
                    containerCount: 0,
                    documents: false,
                    admission: false
                },
                storage: {
                    isPrecursor: false,
                    location: '',
                    conditions: ''
                },
                accounting: {
                    openedShelfLife: '',
                    used: 0,
                    remaining: 0,
                    notes: ''
                },
                custom_columns: {}
            }));
            
            setDataSource(emptyRows);
        } catch (error) {
            console.error('Ошибка при загрузке данных из Excel:', error);
            message.error('Не удалось загрузить данные из Excel файла');
        } finally {
            setIsLoading(false);
        }
    }, [file, headerRows]);

    useEffect(() => {
        loadCustomColumns();
    }, [loadCustomColumns]);

    useEffect(() => {
        loadExcelData();
    }, [loadExcelData]);

    useEffect(() => {
        if (Object.keys(columnMapping).length > 0 && file) {
            fetchExcelDataAndUpdate();
        }
    }, [columnMapping]);

    // Функция для получения данных из Excel и обновления таблицы
    const fetchExcelDataAndUpdate = async () => {
        if (!file || !headerRows || Object.keys(columnMapping).length === 0) return;
        
        try {
            setIsLoading(true);
            
            // Получаем данные из Excel на основе маппинга колонок
            const excelData = await extractExcelData(file, columnMapping, headerRows);
            
            // Обновляем данные в таблице
            updateDataFromExcel(excelData);
            
            message.success(`Данные успешно загружены из Excel`);
        } catch (error) {
            console.error('Ошибка при получении данных из Excel:', error);
            message.error('Не удалось загрузить данные из Excel');
        } finally {
            setIsLoading(false);
        }
    };

    // Функция для обновления данных на основе данных из Excel
    const updateDataFromExcel = (excelData: any[]) => {
        if (!excelData.length) return;
        
        // Создаем новые строки на основе данных из Excel
        const newRows = excelData.map((item, index) => {
            const key = `${index + 1}`;
            
            // Базовая структура строки
            const row: DataType = {
                key,
                number: index + 1,
                receipt: {
                    date: item.receipt?.date ? dayjs(item.receipt.date) : dayjs(),
                    name: item.receipt?.name || '',
                    batch: item.receipt?.batch || '',
                    manufacturer: item.receipt?.manufacturer || '',
                    supplier: item.receipt?.supplier || '',
                    frequencyClass: item.receipt?.frequencyClass || '',
                    expiryDate: item.receipt?.expiryDate ? dayjs(item.receipt.expiryDate) : dayjs(),
                    expiryNotification: item.receipt?.expiryNotification || '',
                    containerSize: item.receipt?.containerSize || 0,
                    containerUnit: item.receipt?.containerUnit || '',
                    containerCount: item.receipt?.containerCount || 0,
                    documents: item.receipt?.documents || false,
                    admission: item.receipt?.admission || false
                },
                storage: {
                    isPrecursor: item.storage?.isPrecursor || false,
                    location: item.storage?.location || '',
                    conditions: item.storage?.conditions || ''
                },
                accounting: {
                    openedShelfLife: item.accounting?.openedShelfLife || '',
                    used: item.accounting?.used || 0,
                    remaining: item.accounting?.remaining || 0,
                    notes: item.accounting?.notes || ''
                },
                custom_columns: item.custom_columns || {}
            };
            
            return row;
        });
        
        // Обновляем состояние
        setDataSource(newRows);
        setCurrentPage(1); // Сбрасываем на первую страницу
    };

    const handleDelete = useCallback((key: React.Key) => {
        setDataSource(prev => prev.filter(item => item.key !== key));
    }, []);

    const handleInputChange = useCallback((key: React.Key, field: string[], value: any) => {
        setDataSource(prev => prev.map(item => {
            if (item.key === key) {
                const newItem = { ...item };
                let current: any = newItem;
                for (let i = 0; i < field.length - 1; i++) {
                    current = current[field[i]];
                }
                current[field[field.length - 1]] = value;
                return newItem;
            }
            return item;
        }));
    }, []);

    const handleColumnMappingChange = (columnKey: string, excelColumn: string) => {
        setColumnMapping(prev => {
            const newMapping = {
                ...prev,
                [columnKey]: excelColumn
            };
            
            // Показываем информационное сообщение
            const mappedCount = Object.values(newMapping).filter(Boolean).length;
            message.info(`Колонка сопоставлена: ${mappedCount} из ${excelHeaders.length}`);
            
            return newMapping;
        });
    };

    const handleAddRow = useCallback(() => {
        const newKey = Date.now().toString();
        const newRow: DataType = {
            key: newKey,
            number: dataSource.length + 1,
            receipt: {
                date: dayjs(),
                name: '',
                batch: '',
                manufacturer: '',
                supplier: '',
                frequencyClass: '',
                expiryDate: dayjs(),
                expiryNotification: '',
                containerSize: 0,
                containerUnit: '',
                containerCount: 0,
                documents: false,
                admission: false
            },
            storage: {
                isPrecursor: false,
                location: '',
                conditions: ''
            },
            accounting: {
                openedShelfLife: '',
                used: 0,
                remaining: 0,
                notes: ''
            },
            custom_columns: {}
        };
        
        setDataSource(prev => [...prev, newRow]);
    }, [dataSource.length]);

    const renderColumnTitle = useCallback((title: string, columnKey: string) => {
        const content = (
            <ColumnMappingPopover>
                <div style={{ marginBottom: 8 }}>
                    <div style={{ marginBottom: 4, color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>
                        Укажите название колонки из Excel
                    </div>
                    <Select
                        placeholder="Выберите колонку"
                        value={columnMapping[columnKey] || undefined}
                        onChange={(value) => handleColumnMappingChange(columnKey, value)}
                        style={{ width: '100%' }}
                        showSearch
                        allowClear
                        loading={isLoading}
                        options={excelHeaders.map(header => ({ label: header, value: header }))}
                    />
                </div>
            </ColumnMappingPopover>
        );

        return (
            <Popover
                content={content}
                title="Сопоставление колонок"
                trigger="click"
                placement="bottom"
            >
                <ColumnTitle>
                    <div className="title-content">
                        <span>{title}</span>
                        {columnMapping[columnKey] && (
                            <div style={{ fontSize: 12, color: '#1890ff', marginTop: 4 }}>
                                {columnMapping[columnKey]}
                            </div>
                        )}
                    </div>
                    <EditOutlined className="edit-icon" />
                </ColumnTitle>
            </Popover>
        );
    }, [columnMapping, excelHeaders, handleColumnMappingChange, isLoading]);

    const handleImport = useCallback(async () => {
        if (!file || !labId) {
            message.error('Не удалось выполнить импорт. Отсутствует файл или ID лаборатории.');
            return;
        }

        try {
            setIsLoading(true);
            const response = await importReagents(file, columnMapping, headerRows, Number(labId));
            message.success(response.message);
            onCancel();
        } catch (error) {
            console.error('Ошибка при импорте реагентов:', error);
            message.error('Не удалось импортировать реагенты');
        } finally {
            setIsLoading(false);
        }
    }, [file, labId, columnMapping, headerRows, onCancel]);

    // Мемоизируем колонки таблицы
    const columns = useMemo(() => {
        const cols: ColumnsType<DataType> = [
            {
                title: renderColumnTitle("№ п/п", "number"),
                dataIndex: 'number',
                key: 'number',
                width: 100,
                fixed: 'left',
            },
            {
                title: renderColumnTitle("Дата поступления", "receipt.date"),
                dataIndex: ['receipt', 'date'],
                key: 'date',
                width: 232,
                render: (value: dayjs.Dayjs, record: DataType) => (
                    <CellDatePicker 
                        value={value || null} 
                        onChange={(date) => handleInputChange(record.key, ['receipt', 'date'], date || dayjs())} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Наименование", "receipt.name"),
                dataIndex: ['receipt', 'name'],
                key: 'name',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'name'], val)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("№ партии/серии", "receipt.batch"),
                dataIndex: ['receipt', 'batch'],
                key: 'batch',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'batch'], val)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Производитель", "receipt.manufacturer"),
                dataIndex: ['receipt', 'manufacturer'],
                key: 'manufacturer',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellSelect 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'manufacturer'], val)} 
                        options={manufacturerOptions} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Поставщик", "receipt.supplier"),
                dataIndex: ['receipt', 'supplier'],
                key: 'supplier',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellSelect 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'supplier'], val)} 
                        options={supplierOptions} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Класс частоты", "receipt.frequencyClass"),
                dataIndex: ['receipt', 'frequencyClass'],
                key: 'frequencyClass',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellSelect 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'frequencyClass'], val)} 
                        options={frequencyClassOptions} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Срок годности", "receipt.expiryDate"),
                dataIndex: ['receipt', 'expiryDate'],
                key: 'expiryDate',
                width: 232,
                render: (value: dayjs.Dayjs, record: DataType) => (
                    <CellDatePicker 
                        value={value || null} 
                        onChange={(date) => handleInputChange(record.key, ['receipt', 'expiryDate'], date || dayjs())} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Уведомление", "receipt.expiryNotification"),
                dataIndex: ['receipt', 'expiryNotification'],
                key: 'expiryNotification',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellSelect 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'expiryNotification'], val)} 
                        options={notificationOptions} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Размер тары", "receipt.containerSize"),
                dataIndex: ['receipt', 'containerSize'],
                key: 'containerSize',
                width: 232,
                render: (value: number, record: DataType) => (
                    <CellNumberInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'containerSize'], val)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Ед. изм. тары", "receipt.containerUnit"),
                dataIndex: ['receipt', 'containerUnit'],
                key: 'containerUnit',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellSelect 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'containerUnit'], val)} 
                        options={containerUnitOptions} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Кол-во тарных мест", "receipt.containerCount"),
                dataIndex: ['receipt', 'containerCount'],
                key: 'containerCount',
                width: 232,
                render: (value: number, record: DataType) => (
                    <CellNumberInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['receipt', 'containerCount'], val)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Документы получены", "receipt.documents"),
                dataIndex: ['receipt', 'documents'],
                key: 'documents',
                width: 232,
                render: (value: boolean, record: DataType) => (
                    <CellCheckbox 
                        checked={value} 
                        onChange={(checked) => handleInputChange(record.key, ['receipt', 'documents'], checked)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Допуск", "receipt.admission"),
                dataIndex: ['receipt', 'admission'],
                key: 'admission',
                width: 232,
                render: (value: boolean, record: DataType) => (
                    <CellCheckbox 
                        checked={value} 
                        onChange={(checked) => handleInputChange(record.key, ['receipt', 'admission'], checked)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Прекурсор", "storage.isPrecursor"),
                dataIndex: ['storage', 'isPrecursor'],
                key: 'isPrecursor',
                width: 232,
                render: (value: boolean, record: DataType) => (
                    <CellCheckbox 
                        checked={value} 
                        onChange={(checked) => handleInputChange(record.key, ['storage', 'isPrecursor'], checked)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Место хранения", "storage.location"),
                dataIndex: ['storage', 'location'],
                key: 'location',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellSelect 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['storage', 'location'], val)} 
                        options={locationOptions} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Условия хранения", "storage.conditions"),
                dataIndex: ['storage', 'conditions'],
                key: 'conditions',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['storage', 'conditions'], val)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Срок после вскрытия", "accounting.openedShelfLife"),
                dataIndex: ['accounting', 'openedShelfLife'],
                key: 'openedShelfLife',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['accounting', 'openedShelfLife'], val)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Использовано", "accounting.used"),
                dataIndex: ['accounting', 'used'],
                key: 'used',
                width: 232,
                render: (value: number, record: DataType) => (
                    <CellNumberInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['accounting', 'used'], val)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Остаток", "accounting.remaining"),
                dataIndex: ['accounting', 'remaining'],
                key: 'remaining',
                width: 232,
                render: (value: number, record: DataType) => (
                    <CellNumberInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['accounting', 'remaining'], val)} 
                    />
                ),
            },
            {
                title: renderColumnTitle("Примечание", "accounting.notes"),
                dataIndex: ['accounting', 'notes'],
                key: 'notes',
                width: 232,
                render: (value: string, record: DataType) => (
                    <CellInput 
                        value={value} 
                        onChange={(val) => handleInputChange(record.key, ['accounting', 'notes'], val)} 
                    />
                ),
            },
        ];
        
        return cols;
    }, [renderColumnTitle, handleInputChange]);

    // Мемоизируем пользовательские колонки
    const customColumnsConfig = useMemo(() => {
        return customColumns.map(col => ({
            title: renderColumnTitle(col.title, `custom_columns.${col.key}`),
            dataIndex: ['custom_columns', col.key],
            key: col.key,
            width: 232,
            render: (value: any, record: DataType) => {
                switch (col.type) {
                    case 'date':
                        return (
                            <CellDatePicker 
                                value={value ? dayjs(value) : null} 
                                onChange={(date) => handleInputChange(record.key, ['custom_columns', col.key], date ? date.toISOString() : null)} 
                            />
                        );
                    case 'boolean':
                        return (
                            <CellCheckbox 
                                checked={Boolean(value)} 
                                onChange={(checked) => handleInputChange(record.key, ['custom_columns', col.key], checked)} 
                            />
                        );
                    case 'int':
                        return (
                            <CellNumberInput 
                                value={value || 0} 
                                onChange={(val) => handleInputChange(record.key, ['custom_columns', col.key], val)} 
                            />
                        );
                    default:
                        return (
                            <CellInput 
                                value={value || ''} 
                                onChange={(val) => handleInputChange(record.key, ['custom_columns', col.key], val)} 
                            />
                        );
                }
            }
        }));
    }, [customColumns, renderColumnTitle, handleInputChange]);

    // Колонка с кнопками удаления
    const actionColumn = useMemo(() => ({
        key: "action",
        width: 48,
        fixed: 'right' as const,
        render: (_: unknown, record: DataType) => (
            <StyledButton 
                type="text" 
                icon={<DeleteOutlined style={{ color: '#f5222d' }} />}
                style={{ width: '32px', padding: 0 }}
                onClick={() => handleDelete(record.key)}
            />
        ),
    }), [handleDelete]);

    // Удаляем колонку с кнопками удаления из основного массива columns
    const columnsWithoutAction = useMemo(() => columns.filter(col => col.key !== "action"), [columns]);

    // Объединяем стандартные и пользовательские колонки, добавляя колонку с кнопками удаления в конец
    const allColumns = useMemo(() => [...columnsWithoutAction, ...customColumnsConfig, actionColumn], 
        [columnsWithoutAction, customColumnsConfig, actionColumn]);

    // Данные для текущей страницы
    const paginatedData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    // Обработчик изменения страницы
    const handlePageChange = (page: number, size?: number) => {
        setCurrentPage(page);
        if (size) setPageSize(size);
    };

    return (
        <Modal
            title={<>Импорт данных из <span style={{ color: '#1890ff' }}>{fileName}</span></>}
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
            centered
        >
            <StyledLink href="#" target="_blank">Нужна помощь с импортом?</StyledLink>
            <div style={{ marginTop: 16, marginBottom: 16 }}>
                <Button 
                    type="dashed" 
                    icon={<PlusOutlined />} 
                    onClick={handleAddRow}
                    style={{ width: '100%' }}
                >
                    Добавить строку
                </Button>
            </div>
            <StyledTable
                columns={allColumns}
                dataSource={paginatedData}
                pagination={false}
                bordered
                scroll={{ x: 'max-content', y: 300 }}
                loading={isLoading}
            />
            {dataSource.length > 0 && (
                <div style={{ marginTop: 16, textAlign: 'right' }}>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={dataSource.length}
                        onChange={handlePageChange}
                        showSizeChanger
                        showQuickJumper
                        showTotal={(total) => `Всего ${total} записей`}
                    />
                </div>
            )}
            <ButtonsRow justify="space-between" style={{ marginTop: 16 }}>
                <Space size={8}>
                    <StyledButton onClick={onCancel}>Отмена</StyledButton>
                </Space>
                <Space size={8}>
                    <StyledButton 
                        type="primary"
                        onClick={handleImport}
                        loading={isLoading}
                        disabled={dataSource.length === 0}
                    >
                        Импортировать
                    </StyledButton>
                </Space>
            </ButtonsRow>
        </Modal>
    );
};

export default ImportTable;
