import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Table, Checkbox, Select, DatePicker, Button, Tooltip, Input, Space, Popover, theme, message } from 'antd';
import type { TableColumnsType, InputRef } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { createStyles } from 'antd-style';
import dayjs from 'dayjs';
import { SearchOutlined, FilterOutlined, EyeInvisibleOutlined, DatabaseOutlined, ArrowUpOutlined, ArrowDownOutlined, ExportOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import EditMSI from '../../components/EditMSI';
import { useLaboratoryId } from '../../hooks/useLaboratoryId';
import { msiApi, MSIData } from '../../api/msi';
import ExportTypes, { ExportFormat } from '../../components/ExportTypes';

const { useToken } = theme;

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table {
      outline: 2px solid #f0f0f0;
      
      .ant-table-container {
        .ant-table-body,
        .ant-table-content {
          scrollbar-width: thin;
          scrollbar-color: #eaeaea transparent;
          scrollbar-gutter: stable;
        }
      }
      
      .ant-table-thead .ant-table-cell {
        color: #595959;
      }

      .ant-table-tbody {
        .ant-table-cell {
          font-weight: 500;

          .ant-select-selection-item,
          .ant-picker-input > input {
            font-weight: 500;
          }

          .ant-select .ant-select-selector {
            border-radius: 4px !important;
          }
        }
      }

      .cell-content {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
}));

export interface MSIType {
  id: number;
  key: React.Key;
  number: number;
  measurement_method: string;
  measurement_methodology: string;
  research_object: string;
  characteristic: string;
  employee: string;
  provider: string;
  deadline: string;
  completed: boolean;
}

export const testMSIData: MSIType[] = Array.from({ length: 100 }).map((_, index) => ({
  id: index + 1,
  key: index,
  number: index + 1,
  measurement_method: ['Метод 1', 'Метод 2', 'Метод 3', 'Метод 4'][Math.floor(Math.random() * 4)],
  measurement_methodology: `Методика ${index + 1}`,
  research_object: `Объект ${index + 1}`,
  characteristic: `Характеристика ${index + 1}`,
  employee: `Сотрудник ${index + 1}`,
  provider: `Провайдер ${index + 1}`,
  deadline: `2024-${String(index % 12 + 1).padStart(2, '0')}-01`,
  completed: Math.random() > 0.5,
}));

const methodOptions = [
  'Метод 1',
  'Метод 2',
  'Метод 3',
  'Метод 4'
].map(item => ({ label: item, value: item }));

const TextWithTooltip: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  if (typeof text === 'string' && text.length > 24) {
    return (
      <Tooltip title={text}>
        <div className="cell-content">{text}</div>
      </Tooltip>
    );
  }
  return <div>{text}</div>;
};

const ColumnMenu = styled.div`
  width: 306px;
  padding: 12px;
  background: #F5F5F5;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const MenuButton = styled(Button)<{ $isBlue?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 6.4px 12px;
  background: ${props => props.$isBlue ? '#1890FF' : 'white'};
  color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'};
  border: 0.5px solid ${props => props.$isBlue ? '#1890FF' : '#D9D9D9'};
  border-radius: 4px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;

  .anticon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: ${props => props.$isBlue ? '#40a9ff' : '#fff'} !important;
    color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'} !important;
    border-color: ${props => props.$isBlue ? '#40a9ff' : '#d9d9d9'} !important;
  }
`;

const FilterContent = styled.div`
  height: 200px;
  overflow-y: auto;
  margin-bottom: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const FilterDropdown: React.FC<{
  close: () => void;
  confirm: (selectedValues: string[]) => void;
  values: string[];
}> = ({ close, confirm, values }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const uniqueValues = Array.from(new Set(values)).sort();

  return (
    <div style={{ padding: 8, background: 'white', borderRadius: 4 }}>
      <FilterContent>
        {uniqueValues.map((value) => (
          <div key={value} style={{ marginBottom: 4 }}>
            <Checkbox
              checked={selectedValues.includes(value)}
              onChange={(e) => {
                const newValues = e.target.checked
                  ? [...selectedValues, value]
                  : selectedValues.filter(v => v !== value);
                setSelectedValues(newValues);
              }}
            >
              {value}
            </Checkbox>
          </div>
        ))}
      </FilterContent>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm(selectedValues);
            close();
          }}
          size="small"
        >
          Применить
        </Button>
        <Button
          onClick={() => {
            setSelectedValues([]);
            confirm([]);
            close();
          }}
          size="small"
        >
          Сбросить
        </Button>
      </Space>
    </div>
  );
};

interface CustomColumnTitleProps {
  title: React.ReactNode;
  onSort?: (order: 'asc' | 'desc') => void;
  onFilter?: (values: string[]) => boolean;
  onHide?: () => void;
  onManage?: () => void;
  type?: 'number' | 'date';
  dataIndex?: string[];
  filterValues?: string[];
}

const CustomColumnTitle: React.FC<CustomColumnTitleProps> = ({
  title,
  onSort,
  onFilter,
  onHide,
  onManage,
  type,
  filterValues = []
}) => {
  const { token } = useToken();
  const [open, setOpen] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterValue, setFilterValue] = useState<string[]>([]);

  const handleMenuClick = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    setOpen(false);
  };

  const handleFilter = () => {
    setOpen(false);
    setFilterVisible(true);
  };

  const columnMenu = (
    <ColumnMenu>
      {type && (
        <>
          <MenuButton 
            icon={<ArrowUpOutlined style={{ color: token['blue-5'] }} />}
            onClick={() => handleMenuClick(() => onSort?.('asc'))}
          >
            Сортировать по возрастанию
          </MenuButton>
          <MenuButton 
            icon={<ArrowDownOutlined style={{ color: token['blue-5'] }} />}
            onClick={() => handleMenuClick(() => onSort?.('desc'))}
          >
            Сортировать по убыванию
          </MenuButton>
        </>
      )}
      <MenuButton 
        icon={<FilterOutlined style={{ color: token['blue-5'] }} />}
        onClick={handleFilter}
      >
        Фильтры
      </MenuButton>
      <MenuButton 
        icon={<EyeInvisibleOutlined style={{ color: token['blue-5'] }} />}
        onClick={() => handleMenuClick(onHide)}
      >
        Скрыть
      </MenuButton>
      <MenuButton 
        $isBlue 
        icon={<DatabaseOutlined style={{ color: 'white' }} />}
        onClick={() => handleMenuClick(onManage)}
      >
        Управление колонками
      </MenuButton>
    </ColumnMenu>
  );

  return (
    <>
      <Popover 
        trigger="click"
        open={open}
        onOpenChange={setOpen}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'transparent', boxShadow: 'none' }}
      >
        <div style={{ cursor: 'pointer' }}>{title}</div>
      </Popover>
      <Popover
        content={
          <FilterDropdown
            close={() => setFilterVisible(false)}
            confirm={(values) => {
              if (onFilter) {
                onFilter(values);
              }
              setFilterVisible(false);
            }}
            values={filterValues}
          />
        }
        trigger="click"
        open={filterVisible}
        onOpenChange={setFilterVisible}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'white', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: filterVisible ? 'block' : 'none' }} />
      </Popover>
    </>
  );
};

interface FilterFunction {
  (value: string): boolean;
}

interface StandartSamplesTableProps {
    data?: MSIType[];
    searchValue?: string;
    onExport?: (selectedIds: React.Key[]) => void;
}

const StandartSamplesTable: React.FC<StandartSamplesTableProps> = ({ 
    data = testMSIData,
    searchValue = '',
    onExport 
}) => {
  const { styles } = useStyle();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [sortedInfo, setSortedInfo] = useState<{ columnKey: string | null; order: 'ascend' | 'descend' | null }>({
    columnKey: null,
    order: null,
  });
  const [filteredData, setFilteredData] = useState<MSIType[]>(data);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [editingRecord, setEditingRecord] = useState<MSIType | null>(null);
  const [editPopoverVisible, setEditPopoverVisible] = useState(false);
  const laboratoryId = useLaboratoryId();
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const searchInFields = useCallback((item: MSIType, value: string) => {
    const searchFields = [
      item.measurement_method,
      item.measurement_methodology,
      item.research_object,
      item.characteristic,
      item.employee,
      item.provider,
      item.deadline,
      item.completed,
    ];

    const lowerValue = value.toLowerCase();
    return searchFields.some(field => 
      field?.toString().toLowerCase().includes(lowerValue)
    );
  }, []);

  useEffect(() => {
    const searchFiltered = searchValue 
      ? data.filter(item => searchInFields(item, searchValue))
      : data;

    const newData = searchFiltered.filter(record => {
      return Object.entries(filters).every(([key, filterValues]) => {
        if (filterValues.length === 0) return true;
        const keys = key.split('.');
        const fieldValue = keys.reduce((obj, key) => obj?.[key], record as any)?.toString();
        return filterValues.includes(fieldValue);
      });
    });

    setFilteredData(newData);
  }, [filters, data, searchValue, searchInFields]);

  const handleCheckboxChange = (
    record: MSIType,
    field: 'completed',
    checked: boolean
  ) => {
    setFilteredData(prevData => 
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: checked
          };
        }
        return item;
      })
    );
  };

  const handleSelectChange = (
    record: MSIType,
    field: string,
    value: string
  ) => {
    setFilteredData(prevData =>
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })
    );
  };

  const handleDateChange = (
    record: MSIType,
    field: string,
    value: dayjs.Dayjs | null
  ) => {
    setFilteredData(prevData =>
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: value ? value.format('YYYY-MM-DD') : null
          };
        }
        return item;
      })
    );
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string[],
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex.join('.'));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string[]) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Поиск"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex.join('.'));
            }}
          >
            Фильтр
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value: any, record: MSIType) => {
      const fieldValue = dataIndex.reduce((obj, key) => obj?.[key], record as any);
      return fieldValue?.toString().toLowerCase().includes((value as string).toLowerCase());
    },
    filterDropdownProps: {
      onOpenChange(open: boolean) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text: string) =>
      searchedColumn === dataIndex.join('.') ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <TextWithTooltip text={text} />
      ),
  });

  const handleHideColumn = (dataIndex: string[]) => {
    setHiddenColumns(prev => [...prev, dataIndex.join('.')]);
  };

  const handleSort = (columnKey: string, order: 'asc' | 'desc') => {
    const antdOrder = order === 'asc' ? 'ascend' : 'descend';
    setSortedInfo({ columnKey, order: antdOrder });

    setFilteredData(prevData => {
      const newData = [...prevData];
      newData.sort((a, b) => {
        const aValue = columnKey.split('.').reduce((obj, key) => obj?.[key], a as any);
        const bValue = columnKey.split('.').reduce((obj, key) => obj?.[key], b as any);

        if (typeof aValue === 'string') {
          return order === 'asc' 
            ? aValue.localeCompare(bValue) 
            : bValue.localeCompare(aValue);
        }

        return order === 'asc' 
          ? (aValue > bValue ? 1 : -1)
          : (bValue > aValue ? 1 : -1);
      });
      return newData;
    });
  };

  const handleFilter = (columnKey: string, values: string[]): boolean => {
    if (values.length === 0) {
      const newFilters = { ...filters };
      delete newFilters[columnKey];
      setFilters(newFilters);
    } else {
      setFilters(prev => ({
        ...prev,
        [columnKey]: values
      }));
    }
    return true;
  };

  const getUniqueValues = (dataIndex: string[]) => {
    return Array.from(new Set(
      filteredData.map(item => {
        const value = dataIndex.reduce((obj, key) => obj?.[key], item as any);
        return value?.toString() || '';
      }).filter(Boolean)
    ));
  };

  const createColumnTitle = (title: string, dataIndex?: string[], isGroupTitle?: boolean, type?: 'number' | 'date') => {
    if (isGroupTitle) {
      return <TextWithTooltip text={title} />;
    }

    return (
      <CustomColumnTitle 
        title={<TextWithTooltip text={title} />}
        onSort={dataIndex && type ? (order) => handleSort(dataIndex.join('.'), order) : undefined}
        onFilter={dataIndex ? (values) => handleFilter(dataIndex.join('.'), values) : undefined}
        onHide={() => dataIndex && handleHideColumn(dataIndex)}
        onManage={() => console.log('Manage columns')}
        type={type}
        dataIndex={dataIndex}
        filterValues={dataIndex ? getUniqueValues(dataIndex) : []}
      />
    );
  };

  const columns: TableColumnsType<MSIType> = [
    {
        title: createColumnTitle('№ п/п', ['number'], false, 'number'),
        dataIndex: 'number',
        key: 'number',
        width: 80,
        fixed: 'left',
        sortOrder: sortedInfo.columnKey === 'number' ? sortedInfo.order : null,
        hidden: hiddenColumns.includes('number'),
    },
    {
        title: createColumnTitle('Метод измерений', ['measurement_method']),
        dataIndex: 'measurement_method',
        key: 'measurement_method',
        width: 232,
        hidden: hiddenColumns.includes('measurement_method'),
        render: (value: string, record: MSIType) => (
            <Select
                value={value}
                onChange={(newValue) => handleSelectChange(record, 'measurement_method', newValue)}
                options={methodOptions}
                style={{ width: '100%' }}
                allowClear
            />
        ),
    },
    {
        title: createColumnTitle('Методика измерений', ['measurement_methodology']),
        dataIndex: 'measurement_methodology',
        key: 'measurement_methodology',
        width: 232,
        hidden: hiddenColumns.includes('measurement_methodology'),
    },
    {
        title: createColumnTitle('Объект исследований', ['research_object']),
        dataIndex: 'research_object',
        key: 'research_object',
        width: 232,
        hidden: hiddenColumns.includes('research_object'),
    },
    {
        title: createColumnTitle('Определяемая характеристика', ['characteristic']),
        dataIndex: 'characteristic',
        key: 'characteristic',
        width: 232,
        hidden: hiddenColumns.includes('characteristic'),
    },
    {
        title: createColumnTitle('Участвующий сотрудник', ['employee']),
        dataIndex: 'employee',
        key: 'employee',
        width: 232,
        hidden: hiddenColumns.includes('employee'),
    },
    {
        title: createColumnTitle('Провайдер МСИ', ['provider']),
        dataIndex: 'provider',
        key: 'provider',
        width: 232,
        hidden: hiddenColumns.includes('provider'),
    },
    {
        title: createColumnTitle('Сроки проведения', ['deadline'], false, 'date'),
        dataIndex: 'deadline',
        key: 'deadline',
        width: 232,
        hidden: hiddenColumns.includes('deadline'),
        render: (value: string, record: MSIType) => (
            <DatePicker
                value={value ? dayjs(value) : null}
                onChange={(date) => handleDateChange(record, 'deadline', date)}
                format="DD.MM.YYYY"
                bordered={false}
                showToday={false}
                allowClear={false}
                style={{ width: '100%' }}
            />
        ),
    },
    {
        title: createColumnTitle('Выполнено', ['completed']),
        dataIndex: 'completed',
        key: 'completed',
        width: 232,
        hidden: hiddenColumns.includes('completed'),
        render: (value: boolean, record: MSIType) => (
            <Checkbox
                checked={value}
                onChange={(e) => handleCheckboxChange(record, 'completed', e.target.checked)}
            />
        ),
    },
    {
        title: createColumnTitle('Заявка', ['application']),
        key: 'application',
        width: 232,
        render: (_, record: MSIType) => (
            <Button 
                type="primary"
                onClick={() => console.log('Подать заявку', record)}
                style={{ width: '100%', height: 40 }}
            >
                Подать заявку
            </Button>
        ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    columnWidth: 48,
    fixed: true
  };

  const getVisibleColumns = (cols: TableColumnsType<MSIType>): TableColumnsType<MSIType> => {
    return cols.map(col => {
      if ('children' in col) {
        return {
          ...col,
          children: getVisibleColumns(col.children || []).filter(child => !child.hidden)
        };
      }
      return col;
    }).filter(col => {
      if ('children' in col) {
        return col.children && col.children.length > 0;
      }
      return !col.hidden;
    });
  };

  const visibleColumns = getVisibleColumns(columns);

  const handleRowClick = (record: MSIType) => {
    if (editingRecord?.id === record.id) {
      setEditPopoverVisible(!editPopoverVisible);
    } else {
      setEditingRecord(record);
      setEditPopoverVisible(true);
    }
  };

  const handleEditCancel = () => {
    setEditPopoverVisible(false);
    setEditingRecord(null);
  };

  const handleEditSuccess = () => {
    fetchData();
    setEditPopoverVisible(false);
    setEditingRecord(null);
  };

  const fetchData = async () => {
    if (!laboratoryId) return;
    
    try {
      const response = await msiApi.getLaboratoryMSI(Number(laboratoryId));
      setFilteredData(response.data);
    } catch (error) {
      message.error('Ошибка при загрузке данных');
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [laboratoryId]);

  const handleExport = async (format: ExportFormat) => {
    try {
      // Преобразуем selectedRowKeys в числа
      const selectedIds = selectedRowKeys.map(key => Number(key));
      
      const response = await msiApi.exportMSI(format, selectedIds);
      
      // Создаем blob из ответа
      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      });
      
      // Создаем ссылку для скачивания
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      
      // Получаем имя файла из заголовков
      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : `msi_export.${format.toLowerCase()}`;
      
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      
      message.success('Экспорт выполнен успешно');
    } catch (error) {
      console.error('Export error:', error);
      message.error('Ошибка при экспорте данных');
    }
  };

  return (
    <>
      <Table<MSIType>
        className={styles.customTable}
        columns={visibleColumns}
        dataSource={filteredData}
        rowSelection={rowSelection}
        bordered
        size="middle"
        scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          style: { cursor: 'pointer' }
        })}
      />
      
      {editingRecord && (
        <Popover
          content={
            <EditMSI
              record={editingRecord}
              onCancel={handleEditCancel}
              onSuccess={handleEditSuccess}
            />
          }
          overlayInnerStyle={{ 
            background: 'transparent', 
            boxShadow: 'none'
          }}
          overlayStyle={{ 
            padding: 0
          }}
          open={editPopoverVisible}
          trigger="click"
          placement="right"
          onOpenChange={(visible) => {
            if (!visible) {
              handleEditCancel();
            }
          }}
          destroyTooltipOnHide
        >
        </Popover>
      )}
      <ExportTypes
        open={isExportModalVisible}
        onCancel={() => setIsExportModalVisible(false)}
        onExport={handleExport}
        selectedRows={selectedRowKeys}
      />
    </>
  );
};

export default StandartSamplesTable;