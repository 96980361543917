import axiosInstance from './axios';

export interface CertifiedValue {
    indicator_name: string;
    value: number;
    unit: string;
    error: number;
    error_unit: string;
}

export interface BatchCreate {
    batch_number: string;
    batch_code: string;
    registration_date: string;
    target_value: number;
    unit: string;
    planned_volume: number;
    
    // Дополнительные поля из формы
    so_name?: string;
    gso_number?: string;
    so_category?: string;
    packaging?: string;
    expiry_date?: string;
    
    certified_values?: CertifiedValue[];
}

export interface Batch extends BatchCreate {
    id: number;
    packaging_permission: boolean;
    packaging_complete: boolean;
}

export interface StandardSample {
    id: number;
    index: string;
    name: string;
    gso_number: string;
    category: string;
    parameter: string;
    measurement_unit: string;
    error_unit: string;
}

export const createBatch = async (data: BatchCreate): Promise<Batch> => {
    try {
        const response = await axiosInstance.post<Batch>('/api/batches', data);
        return response.data;
    } catch (error: any) {
        console.error('Error creating batch:', error);
        throw error;
    }
};

export const getBatches = async (): Promise<Batch[]> => {
    try {
        const response = await axiosInstance.get<Batch[]>('/api/batches');
        return response.data;
    } catch (error: any) {
        console.error('Error getting batches:', error);
        throw error;
    }
};

export const getBatch = async (id: number): Promise<Batch> => {
    try {
        const response = await axiosInstance.get<Batch>(`/api/batches/${id}`);
        return response.data;
    } catch (error: any) {
        console.error(`Error getting batch ${id}:`, error);
        throw error;
    }
};

export const updateBatch = async (id: number, data: Partial<BatchCreate>): Promise<Batch> => {
    try {
        const response = await axiosInstance.put<Batch>(`/api/batches/${id}`, data);
        return response.data;
    } catch (error: any) {
        console.error(`Error updating batch ${id}:`, error);
        throw error;
    }
};

export const deleteBatch = async (id: number): Promise<void> => {
    try {
        await axiosInstance.delete(`/api/batches/${id}`);
    } catch (error: any) {
        console.error(`Error deleting batch ${id}:`, error);
        throw error;
    }
};

export const generateBatchLabels = async (batchIds: number[]): Promise<Blob> => {
    try {
        console.log('Отправка запроса на генерацию этикеток для партий:', batchIds);
        const response = await axiosInstance.post('/api/batches/generate-labels', { batch_ids: batchIds }, {
            responseType: 'blob'
        });
        console.log('Получен ответ от сервера:', response.status, response.headers);
        return response.data;
    } catch (error: any) {
        console.error('Error generating batch labels:', error);
        throw error;
    }
};

export const getStandardSamples = async (): Promise<StandardSample[]> => {
    const response = await axiosInstance.get('/standard-samples/');
    return response.data;
};

/**
 * Поиск стандартных образцов по индексу, наименованию, номеру ГСО или показателю
 * @param query Текст для поиска
 */
export const searchStandardSamples = async (query: string): Promise<StandardSample[]> => {
    const response = await axiosInstance.get('/standard-samples/search', {
        params: { search_query: query }
    });
    return response.data;
}; 