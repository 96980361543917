import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Row, Select, Typography, DatePicker, Checkbox } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import dayjs from 'dayjs';
import { CheckboxField } from '../../components/CheckboxField';
import { message } from 'antd';
import { createStandard } from '../../api/chemicalRegistry';
import { useLaboratoryId } from '../../hooks/useLaboratoryId';
import type { SelectProps } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import type { DatePickerProps } from 'antd';

const { Option } = Select;
const { Text } = Typography;

const ScreenContainer = styled.div`
  width: 400px;
  height: 500px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

const ScreenTitle = styled(Typography.Title)`
  margin: 16px !important;
  font-size: 16px !important;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const BottomButtons = styled.div`
  padding: 16px;
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  /* Скрываем стрелки для числовых полей */
  &[type='number'] {
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
    
    .ant-select-selection-search-input {
      height: 38px !important;
    }
    
    .ant-select-selection-item {
      line-height: 32px !important;
    }
  }

  // Центрируем иконки по вертикали
  .ant-select-arrow,
  .ant-select-clear {
    top: 50% !important;
    margin-top: -3px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  .ant-picker-input > input {
    background-color: #fafafa;
  }
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  justify-content: center;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(Typography.Link)`
  color: #8c8c8c !important;
  font-size: 14px;
  text-decoration: underline !important;
  margin-right: 20px;
`;

// Добавляем пропсы для компонента
interface ScreenProps {
  onCancel: () => void;
  onSuccess?: () => void;
}

// Опции из Reagents.tsx
const standardTypes = [
    'ГСО 1234-56 Стандартный образец 1',
    'ОСО 7890-12 Стандартный образец 2',
    'СОП 3456-78 Стандартный образец 3',
].map(item => ({ label: item, value: item }));

const manufacturerOptions = [
    'ФГУП "ВНИИМ им. Д.И. Менделеева"',
    'ФГУП "УНИИМ"',
    'ООО "Химтест"',
].map(item => ({ label: item, value: item }));

const purposeOptions = [
    'Калибровка',
    'Поверка',
    'Контроль точности',
].map(item => ({ label: item, value: item }));

const indicatorOptions = [
    'Массовая доля железа',
    'Массовая доля углерода',
    'Массовая доля серы',
].map(item => ({ label: item, value: item }));

const notificationOptions = [
    'За месяц',
    'За 2 месяца',
    'За 3 месяца',
].map(item => ({ label: item, value: item }));

const Screen: React.FC<ScreenProps> = ({ onCancel, onSuccess }) => {
  const laboratoryId = useLaboratoryId();
  const [loading, setLoading] = useState(false);
  
  // Сстояния для полей формы
  const [name, setName] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [purpose, setPurpose] = useState('');
  const [indicator, setIndicator] = useState('');
  const [certifiedValue, setCertifiedValue] = useState('');
  const [errorValue, setErrorValue] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [normativeDocument, setNormativeDocument] = useState('');
  const [expirationDate, setExpirationDate] = useState<dayjs.Dayjs | null>(null);
  const [expirationNotification, setExpirationNotification] = useState('');
  const [releaseDate, setReleaseDate] = useState<dayjs.Dayjs | null>(null);
  const [notes, setNotes] = useState('');
  const [isPrecursor, setIsPrecursor] = useState(false);

  // Type-safe handlers for Select components
  const handleSelectChange = (setter: (value: string) => void) => 
      (value: unknown, _: DefaultOptionType | DefaultOptionType[]) => {
          setter(value as string);
      };

  // Обновленный обработчик для DatePicker
  const handleDateChange = (setter: (date: dayjs.Dayjs | null) => void) => 
      (value: any) => {
          setter(value);
      };

  const handleSave = async () => {
    if (!laboratoryId) return;
    
    // Валидация обязательных полей
    if (!name.trim()) {
        message.error('Введите название стандартного образца');
        return;
    }

    try {
        setLoading(true);
        await createStandard(laboratoryId, {
            name,
            manufacturer,
            purpose,
            indicator,
            certified_value: certifiedValue,
            error_value: errorValue,
            additional_info: additionalInfo,
            normative_document: normativeDocument,
            expiration_date: expirationDate?.toISOString(),
            expiration_notification: expirationNotification,
            release_date: releaseDate?.toISOString(),
            is_precursor: isPrecursor,
            notes: notes
        });
        
        message.success('Стандартный образец успешно добавлен');
        onSuccess?.();
        onCancel();
    } catch (error) {
        if (error instanceof Error) {
            message.error(error.message);
        } else {
            message.error('Ошибка при добавлении стандартного образца');
        }
        console.error('Error:', error);
    } finally {
        setLoading(false);
    }
  };

  return (
    <ScreenContainer>
      <HeaderContainer>
        <ScreenTitle level={4} style={{ margin: 0 }}>Новая запись</ScreenTitle>
        <StyledLink>Добавить множество позиций</StyledLink>
      </HeaderContainer>
      
      <ScrollContent>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <StyledText>Наименование, тип, номер и категория СО</StyledText>
            <StyledSelect
              value={name}
              onChange={handleSelectChange(setName)}
              placeholder="выберите из списка"
              style={{ width: "100%" }}
              options={standardTypes}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Изготовитель СО</StyledText>
            <StyledSelect
              value={manufacturer}
              onChange={handleSelectChange(setManufacturer)}
              placeholder="выберите из списка"
              style={{ width: "100%" }}
              options={manufacturerOptions}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Назначение</StyledText>
            <StyledSelect
              value={purpose}
              onChange={handleSelectChange(setPurpose)}
              placeholder="выберите из списка"
              style={{ width: "100%" }}
              options={purposeOptions}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Показатель</StyledText>
            <StyledSelect
              value={indicator}
              onChange={handleSelectChange(setIndicator)}
              placeholder="выберите из списка"
              style={{ width: "100%" }}
              options={indicatorOptions}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Аттестованное значение м.д. %</StyledText>
            <StyledInput
              type="number"
              value={certifiedValue}
              onChange={(e) => setCertifiedValue(e.target.value)}
              placeholder="введите значение"
            />
          </Col>

          <Col span={24}>
            <StyledText>Погрешность а.з. абс. %</StyledText>
            <StyledInput
              type="number"
              value={errorValue}
              onChange={(e) => setErrorValue(e.target.value)}
              placeholder="введите значение"
            />
          </Col>

          <Col span={24}>
            <StyledText>Дополнительные сведения</StyledText>
            <StyledInput
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              placeholder="введите информацию"
            />
          </Col>

          <Col span={24}>
            <StyledText>Нормативный документ</StyledText>
            <StyledInput
              value={normativeDocument}
              onChange={(e) => setNormativeDocument(e.target.value)}
              placeholder="введите документ"
            />
          </Col>

          <Col span={24}>
            <StyledText>Срок годности экземпляра СО</StyledText>
            <StyledDatePicker
              value={expirationDate}
              onChange={handleDateChange(setExpirationDate)}
              style={{ width: '100%' }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
            />
          </Col>

          <Col span={24}>
            <StyledText>Уведомление об окончании срока годности</StyledText>
            <StyledSelect
              value={expirationNotification}
              onChange={handleSelectChange(setExpirationNotification)}
              placeholder="выберите из списка"
              style={{ width: "100%" }}
              options={notificationOptions}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Дата выпуска экземпляра СО</StyledText>
            <StyledDatePicker
              value={releaseDate}
              onChange={handleDateChange(setReleaseDate)}
              style={{ width: '100%' }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
            />
          </Col>

          <Col span={24}>
            <StyledText>Примечание</StyledText>
            <StyledInput
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="введите примечание"
            />
          </Col>

          <Col span={24}>
            <StyledText>Прекурсор</StyledText>
            <CheckboxField
              title="Да"
              checked={isPrecursor}
              onChange={setIsPrecursor}
            />
          </Col>
        </Row>
      </ScrollContent>

      <BottomButtons>
        <ButtonsRow>
          <Button block style={{ width: 178 }} onClick={onCancel}>Отмена</Button>
          <Button
            type="primary"
            block
            style={{ width: 178 }}
            onClick={handleSave}
            loading={loading}
          >
            Сохранить
          </Button>
        </ButtonsRow>
      </BottomButtons>
    </ScreenContainer>
  );
};

export default Screen;

