import { FC, useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Typography, Space, Select, DatePicker, message, InputNumber, Popconfirm, AutoComplete, Spin } from 'antd';
import styled from 'styled-components';
import { CalendarOutlined, PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { createBatch, updateBatch, deleteBatch, CertifiedValue, Batch, getStandardSamples, StandardSample, searchStandardSamples } from '../../api/batches';
import { debounce } from 'lodash';

const StyledForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 14px !important;
    }

    .ant-form-item-label {
        padding-bottom: 0px !important;
        
        > label {
            color: #8C8C8C !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            height: 20px !important;
        }
    }

    .ant-input, .ant-select-selector, .ant-picker {
        height: 40px !important;
        background-color: #F5F5F5 !important;
        border-radius: 4px !important;
    }

    .ant-input-number {
        width: 100%;
        height: 40px !important;
        background-color: #F5F5F5 !important;
        border-radius: 4px !important;

        .ant-input-number-input {
            height: 38px !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            display: flex !important;
            align-items: center !important;
        }

        .ant-input-number-input-wrap {
            height: 100% !important;
        }

        &::placeholder {
            display: flex !important;
            align-items: center !important;
        }
    }

    .ant-select {
        &.ant-select-single {
            height: 40px;
        }
        .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
            padding: 0 11px !important;
            .ant-select-selection-item {
                line-height: 40px !important;
            }
        }
        .ant-select-selection-placeholder {
            line-height: 40px !important;
        }
        .ant-select-arrow {
            inset-block-start: 50% !important;
            transform: translateY(-50%) !important;
            padding-top: 11px !important;
        }
    }

    .ant-picker {
        width: 100%;
    }

    .ant-input-affix-wrapper {
        background-color: #F5F5F5 !important;
        padding: 0 11px !important;
        border-radius: 4px !important;

        &:hover, &:focus, &-focused {
            background-color: #F5F5F5 !important;
        }

        .ant-input {
            background-color: #F5F5F5 !important;
        }
    }
`;

const AddButton = styled(Button)`
    width: 100%;
    margin: 8px 0;
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    height: 40px;
    
    &:hover {
        background-color: #E8E8E8 !important;
    }
`;

const DeleteText = styled(Typography.Text)`
    color: #ff4d4f;
    cursor: pointer;
    margin-left: 8px;
    
    &:hover {
        color: #ff7875;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
`;

// Стилизованный контейнер для кнопок в футере
const FooterContainer = styled.div`
    display: flex;
    width: 100%;
`;

const DeleteButton = styled(Button)`
    width: 80px;
    height: 40px;
    border-color: #ff4d4f;
`;

const SubmitButton = styled(Button)`
    flex: 1;
    height: 40px;
    margin-left: 8px;
`;

interface AddBatchModalProps {
    open: boolean;
    onCancel: () => void;
    onSuccess: () => void;
    editBatch?: Batch;
    onDelete?: () => void;
}

const AddBatchModal: FC<AddBatchModalProps> = ({ open, onCancel, onSuccess, editBatch, onDelete }) => {
    const [form] = Form.useForm();
    const [standardSamples, setStandardSamples] = useState<StandardSample[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState<{ value: string; label: string }[]>([]);
    
    useEffect(() => {
        if (open) {
            fetchStandardSamples();
        }
    }, [open]);
    
    useEffect(() => {
        if (open && editBatch) {
            const certifiedValues = editBatch.certified_values?.map(cv => ({
                certifiedValue: cv.value,
                certifiedValueUnit: cv.unit,
                error: cv.error,
                errorUnit: cv.error_unit
            })) || [{}];
            
            form.setFieldsValue({
                batchCode: editBatch.batch_code,
                sampleIndex: editBatch.batch_number,
                registrationDate: editBatch.registration_date ? dayjs(editBatch.registration_date) : dayjs(),
                soName: editBatch.so_name,
                gsoNumber: editBatch.gso_number,
                soCategory: editBatch.so_category,
                packaging: editBatch.packaging,
                expiryDate: editBatch.expiry_date ? dayjs(editBatch.expiry_date) : undefined,
                indicatorName: editBatch.certified_values?.[0]?.indicator_name || '',
                certifiedValues
            });
        } else if (open && !editBatch) {
            form.resetFields();
            form.setFieldsValue({
                registrationDate: dayjs(),
                certifiedValues: [{}]
            });
        }
    }, [open, editBatch, form]);

    const fetchStandardSamples = async () => {
        try {
            setIsLoading(true);
            const samples = await getStandardSamples();
            setStandardSamples(samples);
            setSearchOptions(samples.map(sample => ({ 
                value: sample.index, 
                label: `${sample.index} • ${sample.name}\n${sample.parameter} • ${sample.category} ${sample.gso_number}` 
            })));
        } catch (error) {
            console.error('Ошибка при загрузке стандартных образцов:', error);
            message.error('Не удалось загрузить список стандартных образцов');
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleSearch = debounce(async (value: string) => {
        if (value && value.length > 0) {
            try {
                setIsLoading(true);
                const searchResults = await searchStandardSamples(value);
                setSearchOptions(searchResults.map(sample => ({ 
                    value: sample.index, 
                    label: `${sample.index} • ${sample.name}\n${sample.parameter} • ${sample.category} ${sample.gso_number}` 
                })));
            } catch (error) {
                console.error('Ошибка при поиске стандартных образцов:', error);
            } finally {
                setIsLoading(false);
            }
        } else if (!value) {
            // Сбросить к полному списку, если поле поиска пустое
            setSearchOptions(standardSamples.map(sample => ({ 
                value: sample.index, 
                label: `${sample.index} • ${sample.name}\n${sample.parameter} • ${sample.category} ${sample.gso_number}` 
            })));
        }
    }, 300);
    
    const handleStandardSampleSelect = (value: string) => {
        const selectedSample = standardSamples.find(sample => sample.index === value);
        if (selectedSample) {
            form.setFieldsValue({
                soName: selectedSample.name,
                gsoNumber: selectedSample.gso_number,
                soCategory: selectedSample.category,
                indicatorName: selectedSample.parameter,
                certifiedValues: form.getFieldValue('certifiedValues').map((cv: any, index: number) => ({
                    ...cv,
                    certifiedValueUnit: selectedSample.measurement_unit,
                    errorUnit: selectedSample.error_unit
                }))
            });
        }
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            
            if (!values.certifiedValues || values.certifiedValues.length === 0 || 
                !values.certifiedValues[0].certifiedValue || 
                !values.certifiedValues[0].certifiedValueUnit || 
                !values.certifiedValues[0].error || 
                !values.certifiedValues[0].errorUnit) {
                message.error('Необходимо заполнить хотя бы одно аттестованное значение');
                return;
            }
            
            const certifiedValues: CertifiedValue[] = values.certifiedValues
                .filter((cv: any) => cv.certifiedValue && cv.certifiedValueUnit && cv.error && cv.errorUnit)
                .map((cv: any) => ({
                    indicator_name: values.indicatorName || 'Неизвестный показатель',
                    value: Number(cv.certifiedValue),
                    unit: cv.certifiedValueUnit,
                    error: Number(cv.error),
                    error_unit: cv.errorUnit
                }));
            
            if (certifiedValues.length === 0) {
                message.error('Необходимо заполнить хотя бы одно аттестованное значение');
                return;
            }
            
            const batchData = {
                batch_number: values.sampleIndex,
                batch_code: values.batchCode,
                registration_date: values.registrationDate.format('YYYY-MM-DD'),
                target_value: 0,
                unit: certifiedValues[0]?.unit || 'г',
                planned_volume: 0,
                
                so_name: values.soName,
                gso_number: values.gsoNumber,
                so_category: values.soCategory,
                packaging: values.packaging,
                expiry_date: values.expiryDate ? values.expiryDate.format('YYYY-MM-DD') : undefined,
                
                certified_values: certifiedValues
            };
            
            if (editBatch) {
                await updateBatch(editBatch.id, batchData);
                message.success('Партия успешно обновлена');
            } else {
                await createBatch(batchData);
                message.success('Партия успешно создана');
            }
            
            form.resetFields();
            onSuccess();
            onCancel();
        } catch (error: any) {
            console.error('Failed:', error);
            
            if (error.response && error.response.data) {
                if (typeof error.response.data.detail === 'string') {
                    message.error(error.response.data.detail);
                } else if (Array.isArray(error.response.data.detail)) {
                    const errorMessages = error.response.data.detail.map((err: any) => 
                        `${err.loc.join('.')} - ${err.msg}`
                    ).join('; ');
                    message.error(`Ошибка валидации: ${errorMessages}`);
                } else {
                    message.error(editBatch ? 'Ошибка при обновлении партии' : 'Ошибка при создании партии');
                }
            } else {
                message.error(editBatch ? 'Ошибка при обновлении партии' : 'Ошибка при создании партии');
            }
        }
    };

    const handleDelete = async () => {
        try {
            if (editBatch) {
                await deleteBatch(editBatch.id);
                message.success('Партия успешно удалена');
                form.resetFields();
                if (onDelete) {
                    onDelete();
                }
                onCancel();
            }
        } catch (error: any) {
            console.error('Failed to delete:', error);
            
            if (error.response && error.response.data) {
                if (typeof error.response.data.detail === 'string') {
                    message.error(error.response.data.detail);
                } else {
                    message.error('Ошибка при удалении партии');
                }
            } else {
                message.error('Ошибка при удалении партии');
            }
        }
    };

    return (
        <Modal
            title={editBatch ? "Редактирование партии" : "Добавление новой партии"}
            open={open}
            onCancel={onCancel}
            footer={[
                <FooterContainer key="footer-container">
                    {editBatch && (
                        <Popconfirm
                            key="delete"
                            title="Удаление партии"
                            description="Вы уверены, что хотите удалить эту партию?"
                            onConfirm={handleDelete}
                            okText="Да"
                            cancelText="Нет"
                            placement="topRight"
                        >
                            <DeleteButton 
                                danger 
                                icon={<DeleteOutlined />} 
                            />
                        </Popconfirm>
                    )}
                    <SubmitButton 
                        type="primary" 
                        onClick={handleOk}
                    >
                        {editBatch ? "Сохранить изменения" : "Создать партию"}
                    </SubmitButton>
                </FooterContainer>
            ]}
            width={400}
            centered
            bodyStyle={{ 
                maxHeight: '70vh', 
                overflow: 'auto',
                msOverflowStyle: 'none',  /* IE и Edge */
                scrollbarWidth: 'none'     /* Firefox */
            }}
        >
            <style>
                {`
                    .modal-body-custom::-webkit-scrollbar {
                        display: none;  /* Chrome, Safari и Opera */
                    }
                    
                    .autocomplete-dropdown-with-long-items .ant-select-item-option-content {
                        white-space: normal;
                        word-break: break-word;
                        line-height: 1.5;
                        padding: 4px 0;
                    }
                    
                    .autocomplete-dropdown-with-long-items .ant-select-item {
                        padding: 8px 12px;
                    }
                    
                    .autocomplete-dropdown-with-long-items .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
                        background-color: #f5f5f5;
                    }
                `}
            </style>
            <Space direction="vertical" size={16} style={{ width: '100%', paddingTop: '16px' }} className="modal-body-custom">
                <StyledForm 
                    form={form} 
                    layout="vertical"
                    initialValues={{
                        registrationDate: dayjs(),
                        certifiedValues: [{}]
                    }}
                >
                    <Form.Item
                        label="Шифр партии"
                        name="batchCode"
                        required={false}
                        rules={[{ required: true, message: 'Введите шифр партии' }]}
                    >
                        <Input placeholder="Введите шифр партии" />
                    </Form.Item>

                    <Form.Item
                        label="Дата регистрации"
                        name="registrationDate"
                        required={false}
                        rules={[{ required: true, message: 'Выберите дату регистрации' }]}
                    >
                        <DatePicker 
                            format="DD.MM.YYYY"
                            placeholder="Выберите дату"
                            suffixIcon={<CalendarOutlined style={{ color: '#1890ff' }} />}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Индекс образца"
                        name="sampleIndex"
                        required={false}
                        rules={[{ required: true, message: 'Введите индекс образца' }]}
                    >
                        <AutoComplete
                            placeholder="Введите индекс или наименование образца"
                            options={searchOptions}
                            onSelect={handleStandardSampleSelect}
                            onSearch={handleSearch}
                            dropdownStyle={{
                                maxWidth: '400px'
                            }}
                            dropdownClassName="autocomplete-dropdown-with-long-items"
                            suffixIcon={isLoading ? <LoadingOutlined spin /> : null}
                            notFoundContent={isLoading ? <Spin size="small" /> : "Не найдено"}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Наименование СО"
                        name="soName"
                        required={false}
                        rules={[{ required: true, message: 'Введите наименование СО' }]}
                    >
                        <Input placeholder="Введите наименование СО" />
                    </Form.Item>

                    <Form.Item
                        label="Номер ГСО"
                        name="gsoNumber"
                        required={false}
                        rules={[{ required: true, message: 'Введите номер ГСО' }]}
                    >
                        <Input placeholder="Введите номер ГСО" />
                    </Form.Item>

                    <Form.Item
                        label="Категория СО"
                        name="soCategory"
                        required={false}
                        rules={[{ required: true, message: 'Выберите категорию СО' }]}
                    >
                        <Select
                            placeholder="Выберите категорию СО"
                            options={[
                                { value: 'ГСО', label: 'ГСО' },
                                { value: 'ОСО', label: 'ОСО' },
                                { value: 'СОП', label: 'СОП' }
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Фасовка"
                        name="packaging"
                        required={false}
                        rules={[{ required: true, message: 'Введите фасовку' }]}
                    >
                        <Input placeholder="Введите фасовку" />
                    </Form.Item>

                    <Form.Item
                        label="Наименование показателя"
                        name="indicatorName"
                        required={false}
                        rules={[{ required: true, message: 'Введите наименование показателя' }]}
                    >
                        <Input placeholder="Введите наименование показателя" />
                    </Form.Item>

                    <Form.List name="certifiedValues">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={field.key} style={{ marginBottom: '16px' }}>
                                        {index > 0 && <div style={{ borderTop: '1px solid #f0f0f0', margin: '16px 0' }} />}

                                        <HeaderContainer>
                                            <Typography.Text type="secondary" style={{ fontSize: '12px', fontWeight: 500 }}>
                                                {`Аттестованное значение ${index + 1}`}
                                            </Typography.Text>
                                            {index > 0 && (
                                                <DeleteText onClick={() => remove(field.name)}>
                                                    Удалить
                                                </DeleteText>
                                            )}
                                        </HeaderContainer>

                                        <Form.Item
                                            name={[field.name, 'certifiedValue']}
                                            required={false}
                                            rules={[{ required: true, message: 'Введите аттестованное значение' }]}
                                        >
                                            <InputNumber 
                                                style={{ width: '100%' }} 
                                                placeholder="Введите аттестованное значение" 
                                                controls={false}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Ед. изм. АЗ"
                                            name={[field.name, 'certifiedValueUnit']}
                                            required={false}
                                            rules={[{ required: true, message: 'Выберите единицу измерения' }]}
                                        >
                                            <Select
                                                placeholder="Выберите ед. измерения"
                                                options={[
                                                    { value: '%', label: '%' },
                                                    { value: 'мг', label: 'мг' },
                                                    { value: 'мл', label: 'мл' },
                                                    { value: 'г', label: 'г' },
                                                    { value: 'кг', label: 'кг' },
                                                    { value: 'л', label: 'л' }
                                                ]}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Погрешность АЗ"
                                            name={[field.name, 'error']}
                                            required={false}
                                            rules={[{ required: true, message: 'Введите погрешность' }]}
                                        >
                                            <InputNumber 
                                                style={{ width: '100%' }} 
                                                placeholder="Введите погрешность" 
                                                controls={false}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Ед. изм. погрешности АЗ"
                                            name={[field.name, 'errorUnit']}
                                            required={false}
                                            rules={[{ required: true, message: 'Выберите единицу измерения' }]}
                                        >
                                            <Select
                                                placeholder="Выберите ед. измерения"
                                                options={[
                                                    { value: '%', label: '%' },
                                                    { value: 'мг', label: 'мг' },
                                                    { value: 'мл', label: 'мл' },
                                                    { value: 'г', label: 'г' },
                                                    { value: 'кг', label: 'кг' },
                                                    { value: 'л', label: 'л' }
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                ))}

                                <AddButton 
                                    type="text"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                >
                                    Добавить еще значение АЗ
                                </AddButton>
                            </>
                        )}
                    </Form.List>

                    <Form.Item
                        label="Срок годности"
                        name="expiryDate"
                        required={false}
                        rules={[{ required: true, message: 'Выберите срок годности' }]}
                    >
                        <DatePicker 
                            format="DD.MM.YYYY"
                            placeholder="Выберите дату"
                            suffixIcon={<CalendarOutlined style={{ color: '#1890ff' }} />}
                        />
                    </Form.Item>
                </StyledForm>
            </Space>
        </Modal>
    );
};

export default AddBatchModal; 