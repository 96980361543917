import { Button, Col, Row, Typography, Modal } from "antd";
import React, { useState } from "react";
import styled from 'styled-components';

const { Text } = Typography;

const FormatButton = styled(Button)<{ $isSelected?: boolean }>`
    background: ${props => props.$isSelected ? '#E6F4FF' : 'transparent'};
    border: ${props => props.$isSelected ? '1px solid #1890FF' : '1px solid transparent'};
    color: ${props => props.$isSelected ? '#1890FF' : 'rgba(0, 0, 0, 0.88)'};
    width: 120px !important;
    height: 22px !important;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        background: ${props => props.$isSelected ? '#E6F4FF' : '#F5F5F5'} !important;
        border-color: ${props => props.$isSelected ? '#1890FF' : 'transparent'} !important;
        color: ${props => props.$isSelected ? '#1890FF' : 'rgba(0, 0, 0, 0.88)'} !important;
    }
`;

export type ExportFormat = 'CSV' | 'XLSX' | 'PDF';

interface ExportTypesProps {
    open: boolean;
    onCancel: () => void;
    onExport: (format: ExportFormat) => void;
    selectedRows: React.Key[];
}

const ExportTypes: React.FC<ExportTypesProps> = ({ 
    open, 
    onCancel, 
    onExport,
    selectedRows 
}): JSX.Element => {
    const [selectedFormat, setSelectedFormat] = useState<ExportFormat | null>(null);
    const [loading, setLoading] = useState(false);

    const handleExport = async () => {
        if (!selectedFormat || selectedRows.length === 0) return;
        
        setLoading(true);
        try {
            await onExport(selectedFormat);
            onCancel();
        } catch (error) {
            console.error('Export error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={<Text strong>Экспорт</Text>}
            open={open}
            onCancel={onCancel}
            footer={null}
            width={303}
            centered
        >
            <Row justify="center" style={{ marginBottom: 16 }}>
                <Text>Выбрано записей: {selectedRows.length}</Text>
            </Row>
            <Row justify="center" style={{ marginBottom: 4 }}>
                <FormatButton 
                    type="text"
                    $isSelected={selectedFormat === 'CSV'}
                    onClick={() => setSelectedFormat('CSV')}
                >
                    CSV
                </FormatButton>
            </Row>
            <Row justify="center" style={{ marginBottom: 4 }}>
                <FormatButton 
                    type="text"
                    $isSelected={selectedFormat === 'XLSX'}
                    onClick={() => setSelectedFormat('XLSX')}
                >
                    XLSX
                </FormatButton>
            </Row>
            <Row justify="center" style={{ marginBottom: 16 }}>
                <FormatButton 
                    type="text"
                    $isSelected={selectedFormat === 'PDF'}
                    onClick={() => setSelectedFormat('PDF')}
                >
                    PDF
                </FormatButton>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Button 
                        block 
                        onClick={onCancel} 
                        style={{ borderRadius: 4, height: 40 }}
                        disabled={loading}
                    >
                        Отмена
                    </Button>
                </Col>
                <Col span={12}>
                    <Button 
                        block 
                        type="primary" 
                        style={{ borderRadius: 4, height: 40 }}
                        onClick={handleExport}
                        disabled={!selectedFormat || selectedRows.length === 0 || loading}
                        loading={loading}
                    >
                        Экспорт
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default ExportTypes;
