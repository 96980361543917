import { FunctionComponent, useState, useCallback, useMemo, useEffect } from 'react';
import styled from "styled-components";
import HeaderLab from './Header';
import { Typography, Layout, Space, Button, theme, Segmented, Input, Popover, message } from 'antd';
import { ExportOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import MeasuringInstrumentsTable, { MeasuringInstrumentType, testMeasuringData } from '../tables/MeasuringInstruments';
import TestInstrumentsTable, { TestInstrumentType, testTestingData } from '../tables/TestInstruments';
import HelpInstrumentsTable, { HelpInstrumentType, testHelpData } from '../tables/HelpInstruments';
import debounce from 'lodash/debounce';
import AddInstruments from '../../components/AddInstruments';
import VerificationRequest from '../../components/VerificationRequest';
import ExportTypes, { ExportFormat } from '../../components/ExportTypes';
import ExportProcess from '../../components/ExportProcess';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { instrumentsApi } from '../../api/instruments';

const { Title } = Typography;
const { Content } = Layout;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const ControlsBlock = styled.div`
    // margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const SearchInput = styled(Input)`
  width: 45px;
  height: 40px;
  border-color: #d9d9d9;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
  padding: 0;
  
  &.expanded {
    width: 240px;
    padding: 4px 11px;
    border-color: #d9d9d9;
  }

  &:hover {
    border-color: #d9d9d9;
  }

  &:focus {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .ant-input {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .ant-input-prefix {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  input {
    line-height: normal !important;
    height: 100% !important;
  }

  &:not(.expanded) {
    .ant-input-prefix {
      margin-right: 0;
    }
    
    .ant-input {
      width: 0;
      padding: 0;
    }
  }

  &.expanded .ant-input-prefix {
    width: auto;
    margin-right: 8px;
  }
`;

const StyledPopover = styled(Popover)`
    .ant-popover-inner {
        padding: 0 !important;
        border-radius: 8px;
    }
    
    .ant-popover-arrow {
        display: none;
    }
`;

// Общий интерфейс для всех типов инструментов
interface BaseInstrument {
    key: React.Key;
    id: number;
}

// Тип объединения всех типов инструментов
type InstrumentType = MeasuringInstrumentType | TestInstrumentType | HelpInstrumentType;

const Laboratory: FunctionComponent = () => {
    const { token } = useToken();
    const [activeSegment, setActiveSegment] = useState(() => {
        const savedSegment = localStorage.getItem('instruments-active-segment');
        return savedSegment || 'Средства измерения (СИ)';  // дефолтное значение
    });
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isAddVisible, setIsAddVisible] = useState(false);
    const [isVerificationVisible, setIsVerificationVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
    const [isExportVisible, setIsExportVisible] = useState(false);
    const [isExportProcessVisible, setIsExportProcessVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    // Состояния для данных таблиц - инициализируем пустыми массивами
    const [testInstrumentsData, setTestInstrumentsData] = useState<TestInstrumentType[]>([]);
    const [helpInstrumentsData, setHelpInstrumentsData] = useState<HelpInstrumentType[]>([]);
    const [measuringData, setMeasuringData] = useState<MeasuringInstrumentType[]>([]);

    const { laboratoryId } = useParams<{ laboratoryId: string }>();

    useEffect(() => {
        if (laboratoryId) {
            fetchInstruments();
        }
    }, [laboratoryId, activeSegment]);

    const fetchInstruments = async () => {
        setIsLoading(true);
        try {
            let endpoint;
            switch (activeSegment) {
                case 'Средства измерения (СИ)':
                    endpoint = `/instruments/laboratory/${laboratoryId}/measuring`;
                    const measuringResponse = await axiosInstance.get(endpoint);
                    setMeasuringData(measuringResponse.data);
                    break;
                case 'Испытательное (ИО)':
                    endpoint = `/instruments/laboratory/${laboratoryId}/testing`;
                    const testingResponse = await axiosInstance.get(endpoint);
                    setTestInstrumentsData(testingResponse.data);
                    break;
                case 'Вспомогательное (ВО)':
                    endpoint = `/instruments/laboratory/${laboratoryId}/helping`;
                    const helpingResponse = await axiosInstance.get(endpoint);
                    setHelpInstrumentsData(helpingResponse.data);
                    break;
            }
        } catch (error) {
            console.error('Error fetching instruments:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Функции поиска для каждого типа данных
    const searchInTestInstrumentsFields = useCallback((item: TestInstrumentType, value: string) => {
        const searchFields = [
            item.status,
            item.characteristics,
            item.testName,
            item.equipmentName,
            item.manufacturer,
            item.technicalSpecs,
            String(item.commissioningYear),
            item.documentInfo,
            item.ownershipDocument,
            item.location,
            item.notes
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    const searchInHelpInstrumentsFields = useCallback((item: HelpInstrumentType, value: string) => {
        const searchFields = [
            item.status,
            item.name,
            item.manufacturer,
            String(item.commissioningYear),
            item.purpose,
            item.location,
            item.ownershipDocument,
            item.notes
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    // Отдельная функция для немедленной фильтрации
    const filterDataImmediate = (value: string) => {
        if (!value) {
            setTestInstrumentsData(testTestingData);
            setHelpInstrumentsData(testHelpData);
            return;
        }

        switch (activeSegment) {
            case 'Испытательное (ИО)':
                const filteredTestInstruments = testTestingData.filter(item => 
                    searchInTestInstrumentsFields(item, value)
                );
                setTestInstrumentsData(filteredTestInstruments);
                break;
            case 'Вспомогательное (ВО)':
                const filteredHelpInstruments = testHelpData.filter(item => 
                    searchInHelpInstrumentsFields(item, value)
                );
                setHelpInstrumentsData(filteredHelpInstruments);
                break;
        }
    };

    // Обработчик изменения сегмента
    const handleSegmentChange = (value: string) => {
        setActiveSegment(value);
        setSelectedRows([]); // Сбрасываем выбранные строки при изменении раздела
    };

    const debouncedFilter = useMemo(
        () => debounce(filterDataImmediate, 300),
        [filterDataImmediate]
    );

    const handleSearch = useCallback((value: string) => {
        setSearchValue(value);
        debouncedFilter(value);
    }, [debouncedFilter]);

    const handleSelectionChange = (newSelectedRows: React.Key[]) => {
        setSelectedRows(newSelectedRows);
    };

    const handleInstrumentChange = useCallback(() => {
        if (laboratoryId) {
            fetchInstruments();
        }
    }, [laboratoryId, fetchInstruments]);

    const renderTable = () => {
        switch (activeSegment) {
            case 'Средства измерения (СИ)':
                return (
                    <MeasuringInstrumentsTable 
                        searchValue={searchValue} 
                        onSelectionChange={handleSelectionChange}
                        loading={isLoading}
                        data={measuringData}
                        onSuccess={handleInstrumentChange}
                    />
                );
            case 'Испытательное (ИО)':
                return (
                    <TestInstrumentsTable 
                        data={testInstrumentsData} 
                        searchValue={searchValue}
                        onSelectionChange={handleSelectionChange}
                        loading={isLoading}
                        onSuccess={handleInstrumentChange}
                    />
                );
            case 'Вспомогательное (ВО)':
                return (
                    <HelpInstrumentsTable 
                        data={helpInstrumentsData} 
                        searchValue={searchValue}
                        onSelectionChange={handleSelectionChange}
                        loading={isLoading}
                        onSuccess={handleInstrumentChange}
                    />
                );
            default:
                return null;
        }
    };

    const selectedItems = useMemo(() => {
        type InstrumentType = MeasuringInstrumentType | TestInstrumentType | HelpInstrumentType;
        
        const activeData: InstrumentType[] = activeSegment === 'Средства измерения (СИ)' 
            ? measuringData 
            : activeSegment === 'Испытательное (ИО)' 
                ? testInstrumentsData 
                : helpInstrumentsData;

        return activeData
            .filter((item: InstrumentType) => selectedRows.includes(item.key))
            .map((item: InstrumentType, index: number) => ({
                key: item.key,
                number: index + 1,
                name: 'equipmentName' in item ? item.equipmentName : item.name,
                type: 'characteristics' in item ? item.characteristics : '',
                verificationType: 'Первичная',
                note: '',
                instrument_id: item.id
            }));
    }, [selectedRows, activeSegment, measuringData, testInstrumentsData, helpInstrumentsData]);

    const handleExport = async (format: ExportFormat) => {
        setIsExportVisible(false);
        setIsExportProcessVisible(true);
        
        try {
            let endpoint;
            // Получаем данные активной таблицы
            const activeData: InstrumentType[] = activeSegment === 'Средства измерения (СИ)' 
                ? measuringData 
                : activeSegment === 'Испытательное (ИО)' 
                    ? testInstrumentsData 
                    : helpInstrumentsData;
            
            // Получаем id выбранных записей
            const selectedIds = activeData
                .filter((item: BaseInstrument) => selectedRows.includes(item.key))
                .map((item: BaseInstrument) => item.id);

            switch (activeSegment) {
                case 'Средства измерения (СИ)':
                    endpoint = instrumentsApi.exportMeasuringInstruments;
                    break;
                case 'Испытательное (ИО)':
                    endpoint = instrumentsApi.exportTestingInstruments;
                    break;
                case 'Вспомогательное (ВО)':
                    endpoint = instrumentsApi.exportHelpingInstruments;
                    break;
                default:
                    throw new Error('Unknown segment');
            }
            
            const response = await endpoint(selectedIds, format);
            
            // Create a blob URL from the response data
            const blob = new Blob([response.data], { 
                type: response.headers['content-type'] 
            });
            const url = window.URL.createObjectURL(blob);
            
            // Create a link and trigger download
            const link = document.createElement('a');
            link.href = url;
            
            // Get filename from Content-Disposition header or use default
            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition
                ? contentDisposition.split('filename=')[1].replace(/"/g, '')
                : `instruments.${format.toLowerCase()}`;
                
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            
        } catch (error) {
            console.error('Export error:', error);
            message.error('Ошибка при экспорте');
        } finally {
            setIsExportProcessVisible(false);
        }
    };

    // Сохраняем активный сегмент при его изменении
    useEffect(() => {
        localStorage.setItem('instruments-active-segment', activeSegment);
    }, [activeSegment]);

    const handleAddSuccess = () => {
        fetchInstruments();
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderLab />
            <StyledContent>
                <ControlsBlock style={{ marginBottom: 24 }}>
                    <Segmented 
                        size="large" 
                        options={['Средства измерения (СИ)', 'Испытательное (ИО)', 'Вспомогательное (ВО)']} 
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                        value={activeSegment}
                        onChange={handleSegmentChange}
                    />
                    <HeadingExtra>
                        <SearchInput
                            className={isSearchExpanded ? "expanded" : ""}
                            placeholder={isSearchExpanded ? "Поиск" : ""}
                            prefix={<SearchOutlined style={{ color: token['blue-6'] }} />}
                            onFocus={() => setIsSearchExpanded(true)}
                            onChange={(e) => handleSearch(e.target.value)}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    setIsSearchExpanded(false);
                                }
                            }}
                            style={{ height: '40px' }}
                            value={searchValue}
                        />
                        <Button 
                            icon={<ExportOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px', width: '45px' }}
                            onClick={() => setIsExportVisible(true)}
                        />
                        <StyledPopover 
                            content={
                                <AddInstruments 
                                    onCancel={() => setIsAddVisible(false)}
                                    type={activeSegment.toString()}
                                    laboratoryId={laboratoryId || ''}
                                    onSuccess={handleAddSuccess}
                                />
                            }
                            trigger="click"
                            open={isAddVisible}
                            onOpenChange={setIsAddVisible}
                            placement="bottomLeft"
                            overlayStyle={{ 
                                padding: 0,
                                zIndex: 1100,
                                marginTop: '57px'
                            }}
                            overlayInnerStyle={{ 
                                padding: 0
                            }}
                            arrow={false}
                        >
                            <Button 
                                icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                                style={{ height: '40px' }}
                            >
                                Добавить запись
                            </Button>
                        </StyledPopover>
                        <Button 
                            icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px' }}
                            onClick={() => setIsVerificationVisible(true)}
                        >
                            Заявка на поверку
                        </Button>
                    </HeadingExtra>
                </ControlsBlock>
                {renderTable()}
                <ExportTypes 
                    open={isExportVisible}
                    onCancel={() => setIsExportVisible(false)}
                    onExport={handleExport}
                    selectedRows={selectedRows}
                />
                <ExportProcess 
                    open={isExportProcessVisible}
                />
                <VerificationRequest 
                    open={isVerificationVisible}
                    onCancel={() => setIsVerificationVisible(false)}
                    selectedInstruments={selectedItems}
                />
            </StyledContent>
        </Layout>
    );
};

export default Laboratory; 