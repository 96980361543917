import { CloseOutlined, DeleteOutlined, FileOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Table, Modal, Space, Popover } from "antd";
import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import ExportTypes, { ExportFormat } from './ExportTypes';
import ExportProcess from './ExportProcess';
import SuccessRequest from './SuccessRequest';

const { Option } = Select;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table-tbody > tr > td {
    height: 55px;
    padding: 8px;
  }
  
  .ant-table-body {
    height: 260px !important;
    overflow-y: auto !important;
  }
`;

interface DataType {
    key: React.Key;
    number: number;
    name: string;
    type?: string;
    quantity?: string;
    note?: string;
}

interface SelectedReagent {
    key: React.Key;
    number: number;
    name: string;
}

interface PurchaseRequestProps {
    onCancel: () => void;
    open: boolean;
    selectedReagents: SelectedReagent[];
}

const PurchaseRequest: React.FC<PurchaseRequestProps> = ({ onCancel, open, selectedReagents }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [isExportVisible, setIsExportVisible] = useState(false);
    const [isExportProcessVisible, setIsExportProcessVisible] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);

    useEffect(() => {
        setDataSource(selectedReagents.map(reagent => ({
            key: reagent.key,
            number: reagent.number,
            name: reagent.name
        })));
    }, [selectedReagents]);

    const handleDelete = (key: React.Key) => {
        setDataSource(prev => prev.filter(item => item.key !== key));
    };

    const handleAdd = () => {
        const newItem: DataType = {
            key: Date.now(),
            number: dataSource.length + 1,
            name: '',
            type: 'Реактив',
            quantity: '',
            note: ''
        };
        setDataSource([...dataSource, newItem]);
    };

    const handleExport = (format: ExportFormat) => {
        setIsExportVisible(false);
        setIsExportProcessVisible(true);
        
        setTimeout(() => {
            setIsExportProcessVisible(false);
        }, 3000);
    };

    const handleSubmit = () => {
        setIsSuccessVisible(true);
    };

    const handleSuccessClose = () => {
        setIsSuccessVisible(false);
        onCancel(); // закрываем основное окно заявки
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "№ п/п",
            dataIndex: "number",
            key: "number",
            width: 87,
        },
        {
            title: "Тип",
            dataIndex: "type",
            key: "type",
            width: 232,
            render: () => (
                <Select defaultValue="Реактив" style={{ width: "100%" }}>
                    <Option value="Реактив">Реактив</Option>
                    <Option value="Прекурсор">Прекурсор</Option>
                    <Option value="Стандартный образец">Стандартный образец</Option>
                </Select>
            ),
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            width: 232,
            render: (text: string) => <Input defaultValue={text} placeholder="введите название" />,
        },
        {
            title: "Количество",
            dataIndex: "quantity",
            key: "quantity",
            width: 232,
            render: () => <Input placeholder="введите количество" />,
        },
        {
            title: "Примечание",
            dataIndex: "note",
            key: "note",
            width: 232,
            render: () => <Input placeholder="описание" />,
        },
        {
            key: "action",
            width: 48,
            render: (_: unknown, record: DataType) => (
                <StyledButton 
                    type="text" 
                    icon={<DeleteOutlined style={{ color: '#f5222d' }} />}
                    style={{ width: '32px', padding: 0 }}
                    onClick={() => handleDelete(record.key)}
                />
            ),
        },
    ];

    return (
        <Modal
            title="Заявка на закупку"
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
            centered
        >
            <StyledTable
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                bordered
                style={{ marginTop: 16 }}
                scroll={{ y: 300 }}
            />
            <ButtonsRow justify="space-between" style={{ marginTop: 16 }}>
                <Space size={8}>
                    <StyledButton onClick={onCancel}>Назад</StyledButton>
                    <StyledButton 
                        icon={<PlusOutlined />}
                        onClick={handleAdd}
                    >
                        Добавить
                    </StyledButton>
                </Space>
                <Space size={8}>
                    <StyledButton 
                        icon={<FileOutlined />}
                        onClick={() => setIsExportVisible(true)}
                    >
                        Экспортировать
                    </StyledButton>
                    <StyledButton 
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Оформить заявку в СпектроХим
                    </StyledButton>
                </Space>
            </ButtonsRow>
            <ExportTypes 
                open={isExportVisible}
                onCancel={() => setIsExportVisible(false)}
                onExport={handleExport}
                selectedRows={dataSource.map(item => item.key)}
            />
            <ExportProcess 
                open={isExportProcessVisible}
            />
            <SuccessRequest 
                open={isSuccessVisible}
                onClose={handleSuccessClose}
            />
        </Modal>
    );
};

export default PurchaseRequest;
