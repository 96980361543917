import { Button, Col, DatePicker, Form, Input, Row, Select, Typography, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import dayjs from 'dayjs';
import { msiApi } from '../api/msi';

const { Text } = Typography;

const ScreenContainer = styled.div`
  width: 400px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const ScreenTitle = styled(Typography.Title)`
  margin: 16px !important;
  font-size: 16px !important;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
  width: 100%;
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  padding: 16px;
  display: flex;
  justify-content: center;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
    width: 178px;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0 !important;
    
    > label {
      font-size: 12px;
      color: #8c8c8c;
      font-weight: 500;
      height: auto;
      margin-bottom: 0px !important;
      line-height: 0 !important;
      
      &::before {
        display: none !important;
      }
      
      &::after {
        display: none;
      }
    }
  }
  
  .ant-form-item-control {
    margin-top: 0px;
  }
  
  .ant-form-item-explain {
    min-height: 0;
    margin-top: 0px;
    font-size: 12px;
  }
`;

interface AddMSIProps {
  onCancel: () => void;
  laboratoryId: string;
  onSuccess: () => void;
}

const methodOptions = [
  'Метод 1',
  'Метод 2',
  'Метод 3',
  'Метод 4'
].map(item => ({ label: item, value: item }));

const AddMSI: React.FC<AddMSIProps> = ({ onCancel, laboratoryId, onSuccess }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);

            // Преобразуем дату в строку ISO
            if (values.deadline) {
                values.deadline = values.deadline.toISOString().split('T')[0];
            }

            // Добавляем laboratory_id и completed
            const submitData = {
                ...values,
                laboratory_id: parseInt(laboratoryId),
                completed: false
            };

            console.log('Submitting data:', submitData);

            const response = await msiApi.createMSI(submitData);
            console.log('Response:', response);
            
            message.success('Запись успешно добавлена');
            onSuccess();
            onCancel();
        } catch (error: any) {
            console.error('Error adding MSI:', error);
            if (error.response) {
                message.error(`Ошибка: ${error.response.data?.detail || error.response.statusText}`);
            } else if (error.request) {
                message.error('Ошибка соединения с сервером');
            } else {
                message.error(error.message || 'Ошибка при добавлении записи');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <ScreenContainer>
            <ScreenTitle level={4}>Новая запись</ScreenTitle>
            
            <ScrollContent>
                <StyledForm form={form} layout="vertical">
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <StyledFormItem
                                name="number"
                                label="№ п/п"
                                rules={[{ required: true, message: 'Введите номер' }]}
                            >
                                <StyledInput type="number" placeholder="введите номер" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="measurement_method"
                                label="Метод измерений"
                                rules={[{ required: true, message: 'Выберите метод' }]}
                            >
                                <StyledSelect
                                    placeholder="выберите метод"
                                    options={methodOptions}
                                    showSearch
                                    allowClear
                                />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="measurement_methodology"
                                label="Методика измерений"
                                rules={[{ required: true, message: 'Введите методику' }]}
                            >
                                <StyledInput placeholder="введите методику" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="research_object"
                                label="Объект исследований"
                                rules={[{ required: true, message: 'Введите объект' }]}
                            >
                                <StyledInput placeholder="введите объект" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="characteristic"
                                label="Определяемая характеристика"
                                rules={[{ required: true, message: 'Введите характеристику' }]}
                            >
                                <StyledInput placeholder="введите характеристику" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="employee"
                                label="Участвующий сотрудник"
                                rules={[{ required: true, message: 'Введите сотрудника' }]}
                            >
                                <StyledInput placeholder="введите сотрудника" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="provider"
                                label="Провайдер МСИ"
                                rules={[{ required: true, message: 'Введите провайдера' }]}
                            >
                                <StyledInput placeholder="введите провайдера" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="deadline"
                                label="Сроки проведения"
                                rules={[{ required: true, message: 'Выберите дату' }]}
                            >
                                <StyledDatePicker format="DD.MM.YYYY" />
                            </StyledFormItem>
                        </Col>
                    </Row>
                </StyledForm>
            </ScrollContent>

            <ButtonsRow>
                <Button onClick={onCancel} disabled={loading}>Отмена</Button>
                <Button type="primary" onClick={handleSubmit} loading={loading}>
                    Сохранить
                </Button>
            </ButtonsRow>
        </ScreenContainer>
    );
};

export default AddMSI;

