import { FunctionComponent, useEffect, useState } from 'react';
import styled from "styled-components";
import HeaderAdmin from './HeaderAdmin';
import { Button, Space, Layout, Typography, theme, Table, Avatar, Switch, Select, Modal, Input, Form, Tag, Checkbox, Row, Col, message } from 'antd';
import { SearchOutlined, PlusOutlined, FilterOutlined, ExportOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';


const { Content } = Layout;
const { Title } = Typography;
const { useToken } = theme;


const HeadingExtra = styled(Space)`
    height: 40px;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    height: 100vh;
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: #fafafa;
    color: #595959 !important;
  }
    padding: 16px 0;
`;

const TagContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const StyledTag = styled(Tag)<{ $tagType: string; $token: any }>`
    width: max-content;
    margin: 0 !important;
    border-radius: 2px;
    color: ${props => {
        switch (props.$tagType) {
            case 'Фармакология':
                return props.$token['blue-6'];
            case 'Геология':
                return props.$token['cyan-6'];
            case 'Экология':
                return props.$token['green-6'];
            case 'Мед. оборудование':
                return props.$token['orange-6'];
            default:
                return props.$token['blue-6'];
        }
    }} !important;
    border-color: ${props => {
        switch (props.$tagType) {
            case 'Фармакология':
                return props.$token['blue-3'];
            case 'Геология':
                return props.$token['cyan-3'];
            case 'Экология':
                return props.$token['green-3'];
            case 'Мед. оборудование':
                return props.$token['orange-3'];
            default:
                return props.$token['blue-3'];
        }
    }} !important;
    background: ${props => {
        switch (props.$tagType) {
            case 'Фамакология':
                return props.$token['blue-1'];
            case 'Геология':
                return props.$token['cyan-1'];
            case 'Экология':
                return props.$token['green-1'];
            case 'Мед. оборудование':
                return props.$token['orange-1'];
            default:
                return props.$token['blue-1'];
        }
    }} !important;

    .anticon-close {
        color: ${props => {
            switch (props.$tagType) {
                case 'Фармакология':
                    return props.$token['blue-6'];
                case 'Геология':
                    return props.$token['cyan-6'];
                case 'Экология':
                    return props.$token['green-6'];
                case 'Мед. оборудование':
                    return props.$token['orange-6'];
                default:
                    return props.$token['blue-6'];
            }
        }} !important;
    }
`;

const NotificationCircle = styled.div<{ $type: 'warning' | 'emergency'; $token: any }>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.$type === 'warning' ? props.$token['yellow-6'] : props.$token['red-5']};
    color: white;
    font-size: 12px;
    font-weight: 600;
`;

const StyledModal = styled(Modal)<{ $token?: any }>`
  .ant-modal-content {
    padding: 24px;
    margin: 0 32px;
    border-radius: 4px;
  }
  
  .ant-modal-close {
    display: none;
  }
  
  label {
    display: block;
    color: ${props => props.$token?.colorTextTertiary} !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0 0 4px !important;
  }

  h2 {
    margin: 24px 0 16px 0;
    color: ${props => props.$token?.colorText};
  }

  .ant-input {
    height: 40px;
    border-radius: 4px;
    background-color: #fAfAfA !important;

    &:hover, &:focus {
      background-color: #fAfAfA !important;
    }
  }

  .ant-input-affix-wrapper {
    background-color: #fAfAfA !important;
    padding: 0 11px !important;

    &:hover, &:focus, &-focused {
      background-color: #fAfAfA !important;
    }

    .ant-input {
      background-color: #fAfAfA !important;
    }
  }

  .ant-btn {
    height: 40px;
    border-radius: 4px;
  }

  .ant-tag {
    border-radius: 4px;
    
    &.new-tag {
      background: white;
      border-style: dashed;
    }
  }

  .ant-input-suffix .anticon {
    color: ${props => props.$token['blue-6']} !important;
  }

  .ant-form-item {
    margin-bottom: 8px !important;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 14px !important;
  }

  .ant-form-item-label {
    padding-bottom: 0 !important;
    
    > label {
      height: 20px !important;
    }
  }
`;

interface Laboratory {
    id: number;
    name: string;
    city: string;
    email: string;
    phone: string;
    tags: string[];
    notification_warning: number;
    notification_emergency: number;
}

const Laboratories: FunctionComponent = () => {
    const { token } = useToken();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [data, setData] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [laboratories, setLaboratories] = useState<Laboratory[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [newTagInput, setNewTagInput] = useState('');

    const handleTagClose = async (recordKey: string, removedTag: string) => {
        try {
            const laboratory = laboratories.find(lab => lab.id.toString() === recordKey);
            if (laboratory) {
                const updatedTags = laboratory.tags.filter(tag => tag !== removedTag);
                const token = localStorage.getItem('token');
                
                await axiosInstance.put(`/api/laboratories/${recordKey}/tags`, updatedTags, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                
                setData(prevData => 
                    prevData.map(record => {
                        if (record.key === recordKey) {
                            return {
                                ...record,
                                tags: updatedTags
                            };
                        }
                        return record;
                    })
                );

                setLaboratories(prevLabs => 
                    prevLabs.map(lab => {
                        if (lab.id.toString() === recordKey) {
                            return {
                                ...lab,
                                tags: updatedTags
                            };
                        }
                        return lab;
                    })
                );
            }
        } catch (error: any) {
            console.error('Error:', error);
            message.error('Ошибка при удалении тега');
        }
    };

    const columns = [
        {
            title: 'Лаборатория',
            key: 'laboratory',
            width: 247,
            render: (record: any) => (
                <Space>
                    <Space direction="vertical" size={0}>
                        <Typography.Text 
                            strong 
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/admin/manage-laboratory/${record.key}`)}
                        >
                            {record.name}
                        </Typography.Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Адрес',
            key: 'legal_address',
            width: 247,
            render: (record: any) => (
                <Space>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.legal_address}</Typography.Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Тег',
            key: 'tags',
            width: 247,
            align: 'left' as const,
            render: (record: any) => (
                <TagContainer>
                    {record.tags.map((tag: string) => (
                        <StyledTag 
                            key={tag}
                            closable
                            $tagType={tag}
                            $token={token}
                            onClose={() => handleTagClose(record.key, tag)}
                        >
                            {tag}
                        </StyledTag>
                    ))}
                </TagContainer>
            ),
        },
        {
            title: 'E-mail',
            key: 'email',
            width: 247,
            align: 'left' as const,
            render: (record: any) => (
                <Space>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.email}</Typography.Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Телефон',
            key: 'phone',
            width: 247,
            align: 'left' as const,
            render: (record: any) => (
                <Space>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.phone}</Typography.Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Уведомленя',
            key: 'notification',
            width: 150,
            align: 'center' as const,
            render: (record: any) => (
                <Space direction="vertical" size={4}>
                    <NotificationCircle $type="warning" $token={token}>
                        {record.notification_warning}
                    </NotificationCircle>
                    <NotificationCircle $type="emergency" $token={token}>
                        {record.notification_emergency}
                    </NotificationCircle>
                </Space>
            ),
        },
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleAddTag = () => {
        if (newTagInput && !selectedTags.includes(newTagInput)) {
            setSelectedTags([...selectedTags, newTagInput]);
            setNewTagInput('');
        }
    };

    const handleRemoveTag = (removedTag: string) => {
        setSelectedTags(selectedTags.filter(tag => tag !== removedTag));
    };

    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            const token = localStorage.getItem('token');
            await axiosInstance.post('/api/laboratories', {
                name: values.name,
                city: values.city || '',
                email: values.email,
                phone: values.phone,
                tags: selectedTags,
                notification_warning: 0,
                notification_emergency: 0,
                legal_address: values.legal_address || null,
                actual_address: values.actual_address || null,
                delivery_address: values.delivery_address || null,
                bank_name: values.bank_name || null,
                account_number: values.account_number || null,
                correspondent_account: values.correspondent_account || null,
                inn: values.inn || null,
                bik: values.bik || null,
                kpp: values.kpp || null,
                ogrn: values.ogrn || null
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            message.success('Лаборатория успешно добавлена');
            setIsModalOpen(false);
            form.resetFields();
            setSelectedTags([]);
            fetchLaboratories();
        } catch (error: any) {
            message.error(error.response?.data?.detail || 'Ошибка при создании лаборатории');
        }
    };

    const fetchLaboratories = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axiosInstance.get('/api/laboratories', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setLaboratories(response.data);
            const tableData = response.data.map((lab: any) => ({
                key: lab.id,
                name: lab.name,
                legal_address: lab.legal_address || '',
                tags: Array.isArray(lab.tags) ? lab.tags : [],
                email: lab.email,
                phone: lab.phone,
                notification_warning: lab.notification_warning,
                notification_emergency: lab.notification_emergency,
            }));
            setData(tableData);
        } catch (error: any) {
            message.error('Ошибка при загрузке лабораторий');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLaboratories();
    }, []);

    return (
        <>
            <HeaderAdmin />
            <StyledContent>
                <TextParentRoot>
                    <Title level={4}>Лаборатории</Title>
                    <HeadingExtra>
                        <Button icon={<ExportOutlined style={{ color: token['blue-5'] }} />} style={{ height: '40px', width: '45px' }} />
                        <Button icon={<PlusOutlined style={{ color: token['blue-5'] }} />} style={{ height: '40px' }} onClick={showModal}>
                            Добавить лабораторию
                        </Button>
                    </HeadingExtra>
                </TextParentRoot>

                <StyledTable 
                    columns={columns} 
                    dataSource={data}
                    pagination={false}
                />

                <StyledModal
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    width="calc(100vw - 64px)"
                    style={{
                        top: '50%',
                        transform: 'translateY(-50%)',
                        padding: 0,
                        maxWidth: 'none'
                    }}
                    $token={token}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <h2 style={{ marginTop: 0 }}>Добавить лабораторию</h2>
                            <StyledForm form={form} layout="vertical">
                                <Row gutter={[16, 0]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="name"
                                            label="Юр.название компании"
                                            rules={[{ required: true, message: 'Введите название компании' }]}
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="phone"
                                            label="Номер телефона"
                                            rules={[{ required: true, message: 'Ведите номер телефона' }]}
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="email"
                                            label="E-mail"
                                            rules={[
                                                { required: true, message: 'Введите email' },
                                                { type: 'email', message: 'Введите корректный email' }
                                            ]}
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="legal_address"
                                            label="Юр.адрес"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="actual_address"
                                            label="Факт.адрес"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="delivery_address"
                                            label="Адрес доставки"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <h2>Тег</h2>
                                <div style={{ marginBottom: 16 }}>
                                    {selectedTags.map(tag => (
                                        <Tag 
                                            key={tag} 
                                            closable 
                                            closeIcon={<CloseOutlined />}
                                            onClose={() => handleRemoveTag(tag)}
                                        >
                                            {tag}
                                        </Tag>
                                    ))}
                                    <Input
                                        type="text"
                                        size="small"
                                        style={{ width: 100, marginRight: 8, height: 22 }}
                                        value={newTagInput}
                                        onChange={e => setNewTagInput(e.target.value)}
                                        onPressEnter={handleAddTag}
                                        placeholder="Новый тег"
                                    />
                                    <Tag 
                                        className="new-tag" 
                                        icon={<PlusOutlined />}
                                        onClick={handleAddTag}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Добавить тег
                                    </Tag>
                                </div>
                            </StyledForm>
                        </Col>
                        <Col span={12}>
                            <h2 style={{ marginTop: 0 }}>Платежные реквизиты</h2>
                            <StyledForm form={form} layout="vertical">
                                <Row gutter={[16, 0]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="bank_name"
                                            label="Название банка"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="account_number"
                                            label="Расчет.счт"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="correspondent_account"
                                            label="Кор.счет"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="inn"
                                            label="ИНН"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="bik"
                                            label="БИК"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="kpp"
                                            label="КПП"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="ogrn"
                                            label="ОГРН"
                                        >
                                            <Input placeholder="Введите данные" suffix={<EditOutlined style={{ color: token['blue-6'] }} />} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{ marginTop: 16 }}>
                                    <Col span={12}>
                                        <Button block onClick={handleCancel}>Зaкрыть</Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button type="primary" block onClick={handleSave}>
                                            Сохранить
                                        </Button>
                                    </Col>
                                </Row>
                            </StyledForm>
                        </Col>
                    </Row>
                </StyledModal>
            </StyledContent>
        </>
    );
};

export default Laboratories;