import { CloseOutlined, DeleteOutlined, FileOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Table, Modal, Space } from "antd";
import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import ExportTypes, { ExportFormat } from './ExportTypes';
import ExportProcess from './ExportProcess';
import SuccessRequest from './SuccessRequest';

const { Option } = Select;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table-tbody > tr > td {
    height: 55px;
    padding: 8px;
  }
  
  .ant-table-body {
    height: 260px !important;
    overflow-y: auto !important;
  }
`;

interface DataType {
    key: React.Key;
    number: number;
    name: string;
    type?: string;
    verificationType?: string;
    note?: string;
}

interface SelectedInstrument {
    key: React.Key;
    number: number;
    name: string;
}

interface VerificationRequestProps {
    onCancel: () => void;
    open: boolean;
    selectedInstruments: SelectedInstrument[];
}

const VerificationRequest: React.FC<VerificationRequestProps> = ({ onCancel, open, selectedInstruments }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [isExportVisible, setIsExportVisible] = useState(false);
    const [isExportProcessVisible, setIsExportProcessVisible] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);

    useEffect(() => {
        if (selectedInstruments.length > 0) {
            setDataSource(selectedInstruments.map(instrument => ({
                key: instrument.key,
                number: instrument.number,
                name: instrument.name,
                type: 'СИ',
                note: ''
            })));
        }
    }, [selectedInstruments]);

    const handleDelete = (key: React.Key) => {
        setDataSource(prev => prev.filter(item => item.key !== key));
    };

    const handleAdd = () => {
        const newItem: DataType = {
            key: Date.now(),
            number: dataSource.length + 1,
            name: '',
            type: 'СИ',
            verificationType: 'Поверка',
            note: ''
        };
        setDataSource([...dataSource, newItem]);
    };

    const handleExport = (format: ExportFormat) => {
        setIsExportVisible(false);
        setIsExportProcessVisible(true);
        
        setTimeout(() => {
            setIsExportProcessVisible(false);
        }, 3000);
    };

    const handleSubmit = () => {
        setIsSuccessVisible(true);
    };

    const handleSuccessClose = () => {
        setIsSuccessVisible(false);
        onCancel();
    };

    const handleNameChange = (key: React.Key, value: string) => {
        setDataSource(prev => prev.map(item => 
            item.key === key ? { ...item, name: value } : item
        ));
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "№ п/п",
            dataIndex: "number",
            key: "number",
            width: 80,
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            width: 232,
            render: (value: string, record: DataType) => (
                <Input 
                    value={value}
                    onChange={(e) => handleNameChange(record.key, e.target.value)}
                    placeholder="введите название"
                />
            ),
        },
        {
            title: "Тип оборудования",
            dataIndex: "type",
            key: "type",
            width: 232,
            render: () => (
                <Select defaultValue="СИ" style={{ width: '100%' }}>
                    <Option value="СИ">СИ</Option>
                    <Option value="ИО">ИО</Option>
                    <Option value="ВО">ВО</Option>
                </Select>
            ),
        },
        {
            title: "Тип проверки",
            dataIndex: "verificationType",
            key: "verificationType",
            width: 232,
            render: () => (
                <Select defaultValue="Поверка" style={{ width: '100%' }}>
                    <Option value="Поверка">Поверка</Option>
                    <Option value="Калибровка">Калибровка</Option>
                    <Option value="Аттестация">Аттестация</Option>
                </Select>
            ),
        },
        {
            title: "Примечание",
            dataIndex: "note",
            key: "note",
            width: 232,
            render: () => <Input placeholder="описание" />,
        },
        {
            key: "action",
            width: 48,
            render: (_: unknown, record: DataType) => (
                <StyledButton 
                    type="text" 
                    icon={<DeleteOutlined style={{ color: '#f5222d' }} />}
                    style={{ width: '32px', padding: 0 }}
                    onClick={() => handleDelete(record.key)}
                />
            ),
        },
    ];

    return (
        <Modal
            title="Заявка на проверку"
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
            centered
        >
            <StyledTable
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                bordered
                style={{ marginTop: 16 }}
                scroll={{ y: 300 }}
            />
            <ButtonsRow justify="space-between" style={{ marginTop: 16 }}>
                <Space size={8}>
                    <StyledButton onClick={onCancel}>Назад</StyledButton>
                    <StyledButton 
                        icon={<PlusOutlined />}
                        onClick={handleAdd}
                    >
                        Добавить
                    </StyledButton>
                </Space>
                <Space size={8}>
                    <StyledButton 
                        icon={<FileOutlined />}
                        onClick={() => setIsExportVisible(true)}
                    >
                        Экспортировать
                    </StyledButton>
                    <StyledButton 
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Отправить заявку
                    </StyledButton>
                </Space>
            </ButtonsRow>
            <ExportTypes 
                open={isExportVisible}
                onCancel={() => setIsExportVisible(false)}
                onExport={handleExport}
                selectedRows={dataSource.map(item => item.key)}
            />
            <ExportProcess 
                open={isExportProcessVisible}
            />
            <SuccessRequest 
                open={isSuccessVisible}
                onClose={handleSuccessClose}
            />
        </Modal>
    );
};

export default VerificationRequest; 