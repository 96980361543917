import React from 'react';
import { Button, Typography, Space, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import styled from 'styled-components';

const { Title, Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 24px;
  text-align: center;
`;

const ErrorImage = styled.img`
  width: 300px;
  margin-bottom: 24px;
`;

const StyledText = styled(Text)`
  opacity: 0.5;
  margin-bottom: 32px;
  max-width: 400px;
`;

const ButtonsContainer = styled(Space)`
  margin-top: 16px;
  gap: 20px;
`;

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();

  const handleRedirectToProfile = () => {
    if (!isAuthenticated) {
      navigate('/auth/login');
      return;
    }

    if (user?.is_admin || user?.role === "admin") {
      navigate('/admin/laboratories');
    } else if (user?.role === 'quality') {
      navigate('/quality-control');
    } else if (user?.role === 'distributor') {
      navigate('/distributor');
    } else if (user?.laboratory_id) {
      navigate(`/laboratory/${user.laboratory_id}/chemicals`);
    } else {
      navigate('/auth/login');
    }
  };

  const handleRedirectToWebsite = () => {
    window.location.href = 'https://gso.ru';
  };

  return (
    <Container>
      <ErrorImage src="/images/404.png" alt="Ошибка 404" />
      <Title level={5}>Запрашиваемая вами страница не существует</Title>
      <StyledText>
      Страница, на которую вы пытаетесь перейти – сейчас недоступна или не существует на платформе
      </StyledText>
      <ButtonsContainer>
        <Button style={{ height: '48px', width: '198px', borderRadius: '4px' }} type="primary" size="large" onClick={handleRedirectToProfile}>
          Вернуться в кабинет
        </Button>
        <Button style={{ height: '48px', width: '198px', borderRadius: '4px' }} size="large" onClick={handleRedirectToWebsite}>
          Перейти на сайт
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default NotFoundPage;
