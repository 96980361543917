import { AxiosResponse } from 'axios';
import axiosInstance from './axios';

export interface InstrumentBase {
    number: number;
    status: string;
    manufacturer?: string;
    commissioning_year?: number;
    ownership_document?: string;
    location?: string;
    notes?: string;
    custom_columns?: Record<string, any>;
}

export interface MeasuringInstrumentCreate extends InstrumentBase {
    characteristics?: string;
    name: string;
    measurement_range?: string;
    accuracy?: string;
    verification_certificate?: string;
    verification_date?: string;
    next_verification_date?: string;
    registration_number?: string;
    verification_method?: string;
}

export interface MeasuringInstrumentResponse extends MeasuringInstrumentCreate {
    id: number;
    laboratory_id: number;
}

export interface TestingInstrumentCreate extends InstrumentBase {
    characteristics?: string;
    test_name: string;
    equipment_name: string;
    technical_specs?: string;
    document_info?: string;
}

export interface TestingInstrumentResponse extends TestingInstrumentCreate {
    id: number;
    laboratory_id: number;
}

export interface HelpingInstrumentCreate extends InstrumentBase {
    name: string;
    purpose?: string;
}

export interface HelpingInstrumentResponse extends HelpingInstrumentCreate {
    id: number;
    laboratory_id: number;
}

export const instrumentsApi = {
    getMeasuringInstruments(laboratoryId: string): Promise<AxiosResponse<MeasuringInstrumentResponse[]>> {
        return axiosInstance.get(`/instruments/laboratory/${laboratoryId}/measuring`);
    },

    createMeasuringInstrument(laboratoryId: string, data: MeasuringInstrumentCreate): Promise<AxiosResponse<MeasuringInstrumentResponse>> {
        return axiosInstance.post(`/instruments/laboratory/${laboratoryId}/measuring`, data);
    },

    getTestingInstruments(laboratoryId: string): Promise<AxiosResponse<TestingInstrumentResponse[]>> {
        return axiosInstance.get(`/instruments/laboratory/${laboratoryId}/testing`);
    },

    createTestingInstrument(laboratoryId: string, data: TestingInstrumentCreate): Promise<AxiosResponse<TestingInstrumentResponse>> {
        return axiosInstance.post(`/instruments/laboratory/${laboratoryId}/testing`, data);
    },

    getHelpingInstruments(laboratoryId: string): Promise<AxiosResponse<HelpingInstrumentResponse[]>> {
        return axiosInstance.get(`/instruments/laboratory/${laboratoryId}/helping`);
    },

    createHelpingInstrument(laboratoryId: string, data: HelpingInstrumentCreate): Promise<AxiosResponse<HelpingInstrumentResponse>> {
        return axiosInstance.post(`/instruments/laboratory/${laboratoryId}/helping`, data);
    },

    deleteMeasuringInstrument: async (laboratoryId: string, instrumentId: number) => {
        const response = await axiosInstance.delete(`/instruments/laboratory/${laboratoryId}/measuring/${instrumentId}`);
        return response.data;
    },

    deleteTestingInstrument: async (laboratoryId: string, instrumentId: number) => {
        const response = await axiosInstance.delete(`/instruments/laboratory/${laboratoryId}/testing/${instrumentId}`);
        return response.data;
    },

    deleteHelpingInstrument: async (laboratoryId: string, instrumentId: number) => {
        const response = await axiosInstance.delete(`/instruments/laboratory/${laboratoryId}/helping/${instrumentId}`);
        return response.data;
    },

    updateMeasuringInstrument(laboratoryId: string, instrumentId: number, data: MeasuringInstrumentCreate): Promise<AxiosResponse<MeasuringInstrumentResponse>> {
        return axiosInstance.put(`/instruments/laboratory/${laboratoryId}/measuring/${instrumentId}`, data);
    },

    updateTestingInstrument(laboratoryId: string, instrumentId: number, data: TestingInstrumentCreate): Promise<AxiosResponse<TestingInstrumentResponse>> {
        return axiosInstance.put(`/instruments/laboratory/${laboratoryId}/testing/${instrumentId}`, data);
    },

    updateHelpingInstrument(laboratoryId: string, instrumentId: number, data: HelpingInstrumentCreate): Promise<AxiosResponse<HelpingInstrumentResponse>> {
        return axiosInstance.put(`/instruments/laboratory/${laboratoryId}/helping/${instrumentId}`, data);
    },

    exportMeasuringInstruments: async (ids: number[], format: string) => {
        const params = new URLSearchParams();
        ids.forEach(id => params.append('instrument_ids', id.toString()));
        params.append('format', format);
        
        return await axiosInstance.get(`/instruments/measuring-instruments/export?${params.toString()}`, {
            responseType: 'blob'
        });
    },

    exportTestingInstruments: async (ids: number[], format: string) => {
        const params = new URLSearchParams();
        ids.forEach(id => params.append('instrument_ids', id.toString()));
        params.append('format', format);
        
        return await axiosInstance.get(`/instruments/testing-instruments/export?${params.toString()}`, {
            responseType: 'blob'
        });
    },

    exportHelpingInstruments: async (ids: number[], format: string) => {
        const params = new URLSearchParams();
        ids.forEach(id => params.append('instrument_ids', id.toString()));
        params.append('format', format);
        
        return await axiosInstance.get(`/instruments/helping-instruments/export?${params.toString()}`, {
            responseType: 'blob'
        });
    }
};

export default instrumentsApi; 