import React, { useState, useEffect } from 'react';
import { Modal, Typography, Button, Carousel, Row, Col } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

interface ScanInstructionsProps {
    open: boolean;
    onClose: () => void;
}

interface InstructionStep {
    title: string;
    description: string;
    imageSrc: string;
}

const ScanInstructions: React.FC<ScanInstructionsProps> = ({ open, onClose }) => {
    const carouselRef = React.useRef<any>(null);
    const [currentStep, setCurrentStep] = useState<number>(0);
    
    // Сброс к первому этапу при открытии инструкции
    useEffect(() => {
        if (open) {
            setCurrentStep(0);
            // Небольшая задержка для корректной работы карусели
            setTimeout(() => {
                carouselRef.current?.goTo(0);
            }, 100);
        }
    }, [open]);
    
    const instructionSteps: InstructionStep[] = [
        {
            title: 'Начало работы',
            description: 'Для внесения данных химического вещества в таблицу вам необходимо отсканировать два QR-кода',
            imageSrc: '/images/example_scan.png'
        },
        {
            title: 'Шаг 1',
            description: 'Отсканируйте QR-код партии вещества',
            imageSrc: '/images/example_scan.png'
        },
        {
            title: 'Шаг 2',
            description: 'Отсканируйте QR-код даты отгрузки',
            imageSrc: '/images/example_scan.png'
        },
        {
            title: 'Шаг 3',
            description: 'Заполните все данные и нажмите кнопку "Сохранить"',
            imageSrc: '/images/example_scan.png'
        },
        {
            title: 'Предупреждение',
            description: 'Если данные будут внесены не верно или не полностью, напротив хим.вещества загорится знак с предупреждением',
            imageSrc: '/images/example_scan.png'
        }
    ];

    const handleNext = () => {
        carouselRef.current?.next();
    };

    const handleAfterChange = (current: number) => {
        setCurrentStep(current);
    };

    const isLastStep = currentStep === instructionSteps.length - 1;

    // Стиль для точек индикатора
    const dotStyle = {
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        margin: '0 4px',
        backgroundColor: '#d9d9d9'
    };

    const activeDotStyle = {
        ...dotStyle,
        backgroundColor: '#1890ff'
    };

    return (
        <Modal
            title="Инструкция"
            open={open}
            onCancel={onClose}
            footer={null}
            width={440}
            bodyStyle={{ display: 'flex', flexDirection: 'column' }}
            centered
        >
            <Carousel
                ref={carouselRef}
                afterChange={handleAfterChange}
                dots={false}
                style={{ flex: 1 }}
            >
                {instructionSteps.map((step, index) => (
                    <div key={index}>
                        <Row justify="center" align="middle" style={{ marginBottom: 20, marginTop: 30 }}>
                            <Col span={24}>
                                <Title level={4} style={{ textAlign: 'center', marginBottom: 8 }}>{step.title}</Title>
                                <Paragraph style={{ textAlign: 'center', opacity: 0.5, fontSize: 13 }}>{step.description}</Paragraph>
                            </Col>
                        </Row>
                        <Row justify="center" align="middle" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Col style={{ textAlign: 'center' }}>
                                <img 
                                    src={step.imageSrc} 
                                    alt={`Шаг ${index + 1}`} 
                                    style={{ 
                                        height: 160, 
                                        maxWidth: '100%', 
                                        objectFit: 'contain',
                                        margin: '0 auto'
                                    }} 
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </Carousel>

            {/* Индикаторы этапов (точки) */}
            <Row justify="center" style={{ marginTop: 16, marginBottom: 16 }}>
                <Col>
                    {instructionSteps.map((_, index) => (
                        <span 
                            key={index} 
                            style={index === currentStep ? activeDotStyle : dotStyle}
                            onClick={() => carouselRef.current?.goTo(index)}
                        />
                    ))}
                </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 24 }}>
                {!isLastStep && (
                    <Col span={12}>
                        <Button 
                            onClick={onClose} 
                            style={{ height: 40, width: '100%' }}
                        >
                            Пропустить
                        </Button>
                    </Col>
                )}
                <Col span={isLastStep ? 24 : 12}>
                    <Button 
                        type="primary" 
                        onClick={isLastStep ? onClose : handleNext}
                        style={{ height: 40, width: '100%' }}
                    >
                        {isLastStep ? 'Начать сканировать' : 'Далее'}
                        {!isLastStep && <RightOutlined />}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default ScanInstructions; 