import React, { useState } from 'react';
import { Button, Col, Input, Row, Select, DatePicker, message, Typography, Modal } from "antd";
import styled from "styled-components";
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import { useLaboratoryId } from '../hooks/useLaboratoryId';
import type { MSIType } from '../pages/tables/MSI';
import instrumentsApi from '../api/instruments';
import { msiApi } from '../api/msi';

const { Text } = Typography;

// Используем те же стили, что и в GetReagent
const ScreenContainer = styled.div`
  width: 400px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const BottomButtons = styled.div`
  padding: 16px;
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  &[type='number'] {
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  justify-content: center;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
  }

  .cancel-button {
    flex: 1;
    max-width: 178px;
  }

  .action-button {
    flex: 1;
    max-width: 178px;
  }
`;

interface EditMSIProps {
  onCancel: () => void;
  record: MSIType;
  onSuccess?: () => void;
}

const ScreenTitle = styled(Typography.Title)`
  margin: 16px !important;
  font-size: 16px !important;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditMSI: React.FC<EditMSIProps> = ({ onCancel, record, onSuccess }) => {
  const laboratoryId = useLaboratoryId();
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [editedRecord, setEditedRecord] = useState(record);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handleSelectChange = (field: string) => (value: unknown) => {
    setEditedRecord(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleDateChange = (field: string) => 
    (_: unknown, dateString: string | string[]) => {
      setEditedRecord(prev => ({
        ...prev,
        [field]: typeof dateString === 'string' ? dateString || null : null
      }));
    };

  const handleSave = async () => {
    if (!laboratoryId) return;

    try {
      setLoading(true);
      await msiApi.updateMSI(record.id, {
        ...editedRecord,
        laboratory_id: Number(laboratoryId)
      });
      
      message.success('Запись успешно обновлена');
      onSuccess?.();
      onCancel();
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('Ошибка при обновлении записи');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!laboratoryId) return;

    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    if (!laboratoryId) return;

    try {
      setLoading(true);
      await msiApi.deleteMSI(record.id);
      
      message.success('Запись успешно удалена');
      onSuccess?.();
      onCancel();
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('Ошибка при удалении записи');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
      setIsDeleteModalVisible(false);
    }
  };

  const methodOptions = [
    'Метод 1',
    'Метод 2',
    'Метод 3',
    'Метод 4'
  ].map(item => ({ label: item, value: item }));

  return (
    <>
      <ScreenContainer>
        <HeaderContainer>
          <ScreenTitle level={4}>Изменение записи</ScreenTitle>
        </HeaderContainer>

        <ScrollContent>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <StyledText>Метод измерений</StyledText>
              <StyledSelect
                value={editedRecord.measurement_method}
                onChange={handleSelectChange('measurement_method')}
                options={methodOptions}
                style={{ width: '100%' }}
              />
            </Col>
            
            <Col span={24}>
              <StyledText>Методика измерений</StyledText>
              <StyledInput
                value={editedRecord.measurement_methodology}
                onChange={(e) => handleSelectChange('measurement_methodology')(e.target.value)}
              />
            </Col>

            <Col span={24}>
              <StyledText>Объект исследований</StyledText>
              <StyledInput
                value={editedRecord.research_object}
                onChange={(e) => handleSelectChange('research_object')(e.target.value)}
              />
            </Col>

            <Col span={24}>
              <StyledText>Определяемая характеристика</StyledText>
              <StyledInput
                value={editedRecord.characteristic}
                onChange={(e) => handleSelectChange('characteristic')(e.target.value)}
              />
            </Col>

            <Col span={24}>
              <StyledText>Участвующий сотрудник</StyledText>
              <StyledInput
                value={editedRecord.employee}
                onChange={(e) => handleSelectChange('employee')(e.target.value)}
              />
            </Col>

            <Col span={24}>
              <StyledText>Провайдер МСИ</StyledText>
              <StyledInput
                value={editedRecord.provider}
                onChange={(e) => handleSelectChange('provider')(e.target.value)}
              />
            </Col>

            <Col span={24}>
              <StyledText>Сроки проведения</StyledText>
              <StyledDatePicker
                value={editedRecord.deadline ? dayjs(editedRecord.deadline) : null}
                onChange={handleDateChange('deadline')}
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
        </ScrollContent>

        <BottomButtons>
          <ButtonsRow>
            <DeleteButton 
              icon={<DeleteOutlined style={{ color: token['red-5'] }} />}
              onClick={handleDelete}
            />
            <Button 
              className="cancel-button"
              onClick={onCancel}
              disabled={loading}
            >
              Отмена
            </Button>
            <Button 
              className="action-button"
              type="primary"
              onClick={handleSave}
              loading={loading}
            >
              Сохранить
            </Button>
          </ButtonsRow>
        </BottomButtons>
      </ScreenContainer>

      <Modal
        title="Подтверждение удаления"
        open={isDeleteModalVisible}
        onOk={handleConfirmDelete}
        onCancel={() => setIsDeleteModalVisible(false)}
        okText="Удалить"
        cancelText="Отмена"
        okButtonProps={{ danger: true }}
        centered
      >
        <p>Вы уверены, что хотите удалить эту запись?</p>
      </Modal>
    </>
  );
};

// Добавляем стили для кнопки удаления
const DeleteButton = styled(Button)`
  min-width: 46px !important;
  width: 46px !important;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 46px !important;
`;

export default EditMSI;

