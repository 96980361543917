import React from 'react';
import { Layout, Button, Badge, Space, Typography, theme, Popover, List } from 'antd';
import { BellOutlined, RightOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import logo from '../../logo.svg'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { useEffect, useState, useMemo } from 'react';
import { getExpirationNotifications, markNotificationsAsRead, ExpirationNotification, NotificationResponse } from '../../api/notifications';

interface CurrentUser {
    name: string;
    role: string;
}

const { Header } = Layout;
const { Text, Paragraph } = Typography;
const { useToken } = theme;

const StyledHeader = styled(Header)`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  height: auto;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LogoImage = styled.img`
  height: 25.5px;
  object-fit: contain;
`;

const NavButtons = styled(Space)`
  display: flex;
  gap: 8px;
`;

const UserInfo = styled.div`
  text-align: right;
  line-height: 16px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;


const NotificationContent = styled.div`
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #f5f5f5;
  margin-bottom: 32px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const NotificationItem = styled.div`
  width: 100%;
  .time {
    color: #8c8c8c;
    font-size: 12px;
  }
  .category-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  .category {
    color: #8c8c8c;
    font-size: 12px;
  }
`;


const StyledListItem = styled(List.Item)`
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
`;



const HeaderLaboratory: React.FC = () => {
    const { token } = useToken();
    const location = useLocation();
    const navigate = useNavigate();
    const { laboratoryId } = useParams<{ laboratoryId: string }>();
    const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState<NotificationResponse | null>(null);
    const [popoverVisible, setPopoverVisible] = useState(false);

    useEffect(() => {
        if (!laboratoryId) {
            navigate('/admin/laboratories');
        }
    }, [laboratoryId, navigate]);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const { data } = await axiosInstance.get('/api/users/me');
                setCurrentUser({
                    name: data.name,
                    role: data.role
                });
            } catch (error) {
                console.error('Failed to fetch current user:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCurrentUser();
    }, []);

    const fetchNotifications = async () => {
        if (!laboratoryId) return;
        
        try {
            const data = await getExpirationNotifications(laboratoryId);
            setNotifications(data);
        } catch (error) {
            console.error('Failed to fetch notifications:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
        const interval = setInterval(fetchNotifications, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, [laboratoryId]);

    const formatNotificationTitle = (notification: ExpirationNotification) => {
        return `${notification.days_until_expiration} дней до окончания срока годности`;
    };

    const formatNotificationType = (type: string) => {
        return type === 'reagent' ? 'Реактивы/Прекурсоры' : 'СО';
    };

    const handleNotificationClick = async (notification: ExpirationNotification) => {
        try {
            if (!notification.is_read) {
                await markNotificationsAsRead([notification.id], notification.type);
                await fetchNotifications();
            }
            setPopoverVisible(false);
            const path = `/laboratory/${laboratoryId}/chemicals`;
            navigate(path, {
                state: { 
                    highlightId: notification.id,
                    tab: notification.type === 'reagent' ? 1 : 2
                }
            });
        } catch (error) {
            console.error('Failed to mark notification as read:', error);
        }
    };

    const unreadCount = useMemo(() => {
        if (!notifications) return 0;
        return notifications.reagents.filter(n => !n.is_read).length + 
               notifications.standards.filter(n => !n.is_read).length;
    }, [notifications]);

    const notificationContent = notifications && (
        <NotificationContent>
            <NotificationHeader>
                <Space>
                    <Typography.Text strong style={{ fontSize: 20 }}>
                        Уведомления
                    </Typography.Text>
                    <Badge count={unreadCount} />
                </Space>
                <Typography.Link onClick={() => navigate(`/laboratory/${laboratoryId}/notifications`)}>
                    Посмотреть все
                </Typography.Link>
            </NotificationHeader>
            <List
                dataSource={[...notifications.reagents, ...notifications.standards]}
                renderItem={item => (
                    <StyledListItem 
                        style={{ 
                            padding: '8px 16px',
                            backgroundColor: item.is_read ? 'rgba(0, 0, 0, 0.02)' : 'white'
                        }}
                        onClick={() => handleNotificationClick(item)}
                    >
                        <NotificationItem>
                            <div className="category-row">
                                <span className="category">{formatNotificationType(item.type)}</span>
                                <span className="time">{new Date(item.expiration_date).toLocaleDateString('ru-RU')}</span>
                            </div>
                            <div>{formatNotificationTitle(item)}</div>
                            <Typography.Text strong>{item.name}</Typography.Text>
                        </NotificationItem>
                    </StyledListItem>
                )}
            />
        </NotificationContent>
    );

    const UserName = styled(Text)`
        display: block;
        color: ${token['blue-6']};
    `;

    const UserRole = styled(Text)`
        display: block;
        color: ${token['colorTextSecondary']};
    `;

    const isLaboratoriesActive = [
        `/laboratory/${laboratoryId}/chemicals`,
        `/laboratory/${laboratoryId}/instruments`,
        `/laboratory/${laboratoryId}/msi`,
        '/settings-laboratory'
    ].includes(location.pathname);

    const handleNavigation = (path: string) => {
        if (laboratoryId) {
            navigate(path);
        } else {
            navigate('/admin/laboratories');
        }
    };

    const handleLogout = () => {
        navigate('/auth/logout');
    };

    const userMenuContent = (
        <List split={false}>
            <List.Item 
                onClick={() => handleNavigation(`/laboratory/${laboratoryId}/settings`)}
                style={{ cursor: 'pointer', padding: '8px 8px' }}
            >
                <Space>
                    <SettingOutlined />
                    <Typography.Text>Настройки</Typography.Text>
                </Space>
            </List.Item>
            <List.Item 
                onClick={handleLogout}
                style={{ cursor: 'pointer', padding: '8px 8px' }}
            >
                <Space>
                    <LogoutOutlined style={{ color: token['red-6'] }} />
                    <Typography.Text>Выйти</Typography.Text>
                </Space>
            </List.Item>
        </List>
    );

    return (
        <StyledHeader>
        <Logo>
            <LogoImage alt="" src={logo} />
        </Logo>

        <NavButtons>
            <Button 
                type={location.pathname === `/laboratory/${laboratoryId}/chemicals` ? 'primary' : 'default'}
                style={location.pathname === `/laboratory/${laboratoryId}/chemicals` ? { backgroundColor: token['blue-6'] } : {}}
                onClick={() => handleNavigation(`/laboratory/${laboratoryId}/chemicals`)}
            >
                Химические вещества
            </Button>
            <Button 
                type={location.pathname === `/laboratory/${laboratoryId}/instruments` ? 'primary' : 'default'}
                onClick={() => handleNavigation(`/laboratory/${laboratoryId}/instruments`)}
            >
                Оборудование
            </Button>
            <Button 
                type={location.pathname === `/laboratory/${laboratoryId}/msi` ? 'primary' : 'default'}
                onClick={() => handleNavigation(`/laboratory/${laboratoryId}/msi`)}
            >
                МСИ
            </Button>
        </NavButtons>

        <RightSection>
            <Space>
            <UserInfo>
                <UserName>
                    {loading ? 'Загрузка...' : currentUser?.name || 'Гость'}
                </UserName>
                <UserRole>Лаборант</UserRole>
            </UserInfo>
            <Popover 
                content={userMenuContent}
                trigger="hover"
                placement="bottom"
                arrow={false}
            >
                <Button 
                    type="primary" 
                    shape="circle" 
                    style={{ 
                        width: "40px", 
                        height: "40px", 
                        backgroundColor: token['blue-6'],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {loading ? '...' : currentUser?.name 
                        ? currentUser.name[0].toUpperCase()
                        : 'Г'
                    }
                </Button>
            </Popover>
            <Popover 
                content={notificationContent}
                trigger="click"
                placement="bottomRight"
                overlayInnerStyle={{ padding: 0, marginTop: 32, borderRadius: 8 }}
                open={popoverVisible}
                onOpenChange={setPopoverVisible}
            >
                <Badge count={unreadCount} size="small" style={{ 
                    borderRadius: '50%',
                    minWidth: '20px',
                    height: '20px',
                    padding: '0 6px',
                    fontSize: '12px',
                    lineHeight: '20px'
                }}>
                    <Button type="text" icon={<BellOutlined style={{ fontSize: '18px' }} />} />
                </Badge>
            </Popover>
            </Space>
        </RightSection>

        </StyledHeader>
    );
};

export default HeaderLaboratory;