import { CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Modal, Space, Typography, Select, DatePicker, Checkbox, Popover, message, Pagination } from "antd";
import React, { useState, useEffect, useCallback, memo } from "react";
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import dayjs from 'dayjs';
import axiosInstance from '../api/axios';
import { useLaboratoryId } from '../hooks/useLaboratoryId';
import { extractExcelHeaders, extractExcelRowsCount, importStandards, extractExcelData } from '../api/import';

const { Link } = Typography;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table-tbody > tr > td {
    height: 55px;
    padding: 8px;
  }
  
  .ant-table-body {
    height: 260px !important;
    overflow-y: auto !important;
  }
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 14px;
  margin-top: 8px;
  display: block;
  text-decoration: underline !important;
  text-underline-offset: 2px;
`;

interface DataType {
    key: React.Key;
    number: number;
    name: string;
    manufacturer: string;
    purpose: string;
    batchCode: string;
    packaging: string;
    indicator: string;
    certifiedValue: number;
    certifiedValueUnit: string;
    errorValue: number;
    errorValueUnit: string;
    additionalInfo: string;
    normativeDocument: string;
    expirationDate: dayjs.Dayjs;
    expirationNotification: string;
    releaseDate: dayjs.Dayjs;
    notes: string;
    isPrecursor: boolean;
    custom_columns?: Record<string, any>;
}

interface CustomColumn {
    title: string;
    key: string;
    type: string;
}

interface ImportTableProps {
    onCancel: () => void;
    open: boolean;
    selectedReagents: any[];
    fileName?: string;
    file?: File | null;
    headerRows?: number;
}

const standardTypes = [
    'ГСО 1234-56 Стандартный образец 1',
    'ОСО 7890-12 Стандартный образец 2',
    'СОП 3456-78 Стандартный образец 3',
].map(item => ({ label: item, value: item }));

const manufacturerOptions = [
    'ФГУП "ВНИИМ им. Д.И. Менделеева"',
    'ФГУП "УНИИМ"',
    'ООО "Химтест"',
].map(item => ({ label: item, value: item }));

const purposeOptions = [
    'Калибровка',
    'Поверка',
    'Контроль точности',
].map(item => ({ label: item, value: item }));

const indicatorOptions = [
    'Массовая доля железа',
    'Массовая доля углерода',
    'Массовая доля серы',
].map(item => ({ label: item, value: item }));

const notificationOptions = [
    'За месяц',
    'За 2 месяца',
    'За 3 месяца',
].map(item => ({ label: item, value: item }));

const ColumnMappingPopover = styled.div`
  width: 250px;
`;

const ColumnTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  
  .title-content {
    display: flex;
    flex-direction: column;
  }
  
  .edit-icon {
    color: #8c8c8c;
    font-size: 14px;
    margin-left: auto;
  }
  
  &:hover {
    color: #1890ff;
    
    .edit-icon {
      color: #1890ff;
    }
  }
`;

// Мемоизированные компоненты ячеек
const CellInput = memo(({ value, onChange }: { value: string, onChange: (value: string) => void }) => (
    <Input value={value} onChange={(e) => onChange(e.target.value)} />
));

const CellNumberInput = memo(({ value, onChange }: { value: number, onChange: (value: number) => void }) => (
    <Input type="number" value={value} onChange={(e) => onChange(Number(e.target.value))} />
));

const CellSelect = memo(({ value, onChange, options }: { value: string, onChange: (value: string) => void, options: { label: string, value: string }[] }) => (
    <Select
        value={value}
        onChange={onChange}
        options={options}
        style={{ width: '100%' }}
        showSearch
        allowClear
    />
));

const CellDatePicker = memo(({ value, onChange }: { value: dayjs.Dayjs | null, onChange: (value: dayjs.Dayjs | null) => void }) => (
    <DatePicker
        value={value}
        onChange={onChange}
        format="DD.MM.YYYY"
        style={{ width: '100%' }}
    />
));

const CellCheckbox = memo(({ checked, onChange }: { checked: boolean, onChange: (checked: boolean) => void }) => (
    <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} />
));

const ImportTable: React.FC<ImportTableProps> = ({ onCancel, open, fileName = "файл", file, headerRows = 1 }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [columnMapping, setColumnMapping] = useState<Record<string, string>>({});
    const [customColumns, setCustomColumns] = useState<CustomColumn[]>([]);
    const [excelHeaders, setExcelHeaders] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const labId = useLaboratoryId();

    const loadCustomColumns = useCallback(async () => {
        if (!labId) return;
        
        try {
            const response = await axiosInstance.get(`/api/custom-columns/standard`, {
                params: {
                    laboratory_id: labId
                }
            });
            
            if (response.status === 200) {
                setCustomColumns(response.data.map((col: any) => ({ 
                    title: col.title, 
                    key: col.key,
                    type: col.type || 'text'
                })));
            }
        } catch (error) {
            console.error('Ошибка при загрузке пользовательских колонок:', error);
        }
    }, [labId]);

    const loadExcelData = useCallback(async () => {
        if (!file || !headerRows) return;
        
        try {
            setIsLoading(true);
            
            const headers = await extractExcelHeaders(file, headerRows);
            setExcelHeaders(headers);
            
            const rowsCount = await extractExcelRowsCount(file, headerRows);
            
            const emptyRows: DataType[] = Array.from({ length: rowsCount }, (_, index) => ({
                key: `${index + 1}`,
                number: index + 1,
                name: '',
                manufacturer: '',
                purpose: '',
                batchCode: '',
                packaging: '',
                indicator: '',
                certifiedValue: 0,
                certifiedValueUnit: '',
                errorValue: 0,
                errorValueUnit: '',
                additionalInfo: '',
                normativeDocument: '',
                expirationDate: dayjs(),
                expirationNotification: '',
                releaseDate: dayjs(),
                notes: '',
                isPrecursor: false,
                custom_columns: {}
            }));
            
            setDataSource(emptyRows);
        } catch (error) {
            console.error('Ошибка при загрузке данных из Excel:', error);
            message.error('Не удалось загрузить данные из Excel файла');
        } finally {
            setIsLoading(false);
        }
    }, [file, headerRows]);

    useEffect(() => {
        loadCustomColumns();
    }, [loadCustomColumns]);

    useEffect(() => {
        loadExcelData();
    }, [loadExcelData]);

    useEffect(() => {
        if (Object.keys(columnMapping).length > 0 && file) {
            fetchExcelDataAndUpdate();
        }
    }, [columnMapping]);

    const fetchExcelDataAndUpdate = async () => {
        if (!file || !headerRows || Object.keys(columnMapping).length === 0) return;
        
        try {
            setIsLoading(true);
            
            const excelData = await extractExcelData(file, columnMapping, headerRows);
            
            updateDataFromExcel(excelData);
            
            message.success(`Данные успешно загружены из Excel`);
        } catch (error) {
            console.error('Ошибка при получении данных из Excel:', error);
            message.error('Не удалось загрузить данные из Excel');
        } finally {
            setIsLoading(false);
        }
    };

    const updateDataFromExcel = (excelData: any[]) => {
        if (!excelData.length) return;
        
        const newRows = excelData.map((item, index) => {
            const key = `${index + 1}`;
            
            const row: DataType = {
                key,
                number: index + 1,
                name: item.name || '',
                manufacturer: item.manufacturer || '',
                purpose: item.purpose || '',
                batchCode: item.batchCode || '',
                packaging: item.packaging || '',
                indicator: item.indicator || '',
                certifiedValue: item.certifiedValue || 0,
                certifiedValueUnit: item.certifiedValueUnit || '',
                errorValue: item.errorValue || 0,
                errorValueUnit: item.errorValueUnit || '',
                additionalInfo: item.additionalInfo || '',
                normativeDocument: item.normativeDocument || '',
                expirationDate: item.expirationDate ? dayjs(item.expirationDate) : dayjs(),
                expirationNotification: item.expirationNotification || '',
                releaseDate: item.releaseDate ? dayjs(item.releaseDate) : dayjs(),
                notes: item.notes || '',
                isPrecursor: item.isPrecursor || false,
                custom_columns: item.custom_columns || {}
            };
            
            return row;
        });
        
        setDataSource(newRows);
        setCurrentPage(1);
    };

    const handleDelete = (key: React.Key) => {
        setDataSource(prev => prev.filter(item => item.key !== key));
    };

    const handleInputChange = (key: React.Key, field: string | string[], value: any) => {
        setDataSource(prev => prev.map(item => {
            if (item.key === key) {
                if (Array.isArray(field)) {
                    const newItem = { ...item };
                    let current: any = newItem;
                    for (let i = 0; i < field.length - 1; i++) {
                        current = current[field[i]];
                    }
                    current[field[field.length - 1]] = value;
                    return newItem;
                }
                
                return {
                    ...item,
                    [field]: value
                };
            }
            return item;
        }));
    };

    const handleColumnMappingChange = (columnKey: string, excelColumn: string) => {
        setColumnMapping(prev => {
            const newMapping = {
                ...prev,
                [columnKey]: excelColumn
            };
            
            // Показываем информационное сообщение
            const mappedCount = Object.values(newMapping).filter(Boolean).length;
            message.info(`Колонка сопоставлена: ${mappedCount} из ${excelHeaders.length}`);
            
            return newMapping;
        });
    };

    const handleAddRow = () => {
        const newKey = Date.now().toString();
        const newRow: DataType = {
            key: newKey,
            number: dataSource.length + 1,
            name: '',
            manufacturer: '',
            purpose: '',
            batchCode: '',
            packaging: '',
            indicator: '',
            certifiedValue: 0,
            certifiedValueUnit: '',
            errorValue: 0,
            errorValueUnit: '',
            additionalInfo: '',
            normativeDocument: '',
            expirationDate: dayjs(),
            expirationNotification: '',
            releaseDate: dayjs(),
            notes: '',
            isPrecursor: false,
            custom_columns: {}
        };
        
        setDataSource([...dataSource, newRow]);
    };

    const renderColumnTitle = (title: string, columnKey: string) => {
        const content = (
            <ColumnMappingPopover>
                <div style={{ marginBottom: 8 }}>
                    <div style={{ marginBottom: 4, color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>
                        Укажите название колонки из Excel
                    </div>
                    <Select
                        placeholder="Выберите колонку"
                        value={columnMapping[columnKey] || undefined}
                        onChange={(value) => handleColumnMappingChange(columnKey, value)}
                        style={{ width: '100%' }}
                        showSearch
                        allowClear
                        loading={isLoading}
                        options={excelHeaders.map(header => ({ label: header, value: header }))}
                    />
                </div>
            </ColumnMappingPopover>
        );

        return (
            <Popover
                content={content}
                title="Сопоставление колонок"
                trigger="click"
                placement="bottom"
            >
                <ColumnTitle>
                    <div className="title-content">
                        <span>{title}</span>
                        {columnMapping[columnKey] && (
                            <div style={{ fontSize: 12, color: '#1890ff', marginTop: 4 }}>
                                {columnMapping[columnKey]}
                            </div>
                        )}
                    </div>
                    <EditOutlined className="edit-icon" />
                </ColumnTitle>
            </Popover>
        );
    };

    const columns: ColumnsType<DataType> = [
        {
            title: renderColumnTitle('№ п/п', 'number'),
            dataIndex: 'number',
            key: 'number',
            width: 100,
            fixed: 'left',
        },
        {
            title: renderColumnTitle('Наименование, тип, номер и категория СО', 'name'),
            dataIndex: 'name',
            key: 'name',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'name', newValue)}
                    options={standardTypes}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Изготовитель СО', 'manufacturer'),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'manufacturer', newValue)}
                    options={manufacturerOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Назначение', 'purpose'),
            dataIndex: 'purpose',
            key: 'purpose',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'purpose', newValue)}
                    options={purposeOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Шифр партии', 'batchCode'),
            dataIndex: 'batchCode',
            key: 'batchCode',
            width: 232,
            render: (value: string, record: DataType) => (
                <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'batchCode', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Фасовка', 'packaging'),
            dataIndex: 'packaging',
            key: 'packaging',
            width: 232,
            render: (value: string, record: DataType) => (
                <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'packaging', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Показатель', 'indicator'),
            dataIndex: 'indicator',
            key: 'indicator',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'indicator', newValue)}
                    options={indicatorOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Аттестов. значение м.д. %', 'certifiedValue'),
            dataIndex: 'certifiedValue',
            key: 'certifiedValue',
            width: 232,
            render: (value: number, record: DataType) => (
                <CellNumberInput value={value} onChange={(newValue) => handleInputChange(record.key, 'certifiedValue', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Единица измерения атт. значения', 'certifiedValueUnit'),
            dataIndex: 'certifiedValueUnit',
            key: 'certifiedValueUnit',
            width: 232,
            render: (value: string, record: DataType) => (
                <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'certifiedValueUnit', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Погрешность а.з. абс. %', 'errorValue'),
            dataIndex: 'errorValue',
            key: 'errorValue',
            width: 232,
            render: (value: number, record: DataType) => (
                <CellNumberInput value={value} onChange={(newValue) => handleInputChange(record.key, 'errorValue', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Единица измерения погрешности', 'errorValueUnit'),
            dataIndex: 'errorValueUnit',
            key: 'errorValueUnit',
            width: 232,
            render: (value: string, record: DataType) => (
                <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'errorValueUnit', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Доп сведения', 'additionalInfo'),
            dataIndex: 'additionalInfo',
            key: 'additionalInfo',
            width: 232,
            render: (value: string, record: DataType) => (
                <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'additionalInfo', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Нормативный документ', 'normativeDocument'),
            dataIndex: 'normativeDocument',
            key: 'normativeDocument',
            width: 232,
            render: (value: string, record: DataType) => (
                <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'normativeDocument', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Срок годности экземпляра СО', 'expirationDate'),
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            width: 232,
            render: (value: dayjs.Dayjs, record: DataType) => (
                <CellDatePicker 
                    value={value || null} 
                    onChange={(date) => handleInputChange(record.key, 'expirationDate', date || dayjs())} 
                />
            ),
        },
        {
            title: renderColumnTitle('Уведомление об окончании срока годности', 'expirationNotification'),
            dataIndex: 'expirationNotification',
            key: 'expirationNotification',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'expirationNotification', newValue)}
                    options={notificationOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Дата выпуска экземпляра СО', 'releaseDate'),
            dataIndex: 'releaseDate',
            key: 'releaseDate',
            width: 232,
            render: (value: dayjs.Dayjs, record: DataType) => (
                <CellDatePicker 
                    value={value || null} 
                    onChange={(date) => handleInputChange(record.key, 'releaseDate', date || dayjs())} 
                />
            ),
        },
        {
            title: renderColumnTitle('Примечание', 'notes'),
            dataIndex: 'notes',
            key: 'notes',
            width: 232,
            render: (value: string, record: DataType) => (
                <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'notes', newValue)} />
            ),
        },
        {
            title: renderColumnTitle('Прекурсор', 'isPrecursor'),
            dataIndex: 'isPrecursor',
            key: 'isPrecursor',
            width: 232,
            render: (value: boolean, record: DataType) => (
                <CellCheckbox checked={value} onChange={(checked) => handleInputChange(record.key, 'isPrecursor', checked)} />
            ),
        },
    ];

    const customColumnsConfig = customColumns.map(col => ({
        title: renderColumnTitle(col.title, `custom_columns.${col.key}`),
        dataIndex: ['custom_columns', col.key],
        key: col.key,
        width: 232,
        render: (value: any, record: DataType) => {
            switch (col.type) {
                case 'date':
                    return (
                        <CellDatePicker 
                            value={value ? dayjs(value) : null} 
                            onChange={(date) => handleInputChange(record.key, ['custom_columns', col.key], date ? date.toISOString() : null)} 
                        />
                    );
                case 'boolean':
                    return (
                        <CellCheckbox checked={Boolean(value)} onChange={(checked) => handleInputChange(record.key, ['custom_columns', col.key], checked)} />
                    );
                case 'int':
                    return (
                        <CellNumberInput value={value} onChange={(newValue) => handleInputChange(record.key, ['custom_columns', col.key], newValue)} />
                    );
                default:
                    return (
                        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, ['custom_columns', col.key], newValue)} />
                    );
            }
        }
    }));

    const actionColumn = {
        key: "action",
        width: 48,
        fixed: 'right' as const,
        render: (_: unknown, record: DataType) => (
            <StyledButton 
                type="text" 
                icon={<DeleteOutlined style={{ color: '#f5222d' }} />}
                style={{ width: '32px', padding: 0 }}
                onClick={() => handleDelete(record.key)}
            />
        ),
    };

    const columnsWithoutAction = columns.filter(col => col.key !== "action");

    const allColumns = [...columnsWithoutAction, ...customColumnsConfig, actionColumn];

    // Данные для текущей страницы
    const paginatedData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    // Обработчик изменения страницы
    const handlePageChange = (page: number, size?: number) => {
        setCurrentPage(page);
        if (size) setPageSize(size);
    };

    const handleImport = async () => {
        if (!file || !labId) {
            message.error('Не удалось выполнить импорт. Отсутствует файл или ID лаборатории.');
            return;
        }

        try {
            setIsLoading(true);
            const response = await importStandards(file, columnMapping, headerRows, Number(labId));
            message.success(response.message);
            onCancel();
        } catch (error) {
            console.error('Ошибка при импорте стандартных образцов:', error);
            message.error('Не удалось импортировать стандартные образцы');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            title={<>Импорт данных из <span style={{ color: '#1890ff' }}>{fileName}</span></>}
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
            centered
        >
            <StyledLink href="#" target="_blank">Нужна помощь с импортом?</StyledLink>
            <div style={{ marginTop: 16, marginBottom: 16 }}>
                <Button 
                    type="dashed" 
                    icon={<PlusOutlined />} 
                    onClick={handleAddRow}
                    style={{ width: '100%' }}
                >
                    Добавить строку
                </Button>
            </div>
            <StyledTable
                columns={allColumns}
                dataSource={paginatedData}
                pagination={false}
                bordered
                scroll={{ x: 'max-content', y: 300 }}
                loading={isLoading}
            />
            {dataSource.length > 0 && (
                <div style={{ marginTop: 16, textAlign: 'right' }}>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={dataSource.length}
                        onChange={handlePageChange}
                        showSizeChanger
                        showQuickJumper
                        showTotal={(total) => `Всего ${total} записей`}
                    />
                </div>
            )}
            <ButtonsRow justify="space-between" style={{ marginTop: 16 }}>
                <Space size={8}>
                    <StyledButton onClick={onCancel}>Отмена</StyledButton>
                </Space>
                <Space size={8}>
                    <StyledButton 
                        type="primary"
                        onClick={handleImport}
                        loading={isLoading}
                        disabled={dataSource.length === 0}
                    >
                        Импортировать
                    </StyledButton>
                </Space>
            </ButtonsRow>
        </Modal>
    );
};

export default ImportTable;
