import axiosInstance from './axios';
import { DataType } from '../pages/tables/Reagents';
import { ExportFormat } from '../components/ExportTypes';
import { StandardType } from '../pages/tables/StandartSamples';

export const getReagents = async (laboratoryId: string): Promise<DataType[]> => {
    try {
        const response = await axiosInstance.get(`/api/laboratories/${laboratoryId}/reagents`);
        console.log('Response data:', response.data);
        
        if (!response.data) {
            console.error('No data received from server');
            return [];
        }
        
        // Преобразуем данные из БД в формат для таблицы
        return response.data.map((item: any, index: number): DataType => ({
            key: item.id,
            number: index + 1,
            receipt: {
                date: item.receipt_date,
                name: item.name,
                batch: item.batch,
                manufacturer: item.manufacturer,
                supplier: item.supplier,
                frequencyClass: item.frequency_class,
                expiryDate: item.expiration_date,
                expiryNotification: '',
                containerSize: item.quantity,
                containerUnit: item.unit,
                containerCount: 1,
                documents: item.has_documents,
                admission: item.has_admission,
            },
            storage: {
                isPrecursor: item.is_precursor,
                location: item.storage_location,
                conditions: item.storage_conditions,
            },
            accounting: {
                openedShelfLife: item.opened_shelf_life,
                used: 0,
                remaining: item.quantity,
                notes: item.notes,
            },
            // Добавляем пользовательские колонки
            custom_columns: item.custom_columns || {}
        }));
    } catch (error) {
        console.error('Error fetching reagents:', error);
        throw error;
    }
}; 

export const createReagent = async (laboratoryId: string, data: any) => {
    try {
        // Выделяем кастомные поля из данных
        const { custom_fields, ...standardFields } = data;
        
        const response = await axiosInstance.post(
            `/api/laboratories/${laboratoryId}/reagents`,
            {
                ...standardFields,
                laboratory_id: parseInt(laboratoryId),
                // Преобразуем в число только если есть значение
                quantity: standardFields.quantity ? Number(standardFields.quantity) : null,
                opened_shelf_life: standardFields.opened_shelf_life ? parseInt(standardFields.opened_shelf_life) : null,
                receipt_date: standardFields.receipt_date || null,
                expiration_date: standardFields.expiration_date || null,
                // Добавляем кастомные поля
                custom_columns: custom_fields || {}
            }
        );
        return response.data;
    } catch (error: any) {
        if (error.response?.data?.detail) {
            throw new Error(error.response.data.detail);
        }
        throw error;
    }
}; 

export const takeReagent = async (
    laboratoryId: string,
    reagentId: number,
    quantity: number,
    research?: string
): Promise<{ success: boolean; remaining_quantity: number }> => {
    const response = await axiosInstance.post(
        `/api/take/${reagentId}`,
        {
            quantity,
            research
        }
    );
    return response.data;
}; 

export const deleteReagent = async (reagentId: number): Promise<void> => {
  try {
    await axiosInstance.delete(`/api/reagents/${reagentId}`);
  } catch (error: any) {
    if (error.response?.data?.detail) {
      throw new Error(error.response.data.detail);
    }
    throw error;
  }
};

interface ReagentUpdateData {
  receipt_date?: string;
  name?: string;
  batch?: string;
  manufacturer?: string;
  supplier?: string;
  frequency_class?: string;
  expiration_date?: string;
  quantity?: number;
  unit?: string;
  has_documents?: boolean;
  has_admission?: boolean;
  is_precursor?: boolean;
  storage_location?: string;
  storage_conditions?: string;
  opened_shelf_life?: number;
  notes?: string;
  custom_columns?: Record<string, any>; // Добавляем поле для кастомных колонок
}

export const updateReagent = async (reagentId: number, data: ReagentUpdateData): Promise<void> => {
  try {
    await axiosInstance.put(`/api/reagents/${reagentId}`, data);
  } catch (error: any) {
    if (error.response?.data?.detail) {
      throw new Error(error.response.data.detail);
    }
    throw error;
  }
};

export const exportReagents = async (reagentIds: React.Key[], format: ExportFormat) => {
    try {
        const response = await axiosInstance.get(
            `/api/reagents/export?${reagentIds.map(id => `reagent_ids=${id}`).join('&')}&format=${format}`,
            {
                responseType: 'blob'
            }
        );
        
        // Создаем ссылку для скачивания
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `reagents.${format.toLowerCase()}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        
        return true;
    } catch (error) {
        console.error('Error exporting reagents:', error);
        throw error;
    }
};

export const getStandards = async (laboratoryId: number): Promise<StandardType[]> => {
    const response = await axiosInstance.get(`/api/laboratories/${laboratoryId}/standards`);
    return response.data.map((standard: any) => ({
        key: standard.id,
        number: standard.id,
        name: standard.name,
        manufacturer: standard.manufacturer,
        purpose: standard.purpose,
        batchCode: standard.batch_code,
        packaging: standard.packaging,
        indicator: standard.indicator,
        certifiedValue: standard.certified_value,
        certifiedValueUnit: standard.certified_value_unit,
        errorValue: standard.error_value,
        errorValueUnit: standard.error_value_unit,
        additionalInfo: standard.additional_info,
        normativeDocument: standard.normative_document,
        expirationDate: standard.expiration_date,
        expirationNotification: standard.expiration_notification,
        releaseDate: standard.release_date,
        notes: standard.notes,
        isPrecursor: standard.is_precursor,
        custom_columns: standard.custom_columns || {}
    }));
};

export const createStandard = async (laboratoryId: string | number, data: any) => {
  try {
    // Выделяем кастомные поля из данных
    const { custom_columns, ...standardFields } = data;
    
    const response = await axiosInstance.post(
      `/api/laboratories/${laboratoryId}/standards`,
      {
        name: standardFields.name,
        manufacturer: standardFields.manufacturer || null,
        purpose: standardFields.purpose || null,
        batch_code: standardFields.batch_code || null,
        packaging: standardFields.packaging || null,
        indicator: standardFields.indicator || null,
        certified_value: standardFields.certified_value !== undefined ? Number(standardFields.certified_value) : null,
        certified_value_unit: standardFields.certified_value_unit || null,
        error_value: standardFields.error_value !== undefined ? Number(standardFields.error_value) : null,
        error_value_unit: standardFields.error_value_unit || null,
        additional_info: standardFields.additional_info || null,
        normative_document: standardFields.normative_document || null,
        expiration_date: standardFields.expiration_date || null,
        expiration_notification: standardFields.expiration_notification || null,
        release_date: standardFields.release_date || null,
        notes: standardFields.notes || null,
        is_precursor: Boolean(standardFields.is_precursor),
        custom_columns: custom_columns || {}
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.data?.detail) {
      throw new Error(error.response.data.detail);
    }
    throw error;
  }
};

export const updateStandard = async (laboratoryId: string | number, standardId: number, data: any) => {
  try {
    // Выделяем кастомные поля из данных
    const { custom_columns, ...standardFields } = data;
    
    console.log('Данные после деструктуризации:', { custom_columns, standardFields });
    
    const requestData = {
      name: standardFields.name,
      manufacturer: standardFields.manufacturer || null,
      purpose: standardFields.purpose || null,
      batch_code: standardFields.batch_code || null,
      packaging: standardFields.packaging || null,
      indicator: standardFields.indicator || null,
      certified_value: standardFields.certified_value !== undefined ? Number(standardFields.certified_value) : null,
      certified_value_unit: standardFields.certified_value_unit || null,
      error_value: standardFields.error_value !== undefined ? Number(standardFields.error_value) : null,
      error_value_unit: standardFields.error_value_unit || null,
      additional_info: standardFields.additional_info || null,
      normative_document: standardFields.normative_document || null,
      expiration_date: standardFields.expiration_date || null,
      expiration_notification: standardFields.expiration_notification || null,
      release_date: standardFields.release_date || null,
      notes: standardFields.notes || null,
      is_precursor: Boolean(standardFields.is_precursor),
      custom_columns: custom_columns || {}
    };
    
    console.log('Данные для отправки на сервер:', requestData);
    
    const response = await axiosInstance.patch(
      `/api/laboratories/${laboratoryId}/standards/${standardId}`,
      requestData
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.data?.detail) {
      throw new Error(error.response.data.detail);
    }
    throw error;
  }
};

export const deleteStandard = async (laboratoryId: string | number, standardId: number): Promise<void> => {
  try {
    await axiosInstance.delete(`/api/laboratories/${laboratoryId}/standards/${standardId}`);
  } catch (error: any) {
    if (error.response?.data?.detail) {
      throw new Error(error.response.data.detail);
    }
    throw error;
  }
};

export const exportStandards = async (standardIds: React.Key[], format: ExportFormat) => {
    const response = await axiosInstance.get(`/api/standards/export`, {
        params: {
            standard_ids: standardIds,
            format: format
        },
        responseType: 'blob'
    });
    
    // Create a download link
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `standards_export.${format.toLowerCase()}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const getPrecursorGroups = async (laboratoryId: string): Promise<string[]> => {
    try {
        const response = await axiosInstance.get(`/api/laboratories/${laboratoryId}/precursor-groups`);
        return response.data;
    } catch (error) {
        console.error('Error fetching precursor groups:', error);
        throw error;
    }
};