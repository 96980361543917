import React, { useState, useRef, useCallback } from 'react';
import { Layout, Row, Col, Button, theme, Space, Typography } from 'antd';
import styled from 'styled-components';
import { PlusOutlined, ExportOutlined } from '@ant-design/icons';
import HeaderAdmin from './HeaderAdmin';
import MSIYearPlanTable from '../tables/MSIYearPlan';
import MSIGroups from '../../components/MSIgroups';
import AdminAddMSI from '../../components/AdminAddMSI';
import { msiApi } from '../../api/msi';

const { Content } = Layout;
const { useToken } = theme;
const { Title } = Typography;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const ControlsBlock = styled.div`
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const ButtonsContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const ActionButton = styled(Button)`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const AddMSIContainer = styled.div`
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 1000;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
`;

const MSI: React.FC = () => {
    const { token } = useToken();
    const [selectedMSIGroup, setSelectedMSIGroup] = useState<string>('Нефть и нефтепродукты');
    const [isAddMSIModalOpen, setIsAddMSIModalOpen] = useState(false);
    const addMSIRef = useRef<HTMLDivElement>(null);
    const [searchValue, setSearchValue] = useState('');

    const handleAddMSI = () => {
        setIsAddMSIModalOpen(true);
    };

    const handleAddMSICancel = () => {
        setIsAddMSIModalOpen(false);
    };

    const handleAddMSISuccess = useCallback(() => {
        setIsAddMSIModalOpen(false);
        // Здесь можно добавить обновление данных таблицы, если необходимо
    }, []);

    // Обработчик клика вне формы добавления МСИ
    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            
            // Проверяем, является ли клик по элементу Select или его выпадающему списку
            const isSelectElement = target.closest('.ant-select') !== null ||
                                  target.closest('.ant-select-dropdown') !== null;
            
            if (isSelectElement) {
                return;
            }

            if (addMSIRef.current && !addMSIRef.current.contains(target)) {
                setIsAddMSIModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderAdmin />
            <StyledContent>
                <ControlsBlock>
                    <Space />
                    <Title level={4}>Годовой план МСИ</Title>
                    <HeadingExtra>
                        <ButtonsContainer>
                            <ActionButton
                                icon={<PlusOutlined style={{ color: token['blue-5'] }} />}
                                onClick={handleAddMSI}
                            >
                                Добавить запись
                            </ActionButton>
                            {isAddMSIModalOpen && (
                                <AddMSIContainer ref={addMSIRef}>
                                    <AdminAddMSI
                                        onCancel={handleAddMSICancel}
                                        onSuccess={handleAddMSISuccess}
                                    />
                                </AddMSIContainer>
                            )}
                            <Button 
                                icon={<ExportOutlined style={{ color: token['blue-5'] }} />} 
                                style={{ height: '40px', width: '45px', display: 'none' }} 
                            />
                        </ButtonsContainer>
                    </HeadingExtra>
                </ControlsBlock>

                <Row gutter={24} style={{ marginTop: '0px' }}>
                    <Col span={6}>
                        <MSIGroups onSelect={(group) => setSelectedMSIGroup(group)} />
                    </Col>
                    <Col span={18}>
                        <MSIYearPlanTable 
                            searchValue={searchValue}
                            selectedGroup={selectedMSIGroup}
                            isAdmin={true}
                        />
                    </Col>
                </Row>
            </StyledContent>
        </Layout>
    );
};

export default MSI;