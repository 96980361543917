import { FunctionComponent, useEffect, useState } from 'react';
import { Table, theme, message, Button, Upload } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { createStyles } from 'antd-style';
import styled from 'styled-components';
import type { UploadProps } from 'antd';
import { getStandardSamples, uploadStandardSamples, type StandardSample } from '../../api/standardSamples';
import { UploadOutlined } from '@ant-design/icons';
const { useToken } = theme;

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table {
      border: 1px solid #E0E0E0;
      margin-top: 16px;
      
      .ant-table-thead .ant-table-cell {
        color: #595959;
        height: 50px;
        padding: 8px 16px;
        white-space: nowrap;
      }

      .ant-table-tbody {
        .ant-table-cell {
          height: 50px;
          padding: 8px 16px;
          font-weight: 500;
          white-space: nowrap;

          .ant-select-selection-item,
          .ant-picker-input > input {
            font-weight: 500;
          }

          .ant-select .ant-select-selector {
            border-radius: 4px !important;
          }
        }
      }

      .cell-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
}));

const TextWithTooltip: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  if (typeof text === 'string' && text.length > 24) {
    return (
      <div className="cell-content">{text}</div>
    );
  }
  return <div>{text}</div>;
};

export const StandardSamplesUpload: FunctionComponent<{ onUploadSuccess?: () => void }> = ({ onUploadSuccess }) => {
    const uploadProps: UploadProps = {
        name: 'file',
        accept: '.xlsx',
        showUploadList: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                await uploadStandardSamples(file as File);
                message.success('Файл успешно загружен');
                onUploadSuccess?.();
                onSuccess?.('Ok');
            } catch (err) {
                message.error('Ошибка при загрузке файла');
                console.error(err);
                onError?.(err as Error);
            }
        },
    };

    return (
        <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} size="large" style={{ borderRadius: 4 }}>Загрузить файл Excel</Button>
        </Upload>
    );
};

const StandardSamplesBase: FunctionComponent = () => {
    const { styles } = useStyle();
    const [data, setData] = useState<StandardSample[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            console.log('Загрузка стандартных образцов...');
            
            // Получаем текущего пользователя из localStorage
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            console.log('Current user:', user);
            
            const samples = await getStandardSamples();
            console.log('Стандартные образцы загружены:', samples);
            setData(samples);
        } catch (error: any) {
            console.error('Ошибка при загрузке стандартных образцов:', error);
            if (error.response) {
                console.error('Детали ошибки:', {
                    status: error.response.status,
                    data: error.response.data
                });
                message.error(`Ошибка при загрузке данных: ${error.response.data?.detail || error.message}`);
            } else {
                message.error(`Ошибка при загрузке данных: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns: ColumnsType<StandardSample> = [
        {
            title: 'Индекс СО',
            dataIndex: 'index',
            key: 'index',
            ellipsis: true,
            minWidth: 100,
            render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
            title: 'Наименование СО',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            minWidth: 100,
            render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
            title: 'Номер ГСО',
            dataIndex: 'gso_number',
            key: 'gso_number',
            ellipsis: true,
            minWidth: 100,
            render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
            title: 'Категория СО',
            dataIndex: 'category',
            key: 'category',
            width: 150,
            render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
            title: 'Наименование показателя',
            dataIndex: 'parameter',
            key: 'parameter',
            ellipsis: true,
            minWidth: 100,
            render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
            title: 'Единицы измерения АЗ',
            dataIndex: 'measurement_unit',
            key: 'measurement_unit',
            ellipsis: true,
            minWidth: 100,
            render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
            title: 'Единицы измерения погрешности АЗ',
            dataIndex: 'error_unit',
            key: 'error_unit',
            ellipsis: true,
            minWidth: 100,
            render: (text: string) => <TextWithTooltip text={text} />,
        },
    ];

    return (
        <>
            <Table<StandardSample>
                className={styles.customTable}
                columns={columns}
                dataSource={data}
                loading={loading}
                bordered
                size="middle"
                scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
                pagination={{
                    pageSize: 1000,
                }}
                rowKey="id"
            />
        </>
    );
};

export default StandardSamplesBase; 