import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { theme } from './theme/themeConfig';
import ruRU from 'antd/es/locale/ru_RU';
import { LoginPage } from './pages/auth/LoginPage';
import LoginForm from './pages/auth/LoginForm';
import ResetPassword from './pages/auth/ResetPassword';
import Employers from './pages/admin/Employers';
import AdminMSI from './pages/admin/MSI';
import Laboratories from './pages/admin/Laboratories';
import ManageLaboratory from './pages/admin/ManageLaboratory';
import EmployersLaboratory from './pages/admin/EmployersLaboratory';
import SettingsLaboratoryAdmin from './pages/admin/SettingsLaboratory';
import Chemicals from './pages/admin/Chemicals';
import ChemicalsLaboratory from './pages/laboratory/Chemicals';
import Instruments from './pages/laboratory/Instruments';
import MSI from './pages/laboratory/MSI';
import { ProtectedRoute } from './components/ProtectedRoute';
import { useAuth } from './hooks/useAuth';
import SettingsLaboratory from './pages/laboratory/SettingsLaboratory';
import MainPage from './pages/quality-control/MainPage';
import DistributorMainPage from './pages/distributor/MainPage';
import DistributorManageLaboratory from './pages/distributor/ManageLaboratory';
import AdminNotifications from './pages/admin/Notifications';
import Notifications from './pages/distributor/Notifications';
import LaboratoryNotifications from './pages/laboratory/Notifications';
import NotFoundPage from './pages/404';

const LogoutComponent: React.FC = () => {
  const { logout } = useAuth();
  
  useEffect(() => {
    logout();
  }, [logout]);
  
  return null;
};

const InitialRoute: React.FC = () => {
  const { user } = useAuth();
  
  if (!user) return <Navigate to="/auth/login" replace />;
  
  if (user.is_admin || user.role === "admin") {
    return <Navigate to="/admin/laboratories" replace />;
  }
  
  switch (user.role) {
    case 'quality':
      return <Navigate to="/quality-control" replace />;
    case 'laboratory_employee':
      return <Navigate to={`/laboratory/${user.laboratory_id}/chemicals`} replace />;
    case 'distributor':
      return <Navigate to="/distributor" replace />;
    default:
      return <Navigate to="/auth/login" replace />;
  }
};

const App: React.FC = () => {
  return (
    <ConfigProvider theme={theme} locale={ruRU}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<InitialRoute />} />
          <Route path="/auth/login" element={<LoginForm />} />
          <Route path="/auth/forgot-password" element={<ResetPassword />} />
          
          <Route 
            path="/quality-control/*" 
            element={
              <ProtectedRoute requireRole="quality">
                <Routes>
                  <Route path="/" element={<MainPage />} />
                </Routes>
              </ProtectedRoute>
            } 
          />

          <Route 
            path="/distributor/*" 
            element={
              <ProtectedRoute requireRole="distributor">
                <Routes>
                  <Route path="/" element={<DistributorMainPage />} />
                  <Route path="/manage-laboratory/:id" element={<DistributorManageLaboratory />} />
                  <Route path="/notifications" element={<Notifications />} />
                </Routes>
              </ProtectedRoute>
            } 
          />

          <Route 
            path="/admin/*" 
            element={
              <ProtectedRoute requireAdmin={true}>
                <Routes>
                  <Route path="employers" element={<Employers />} />
                  <Route path="msi" element={<AdminMSI />} />
                  <Route path="laboratories" element={<Laboratories />} />
                  <Route path="manage-laboratory/:id" element={<ManageLaboratory />} />
                  <Route path="employers-laboratory/:id" element={<EmployersLaboratory />} />
                  <Route path="settings-laboratory/:id" element={<SettingsLaboratoryAdmin />} />
                  <Route path="chemicals" element={<Chemicals />} />
                  <Route path="notifications" element={<AdminNotifications />} />
                </Routes>
              </ProtectedRoute>
            } 
          />
          <Route
            path="/laboratory/:laboratoryId/instruments"
            element={
                <ProtectedRoute requireRole="laboratory_employee">
                    <Instruments />
                </ProtectedRoute>
            }
          />
          <Route
            path="/laboratory/:laboratoryId/msi"
            element={
                <ProtectedRoute requireRole="laboratory_employee">
                    <MSI />
                </ProtectedRoute>
            }
          />
          <Route
            path="/laboratory/:laboratoryId/settings"
            element={
                <ProtectedRoute requireRole="laboratory_employee">
                    <SettingsLaboratory />
                </ProtectedRoute>
            }
          />
          <Route
            path="/laboratory/:laboratoryId/notifications"
            element={
                <ProtectedRoute requireRole="laboratory_employee">
                    <LaboratoryNotifications />
                </ProtectedRoute>
            }
          />
          <Route path="/auth/logout" element={<LogoutComponent />} />
          <Route 
            path="/laboratory/:laboratoryId/chemicals" 
            element={
                <ProtectedRoute requireRole="laboratory_employee">
                    <ChemicalsLaboratory />
                </ProtectedRoute>
            } 
          />
          
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;