import React, { useState, useRef, useEffect, useCallback, useMemo, FunctionComponent } from 'react';
import { Table, Checkbox, Button, Tooltip, Space, Popover, theme, message } from 'antd';
import type { TableColumnsType } from 'antd';
import { createStyles } from 'antd-style';
import { FilterOutlined, EyeInvisibleOutlined, DatabaseOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { msiApi, MSIYearPlanData } from '../../api/msi';
import AdminAddMSI from '../../components/AdminAddMSI';

const { useToken } = theme;

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table {
      border: 1px solid #E0E0E0;
      
      .ant-table-thead .ant-table-cell {
        color: #595959;
      }

      .ant-table-tbody {
        .ant-table-cell {
          font-weight: 500;

          .ant-select-selection-item,
          .ant-picker-input > input {
            font-weight: 500;
          }

          .ant-select .ant-select-selector {
            border-radius: 4px !important;
          }
        }
      }

      .cell-content {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
}));

export interface MSIYearPlanType extends MSIYearPlanData {
  key: React.Key;
  isHeader?: boolean;
}

export const testMSIYearPlanData: MSIYearPlanType[] = Array.from({ length: 5 }).map((_, index) => ({
  key: index,
  group: `Группа ${index + 1}`,
  object: `Объект ${index + 1}`,
  round: `Раунд ${index + 1}`,
  round_dates: `01.${String(index % 12 + 1).padStart(2, '0')}.2024 - 30.${String(index % 12 + 1).padStart(2, '0')}.2024`,
  application_deadline: `15.${String(index % 12 + 1).padStart(2, '0')}.2024`,
  pickup_dates: `20.${String(index % 12 + 1).padStart(2, '0')}.2024 - 25.${String(index % 12 + 1).padStart(2, '0')}.2024`,
  result_submission: `10.${String((index % 12 + 2) % 12 + 1).padStart(2, '0')}.2024`,
  conclusion_dates: `20.${String((index % 12 + 2) % 12 + 1).padStart(2, '0')}.2024`,
  program_type: ['Тип A', 'Тип B', 'Тип C'][Math.floor(Math.random() * 3)],
}));

const methodOptions = [
  'Метод 1',
  'Метод 2',
  'Метод 3',
  'Метод 4'
].map(item => ({ label: item, value: item }));

const TextWithTooltip: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  if (typeof text === 'string' && text.length > 24) {
    return (
      <Tooltip title={text}>
        <div className="cell-content">{text}</div>
      </Tooltip>
    );
  }
  return <div>{text}</div>;
};

const ColumnMenu = styled.div`
  width: 306px;
  padding: 12px;
  background: #F5F5F5;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const MenuButton = styled(Button)<{ $isBlue?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 6.4px 12px;
  background: ${props => props.$isBlue ? '#1890FF' : 'white'};
  color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'};
  border: 0.5px solid ${props => props.$isBlue ? '#1890FF' : '#D9D9D9'};
  border-radius: 4px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;

  .anticon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: ${props => props.$isBlue ? '#40a9ff' : '#fff'} !important;
    color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'} !important;
    border-color: ${props => props.$isBlue ? '#40a9ff' : '#d9d9d9'} !important;
  }
`;

const FilterContent = styled.div`
  height: 200px;
  overflow-y: auto;
  margin-bottom: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const FilterDropdown: React.FC<{
  close: () => void;
  confirm: (selectedValues: string[]) => void;
  values: string[];
}> = ({ close, confirm, values }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const uniqueValues = Array.from(new Set(values)).sort();

  return (
    <div style={{ padding: 8, background: 'white', borderRadius: 4 }}>
      <FilterContent>
        {uniqueValues.map((value) => (
          <div key={value} style={{ marginBottom: 4 }}>
            <Checkbox
              checked={selectedValues.includes(value)}
              onChange={(e) => {
                const newValues = e.target.checked
                  ? [...selectedValues, value]
                  : selectedValues.filter(v => v !== value);
                setSelectedValues(newValues);
              }}
            >
              {value}
            </Checkbox>
          </div>
        ))}
      </FilterContent>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm(selectedValues);
            close();
          }}
          size="small"
        >
          Применить
        </Button>
        <Button
          onClick={() => {
            setSelectedValues([]);
            confirm([]);
            close();
          }}
          size="small"
        >
          Сбросить
        </Button>
      </Space>
    </div>
  );
};

interface CustomColumnTitleProps {
  title: React.ReactNode;
  onSort?: (order: 'asc' | 'desc') => void;
  onFilter?: (values: string[]) => boolean;
  onHide?: () => void;
  onManage?: () => void;
  type?: 'number' | 'date';
  dataIndex?: string[];
  filterValues?: string[];
}

const CustomColumnTitle: React.FC<CustomColumnTitleProps> = ({
  title,
  onSort,
  onFilter,
  onHide,
  onManage,
  type,
  filterValues = []
}) => {
  const { token } = useToken();
  const [open, setOpen] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterValue, setFilterValue] = useState<string[]>([]);

  const handleMenuClick = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    setOpen(false);
  };

  const handleFilter = () => {
    setOpen(false);
    setFilterVisible(true);
  };

  const columnMenu = (
    <ColumnMenu>
      {type && (
        <>
          <MenuButton 
            icon={<ArrowUpOutlined style={{ color: token['blue-5'] }} />}
            onClick={() => handleMenuClick(() => onSort?.('asc'))}
          >
            Сортировать по возрастанию
          </MenuButton>
          <MenuButton 
            icon={<ArrowDownOutlined style={{ color: token['blue-5'] }} />}
            onClick={() => handleMenuClick(() => onSort?.('desc'))}
          >
            Сортировать по убыванию
          </MenuButton>
        </>
      )}
      <MenuButton 
        icon={<FilterOutlined style={{ color: token['blue-5'] }} />}
        onClick={handleFilter}
      >
        Фильтры
      </MenuButton>
      <MenuButton 
        icon={<EyeInvisibleOutlined style={{ color: token['blue-5'] }} />}
        onClick={() => handleMenuClick(onHide)}
      >
        Скрыть
      </MenuButton>
      <MenuButton 
        $isBlue 
        icon={<DatabaseOutlined style={{ color: 'white' }} />}
        onClick={() => handleMenuClick(onManage)}
      >
        Управление колонками
      </MenuButton>
    </ColumnMenu>
  );

  return (
    <>
      <Popover 
        trigger="click"
        open={open}
        onOpenChange={setOpen}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'transparent', boxShadow: 'none' }}
      >
        <div style={{ cursor: 'pointer' }}>{title}</div>
      </Popover>
      <Popover
        content={
          <FilterDropdown
            close={() => setFilterVisible(false)}
            confirm={(values) => {
              if (onFilter) {
                onFilter(values);
              }
              setFilterVisible(false);
            }}
            values={filterValues}
          />
        }
        trigger="click"
        open={filterVisible}
        onOpenChange={setFilterVisible}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'white', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: filterVisible ? 'block' : 'none' }} />
      </Popover>
    </>
  );
};

interface FilterFunction {
  (value: string): boolean;
}

interface StandartSamplesTableProps {
  searchValue?: string;
  selectedGroup?: string;
  isAdmin?: boolean;
}

const TableContainer = styled.div`
  position: relative;
  width: 100%;
`;

const EditFormContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

const StandartSamplesTable: FunctionComponent<StandartSamplesTableProps> = ({ 
  searchValue = '',
  selectedGroup,
  isAdmin = false
}) => {
  const { styles } = useStyle();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MSIYearPlanData[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [editingRecord, setEditingRecord] = useState<(MSIYearPlanType & { id: number }) | null>(null);
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      
      // Проверяем, является ли клик по элементу Select или его выпадающему списку
      const isSelectClick = target.closest('.ant-select') !== null || 
                          target.closest('.ant-select-dropdown') !== null;
      
      if (isSelectClick) {
        return;
      }

      if (formRef.current && !formRef.current.contains(target)) {
        setEditingRecord(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Загрузка данных
  const fetchData = useCallback(async () => {
    if (!selectedGroup) return;
    
    try {
      setLoading(true);
      console.log('Fetching data for group:', selectedGroup); // Отладочный вывод
      const response = await msiApi.getMSIYearPlan(selectedGroup);
      setData(response.data);
    } catch (error) {
      message.error('Ошибка при загрузке данных');
      console.error('Error fetching MSI year plan:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedGroup]);

  // Вызываем загрузку данных при изменении выбранной группы
  useEffect(() => {
    if (selectedGroup) {
      fetchData();
    }
  }, [selectedGroup, fetchData]);

  // Фильтрация и поиск данных
  const processedData = useMemo(() => {
    const withKeys = data.map((item, index) => ({
      ...item,
      key: index,
    })) as MSIYearPlanType[];

    if (!searchValue && Object.keys(filters).length === 0) {
      return withKeys;
    }

    return withKeys.filter(item => {
      const matchesSearch = !searchValue || searchInFields(item, searchValue);
      const matchesFilters = Object.entries(filters).every(([key, filterValues]) => {
        if (filterValues.length === 0) return true;
        const keys = key.split('.');
        const fieldValue = keys.reduce((obj, key) => obj?.[key], item as any)?.toString();
        return filterValues.includes(fieldValue);
      });

      return matchesSearch && matchesFilters;
    });
  }, [data, searchValue, filters]);

  // Группировка данных по раундам
  const groupedData = useMemo(() => {
    if (!processedData.length) return [];

    const result: MSIYearPlanType[] = [];
    const roundGroups = processedData.reduce((acc, item) => {
      if (!item.round) return acc;
      
      if (!acc[item.round]) {
        acc[item.round] = [];
      }
      acc[item.round].push(item);
      return acc;
    }, {} as Record<string, MSIYearPlanType[]>);

    // Сортируем раунды
    const sortedRounds = Object.keys(roundGroups).sort((a, b) => {
      const aNum = parseInt(a.replace(/[^0-9]/g, ''));
      const bNum = parseInt(b.replace(/[^0-9]/g, ''));
      return aNum - bNum;
    });

    sortedRounds.forEach((round) => {
      // Добавляем заголовок раунда
      const headerItem: MSIYearPlanType = {
        key: `header-${round}`,
        isHeader: true,
        group: selectedGroup || '',
        object: `${round}`,
        round: '',
        round_dates: '',
        application_deadline: '',
        pickup_dates: '',
        result_submission: '',
        conclusion_dates: '',
        program_type: '',
      };
      result.push(headerItem);
      // Добавляем записи раунда
      result.push(...roundGroups[round]);
    });

    return result;
  }, [processedData, selectedGroup]);

  // Обновляем стили для строки-заголовка
  const groupHeaderStyle: React.CSSProperties = {
    fontWeight: 400,
    padding: '8px 16px',
    textAlign: 'center',
    fontSize: '14px',
  };

  const renderCell = (value: any, record: MSIYearPlanType, column: typeof columns[0]) => {
    if (record.isHeader) {
      // Показываем заголовок только в первой колонке
      if ('dataIndex' in column && column.dataIndex === 'object') {
        return (
          <div style={{
            ...groupHeaderStyle,
            gridColumn: '1 / -1',
            width: '100%'
          }}>
            {record.object}
          </div>
        );
      }
      // Для остальных колонок возвращаем пустую ячейку
      return null;
    }
    return <TextWithTooltip text={value} />;
  };

  const columnsWithRender = columns.map(col => ({
    ...col,
    render: (value: any, record: MSIYearPlanType) => renderCell(value, record, col),
    onCell: (record: MSIYearPlanType) => {
      if (record.isHeader) {
        // Для первой колонки растягиваем на всю ширину
        if ('dataIndex' in col && col.dataIndex === 'object') {
          return { colSpan: columns.length };
        }
        // Для остальных колонок скрываем
        return { colSpan: 0 };
      }
      return {};
    },
  }));

  const getVisibleColumns = (cols: TableColumnsType<MSIYearPlanType>): TableColumnsType<MSIYearPlanType> => {
    return cols.filter(col => !col.hidden);
  };

  const handleRowClick = (record: MSIYearPlanType & { id: number }) => {
    if (isAdmin && !record.isHeader) {
      setEditingRecord(record);
    }
  };

  const handleEditSuccess = () => {
    setEditingRecord(null);
    // Немедленно обновляем данные
    fetchData();
  };

  return (
    <TableContainer>
      <Table<MSIYearPlanType>
        className={styles.customTable}
        columns={getVisibleColumns(columnsWithRender)}
        dataSource={groupedData}
        bordered
        size="middle"
        loading={loading}
        scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record as MSIYearPlanType & { id: number }),
          style: { 
            cursor: isAdmin && !record.isHeader ? 'pointer' : 'default',
            backgroundColor: record.isHeader ? '#fafafa' : undefined
          }
        })}
      />

      {editingRecord && (
        <EditFormContainer ref={formRef}>
          <AdminAddMSI
            editData={editingRecord}
            onCancel={() => setEditingRecord(null)}
            onSuccess={handleEditSuccess}
          />
        </EditFormContainer>
      )}
    </TableContainer>
  );
};

const searchFields = [
  'object',
  'round_dates',
  'application_deadline',
  'pickup_dates',
  'result_submission',
  'conclusion_dates',
  'program_type'
];

const searchInFields = (record: MSIYearPlanType, value: string): boolean => {
  return searchFields.some(field => {
    const fieldValue = record[field as keyof MSIYearPlanType];
    return fieldValue?.toString().toLowerCase().includes(value.toLowerCase());
  });
};

const columns: TableColumnsType<MSIYearPlanType> = [
  {
    title: 'Объект',
    dataIndex: 'object',
    key: 'object',
    width: 232,
  },
  {
    title: 'Сроки проведения раунда',
    dataIndex: 'round_dates',
    key: 'round_dates',
    width: 232,
  },
  {
    title: 'Прием заявок до',
    dataIndex: 'application_deadline',
    key: 'application_deadline',
    width: 232,
  },
  {
    title: 'Сроки самовывоза',
    dataIndex: 'pickup_dates',
    key: 'pickup_dates',
    width: 232,
  },
  {
    title: 'Предоставление результата',
    dataIndex: 'result_submission',
    key: 'result_submission',
    width: 232,
  },
  {
    title: 'Предоставление заключений',
    dataIndex: 'conclusion_dates',
    key: 'conclusion_dates',
    width: 232,
  },
  {
    title: 'Тип программы*',
    dataIndex: 'program_type',
    key: 'program_type',
    width: 232,
  },
];

export default StandartSamplesTable;

