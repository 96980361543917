import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axios';

interface User {
  id: number;
  name: string;
  role: string;
  is_admin: boolean;
  laboratory_id?: number;
}

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  const login = (userData: User, token: string) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
    setIsAuthenticated(true);
    setIsAdmin(userData.is_admin || userData.role === "admin");
  };

  const logout = async () => {
    try {
      await axiosInstance.post('/api/auth/logout');
    } catch (error) {
      console.error('Ошибка при выходе:', error);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setIsAuthenticated(false);
      setIsAdmin(false);
      setUser(null);
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userStr = localStorage.getItem('user');
    
    if (token && userStr) {
      const userData: User = JSON.parse(userStr);
      setIsAuthenticated(true);
      setIsAdmin(userData.is_admin || userData.role === "admin");
      setUser(userData);
    } else {
      setIsAuthenticated(false);
      setIsAdmin(false);
      setUser(null);
    }
    setIsLoading(false);
  }, []);

  return { isAuthenticated, isAdmin, isLoading, login, logout, user };
};