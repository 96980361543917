import { Col, Progress, Row, Typography, Modal } from "antd";
import React, { useState, useEffect } from "react";

const { Text } = Typography;

interface ExportProcessProps {
    open: boolean;
}

const ExportProcess: React.FC<ExportProcessProps> = ({ open }): JSX.Element => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        
        if (open) {
            setProgress(0);
            timer = setInterval(() => {
                setProgress(prev => {
                    if (prev >= 100) {
                        clearInterval(timer);
                        return 100;
                    }
                    return prev + 1;
                });
            }, 30); // 3000ms / 100 steps = 30ms per step
        }

        return () => {
            clearInterval(timer);
            setProgress(0);
        };
    }, [open]);

    return (
        <Modal
            open={open}
            footer={null}
            width={303}
            centered
            closable={false}
        >
            <Row justify="center">
                <Col>
                    <Text strong style={{ fontSize: 16 }}>
                        Экспортируется
                    </Text>
                </Col>
            </Row>
            <Row justify="center" style={{ marginTop: 16, marginBottom: 16 }}>
                <Col>
                    <Progress type="circle" percent={progress} width={80} />
                </Col>
            </Row>
        </Modal>
    );
};

export default ExportProcess;
