import { FunctionComponent, useState, useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import HeaderLab from './Header';
import { Typography, Layout, Space, Button, theme, Segmented, Input, Popover, message } from 'antd';
import { ExportOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import MSITable, { MSIType, testMSIData } from '../tables/MSI';
import MSIYearPlanTable, { MSIYearPlanType, testMSIYearPlanData } from '../tables/MSIYearPlan';
import debounce from 'lodash/debounce';
import MSIGroups from '../../components/MSIgroups';
import AddMSI from '../../components/AddMSI';
import { Col, Row } from 'antd';
import { msiApi, MSIData } from '../../api/msi';
import ExportTypes, { ExportFormat } from '../../components/ExportTypes';

const { Title } = Typography;
const { Content } = Layout;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const ControlsBlock = styled.div`
    // margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const SearchInput = styled(Input)`
  width: 45px;
  height: 40px;
  border-color: #d9d9d9;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
  padding: 0;
  
  &.expanded {
    width: 240px;
    padding: 4px 11px;
    border-color: #d9d9d9;
  }

  &:hover {
    border-color: #d9d9d9;
  }

  &:focus {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .ant-input {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .ant-input-prefix {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  input {
    line-height: normal !important;
    height: 100% !important;
  }

  &:not(.expanded) {
    .ant-input-prefix {
      margin-right: 0;
    }
    
    .ant-input {
      width: 0;
      padding: 0;
    }
  }

  &.expanded .ant-input-prefix {
    width: auto;
    margin-right: 8px;
  }
`;

interface RouteParams {
    laboratoryId?: string;
}

const Laboratory: FunctionComponent = () => {
    const { laboratoryId } = useParams<keyof RouteParams>();
    const { token } = useToken();
    const [activeSegment, setActiveSegment] = useState<string | number>('План участия МСИ');
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isAddMSIVisible, setIsAddMSIVisible] = useState(false);
    const [isExportModalVisible, setIsExportModalVisible] = useState(false);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    
    // Состояния для данных таблиц
    const [msiData, setMsiData] = useState<MSIType[]>(testMSIData);
    const [msiYearPlanData, setMsiYearPlanData] = useState<MSIYearPlanType[]>(testMSIYearPlanData);

    // Функции поиска для каждого типа данных
    const searchInMSIFields = useCallback((item: MSIType, value: string) => {
        const searchFields = [
            item.measurement_method,
            item.measurement_methodology,
            item.research_object,
            item.characteristic,
            item.employee,
            item.provider,
            item.deadline
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    const searchInMSIYearPlanFields = useCallback((item: MSIYearPlanType, value: string) => {
        const searchFields = [
            item.object,
            item.roundDates,
            item.applicationDeadline,
            item.pickupDates,
            item.resultSubmission,
            item.conclusionDates,
            item.programType
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    // Отдельная функция для немедленной фильтрации
    const filterDataImmediate = (value: string) => {
        if (!value) {
            setMsiData(testMSIData);
            setMsiYearPlanData(testMSIYearPlanData);
            return;
        }

        switch (activeSegment) {
            case 'План участия МСИ':
                const filteredMSI = testMSIData.filter(item => 
                    searchInMSIFields(item, value)
                );
                setMsiData(filteredMSI);
                break;
            case 'Годовой план МСИ':
                const filteredMSIYearPlan = testMSIYearPlanData.filter(item => 
                    searchInMSIYearPlanFields(item, value)
                );
                setMsiYearPlanData(filteredMSIYearPlan);
                break;
        }
    };

    // Обработчик изменения сегмента
    const handleSegmentChange = (value: string | number) => {
        setActiveSegment(value);
        if (searchValue) {
            filterDataImmediate(searchValue);
        }
    };

    const handleSearch = useCallback((value: string) => {
        setSearchValue(value);
        filterDataImmediate(value);
    }, [filterDataImmediate]);

    const handleAddMSISuccess = async () => {
        setIsAddMSIVisible(false);
        if (laboratoryId) {
            try {
                const response = await msiApi.getLaboratoryMSI(parseInt(laboratoryId));
                const msiWithKeys = response.data.map(item => ({
                    ...item,
                    key: item.id
                }));
                setMsiData(msiWithKeys);
                message.success('Данные успешно обновлены');
            } catch (error) {
                console.error('Error fetching MSI data:', error);
                message.error('Ошибка при обновлении данных');
            }
        }
    };

    const handleExport = async (format: ExportFormat) => {
        try {
            const response = await msiApi.exportMSI(format, selectedIds.map(id => Number(id)));
            
            const blob = new Blob([response.data], {
                type: response.headers['content-type']
            });
            
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            
            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition
                ? contentDisposition.split('filename=')[1].replace(/"/g, '')
                : `msi_export.${format.toLowerCase()}`;
            
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            
            message.success('Экспорт выполнен успешно');
        } catch (error) {
            console.error('Export error:', error);
            message.error('Ошибка при экспорте данных');
        }
    };

    const renderTable = () => {
        switch (activeSegment) {
            case 'План участия МСИ':
                return <MSITable data={msiData} searchValue={searchValue} onExport={setSelectedIds} />;
            case 'Годовой план МСИ':
                return <MSIYearPlanTable data={msiYearPlanData} searchValue={searchValue} />;
            default:
                return <MSITable data={msiData} searchValue={searchValue} />;
        }
    };

    const renderButtons = () => {
        switch (activeSegment) {
            case 'План участия МСИ':
                return (
                    <>
                        <Button 
                            style={{ height: '40px', width: '45px' }} 
                            icon={<ExportOutlined style={{ color: token.colorPrimary }} />}
                            onClick={() => setIsExportModalVisible(true)}
                        />
                        <Popover
                            content={
                                <AddMSI
                                    onCancel={() => setIsAddMSIVisible(false)}
                                    onSuccess={handleAddMSISuccess}
                                    laboratoryId={laboratoryId || ''}
                                />
                            }
                            trigger="click"
                            open={isAddMSIVisible}
                            onOpenChange={setIsAddMSIVisible}
                            placement="bottomRight"
                            overlayStyle={{ padding: 0 }}
                        >
                            <Button 
                                icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                                style={{ height: '40px' }}
                            >
                                Добавить запись
                            </Button>
                        </Popover>
                        <Button 
                            icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px' }}
                        >
                            Заявка на участие
                        </Button>
                    </>
                );
            case 'Годовой план МСИ':
                return (
                    <>
                        <Button 
                            icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px' }}
                        >
                            Заявка на участие
                        </Button>
                        <Button 
                            style={{ height: '40px' }}
                            type='primary'
                            href="https://google.com"
                        >
                            Перейти на сайт
                        </Button>
                    </>
                );
            default:
                return null;
        }
    };

    const renderContent = () => {
        switch (activeSegment) {
            case 'План участия МСИ':
                return (
                    <Row>
                        <Col span={24}>
                            {renderTable()}
                        </Col>
                    </Row>
                );
            case 'Годовой план МСИ':
                return (
                    <Row gutter={24}>
                        <Col span={6}>
                            <MSIGroups onSelect={(item) => console.log('Selected:', item)} />
                        </Col>
                        <Col span={18}>
                            {renderTable()}
                        </Col>
                    </Row>
                );
            default:
                return renderTable();
        }
    };

    useEffect(() => {
        const fetchMSIData = async () => {
            if (!laboratoryId) return;
            
            try {
                const response = await msiApi.getLaboratoryMSI(parseInt(laboratoryId));
                const msiWithKeys = response.data.map(item => ({
                    ...item,
                    key: item.id
                }));
                setMsiData(msiWithKeys);
            } catch (error) {
                console.error('Error fetching MSI data:', error);
            }
        };
        
        fetchMSIData();
    }, [laboratoryId]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderLab />
            <StyledContent>
                <ControlsBlock style={{ marginBottom: 24 }}>
                    <Segmented 
                        size="large" 
                        options={['План участия МСИ', 'Годовой план МСИ']} 
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                        value={activeSegment}
                        onChange={handleSegmentChange}
                    />
                    <HeadingExtra>
                        <SearchInput
                            className={isSearchExpanded ? "expanded" : ""}
                            placeholder={isSearchExpanded ? "Поиск" : ""}
                            prefix={<SearchOutlined style={{ color: token['blue-6'] }} />}
                            onFocus={() => setIsSearchExpanded(true)}
                            onChange={(e) => handleSearch(e.target.value)}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    setIsSearchExpanded(false);
                                }
                            }}
                            style={{ height: '40px' }}
                            value={searchValue}
                        />
                        {renderButtons()}
                    </HeadingExtra>
                </ControlsBlock>
                {renderContent()}
            </StyledContent>
            <ExportTypes
                open={isExportModalVisible}
                onCancel={() => setIsExportModalVisible(false)}
                onExport={handleExport}
                selectedRows={selectedIds}
            />
        </Layout>
    );
};

export default Laboratory; 