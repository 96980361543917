import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Row, Select, Typography, DatePicker, Form, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import dayjs from 'dayjs';
import axiosInstance from '../api/axios';

const { Option } = Select;
const { Text } = Typography;

const ScreenContainer = styled.div`
  width: 400px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const ScreenTitle = styled(Typography.Title)`
  margin: 16px !important;
  font-size: 16px !important;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
  width: 100%;
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  padding: 16px;
  display: flex;
  justify-content: center;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
    width: 178px;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0 !important;
    
    > label {
      font-size: 12px;
      color: #8c8c8c;
      font-weight: 500;
      height: auto;
      margin-bottom: 0px !important;
      line-height: 0 !important;
      
      &::before {
        display: none !important;
      }
      
      &::after {
        display: none;
      }
    }
  }
  
  .ant-form-item-control {
    margin-top: 0px;
  }
  
  .ant-form-item-explain {
    min-height: 0;
    margin-top: 0px;
    font-size: 12px;
  }
`;

interface AddInstrumentsProps {
  onCancel: () => void;
  type: string;
  laboratoryId: string;
  onSuccess: () => void;
}

const AddInstruments: React.FC<AddInstrumentsProps> = ({ onCancel, type, laboratoryId, onSuccess }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);

            // Преобразуем даты в строки ISO
            if (values.verification_date) {
                values.verification_date = values.verification_date.toISOString().split('T')[0];
            }
            if (values.next_verification_date) {
                values.next_verification_date = values.next_verification_date.toISOString().split('T')[0];
            }
            if (values.commissioning_year) {
                values.commissioning_year = values.commissioning_year.year();
            }

            // Добавляем number для соответствия схеме
            values.number = 1; // или любая логика генерации номера

            let endpoint;
            switch (type) {
                case 'Средства измерения (СИ)':
                    endpoint = `/instruments/laboratory/${laboratoryId}/measuring`;
                    break;
                case 'Испытательное (ИО)':
                    endpoint = `/instruments/laboratory/${laboratoryId}/testing`;
                    break;
                case 'Вспомогательное (ВО)':
                    endpoint = `/instruments/laboratory/${laboratoryId}/helping`;
                    break;
                default:
                    throw new Error('Invalid instrument type');
            }

            // Очищаем undefined значения
            const cleanValues = Object.fromEntries(
                Object.entries(values).filter(([_, v]) => v !== undefined)
            );

            await axiosInstance.post(endpoint, cleanValues);
            message.success('Запись успешно добавлена');
            form.resetFields();
            onSuccess();
            onCancel();
        } catch (error) {
            console.error('Error adding instrument:', error);
            message.error('Ошибка при добавлении записи');
        } finally {
            setLoading(false);
        }
    };

    const renderFields = () => {
        switch (type) {
            case 'Средства измерения (СИ)':
                return (
                    <>
                        <Col span={24}>
                            <StyledFormItem
                                name="status"
                                label="Статус оборудования"
                                rules={[{ required: true, message: 'Выберите статус' }]}
                            >
                                <StyledSelect placeholder="выберите статус">
                                    <Option value="В работе">В работе</Option>
                                    <Option value="На поверке">На поверке</Option>
                                    <Option value="В ремонте">В ремонте</Option>
                                    <Option value="Списан">Списан</Option>
                                </StyledSelect>
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="characteristics"
                                label="Наименование определяемых характеристик"
                            >
                                <StyledInput placeholder="введите характеристики" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="name"
                                label="Наименование СИ"
                                rules={[{ required: true, message: 'Введите наименование' }]}
                            >
                                <StyledInput placeholder="введите название" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="manufacturer"
                                label="Изготовитель"
                            >
                                <StyledInput placeholder="введите изготовителя" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="commissioning_year"
                                label="Год ввода в эксплуатацию"
                                rules={[{ required: true, message: 'Введите год ввода в эксплуатацию' }]}
                            >
                                <StyledDatePicker picker="year" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="measurement_range"
                                label="Диапазон измерений"
                            >
                                <StyledInput placeholder="введите диапазон" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="accuracy_class"
                                label="Класс точности, погрешность"
                                rules={[{ required: true, message: 'Выберите класс точности' }]}
                            >
                                <StyledSelect placeholder="выберите класс точности">
                                    <Option value="0.1">0.1</Option>
                                    <Option value="0.5">0.5</Option>
                                    <Option value="1.0">1.0</Option>
                                    <Option value="2.0">2.0</Option>
                                </StyledSelect>
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="certificate_number"
                                label="Свидетельство о проверке"
                            >
                                <StyledInput placeholder="введите номер свидетельства" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="verification_date"
                                label="Дата проверки / калибровки"
                                rules={[{ required: true, message: 'Введите дату проверки' }]}
                            >
                                <StyledDatePicker format="DD.MM.YYYY" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="next_verification_date"
                                label="Дата проведения следующей проверки"
                                rules={[{ required: true, message: 'Введите дату следующей проверки' }]}
                            >
                                <StyledDatePicker format="DD.MM.YYYY" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="registration_number"
                                label="Регистрационный номер"
                            >
                                <StyledInput placeholder="введите номер" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="verification_method"
                                label="Методика проверки"
                                rules={[{ required: true, message: 'Выберите методику' }]}
                            >
                                <StyledSelect placeholder="выберите методику">
                                    <Option value="МП-001">МП-001</Option>
                                    <Option value="МП-002">МП-002</Option>
                                    <Option value="МП-003">МП-003</Option>
                                </StyledSelect>
                            </StyledFormItem>
                        </Col>
                    </>
                );

            case 'Испытательное (ИО)':
                return (
                    <>
                        <Col span={24}>
                            <StyledFormItem
                                name="status"
                                label="Статус оборудования"
                                rules={[{ required: true, message: 'Выберите статус' }]}
                            >
                                <StyledSelect placeholder="выберите статус">
                                    <Option value="В работе">В работе</Option>
                                    <Option value="На аттестации">На аттестации</Option>
                                    <Option value="В ремонте">В ремонте</Option>
                                    <Option value="Списан">Списан</Option>
                                </StyledSelect>
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="characteristics"
                                label="Наименование определяемых характеристик"
                            >
                                <StyledInput placeholder="введите характеристики" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="test_groups"
                                label="Наименование испытуемых групп объектов"
                                rules={[{ required: true, message: 'Введите название' }]}
                            >
                                <StyledInput placeholder="введите название" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="testing_equipment"
                                label="Наименование испытательного оборудования"
                                rules={[{ required: true, message: 'Введите название' }]}
                            >
                                <StyledInput placeholder="введите название" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="manufacturer"
                                label="Изготов��тель"
                            >
                                <StyledInput placeholder="введите название" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="technical_characteristics"
                                label="Основные тех. характеристики"
                            >
                                <StyledInput placeholder="введите характеристики" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="commissioning_year"
                                label="Год ввода в эксплуатацию"
                                rules={[{ required: true, message: 'Введите год' }]}
                            >
                                <StyledDatePicker picker="year" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="document_date_and_number"
                                label="Дата и номер документа"
                            >
                                <StyledInput placeholder="введите данные" />
                            </StyledFormItem>
                        </Col>
                    </>
                );

            case 'Вспомогательное (ВО)':
                return (
                    <>
                        <Col span={24}>
                            <StyledFormItem
                                name="status"
                                label="Статус оборудования"
                                rules={[{ required: true, message: 'Выберите статус' }]}
                            >
                                <StyledSelect placeholder="выберите статус">
                                    <Option value="В работе">В работе</Option>
                                    <Option value="На обслуживании">На обслуживании</Option>
                                    <Option value="В ремонте">В ремонте</Option>
                                    <Option value="Списан">Списан</Option>
                                </StyledSelect>
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="name"
                                label="Наименование"
                                rules={[{ required: true, message: 'Введите название' }]}
                            >
                                <StyledInput placeholder="введите название" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="manufacturer"
                                label="Изготовитель"
                                rules={[{ required: true, message: 'Введите название' }]}
                            >
                                <StyledInput placeholder="введите название" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="commissioning_year"
                                label="Год ввода в эксплуатацию"
                                rules={[{ required: true, message: 'Введите год ввода в эксплуатацию' }]}
                            >
                                <StyledDatePicker picker="year" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="purpose"
                                label="Назначение"
                                rules={[{ required: true, message: 'Введите назначение' }]}
                            >
                                <StyledInput placeholder="введите назначение" />
                            </StyledFormItem>
                        </Col>
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <ScreenContainer>
            <ScreenTitle level={4}>Новая запись</ScreenTitle>
            
            <ScrollContent>
                <StyledForm form={form} layout="vertical">
                    <Row gutter={[16, 16]}>
                        {renderFields()}
                        
                        {/* Общие поля для всех типов */}
                        <Col span={24}>
                            <StyledFormItem
                                name="ownership_document"
                                label="Право собственности"
                            >
                                <StyledInput placeholder="введите текст" />
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="location"
                                label="Место установки или хранения"
                            >
                                <StyledSelect placeholder="выберите место">
                                    <Option value="Лаборатория A">Лаборатори�� A</Option>
                                    <Option value="Лаборатория B">Лаборатория B</Option>
                                    <Option value="Лаборатория C">Лаборатория C</Option>
                                </StyledSelect>
                            </StyledFormItem>
                        </Col>
                        <Col span={24}>
                            <StyledFormItem
                                name="notes"
                                label="Примечание"
                            >
                                <StyledInput placeholder="введите примечание" />
                            </StyledFormItem>
                        </Col>
                    </Row>
                </StyledForm>
            </ScrollContent>

            <ButtonsRow>
                <Button onClick={onCancel} disabled={loading}>Отмена</Button>
                <Button type="primary" onClick={handleSubmit} loading={loading}>
                    Сохранить
                </Button>
            </ButtonsRow>
        </ScreenContainer>
    );
};

export default AddInstruments;

