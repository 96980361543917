import React, { useEffect, useState } from 'react';
import { Table, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import axiosInstance from '../api/axios';
import dayjs from 'dayjs';
import RequestDetails from './RequestDetails';

interface RequestData {
    key: React.Key;
    id: number;
    created_at: string;
    request_type: string;
    user: {
        name: string;
    };
}

const StyledTable = styled(Table<RequestData>)`
    .ant-table-thead > tr > th {
        background: #fafafa;
        color: #595959 !important;
    }
    padding: 16px 0;
    
    .ant-table {
        border: 1px solid #E0E0E0;
    }

    .ant-table-tbody > tr {
        cursor: pointer;
        
        &:hover {
            background-color: #fafafa;
        }
    }
`;

interface RequestsTableProps {
    laboratoryId?: string;
}

const RequestsTable: React.FC<RequestsTableProps> = ({ laboratoryId }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<RequestData[]>([]);
    const [selectedRequest, setSelectedRequest] = useState<RequestData | null>(null);
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);

    const fetchRequests = async () => {
        if (!laboratoryId) return;
        
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axiosInstance.get(`/api/laboratories/${laboratoryId}/requests`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (response.data) {
                const formattedData = response.data.map((item: any) => ({
                    key: item.id,
                    id: item.id,
                    created_at: item.created_at,
                    request_type: item.request_type,
                    user: item.user
                }));
                setData(formattedData);
            }
        } catch (error: any) {
            console.error('Error fetching requests:', error);
            message.error(error.response?.data?.detail || 'Ошибка при загрузке заявок');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRequests();
    }, [laboratoryId]);

    const getRequestTypeText = (type: string): string => {
        switch (type) {
            case 'verification':
                return 'Поверка';
            case 'purchase':
                return 'Закупка';
            case 'msi':
                return 'МСИ';
            default:
                return type;
        }
    };

    const handleRowClick = (record: RequestData) => {
        setSelectedRequest(record);
        setIsDetailsVisible(true);
    };

    const columns: ColumnsType<RequestData> = [
        {
            title: '№ заявки',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Дата отправки',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (date: string) => dayjs(date).format('DD.MM.YYYY'),
            sorter: (a, b) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix(),
        },
        {
            title: 'Тип заявки',
            dataIndex: 'request_type',
            key: 'request_type',
            width: 150,
            render: (type: string) => getRequestTypeText(type),
            sorter: (a, b) => a.request_type.localeCompare(b.request_type),
        },
        {
            title: 'Ответственный сотрудник',
            dataIndex: ['user', 'name'],
            key: 'user_name',
            width: 200,
            sorter: (a, b) => a.user.name.localeCompare(b.user.name),
        }
    ];

    return (
        <>
            <StyledTable
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={{
                    total: data.length,
                    pageSize: 10,
                    showSizeChanger: false,
                    showTotal: (total) => `Всего ${total} записей`
                }}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            />
            {selectedRequest && (
                <RequestDetails
                    open={isDetailsVisible}
                    onCancel={() => setIsDetailsVisible(false)}
                    requestId={selectedRequest.id}
                    requestType={selectedRequest.request_type}
                />
            )}
        </>
    );
};

export default RequestsTable; 