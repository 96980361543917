import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, List, Row, Typography, theme, Popover } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import styled from 'styled-components';
import SubmenuGroups from './SubmenuGroups';
import { getPrecursorGroups } from '../api/chemicalRegistry';
import { useParams } from 'react-router-dom';

const { useToken } = theme;

interface StyledListItemProps {
  $isActive: boolean;
  $blueColor: string;
}

const StyledListItem = styled(List.Item)<StyledListItemProps>`
  && {
    background-color: ${props => props.$isActive ? "#e6f7ff" : "transparent"};
    cursor: pointer;
    padding: 8px 12px 8px 15px;
    border-radius: 4px;
    margin-bottom: 4px;
    transition: all 0.3s;
    color: ${props => props.$isActive ? props.$blueColor : 'rgba(0, 0, 0, 0.85)'};
    font-weight: ${props => props.$isActive ? 500 : 400};
    border-radius: 4px !important;
    border-bottom: none;

    &:hover {
      background-color: ${props => props.$isActive ? "#e6f7ff" : "#f5f5f5"};
    }
  }
`;

interface PrecursorGroupsProps {
  onGroupSelect?: (group: string) => void;
}

const PrecursorGroups: React.FC<PrecursorGroupsProps> = ({ onGroupSelect }) => {
  const { token } = useToken();
  const { laboratoryId } = useParams<{ laboratoryId: string }>();
  const [precursorGroups, setPrecursorGroups] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<string>('');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrecursorGroups = async () => {
      if (!laboratoryId) return;
      
      try {
        setLoading(true);
        const groups = await getPrecursorGroups(laboratoryId);
        setPrecursorGroups(groups);
        if (groups.length > 0) {
          setActiveItem(groups[0]);
          onGroupSelect?.(groups[0]);
        }
      } catch (error) {
        console.error('Error fetching precursor groups:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrecursorGroups();
  }, [laboratoryId, onGroupSelect]);

  const filteredData = useMemo(() => {
    return precursorGroups.filter(item => 
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [precursorGroups, searchText]);

  const handleItemClick = (item: string) => {
    setActiveItem(item);
    onGroupSelect?.(item);
  };

  return (
    <Card style={{ borderColor: '#d9d9d9' }}>
      <Row gutter={[16, 16]} style={{ padding: '0px', margin: '0px' }}>
        <Col span={24} style={{padding: '0px'}}>
          <Typography.Title level={5}>Группы прекурсоров</Typography.Title>
        </Col>
        <Col span={24} style={{padding: '0px'}}>
          <Input
            placeholder="Поиск"
            prefix={<SearchOutlined />}
            style={{ width: "100%", height: '32px', borderRadius: '4px' }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
        <Col span={24} style={{padding: '0px'}}>
          <Popover
            content={<SubmenuGroups />}
            trigger="click"
            placement="rightTop"
            open={popoverVisible}
            onOpenChange={setPopoverVisible}
            overlayStyle={{ padding: 0 }}
            arrow={false}
          >
            <Button
              type="text"
              style={{ 
                width: "100%",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: token['blue-5'],
                backgroundColor: '#f0f0f0',
                height: '40px'
              }}
            >
              <span>Все группы</span>
              <RightOutlined style={{ color: token['blue-5'], fontSize: '10px', fontWeight: 'bold' }} />
            </Button>
          </Popover>
        </Col>

        <Col span={24} style={{ marginTop: '16px', padding: '0px' }}>
          <List
            loading={loading}
            dataSource={filteredData}
            renderItem={(item) => (
              <StyledListItem
                $isActive={activeItem === item}
                $blueColor={token['blue-5']}
                onClick={() => handleItemClick(item)}
              >
                {item}
              </StyledListItem>
            )}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default PrecursorGroups;
