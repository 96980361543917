import { Menu } from "antd";
import React from "react";

const { SubMenu } = Menu;

const InlineMenuItem = (): JSX.Element => {
  return (
    <Menu 
      mode="inline" 
      style={{ 
        width: 160,
        borderInlineEnd: 'none',
        padding: 0,
        margin: 0
      }}
    >
        <Menu.Item key="1">Группа 1</Menu.Item>
        <Menu.Item key="2">Группа 2</Menu.Item>
        <Menu.Item key="3">Группа 3</Menu.Item>
        <Menu.Item key="4">Группа 4</Menu.Item>
    </Menu>
  );
};

export default InlineMenuItem;
