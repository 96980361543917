import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Row, Select, Typography, DatePicker, Checkbox, Segmented, theme, message, Modal } from "antd";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import dayjs from 'dayjs';
import { CheckboxField } from './CheckboxField';
import { DataType } from '../pages/tables/Reagents';
import { takeReagent, getReagents, deleteReagent, updateReagent } from '../api/chemicalRegistry';
import { useParams } from 'react-router-dom';
import type { Dayjs } from 'dayjs';

const { Option } = Select;
const { Text } = Typography;

const ScreenContainer = styled.div<{ $isGetMode?: boolean }>`
  width: 400px;
  height: ${props => props.$isGetMode ? 'auto' : '500px'};
  min-height: ${props => props.$isGetMode ? '300px' : '500px'};
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const BottomButtons = styled.div`
  padding: 16px;
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  /* Скрываем стрелки для числовых полей */
  &[type='number'] {
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
    
    .ant-select-selection-search-input {
      height: 38px !important;
    }
    
    .ant-select-selection-item {
      line-height: 32px !important;
    }
  }

  // Центрируем иконки по вертикали
  .ant-select-arrow,
  .ant-select-clear {
    top: 50% !important;
    margin-top: -3px !important;
  }

  // Добавляем стили для dropdown
  .ant-select-dropdown {
    z-index: 1100 !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  .ant-picker-input > input {
    background-color: #fafafa;
  }

  // Добавляем z-index для календаря
  .ant-picker-dropdown {
    z-index: 1100 !important;
  }
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
  }

  .cancel-button {
    flex: 1;
    max-width: 178px;
  }

  .action-button {
    flex: 1;
    max-width: 178px;
  }
`;

const HeaderContainer = styled.div`
  padding: 16px;
  
  .ant-segmented {
    .ant-segmented-item-label {
      font-size: 14px;
    }
  }
`;

// Обновляем интерфейс ScreenProps
interface ScreenProps {
  onCancel: () => void;
  reagentNames: string[];
  selectedRecord: DataType | null;  // Разрешаем null
  onSuccess?: () => void;
}

// Опции из Reagents.tsx
const manufacturerOptions = [
  'Поизводитель 1',
  'Производитель 2',
  'Производитель 3',
  'Другой'
].map(item => ({ label: item, value: item }));

const supplierOptions = [
  'Поставщик 1',
  'Поставщик 2',
  'Поставщик 3',
  'Другой'
].map(item => ({ label: item, value: item }));

const frequencyClassOptions = [
  'A',
  'B',
  'C',
  'D'
].map(item => ({ label: item, value: item }));

const notificationOptions = [
  'За 30 дней',
  'За 60 дней',
  'За 90 дней',
  'Другое'
].map(item => ({ label: item, value: item }));

const containerUnitOptions = [
  'мл',
  'л',
  'кг',
  'г',
  'Другое'
].map(item => ({ label: item, value: item }));

const locationOptions = [
  'Склад A',
  'Склад B',
  'Склад C',
  'Другое'
].map(item => ({ label: item, value: item }));

// Обновим интерфейс EditReagentFormProps
interface EditReagentFormProps {
  selectedRecord: DataType | null;
  onFieldChange: (field: string, value: any, category: 'receipt' | 'storage' | 'accounting') => void;
}

// Добаи интерфейс для пропсов GetReagentForm
interface GetReagentFormProps {
  reagentNames: string[];
  onTake: (name: string, quantity: number, research: string) => Promise<void>;
}

// Добавим новый стилизованный компонент для текстового поля с большой высотой
const StyledTextArea = styled(Input.TextArea)`
  background-color: #fafafa;
  border-radius: 4px;
  
  .ant-input {
    background-color: #fafafa;
  }
`;

// Обновим инрфейс для GetReagentForm
interface GetReagentFormHandle {
  handleSubmit: () => Promise<void>;
}

// Обновим компонент для режима "Взять реактив", используя forwardRef
const GetReagentForm = React.forwardRef<GetReagentFormHandle, GetReagentFormProps>(
  ({ reagentNames, onTake }, ref) => {
    const [selectedName, setSelectedName] = useState<string>('');
    const [quantity, setQuantity] = useState<string>('');
    const [research, setResearch] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const handleNameChange = (value: unknown) => {
      setSelectedName(value as string);
    };

    const handleSubmit = async () => {
      if (!selectedName || !quantity) {
        message.error('Пожалуйста, заполните обязательные поля');
        return;
      }

      const numQuantity = parseFloat(quantity);
      if (isNaN(numQuantity) || numQuantity <= 0) {
        message.error('Количество должно быть положительным числом');
        return;
      }

      setLoading(true);
      try {
        await onTake(selectedName, numQuantity, research);
        // Clear form after successful operation
        setSelectedName('');
        setQuantity('');
        setResearch('');
      } finally {
        setLoading(false);
      }
    };

    // Expose handleSubmit method via ref
    React.useImperativeHandle(ref, () => ({
      handleSubmit
    }));

    return (
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <StyledText>Наименование</StyledText>
          <StyledSelect 
            placeholder="выберите из списка" 
            style={{ width: "100%" }}
            options={reagentNames.map(name => ({ label: name, value: name }))}
            showSearch
            allowClear
            value={selectedName}
            onChange={handleNameChange}
            onSelect={(value, option) => {
              // Предотвращаем всплытие события
              setTimeout(() => {
                handleNameChange(value);
              }, 0);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            dropdownStyle={{ zIndex: 1100 }}
          />
        </Col>

        <Col span={24}>
          <StyledText>Количество</StyledText>
          <StyledInput 
            type="number"
            placeholder="ввдите значение"
            value={quantity}
            onChange={e => setQuantity(e.target.value)}
          />
        </Col>

        <Col span={24}>
          <StyledText>Исследование</StyledText>
          <StyledTextArea 
            placeholder="описание"
            style={{ height: 63, resize: 'none' }}
            autoSize={false}
            value={research}
            onChange={e => setResearch(e.target.value)}
          />
        </Col>
      </Row>
    );
  }
);

// Обновим компонент для режима "Изменение данных"
const EditReagentForm: React.FC<EditReagentFormProps> = ({
  selectedRecord,
  onFieldChange
}) => {
  if (!selectedRecord) return null;

  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      const handleClickOutside = (e: MouseEvent) => {
        e.stopPropagation();
      };
      document.addEventListener('mousedown', handleClickOutside);
      setTimeout(() => {
        document.removeEventListener('mousedown', handleClickOutside);
      }, 0);
    }
  };

  const handleDateChange = (field: string, date: Dayjs | null) => {
    onFieldChange(field, date ? date.format('YYYY-MM-DD') : null, 'receipt');
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <StyledText>Дата</StyledText>
        <StyledDatePicker 
          style={{ width: '100%' }}
          format="DD.MM.YYYY"
          placeholder="Выберите дату"
          value={selectedRecord.receipt.date ? dayjs(selectedRecord.receipt.date) : null}
          onChange={(date, dateString) => handleDateChange('date', date as Dayjs | null)}
          onMouseDown={handleMouseDown}
          onClick={e => e.stopPropagation()}
          onOpenChange={handleOpenChange}
          popupStyle={{ zIndex: 1100 }}
          getPopupContainer={(trigger) => trigger.parentElement || document.body}
        />
      </Col>

      <Col span={24}>
        <StyledText>Наименование</StyledText>
        <StyledInput 
          placeholder="введите название"
          value={selectedRecord.receipt.name}
          onChange={(e) => onFieldChange('name', e.target.value, 'receipt')}
        />
      </Col>

      <Col span={24}>
        <StyledText>№ Партии/серии</StyledText>
        <StyledInput 
          placeholder="введите номер"
          value={selectedRecord.receipt.batch}
          onChange={(e) => onFieldChange('batch', e.target.value, 'receipt')}
        />
      </Col>

      <Col span={24}>
        <StyledText>Производитель</StyledText>
        <StyledSelect 
          placeholder="выберите из списка" 
          style={{ width: "100%" }}
          options={manufacturerOptions}
          showSearch
          allowClear
          value={selectedRecord.receipt.manufacturer}
          onChange={(value) => onFieldChange('manufacturer', value, 'receipt')}
          onMouseDown={handleMouseDown}
          onClick={e => e.stopPropagation()}
          dropdownStyle={{ zIndex: 1100 }}
        />
      </Col>

      <Col span={24}>
        <StyledText>Поставщик</StyledText>
        <StyledSelect 
          placeholder="выберите из списка" 
          style={{ width: "100%" }}
          options={supplierOptions}
          showSearch
          allowClear
          value={selectedRecord.receipt.supplier}
          onChange={(value) => onFieldChange('supplier', value, 'receipt')}
          onMouseDown={handleMouseDown}
          onClick={e => e.stopPropagation()}
          dropdownStyle={{ zIndex: 1100 }}
        />
      </Col>

      <Col span={24}>
        <StyledText>Класс чистоты</StyledText>
        <StyledSelect 
          placeholder="выберите из списка" 
          style={{ width: "100%" }}
          options={frequencyClassOptions}
          allowClear
          value={selectedRecord.receipt.frequencyClass}
          onChange={(value) => onFieldChange('frequencyClass', value, 'receipt')}
          onMouseDown={handleMouseDown}
          onClick={e => e.stopPropagation()}
          dropdownStyle={{ zIndex: 1100 }}
        />
      </Col>

      <Col span={24}>
        <StyledText>Срок годности</StyledText>
        <StyledDatePicker 
          style={{ width: '100%', height: '40px' }}
          format="DD.MM.YYYY"
          placeholder="Выберите дату"
          value={selectedRecord.receipt.expiryDate ? dayjs(selectedRecord.receipt.expiryDate) : null}
          onChange={(date, dateString) => handleDateChange('expiryDate', date as Dayjs | null)}
          onMouseDown={handleMouseDown}
          onClick={e => e.stopPropagation()}
          onOpenChange={handleOpenChange}
          popupStyle={{ zIndex: 1100 }}
          getPopupContainer={(trigger) => trigger.parentElement || document.body}
        />
      </Col>

      <Col span={24}>
        <StyledText>Размер тары</StyledText>
        <StyledInput 
          type="number"
          placeholder="введите значение"
          value={selectedRecord.receipt.containerSize}
          onChange={(e) => onFieldChange('containerSize', parseFloat(e.target.value), 'receipt')}
        />
      </Col>

      <Col span={24}>
        <StyledText>Ед.измерения тары</StyledText>
        <StyledSelect 
          placeholder="выберите из списка" 
          style={{ width: "100%" }}
          options={containerUnitOptions}
          allowClear
          value={selectedRecord.receipt.containerUnit}
          onChange={(value) => onFieldChange('containerUnit', value, 'receipt')}
          onMouseDown={handleMouseDown}
          onClick={e => e.stopPropagation()}
          dropdownStyle={{ zIndex: 1100 }}
        />
      </Col>

      <Col span={24}>
        <StyledText>Комплект документов</StyledText>
        <CheckboxField 
          title="Получен" 
          checked={selectedRecord.receipt.documents}
          onChange={(checked) => onFieldChange('documents', checked, 'receipt')}
        />
      </Col>

      <Col span={24}>
        <StyledText>Доступ</StyledText>
        <CheckboxField 
          title="Получен" 
          checked={selectedRecord.receipt.admission}
          onChange={(checked) => onFieldChange('admission', checked, 'receipt')}
        />
      </Col>

      <Col span={24}>
        <StyledText>Прекурсор</StyledText>
        <CheckboxField 
          title="Да" 
          checked={selectedRecord.storage.isPrecursor}
          onChange={(checked) => onFieldChange('isPrecursor', checked, 'storage')}
        />
      </Col>

      <Col span={24}>
        <StyledText>Место хранения</StyledText>
        <StyledSelect 
          placeholder="выберите из списка" 
          style={{ width: "100%" }}
          options={locationOptions}
          showSearch
          allowClear
          value={selectedRecord.storage.location}
          onChange={(value) => onFieldChange('location', value, 'storage')}
          onMouseDown={handleMouseDown}
          onClick={e => e.stopPropagation()}
          dropdownStyle={{ zIndex: 1100 }}
        />
      </Col>

      <Col span={24}>
        <StyledText>Условия хранения</StyledText>
        <StyledInput 
          placeholder="введите значение"
          value={selectedRecord.storage.conditions}
          onChange={(e) => onFieldChange('conditions', e.target.value, 'storage')}
        />
      </Col>

      <Col span={24}>
        <StyledText>Срок хранения после вскрытия тары</StyledText>
        <StyledInput 
          placeholder="введите значение"
          value={selectedRecord.accounting.openedShelfLife}
          onChange={(e) => onFieldChange('openedShelfLife', e.target.value, 'accounting')}
        />
      </Col>

      <Col span={24}>
        <StyledText>Примечание</StyledText>
        <StyledInput 
          placeholder="введите текст"
          value={selectedRecord.accounting.notes}
          onChange={(e) => onFieldChange('notes', e.target.value, 'accounting')}
        />
      </Col>
    </Row>
  );
};

// Добавим стили для кнопки удаления
const DeleteButton = styled(Button)`
  min-width: 46px !important;
  width: 46px !important;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 46px !important;
`;

// Обновлем основной компонент для получения reagentNames из пропсов
const GetReagent: React.FC<ScreenProps> = ({ onCancel, reagentNames, selectedRecord, onSuccess }) => {
  const { token } = theme.useToken();
  const [documentsReceived, setDocumentsReceived] = useState(false);
  const [accessGranted, setAccessGranted] = useState(false);
  const [isPrecursor, setIsPrecursor] = useState(false);
  const [activeTab, setActiveTab] = useState<string | number>('get');
  const [loading, setLoading] = useState(false);
  const { laboratoryId } = useParams<{ laboratoryId: string }>();
  const [reagentsData, setReagentsData] = useState<DataType[]>([]);
  const formRef = useRef<GetReagentFormHandle>(null);
  const [editedRecord, setEditedRecord] = useState<DataType | null>(null);

  // Add useEffect to fetch reagents data
  useEffect(() => {
    const fetchReagents = async () => {
      if (!laboratoryId) return;
      try {
        const data = await getReagents(laboratoryId);
        setReagentsData(data);
      } catch (error) {
        console.error('Error fetching reagents:', error);
        message.error('Ошибка при загрузке данных реагентов');
      }
    };

    fetchReagents();
  }, [laboratoryId]);

  // ��ри изменении selectedRecord обновляем editedRecord
  useEffect(() => {
    setEditedRecord(selectedRecord);
  }, [selectedRecord]);

  const handleFieldChange = (field: string, value: any, category: 'receipt' | 'storage' | 'accounting') => {
    if (!editedRecord) return;

    setEditedRecord(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        [category]: {
          ...prev[category],
          [field]: value
        }
      };
    });
  };

  const handleDelete = async () => {
    if (!selectedRecord?.key) {
      message.error('Запись для удаления не найдена');
      return;
    }

    try {
      setLoading(true);
      await deleteReagent(selectedRecord.key as number);
      message.success('Запись успешно удалена');
      onSuccess?.();
      onCancel();
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('Ошибка при удалении записи');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `Вы уверены что хотите удалить запись №${selectedRecord?.number}?`,
      content: 'Данные об удалнии записи сохранятся в истории изменений',
      okText: 'Да, удалить',
      cancelText: 'Отмена',
      okButtonProps: { 
        danger: true,
        style: { height: '40px' }
      },
      cancelButtonProps: {
        style: { height: '40px' }
      },
      onOk: handleDelete,
      centered: true,
    });
  };

  const handleTakeReagent = async (name: string, quantity: number, research: string) => {
    if (!laboratoryId) {
      message.error('Laboratory ID not found');
      return;
    }

    try {
      setLoading(true);
      // Find reagent ID by name
      const reagent = reagentsData.find(r => r.receipt.name === name);
      if (!reagent) {
        throw new Error('Reagent not found');
      }

      await takeReagent(laboratoryId, reagent.key as number, quantity, research);
      message.success('Реактив успешно взят');
      onSuccess?.();
      onCancel();
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('Ошибка при взятии реактива');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!editedRecord?.key) {
      message.error('Запись для обновления не найдена');
      return;
    }

    try {
      setLoading(true);
      const updateData = {
        receipt_date: editedRecord.receipt.date,
        name: editedRecord.receipt.name,
        batch: editedRecord.receipt.batch,
        manufacturer: editedRecord.receipt.manufacturer,
        supplier: editedRecord.receipt.supplier,
        frequency_class: editedRecord.receipt.frequencyClass,
        expiration_date: editedRecord.receipt.expiryDate,
        quantity: editedRecord.receipt.containerSize,
        unit: editedRecord.receipt.containerUnit,
        has_documents: editedRecord.receipt.documents,
        has_admission: editedRecord.receipt.admission,
        is_precursor: editedRecord.storage.isPrecursor,
        storage_location: editedRecord.storage.location,
        storage_conditions: editedRecord.storage.conditions,
        opened_shelf_life: parseInt(editedRecord.accounting.openedShelfLife),
        notes: editedRecord.accounting.notes
      };

      await updateReagent(editedRecord.key as number, updateData);
      message.success('Запись успешно обновлена');
      onSuccess?.();
      onCancel();
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('Ошибка при обновлении записи');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTakeClick = () => {
    formRef.current?.handleSubmit();
  };

  const handleContainerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      <ScreenContainer 
        $isGetMode={activeTab === 'get'}
        onClick={handleContainerClick}
      >
        <HeaderContainer>
          <Segmented
            options={[
              { label: 'Взять реактив', value: 'get' },
              { label: 'Изменение данных', value: 'edit' }
            ]}
            value={activeTab}
            onChange={setActiveTab}
            size="large"
            block
          />
        </HeaderContainer>
        
        <ScrollContent>
          {activeTab === 'get' ? (
            <GetReagentForm 
              reagentNames={reagentNames}
              onTake={handleTakeReagent}
              ref={formRef}
            />
          ) : (
            <EditReagentForm
              selectedRecord={editedRecord}
              onFieldChange={handleFieldChange}
            />
          )}
        </ScrollContent>

        <BottomButtons>
          <ButtonsRow>
            {activeTab === 'edit' && (
              <DeleteButton 
                icon={<DeleteOutlined style={{ color: token['red-5'] }} />}
                onClick={showDeleteConfirm}
              />
            )}
            <Button 
              className="cancel-button"
              onClick={onCancel}
              disabled={loading}
            >
              Отмена
            </Button>
            <Button 
              className="action-button"
              type="primary"
              loading={loading}
              onClick={activeTab === 'get' ? handleTakeClick : handleSave}
            >
              {activeTab === 'get' ? 'зять' : 'Сохранить'}
            </Button>
          </ButtonsRow>
        </BottomButtons>
      </ScreenContainer>
    </>
  );
};

export default GetReagent;

