import React, { useState, useEffect } from 'react';
import { Button, Col, Input, Row, Select, Typography, DatePicker, message, Modal, Checkbox } from "antd";
import styled from "styled-components";
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import { useLaboratoryId } from '../hooks/useLaboratoryId';
import type { MeasuringInstrumentType } from '../pages/tables/MeasuringInstruments';
import type { TestInstrumentType } from '../pages/tables/TestInstruments';
import type { HelpInstrumentType } from '../pages/tables/HelpInstruments';
import instrumentsApi from '../api/instruments';
import { INSTRUMENT_TYPES, InstrumentType } from '../constants/instrumentTypes';
import { getCustomColumns, CustomColumn } from '../api/customColumns';

const { Text } = Typography;

// Используем те же стили, что и в GetReagent
const ScreenContainer = styled.div`
  width: 400px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const BottomButtons = styled.div`
  padding: 16px;
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  &[type='number'] {
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  justify-content: center;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
  }

  .cancel-button {
    flex: 1;
    max-width: 178px;
  }

  .action-button {
    flex: 1;
    max-width: 178px;
  }
`;

interface EditInstrumentProps {
  onCancel: () => void;
  record: MeasuringInstrumentType | TestInstrumentType | HelpInstrumentType;
  type: InstrumentType;
  onSuccess?: () => void;
}

const ScreenTitle = styled(Typography.Title)`
  margin: 16px !important;
  font-size: 16px !important;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditInstrument: React.FC<EditInstrumentProps> = ({ onCancel, record, type, onSuccess }) => {
  const laboratoryId = useLaboratoryId();
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [editedRecord, setEditedRecord] = useState(record);
  const [customColumns, setCustomColumns] = useState<CustomColumn[]>([]);
  const [customFieldValues, setCustomFieldValues] = useState<Record<string, any>>({});

  useEffect(() => {
    setEditedRecord(record);
    // Инициализируем кастомные поля из выбранной записи
    if (record?.custom_columns) {
      setCustomFieldValues(record.custom_columns);
    } else {
      setCustomFieldValues({});
    }
  }, [record]);

  // Загружаем кастомные колонки при монтировании компонента
  useEffect(() => {
    const fetchCustomColumns = async () => {
      if (!laboratoryId) return;
      
      let registryType = '';
      switch (type) {
        case INSTRUMENT_TYPES.MEASURING:
          registryType = 'measuring_instrument';
          break;
        case INSTRUMENT_TYPES.TESTING:
          registryType = 'testing_instrument';
          break;
        case INSTRUMENT_TYPES.HELPING:
          registryType = 'helping_instrument';
          break;
      }
      
      try {
        const columns = await getCustomColumns(registryType, Number(laboratoryId));
        setCustomColumns(columns);
      } catch (error) {
        console.error('Ошибка при загрузке кастомных колонок:', error);
      }
    };

    fetchCustomColumns();
  }, [laboratoryId, type]);

  const handleSelectChange = (field: string) => (value: unknown) => {
    setEditedRecord(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleDateChange = (field: string) => 
    (_: unknown, dateString: string | string[]) => {
      setEditedRecord(prev => ({
        ...prev,
        [field]: typeof dateString === 'string' ? dateString || null : null
      }));
    };

  const handleCustomFieldChange = (key: string, value: any) => {
    setCustomFieldValues(prev => ({
      ...prev,
      [key]: value
    }));
  };

  // Функция для рендеринга кастомного поля в зависимости от его типа
  const renderCustomField = (column: CustomColumn) => {
    const value = customFieldValues[column.key];
    
    switch (column.type) {
      case 'text':
        return (
          <StyledInput
            value={value || ''}
            onChange={(e) => handleCustomFieldChange(column.key, e.target.value)}
            placeholder={`Введите ${column.title.toLowerCase()}`}
          />
        );
      case 'int':
        return (
          <StyledInput
            type="number"
            value={value || ''}
            onChange={(e) => handleCustomFieldChange(column.key, e.target.value ? Number(e.target.value) : null)}
            placeholder={`Введите ${column.title.toLowerCase()}`}
          />
        );
      case 'date':
        return (
          <StyledDatePicker
            value={value ? dayjs(value) : null}
            onChange={(date, dateString) => handleCustomFieldChange(column.key, dateString)}
            format="DD.MM.YYYY"
            style={{ width: '100%' }}
          />
        );
      case 'boolean':
        return (
          <Checkbox
            checked={Boolean(value)}
            onChange={(e) => handleCustomFieldChange(column.key, e.target.checked)}
          >
            {column.title}
          </Checkbox>
        );
      default:
        return (
          <StyledInput
            value={value || ''}
            onChange={(e) => handleCustomFieldChange(column.key, e.target.value)}
            placeholder={`Введите ${column.title.toLowerCase()}`}
          />
        );
    }
  };

  const handleSave = async () => {
    if (!laboratoryId) return;

    try {
      setLoading(true);
      
      switch (type) {
        case INSTRUMENT_TYPES.MEASURING: {
          const measuringRecord = editedRecord as MeasuringInstrumentType;
          await instrumentsApi.updateMeasuringInstrument(laboratoryId, record.id, {
            number: measuringRecord.number,
            status: measuringRecord.status,
            characteristics: measuringRecord.characteristics,
            name: measuringRecord.name,
            manufacturer: measuringRecord.manufacturer,
            commissioning_year: measuringRecord.commissioning_year,
            measurement_range: measuringRecord.measurement_range,
            accuracy: measuringRecord.accuracy,
            verification_certificate: measuringRecord.verification_certificate,
            verification_date: measuringRecord.verification_date,
            next_verification_date: measuringRecord.next_verification_date,
            registration_number: measuringRecord.registration_number,
            verification_method: measuringRecord.verification_method,
            ownership_document: measuringRecord.ownership_document,
            location: measuringRecord.location,
            notes: measuringRecord.notes,
            custom_columns: customFieldValues
          });
          break;
        }

        case INSTRUMENT_TYPES.TESTING: {
          const testRecord = editedRecord as TestInstrumentType;
          await instrumentsApi.updateTestingInstrument(laboratoryId, record.id, {
            number: testRecord.number,
            status: testRecord.status,
            characteristics: testRecord.characteristics,
            test_name: testRecord.testName,
            equipment_name: testRecord.equipmentName,
            manufacturer: testRecord.manufacturer,
            technical_specs: testRecord.technicalSpecs,
            commissioning_year: testRecord.commissioningYear,
            document_info: testRecord.documentInfo,
            ownership_document: testRecord.ownershipDocument,
            location: testRecord.location,
            notes: testRecord.notes,
            custom_columns: customFieldValues
          });
          break;
        }

        case INSTRUMENT_TYPES.HELPING: {
          const helpRecord = editedRecord as HelpInstrumentType;
          await instrumentsApi.updateHelpingInstrument(laboratoryId, record.id, {
            number: helpRecord.number,
            status: helpRecord.status,
            name: helpRecord.name,
            manufacturer: helpRecord.manufacturer,
            commissioning_year: helpRecord.commissioningYear,
            purpose: helpRecord.purpose,
            ownership_document: helpRecord.ownershipDocument,
            location: helpRecord.location,
            notes: helpRecord.notes,
            custom_columns: customFieldValues
          });
          break;
        }
      }
      
      message.success('Запись успешно обновлена');
      onSuccess?.();
      onCancel();
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('Ошибка при обновлении записи');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      title: 'Подтверждение удаления',
      content: 'Вы уверены, что хотите удалить эту запись?',
      okText: 'Удалить',
      cancelText: 'Отмена',
      centered: true,
      icon: null,
      okButtonProps: { danger: true },
      onCancel: () => {
        console.log('Cancel clicked');
      },
      width: 500,
      onOk: async () => {
        if (!laboratoryId) return;

        try {
          setLoading(true);
          
          switch (type) {
            case INSTRUMENT_TYPES.MEASURING:
              await instrumentsApi.deleteMeasuringInstrument(laboratoryId, record.id);
              break;
            case INSTRUMENT_TYPES.TESTING:
              await instrumentsApi.deleteTestingInstrument(laboratoryId, record.id);
              break;
            case INSTRUMENT_TYPES.HELPING:
              await instrumentsApi.deleteHelpingInstrument(laboratoryId, record.id);
              break;
          }
          
          message.success('Запись успешно удалена');
          onSuccess?.();
          onCancel();
        } catch (error) {
          if (error instanceof Error) {
            message.error(error.message);
          } else {
            message.error('Ошибка при удалении записи');
          }
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const renderFields = () => {
    switch (type) {
      case INSTRUMENT_TYPES.MEASURING:
        const measuringRecord = editedRecord as MeasuringInstrumentType;
        return (
          <>
            <Col span={24}>
              <StyledText>Статус оборудования</StyledText>
              <StyledSelect
                value={measuringRecord.status}
                onChange={handleSelectChange('status')}
                options={[
                  'В работе',
                  'На поверке',
                  'В ремонте',
                  'Списан'
                ].map(item => ({ label: item, value: item }))}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Наименование определяемых характеристик</StyledText>
              <StyledInput
                value={measuringRecord.characteristics}
                onChange={(e) => handleSelectChange('characteristics')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Наименование СИ</StyledText>
              <StyledInput
                value={measuringRecord.name}
                onChange={(e) => handleSelectChange('name')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Изготовитель</StyledText>
              <StyledInput
                value={measuringRecord.manufacturer}
                onChange={(e) => handleSelectChange('manufacturer')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Год ввода в эксплуатацию</StyledText>
              <StyledInput
                type="number"
                value={measuringRecord.commissioning_year}
                onChange={(e) => handleSelectChange('commissioning_year')(Number(e.target.value))}
              />
            </Col>
            <Col span={24}>
              <StyledText>Диапазон измерени</StyledText>
              <StyledInput
                value={measuringRecord.measurement_range}
                onChange={(e) => handleSelectChange('measurement_range')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Класс точности, погрешность</StyledText>
              <StyledSelect
                value={measuringRecord.accuracy}
                onChange={handleSelectChange('accuracy')}
                options={['0.1', '0.5', '1.0', '2.0'].map(item => ({ label: item, value: item }))}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Свидетельство о поверке</StyledText>
              <StyledInput
                value={measuringRecord.verification_certificate}
                onChange={(e) => handleSelectChange('verification_certificate')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Дата поверки / калибровки</StyledText>
              <StyledDatePicker
                value={measuringRecord.verification_date ? dayjs(measuringRecord.verification_date) : null}
                onChange={handleDateChange('verification_date')}
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Дата проведения следующей проверки</StyledText>
              <StyledDatePicker
                value={measuringRecord.next_verification_date ? dayjs(measuringRecord.next_verification_date) : null}
                onChange={handleDateChange('next_verification_date')}
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Регистрационный номер</StyledText>
              <StyledInput
                value={measuringRecord.registration_number}
                onChange={(e) => handleSelectChange('registration_number')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Методика поверки</StyledText>
              <StyledSelect
                value={measuringRecord.verification_method}
                onChange={handleSelectChange('verification_method')}
                options={['МП-001', 'МП-002', 'МП-003'].map(item => ({ label: item, value: item }))}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Право собственности или документ</StyledText>
              <StyledInput
                value={measuringRecord.ownership_document}
                onChange={(e) => handleSelectChange('ownership_document')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Место установки или хранения</StyledText>
              <StyledSelect
                value={measuringRecord.location}
                onChange={handleSelectChange('location')}
                options={[
                  'Лаборатория A',
                  'Лаборатория B',
                  'Лаборатория C'
                ].map(item => ({ label: item, value: item }))}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Примечания</StyledText>
              <StyledInput
                value={measuringRecord.notes}
                onChange={(e) => handleSelectChange('notes')(e.target.value)}
              />
            </Col>
            
            {/* Добавляем кастомные поля */}
            {customColumns.map(column => (
              <Col span={24} key={column.key}>
                <StyledText>{column.title}</StyledText>
                {renderCustomField(column)}
              </Col>
            ))}
          </>
        );

      case INSTRUMENT_TYPES.TESTING:
        const testRecord = editedRecord as TestInstrumentType;
        return (
          <>
            <Col span={24}>
              <StyledText>Статус оборудования</StyledText>
              <StyledSelect
                value={testRecord.status}
                onChange={handleSelectChange('status')}
                options={[
                  'В работе',
                  'На аттестации',
                  'В ремонте',
                  'Списан'
                ].map(item => ({ label: item, value: item }))}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Наименование определяемых характеристик</StyledText>
              <StyledInput
                value={testRecord.characteristics}
                onChange={(e) => handleSelectChange('characteristics')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Наименование испытуемых групп объектов</StyledText>
              <StyledInput
                value={testRecord.testName}
                onChange={(e) => handleSelectChange('testName')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Наименование спытательного оборудования</StyledText>
              <StyledInput
                value={testRecord.equipmentName}
                onChange={(e) => handleSelectChange('equipmentName')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Изготовитель</StyledText>
              <StyledInput
                value={testRecord.manufacturer}
                onChange={(e) => handleSelectChange('manufacturer')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Основные тех. характеристики</StyledText>
              <StyledInput
                value={testRecord.technicalSpecs}
                onChange={(e) => handleSelectChange('technicalSpecs')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Год ввода в эксплуатацию</StyledText>
              <StyledInput
                type="number"
                value={testRecord.commissioningYear}
                onChange={(e) => handleSelectChange('commissioningYear')(Number(e.target.value))}
              />
            </Col>
            <Col span={24}>
              <StyledText>Дата и номер документа</StyledText>
              <StyledInput
                value={testRecord.documentInfo}
                onChange={(e) => handleSelectChange('documentInfo')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Право собственности или документ</StyledText>
              <StyledInput
                value={testRecord.ownershipDocument}
                onChange={(e) => handleSelectChange('ownershipDocument')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Место установки или хранения</StyledText>
              <StyledSelect
                value={testRecord.location}
                onChange={handleSelectChange('location')}
                options={[
                  'Лаборатория A',
                  'Лаборатория B',
                  'Лаборатория C'
                ].map(item => ({ label: item, value: item }))}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Примечания</StyledText>
              <StyledInput
                value={testRecord.notes}
                onChange={(e) => handleSelectChange('notes')(e.target.value)}
              />
            </Col>
            
            {/* Добавляем кастомные поля */}
            {customColumns.map(column => (
              <Col span={24} key={column.key}>
                <StyledText>{column.title}</StyledText>
                {renderCustomField(column)}
              </Col>
            ))}
          </>
        );

      case INSTRUMENT_TYPES.HELPING:
        const helpRecord = editedRecord as HelpInstrumentType;
        return (
          <>
            <Col span={24}>
              <StyledText>Статус оборудования</StyledText>
              <StyledSelect
                value={helpRecord.status}
                onChange={handleSelectChange('status')}
                options={[
                  'В работе',
                  'На обслуживании',
                  'В ремонте',
                  'Списан'
                ].map(item => ({ label: item, value: item }))}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Наименование</StyledText>
              <StyledInput
                value={helpRecord.name}
                onChange={(e) => handleSelectChange('name')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Изготовитель</StyledText>
              <StyledInput
                value={helpRecord.manufacturer}
                onChange={(e) => handleSelectChange('manufacturer')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Год ввода в эксплуатацию</StyledText>
              <StyledInput
                type="number"
                value={helpRecord.commissioningYear}
                onChange={(e) => handleSelectChange('commissioningYear')(Number(e.target.value))}
              />
            </Col>
            <Col span={24}>
              <StyledText>Назначение</StyledText>
              <StyledInput
                value={helpRecord.purpose}
                onChange={(e) => handleSelectChange('purpose')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Право собственности или документ</StyledText>
              <StyledInput
                value={helpRecord.ownershipDocument}
                onChange={(e) => handleSelectChange('ownershipDocument')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <StyledText>Место установки или хранения</StyledText>
              <StyledSelect
                value={helpRecord.location}
                onChange={handleSelectChange('location')}
                options={[
                  'Лаборатория A',
                  'Лаборатория B',
                  'Лаборатория C'
                ].map(item => ({ label: item, value: item }))}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={24}>
              <StyledText>Примечания</StyledText>
              <StyledInput
                value={helpRecord.notes}
                onChange={(e) => handleSelectChange('notes')(e.target.value)}
              />
            </Col>
            
            {/* Добавляем кастомные поля */}
            {customColumns.map(column => (
              <Col span={24} key={column.key}>
                <StyledText>{column.title}</StyledText>
                {renderCustomField(column)}
              </Col>
            ))}
          </>
        );
    }
  };

  return (
    <ScreenContainer>
      <HeaderContainer>
        <ScreenTitle level={4}>Изменение записи</ScreenTitle>
      </HeaderContainer>

      <ScrollContent>
        <Row gutter={[16, 16]}>
          {renderFields()}
        </Row>
      </ScrollContent>

      <BottomButtons>
        <ButtonsRow>
          <DeleteButton 
            icon={<DeleteOutlined style={{ color: token['red-5'] }} />}
            onClick={handleDelete}
          />
          <Button 
            className="cancel-button"
            onClick={onCancel}
            disabled={loading}
          >
            Отмена
          </Button>
          <Button 
            className="action-button"
            type="primary"
            onClick={handleSave}
            loading={loading}
          >
            Сохранить
          </Button>
        </ButtonsRow>
      </BottomButtons>
    </ScreenContainer>
  );
};

// Добавляем стили для кнопки удаления
const DeleteButton = styled(Button)`
  min-width: 46px !important;
  width: 46px !important;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 46px !important;
`;

export default EditInstrument;

