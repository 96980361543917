import React, { useState, useEffect, memo, useRef } from 'react';
import { Modal, Button, Input, DatePicker, Checkbox, Row, Col, Typography, message, Table, Select, Space } from 'antd';
import { CloseOutlined, DeleteOutlined, PlusOutlined, ScanOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import dayjs from 'dayjs';
import axiosInstance from '../api/axios';
import { useLaboratoryId } from '../hooks/useLaboratoryId';
import { createStandard } from '../api/chemicalRegistry';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import Scan from './Scan'; // Импортируем компонент сканирования
import type { InputRef } from 'antd';

const { Text, Link } = Typography;

const StyledButton = styled(Button)`
  height: 40px;
  width: 150px;
  border-radius: 4px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

// Определяем интерфейс DataType перед использованием в StyledTable
interface DataType {
  key: React.Key;
  number: number;
  name: string;
  manufacturer: string;
  purpose: string;
  batchCode: string;
  packaging: string;
  indicator: string;
  certifiedValue: number;
  certifiedValueUnit: string;
  errorValue: number;
  errorValueUnit: string;
  additionalInfo: string;
  normativeDocument: string;
  expirationDate: dayjs.Dayjs;
  expirationNotification: string;
  releaseDate: dayjs.Dayjs;
  notes: string;
  isPrecursor: boolean;
  custom_columns?: Record<string, any>;
}

// Интерфейс для данных, полученных из QR-кода
interface ParsedBatchData {
  batch_id: string;
  [key: string]: any; // Для других возможных полей
}

// Явно указываем тип данных для StyledTable
const StyledTable = styled((props: TableProps<DataType>) => <Table<DataType> {...props} />)`
  .ant-table-tbody > tr > td {
    height: 55px;
    padding: 8px;
  }
  
  .ant-table-body {
    height: 260px !important;
    overflow-y: auto !important;
  }
`;

interface ScanPreviewStandardSampleProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  scannedData: string;
}

// Компоненты для ячеек таблицы
const CellInput = memo(({ value, onChange }: { value: string, onChange: (value: string) => void }) => (
  <Input value={value} onChange={(e) => onChange(e.target.value)} style={{ width: '100%' }} />
));

const CellNumberInput = memo(({ value, onChange }: { value: number, onChange: (value: number) => void }) => (
  <Input
    type="number"
    value={value}
    onChange={(e) => onChange(parseFloat(e.target.value) || 0)}
    style={{ width: '100%' }}
  />
));

const CellSelect = memo(({ value, onChange, options }: { value: string, onChange: (value: string) => void, options: { label: string, value: string }[] }) => (
  <Select
    value={value}
    onChange={onChange}
    options={options}
    style={{ width: '100%' }}
    showSearch
    allowClear
  />
));

const CellDatePicker = memo(({ value, onChange }: { value: dayjs.Dayjs | null, onChange: (value: dayjs.Dayjs | null) => void }) => (
  <DatePicker
    value={value}
    onChange={onChange}
    format="DD.MM.YYYY"
    style={{ width: '100%' }}
  />
));

const CellCheckbox = memo(({ checked, onChange }: { checked: boolean, onChange: (checked: boolean) => void }) => (
  <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} />
));

// Опции для селектов
const standardTypes = [
  'ГСО 1234-56 Стандартный образец 1',
  'ОСО 7890-12 Стандартный образец 2',
  'СОП 3456-78 Стандартный образец 3',
].map(item => ({ label: item, value: item }));

const manufacturerOptions = [
  'ФГУП "ВНИИМ им. Д.И. Менделеева"',
  'ФГУП "УНИИМ"',
  'ООО "Химтест"',
].map(item => ({ label: item, value: item }));

const purposeOptions = [
  'Калибровка',
  'Поверка',
  'Контроль точности',
].map(item => ({ label: item, value: item }));

const indicatorOptions = [
  'Массовая доля железа',
  'Массовая доля углерода',
  'Массовая доля серы',
].map(item => ({ label: item, value: item }));

const notificationOptions = [
  '1 месяц',
  '3 месяца',
  '6 месяцев',
].map(item => ({ label: item, value: item }));

// Стилизованный невидимый Input для сканирования
const HiddenInput = styled(Input)`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  z-index: -1;
`;

const ScanPreviewStandardSample: React.FC<ScanPreviewStandardSampleProps> = ({
  open,
  onCancel,
  onSuccess,
  scannedData
}) => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const labId = useLaboratoryId();
  const [scanModalVisible, setScanModalVisible] = useState<boolean>(false);
  const [directScanInput, setDirectScanInput] = useState<string>(''); // Состояние для прямого ввода сканирования
  const directScanInputRef = useRef<InputRef>(null); // Реф для фокуса на поле ввода
  const [scanStatus, setScanStatus] = useState<string>(''); // Статус сканирования для отладки
  const scanTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Реф для таймера автоматической обработки
  const [processedBatchIds, setProcessedBatchIds] = useState<Set<string>>(new Set()); // Для отслеживания уже обработанных batch_id
  const isDevMode = process.env.NODE_ENV === 'development'; // Проверка режима разработки

  useEffect(() => {
    if (open && scannedData) {
      processScannedData(scannedData);
    }
  }, [open, scannedData]);

  // Сбрасываем обработанные batch_id при закрытии модального окна
  useEffect(() => {
    if (!open) {
      setProcessedBatchIds(new Set());
    }
  }, [open]);

  // Фокус на скрытом поле ввода при открытии модального окна и при каждом клике на модальное окно
  useEffect(() => {
    if (open) {
      // Устанавливаем фокус на скрытое поле ввода при открытии
      const focusInput = () => {
        directScanInputRef.current?.focus();
      };
      
      // Вызываем фокус сразу и через небольшую задержку для надежности
      focusInput();
      setTimeout(focusInput, 100);
      setTimeout(focusInput, 500);

      // Добавляем обработчик клика для всего модального окна
      const handleModalClick = () => {
        focusInput();
      };

      // Добавляем обработчик потери фокуса для поля ввода
      const handleBlur = () => {
        // Возвращаем фокус на поле ввода при потере фокуса
        setTimeout(focusInput, 10);
      };

      // Находим модальное окно по классу и добавляем обработчик
      const modalElement = document.querySelector('.ant-modal-content');
      if (modalElement) {
        modalElement.addEventListener('click', handleModalClick);
      }

      // Добавляем обработчик потери фокуса
      const inputElement = directScanInputRef.current;
      if (inputElement && inputElement.input) {
        inputElement.input.addEventListener('blur', handleBlur);
      }

      // Очистка обработчиков при закрытии
      return () => {
        if (modalElement) {
          modalElement.removeEventListener('click', handleModalClick);
        }
        if (inputElement && inputElement.input) {
          inputElement.input.removeEventListener('blur', handleBlur);
        }
      };
    }
  }, [open]);

  // Очистка данных при размонтировании компонента
  useEffect(() => {
    return () => {
      setDataSource([]);
      setDirectScanInput('');
      // Очищаем таймер при размонтировании
      if (scanTimeoutRef.current) {
        clearTimeout(scanTimeoutRef.current);
      }
    };
  }, []);

  // Обработчик прямого сканирования
  const handleDirectScanInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setDirectScanInput(newValue);
    
    // Сбрасываем предыдущий таймер, если он был установлен
    if (scanTimeoutRef.current) {
      clearTimeout(scanTimeoutRef.current);
    }
    
    // Проверяем, содержит ли ввод полные данные сканирования
    // Многие сканеры автоматически добавляют Enter в конце, но некоторые нет
    // Поэтому проверяем, есть ли в строке полные данные JSON или ID
    if (newValue.trim()) {
      // Проверяем, является ли строка полным JSON
      if ((newValue.startsWith('{') && newValue.endsWith('}')) || 
          (newValue.startsWith('[') && newValue.endsWith(']'))) {
        console.log('Обнаружены полные данные JSON:', newValue);
        // Имитируем нажатие Enter для обработки данных
        setTimeout(() => {
          handleDirectScanKeyPress({ key: 'Enter', preventDefault: () => {} } as React.KeyboardEvent<HTMLInputElement>);
        }, 50);
      } 
      // Проверяем, является ли строка UUID или другим ID (полная строка)
      else if (newValue.match(/^[0-9a-fA-F-]+$/) && newValue.length > 8) {
        console.log('Обнаружен полный ID:', newValue);
        // Имитируем нажатие Enter для обработки данных
        setTimeout(() => {
          handleDirectScanKeyPress({ key: 'Enter', preventDefault: () => {} } as React.KeyboardEvent<HTMLInputElement>);
        }, 50);
      }
      // Проверяем, является ли строка кодом партии (полная строка)
      else if (newValue.match(/^[A-Z0-9-]+$/i) && newValue.length > 3) {
        console.log('Обнаружен полный код партии:', newValue);
        // Имитируем нажатие Enter для обработки данных
        setTimeout(() => {
          handleDirectScanKeyPress({ key: 'Enter', preventDefault: () => {} } as React.KeyboardEvent<HTMLInputElement>);
        }, 50);
      }
      // Устанавливаем таймер для автоматической обработки данных через 500 мс,
      // если сканер не отправляет Enter и данные не были обработаны выше
      else if (newValue.length > 0) {
        scanTimeoutRef.current = setTimeout(() => {
          console.log('Автоматическая обработка данных по таймеру:', newValue);
          handleDirectScanKeyPress({ key: 'Enter', preventDefault: () => {} } as React.KeyboardEvent<HTMLInputElement>);
        }, 500);
      }
    }
  };

  // Обработчик нажатия клавиш для прямого сканирования
  const handleDirectScanKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      // Предотвращаем повторную обработку одних и тех же данных
      if (!directScanInput || directScanInput.length === 0) {
        console.log('Пустые данные сканирования, пропускаем обработку');
        return;
      }
      
      // Сохраняем текущее значение и очищаем поле ввода сразу,
      // чтобы предотвратить повторную обработку
      const currentInput = directScanInput;
      setDirectScanInput('');
      
      setScanStatus(`Получены данные: ${currentInput.substring(0, 20)}...`);
      console.log('Получены данные сканирования:', currentInput);
      
      try {
        // Попытка обработать введенные данные как JSON
        // Некоторые сканеры могут добавлять символы переноса строки или другие символы
        const cleanedInput = currentInput.trim().replace(/[\r\n]/g, '');
        console.log('Очищенные данные сканирования:', cleanedInput);
        setScanStatus(`Обработка данных...`);
        
        // Пробуем распарсить JSON
        try {
          const parsedData: ParsedBatchData = JSON.parse(cleanedInput);
          console.log('Успешно распарсили JSON:', parsedData);
          setScanStatus(`Данные распарсены как JSON`);
          processScannedData(cleanedInput);
          return; // Добавляем return, чтобы избежать дальнейшей обработки
        } catch (jsonError) {
          console.error('Ошибка парсинга JSON:', jsonError);
          setScanStatus(`Ошибка парсинга JSON, пробуем другие форматы`);
          
          // Если не удалось распарсить как JSON, проверяем разные форматы
          
          // 1. Проверяем, является ли это UUID или другим ID
          if (cleanedInput.match(/^[0-9a-fA-F-]+$/)) {
            console.log('Обрабатываем как ID:', cleanedInput);
            setScanStatus(`Обрабатываем как ID: ${cleanedInput}`);
            // Создаем объект с batch_id
            const dataObj: ParsedBatchData = { batch_id: cleanedInput };
            processScannedData(JSON.stringify(dataObj));
            return;
          }
          
          // 2. Проверяем, является ли это числом партии
          if (cleanedInput.match(/^[A-Z0-9-]+$/i)) {
            console.log('Обрабатываем как номер партии:', cleanedInput);
            setScanStatus(`Обрабатываем как номер партии: ${cleanedInput}`);
            // Пытаемся найти партию по номеру
            handleBatchCodeScan(cleanedInput);
            return;
          }
          
          // 3. Если ничего не подошло, показываем ошибку
          setScanStatus(`Ошибка: некорректные данные сканирования`);
          // message.error('Некорректные данные сканирования. Не удалось распарсить данные.');
        }
      } catch (error) {
        console.error('Общая ошибка обработки сканирования:', error);
        setScanStatus(`Общая ошибка обработки сканирования`);
        message.error('Ошибка при обработке отсканированных данных');
      }
    }
  };

  // Обработка сканирования по коду партии
  const handleBatchCodeScan = async (batchCode: string) => {
    try {
      setLoading(true);
      console.log('Поиск партии по коду:', batchCode);
      
      // Запрос к API для поиска партии по коду
      const response = await axiosInstance.get(`/api/batches/by-code/${batchCode}`);
      
      if (response.data && response.data.id) {
        console.log('Найдена партия по коду:', response.data);
        // Создаем объект с batch_id и обрабатываем его
        const dataObj: ParsedBatchData = { batch_id: response.data.id };
        processScannedData(JSON.stringify(dataObj));
      } else {
        message.error(`Партия с кодом ${batchCode} не найдена`);
      }
    } catch (error) {
      console.error('Ошибка при поиске партии по коду:', error);
      message.error(`Не удалось найти партию с кодом ${batchCode}`);
    } finally {
      setLoading(false);
    }
  };

  // Функция для обработки отсканированных данных
  const processScannedData = async (data: string) => {
    try {
      setLoading(true);
      console.log('Начинаем обработку данных:', data);
      
      // Парсим данные из QR-кода
      let parsedData: ParsedBatchData;
      try {
        parsedData = JSON.parse(data);
        console.log('Данные успешно распарсены:', parsedData);
      } catch (parseError) {
        console.error('Ошибка парсинга данных:', parseError);
        // message.error('Некорректные данные QR-кода: не удалось распарсить JSON');
        setLoading(false);
        return;
      }
      
      if (!parsedData.batch_id) {
        console.error('Отсутствует batch_id в данных:', parsedData);
        message.error('Некорректные данные QR-кода: отсутствует batch_id');
        setLoading(false);
        return;
      }
      
      // Проверяем, не обрабатывали ли мы уже этот batch_id
      if (processedBatchIds.has(parsedData.batch_id)) {
        console.log('Этот batch_id уже был обработан:', parsedData.batch_id);
        message.info('Этот элемент уже был отсканирован ранее');
        setLoading(false);
        return;
      }
      
      console.log('Отправляем запрос к API для batch_id:', parsedData.batch_id);
      
      // Получаем данные партии по batch_id
      try {
        const response = await axiosInstance.get(`/api/batches/${parsedData.batch_id}`);
        console.log('Получен ответ от API:', response.data);
        
        // Преобразуем данные партии в формат для стандартного образца
        const batchData = response.data;
        
        // Проверяем наличие аттестованных значений
        const certifiedValues = batchData.certified_values || [];
        console.log('Аттестованные значения:', certifiedValues);
        
        // Добавляем batch_id в список обработанных
        setProcessedBatchIds(prev => new Set(prev).add(parsedData.batch_id));
        
        // Создаем строки для каждого аттестованного значения
        // Если аттестованных значений нет, создаем одну строку с базовыми данными
        if (certifiedValues.length === 0) {
          console.log('Аттестованных значений нет, создаем одну строку');
          const newRow: DataType = {
            key: Date.now().toString(),
            number: dataSource.length + 1,
            name: batchData.so_name + ', ' + batchData.gso_number + ', ' + batchData.so_category || '',
            manufacturer: 'ООО "Спектрохим"',
            purpose: '-',
            batchCode: batchData.batch_code || '',
            packaging: batchData.packaging || '',
            indicator: batchData.indicator_name || '',
            certifiedValue: batchData.target_value || 0,
            certifiedValueUnit: batchData.target_unit || '',
            errorValue: 0,
            errorValueUnit: '',
            additionalInfo: '',
            normativeDocument: '',
            expirationDate: batchData.expiry_date ? dayjs(batchData.expiry_date) : dayjs(),
            expirationNotification: '',
            releaseDate: batchData.registration_date ? dayjs(batchData.registration_date) : dayjs(),
            notes: '',
            isPrecursor: false,
            custom_columns: {}
          };
          
          setDataSource(prev => {
            const newData = [...prev, newRow];
            console.log('Новые данные после добавления строки:', newData);
            return newData;
          });
        } else {
          console.log('Создаем строки для каждого аттестованного значения');
          // Создаем строки для каждого аттестованного значения
          const rows = certifiedValues.map((value: any, index: number) => ({
            key: `${Date.now()}-${index}`,
            number: dataSource.length + index + 1,
            name: batchData.so_name + ', ' + batchData.gso_number + ', ' + batchData.so_category || '',
            manufacturer: 'ООО "Спектрохим"',
            purpose: '-',
            batchCode: batchData.batch_code || '',
            packaging: batchData.packaging || '',
            indicator: value.indicator_name || batchData.indicator_name || '',
            certifiedValue: value.value || 0,
            certifiedValueUnit: value.unit || batchData.target_unit || '',
            errorValue: value.error || 0,
            errorValueUnit: value.error_unit || '',
            additionalInfo: '',
            normativeDocument: '',
            expirationDate: batchData.expiry_date ? dayjs(batchData.expiry_date) : dayjs(),
            expirationNotification: '',
            releaseDate: batchData.registration_date ? dayjs(batchData.registration_date) : dayjs(),
            notes: '',
            isPrecursor: false,
            custom_columns: {}
          }));
          
          setDataSource(prev => {
            const newData = [...prev, ...rows];
            console.log('Новые данные после добавления строк:', newData);
            return newData;
          });
        }
        
        // Обновляем номера строк
        setTimeout(() => {
          updateRowNumbers();
          // message.success('Данные успешно добавлены');
          // Очищаем поле ввода после успешной обработки
          setDirectScanInput('');
        }, 100);
        
      } catch (apiError: any) {
        console.error('Ошибка при запросе к API:', apiError);
        
        // Проверяем, является ли это ошибкой тестовых данных
        if (parsedData.batch_id === 'test-batch-123') {
          console.log('Обнаружены тестовые данные, создаем тестовую строку');
          
          // Добавляем тестовый batch_id в список обработанных
          setProcessedBatchIds(prev => new Set(prev).add(parsedData.batch_id));
          
          // Создаем тестовую строку для демонстрации
          const testRow: DataType = {
            key: Date.now().toString(),
            number: dataSource.length + 1,
            name: 'Тестовый стандартный образец',
            manufacturer: 'ООО "Спектрохим"',
            purpose: 'Тестирование',
            batchCode: 'TEST-123',
            packaging: '100 мл',
            indicator: 'Тестовый показатель',
            certifiedValue: 99.9,
            certifiedValueUnit: '%',
            errorValue: 0.1,
            errorValueUnit: '%',
            additionalInfo: 'Тестовые данные',
            normativeDocument: 'ТУ 123-456',
            expirationDate: dayjs().add(1, 'year'),
            expirationNotification: '1 месяц',
            releaseDate: dayjs(),
            notes: 'Тестовая запись',
            isPrecursor: false,
            custom_columns: {}
          };
          
          setDataSource(prev => {
            const newData = [...prev, testRow];
            console.log('Добавлены тестовые данные:', newData);
            return newData;
          });
          
          // Обновляем номера строк
          setTimeout(() => {
            updateRowNumbers();
            // message.success('Тестовые данные успешно добавлены');
            // Очищаем поле ввода после успешной обработки
            setDirectScanInput('');
          }, 100);
          
          setLoading(false);
          return;
        }
        
        // Более подробная информация об ошибке
        const errorMessage = apiError.response?.data?.detail || 'Не удалось получить данные партии';
        message.error(`Ошибка API: ${errorMessage}`);
      }
      
    } catch (error: any) {
      console.error('Общая ошибка при обработке данных стандартного образца:', error);
      // Более подробная информация об ошибке
      const errorMessage = error.message || 'Не удалось обработать данные стандартного образца';
      message.error(`Ошибка: ${errorMessage}`);
    } finally {
      setLoading(false);
      // Очищаем поле ввода в любом случае
      setDirectScanInput('');
    }
  };

  // Функция для обновления номеров строк
  const updateRowNumbers = () => {
    setDataSource(prev => prev.map((item, index) => ({
      ...item,
      number: index + 1
    })));
  };

  // Заменяем fetchStandardSampleData на processScannedData
  const fetchStandardSampleData = () => {
    // Очищаем предыдущие данные при первом открытии
    setDataSource([]);
    processScannedData(scannedData);
  };

  // Обработчик для нового сканирования
  const handleNewScan = (newScannedData: string) => {
    setScanModalVisible(false);
    processScannedData(newScannedData);
  };

  const handleInputChange = (key: React.Key, field: string | string[], value: any) => {
    setDataSource(prev => prev.map(item => {
      if (item.key === key) {
        if (Array.isArray(field)) {
          const newItem = { ...item };
          let current: any = newItem;
          for (let i = 0; i < field.length - 1; i++) {
            current = current[field[i]];
          }
          current[field[field.length - 1]] = value;
          return newItem;
        }
        
        return {
          ...item,
          [field]: value
        };
      }
      return item;
    }));
  };

  const handleAddRow = () => {
    const newKey = Date.now().toString();
    const newRow: DataType = {
      key: newKey,
      number: dataSource.length + 1,
      name: '',
      manufacturer: 'ООО "Спектрохим"',
      purpose: '',
      batchCode: '',
      packaging: '',
      indicator: '',
      certifiedValue: 0,
      certifiedValueUnit: '',
      errorValue: 0,
      errorValueUnit: '',
      additionalInfo: '',
      normativeDocument: '',
      expirationDate: dayjs(),
      expirationNotification: '',
      releaseDate: dayjs(),
      notes: '',
      isPrecursor: false,
      custom_columns: {}
    };
    
    setDataSource([...dataSource, newRow]);
  };

  const handleDelete = (key: React.Key) => {
    setDataSource(prev => prev.filter(item => item.key !== key));
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '№ п/п',
      dataIndex: 'number',
      key: 'number',
      width: 80,
    },
    {
      title: 'Наименование, тип, номер и категория СО',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'name', newValue)} />
      ),
    },
    {
      title: 'Изготовитель СО',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'manufacturer', newValue)} />
      ),
    },
    {
      title: 'Назначение',
      dataIndex: 'purpose',
      key: 'purpose',
      width: 200,
      render: (value: string, record: DataType) => (
        <Select
          value={value}
          onChange={(newValue) => handleInputChange(record.key, 'purpose', newValue)}
          options={purposeOptions}
          style={{ width: '100%' }}
          showSearch
          allowClear
        />
      ),
    },
    {
      title: 'Шифр партии',
      dataIndex: 'batchCode',
      key: 'batchCode',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'batchCode', newValue)} />
      ),
    },
    {
      title: 'Фасовка',
      dataIndex: 'packaging',
      key: 'packaging',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'packaging', newValue)} />
      ),
    },
    {
      title: 'Показатель',
      dataIndex: 'indicator',
      key: 'indicator',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'indicator', newValue)} />
      ),
    },
    {
      title: 'Аттестов. значение м.д. %',
      dataIndex: 'certifiedValue',
      key: 'certifiedValue',
      width: 200,
      render: (value: number, record: DataType) => (
        <CellNumberInput value={value} onChange={(newValue) => handleInputChange(record.key, 'certifiedValue', newValue)} />
      ),
    },
    {
      title: 'Единица измерения атт. значения',
      dataIndex: 'certifiedValueUnit',
      key: 'certifiedValueUnit',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'certifiedValueUnit', newValue)} />
      ),
    },
    {
      title: 'Погрешность а.з. абс. %',
      dataIndex: 'errorValue',
      key: 'errorValue',
      width: 200,
      render: (value: number, record: DataType) => (
        <CellNumberInput value={value} onChange={(newValue) => handleInputChange(record.key, 'errorValue', newValue)} />
      ),
    },
    {
      title: 'Единица измерения погрешности',
      dataIndex: 'errorValueUnit',
      key: 'errorValueUnit',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'errorValueUnit', newValue)} />
      ),
    },
    {
      title: 'Доп сведения',
      dataIndex: 'additionalInfo',
      key: 'additionalInfo',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'additionalInfo', newValue)} />
      ),
    },
    {
      title: 'Нормативный документ',
      dataIndex: 'normativeDocument',
      key: 'normativeDocument',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'normativeDocument', newValue)} />
      ),
    },
    {
      title: 'Срок годности экземпляра СО',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      width: 200,
      render: (value: dayjs.Dayjs, record: DataType) => (
        <CellDatePicker 
          value={value || null} 
          onChange={(date) => handleInputChange(record.key, 'expirationDate', date || dayjs())} 
        />
      ),
    },
    {
      title: 'Уведомление об окончании срока годности',
      dataIndex: 'expirationNotification',
      key: 'expirationNotification',
      width: 200,
      render: (value: string, record: DataType) => (
        <Select
          value={value}
          onChange={(newValue) => handleInputChange(record.key, 'expirationNotification', newValue)}
          options={notificationOptions}
          style={{ width: '100%' }}
          showSearch
          allowClear
        />
      ),
    },
    {
      title: 'Дата выпуска экземпляра СО',
      dataIndex: 'releaseDate',
      key: 'releaseDate',
      width: 200,
      render: (value: dayjs.Dayjs, record: DataType) => (
        <CellDatePicker 
          value={value || null} 
          onChange={(date) => handleInputChange(record.key, 'releaseDate', date || dayjs())} 
        />
      ),
    },
    {
      title: 'Примечание',
      dataIndex: 'notes',
      key: 'notes',
      width: 200,
      render: (value: string, record: DataType) => (
        <CellInput value={value} onChange={(newValue) => handleInputChange(record.key, 'notes', newValue)} />
      ),
    },
    {
      title: 'Прекурсор',
      dataIndex: 'isPrecursor',
      key: 'isPrecursor',
      width: 120,
      render: (value: boolean, record: DataType) => (
        <CellCheckbox checked={value} onChange={(checked) => handleInputChange(record.key, 'isPrecursor', checked)} />
      ),
    },
    {
      key: "action",
      width: 60,
      render: (_: unknown, record: DataType) => (
        <Button 
          type="text" 
          icon={<DeleteOutlined style={{ color: '#f5222d' }} />}
          style={{ width: '32px', padding: 0 }}
          onClick={() => handleDelete(record.key)}
        />
      ),
    },
  ];

  const handleSave = async () => {
    try {
      if (!labId) {
        message.error('ID лаборатории не найден');
        return;
      }
      
      if (dataSource.length === 0) {
        message.error('Нет данных для сохранения');
        return;
      }
      
      setLoading(true);
      
      // Сохраняем все строки таблицы как стандартные образцы
      for (const item of dataSource) {
        const formattedValues = {
          name: item.name,
          manufacturer: item.manufacturer,
          purpose: item.purpose,
          batch_code: item.batchCode,
          packaging: item.packaging,
          indicator: item.indicator,
          certified_value: item.certifiedValue,
          certified_value_unit: item.certifiedValueUnit,
          error_value: item.errorValue,
          error_value_unit: item.errorValueUnit,
          additional_info: item.additionalInfo,
          normative_document: item.normativeDocument,
          expiration_date: item.expirationDate ? item.expirationDate.format('YYYY-MM-DD') : null,
          expiration_notification: item.expirationNotification,
          release_date: item.releaseDate ? item.releaseDate.format('YYYY-MM-DD') : null,
          notes: item.notes,
          is_precursor: item.isPrecursor,
          custom_columns: item.custom_columns || {}
        };
        
        await createStandard(labId, formattedValues);
      }
      
      message.success(`${dataSource.length} стандартных образцов успешно добавлено`);
      onSuccess();
      onCancel();
    } catch (error) {
      console.error('Ошибка при сохранении стандартных образцов:', error);
      message.error('Не удалось сохранить стандартные образцы');
    } finally {
      setLoading(false);
    }
  };

  // Функция для симуляции сканирования (для тестирования)
  const simulateScan = () => {
    // Очищаем предыдущие данные сканирования
    setDirectScanInput('');
    
    // Небольшая задержка перед установкой новых данных
    setTimeout(() => {
      // Пример данных QR-кода
      const testData = JSON.stringify({ batch_id: "test-batch-123" } as ParsedBatchData);
      setDirectScanInput(testData);
      
      // Имитируем нажатие Enter
      setTimeout(() => {
        handleDirectScanKeyPress({ key: 'Enter', preventDefault: () => {} } as React.KeyboardEvent<HTMLInputElement>);
      }, 100);
    }, 50);
  };

  // Обертка для onCancel, чтобы сбросить состояние
  const handleCancel = () => {
    // Сбрасываем все состояния
    setDirectScanInput('');
    setScanStatus('');
    setProcessedBatchIds(new Set());
    
    // Вызываем оригинальный onCancel
    onCancel();
  };

  return (
    <>
      <Modal
        title={<>Сканирование</>}
        open={open}
        onCancel={handleCancel}
        width={1200}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
        centered
      >
        {/* Невидимое поле ввода для сканирования */}
        <HiddenInput
          ref={directScanInputRef}
          value={directScanInput}
          onChange={handleDirectScanInput}
          onKeyPress={handleDirectScanKeyPress}
          autoFocus
        />
        
        <div style={{ marginBottom: 16, display: 'none' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Button 
                type="primary" 
                icon={<ScanOutlined />} 
                onClick={() => setScanModalVisible(true)}
                style={{ marginRight: 8 }}
              >
                Открыть сканер камеры
              </Button>
              <Button 
                type="dashed" 
                icon={<PlusOutlined />} 
                onClick={handleAddRow}
                style={{ marginRight: 8 }}
              >
                Добавить строку вручную
              </Button>
              
              {/* Кнопка для тестирования сканирования (только в режиме разработки) */}
              {isDevMode && (
                <Button 
                  onClick={simulateScan}
                  style={{ marginRight: 8 }}
                >
                  Тест сканирования
                </Button>
              )}
              
              {/* Индикатор статуса сканирования (только в режиме разработки) */}
              {isDevMode && scanStatus && (
                <Typography.Text style={{ marginLeft: 16, color: '#1890ff' }}>
                  Статус: {scanStatus}
                </Typography.Text>
              )}
            </Col>
          </Row>
        </div>
        
        <div style={{ marginBottom: 16 }}>
          <Typography.Text style={{ opacity: 0.7 }}>
            Отсканируйте QR-код этикетки реактива для добавления в таблицу. Сканирование и добавление происходит автоматически.
          </Typography.Text>
        </div>
        
        <StyledTable
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          scroll={{ x: 'max-content', y: 300 }}
          loading={loading}
        />
        
        <ButtonsRow justify="end" style={{ marginTop: 24 }}>
          <Space>
            <Typography.Text style={{ marginRight: 16, opacity: 0.5 }}>
              Отсканировано {dataSource.length} позиций
            </Typography.Text>
            <StyledButton onClick={handleCancel}>
              Отмена
            </StyledButton>
            <StyledButton type="primary" onClick={handleSave} disabled={dataSource.length === 0}>
              Сохранить
            </StyledButton>
          </Space>
        </ButtonsRow>
      </Modal>
      
      {/* Модальное окно для сканирования дополнительных позиций */}
      <Scan 
        open={scanModalVisible} 
        onCancel={() => setScanModalVisible(false)} 
        onScan={handleNewScan} 
      />
    </>
  );
};

export default ScanPreviewStandardSample; 