import { FunctionComponent, useState } from 'react';
import styled from "styled-components";
import HeaderAdmin from './HeaderAdmin';
import { Typography, Layout, Space, Button, theme, Segmented } from 'antd';
import { FilterOutlined, ExportOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import StandardSamplesBase, { StandardSamplesUpload } from '../tables/StandardSamplesBase';
import PrecursorsBase, { PrecursorsUpload } from '../tables/PrecursorsBase';
import { useParams } from 'react-router-dom';

const { Title } = Typography;
const { Content } = Layout;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const ControlsBlock = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Chemicals: FunctionComponent = () => {
    const { token } = useToken();
    const [activeSegment, setActiveSegment] = useState<string | number>('Стандартные образцы');
    const { laboratoryId } = useParams();
    const [refreshSamplesTable, setRefreshSamplesTable] = useState(0);
    const [refreshPrecursorsTable, setRefreshPrecursorsTable] = useState(0);

    const handleSamplesUploadSuccess = () => {
        setRefreshSamplesTable(prev => prev + 1);
    };

    const handlePrecursorsUploadSuccess = () => {
        setRefreshPrecursorsTable(prev => prev + 1);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderAdmin />
            <StyledContent>
                <TextParentRoot>
                    <Title level={4}>База химических веществ</Title>
                </TextParentRoot>
                <ControlsBlock>
                    <Segmented
                        size="large"
                        options={['Стандартные образцы', 'Прекурсоры']}
                        value={activeSegment}
                        onChange={setActiveSegment}
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                    />
                    {activeSegment === 'Стандартные образцы' ? (
                        <StandardSamplesUpload onUploadSuccess={handleSamplesUploadSuccess} />
                    ) : (
                        <PrecursorsUpload onUploadSuccess={handlePrecursorsUploadSuccess} />
                    )}
                </ControlsBlock>
                {activeSegment === 'Стандартные образцы' ? (
                    <StandardSamplesBase key={refreshSamplesTable} />
                ) : (
                    <PrecursorsBase key={refreshPrecursorsTable} />
                )}
            </StyledContent>
        </Layout>
    );
};

export default Chemicals; 