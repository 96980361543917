import React from 'react';
import { Button, Typography, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const { Title } = Typography;
const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background-color: #f5f5f5;
`;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  width: 472px;
`;

const StyledTitle = styled(Title)`
  margin: 0 !important;
  color: #000000;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 80px;
  font-size: 20px;
  font-weight: 400;
  border-radius: 4px;
`;

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/auth/login');
  };

  return (
    <StyledLayout>
      <StyledContent>
        <ContentWrapper>
          <StyledTitle level={2}>Личный кабинет</StyledTitle>
          <StyledButton 
            type="primary" 
            size="large"
            onClick={handleLogin}
          >
            Войти
          </StyledButton>
        </ContentWrapper>
      </StyledContent>
    </StyledLayout>
  );
};