import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axios';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const navigate = useNavigate();

  const logout = async () => {
    try {
      await axiosInstance.post('/api/auth/logout');
    } catch (error) {
      console.error('Ошибка при выходе:', error);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setIsAuthenticated(false);
      setIsAdmin(false);
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    
    if (token && user) {
      const userData = JSON.parse(user);
      setIsAuthenticated(true);
      setIsAdmin(userData.is_admin);
      
      if (!userData.is_admin && window.location.pathname.startsWith('/admin')) {
        navigate('/');
      }
      else if (userData.is_admin && window.location.pathname === '/auth/login') {
        navigate('/admin/employers');
      }
    } else {
      setIsAuthenticated(false);
      setIsAdmin(false);
      if (window.location.pathname.startsWith('/admin')) {
        navigate('/auth/login');
      }
    }
    setIsLoading(false);
  }, [navigate]);

  return { isAuthenticated, isAdmin, isLoading, logout };
};