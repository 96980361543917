/**
 * Компонент для управления сотрудниками производства.
 * Сотрудники лабораторий управляются отдельно в компоненте EmployersLaboratory.
 */
import { FunctionComponent, useEffect } from 'react';
import styled from "styled-components";
import HeaderAdmin from './HeaderAdmin';
import { Button, Space, Layout, Typography, theme, Table, Avatar, Switch, Select, Modal, Input, Form, Tag, Checkbox, message } from 'antd';
import { SearchOutlined, UserAddOutlined, MailOutlined, PhoneOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import axiosInstance from '../../api/axios';


const { Content } = Layout;
const { Title } = Typography;
const { useToken } = theme;


const HeadingExtra = styled(Space)`
    height: 40px;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    height: 100vh;
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: #fafafa;
    color: #595959 !important;
  }
    padding: 16px 0;
`;


const FormRow = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    
    .ant-form-item {
        flex: 1;
        margin-bottom: 0;
    }

    .ant-form-item-label > label {
        color: #8C8C8C;
        font-size: 12px;
    }
`;


const StyledForm = styled(Form)`
    .ant-form-item-label {
        padding: 0 0 4px !important;
    }
`;


const ScrollableContent = styled.div`
    max-height: 400px;
    overflow-y: auto;
    padding-right: 6px;
    
    /* Стили для скроллбара */
    &::-webkit-scrollbar {
        width: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
`;


interface User {
  id: number;
  username: string;
  email: string;
  name?: string;
  surname?: string;
  phone?: string;
  is_admin: boolean;
  role?: string;
}

const Employers: FunctionComponent = () => {
    const { token } = useToken();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [editingUser, setEditingUser] = useState<User | null>(null);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/api/users');
            setUsers(response.data);
        } catch (error) {
            message.error('Ошибка при загрузке пользователей');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const [surname, name] = values.name.split(' ');
            const userData = {
                username: values.email,
                email: values.email,
                password: values.password,
                name,
                surname,
                is_admin: false,
                role: 'quality'
            };
            
            await axiosInstance.post('/api/users', userData);
            message.success('Сотрудник успешно добавлен');
            setIsModalOpen(false);
            form.resetFields();
            fetchUsers();
        } catch (error: any) {
            message.error(error.response?.data?.detail || 'Ошибка при добавлении сотрудника');
        }
    };

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    // Обработчики для модального окна удаления
    const showDeleteModal = (userId: number) => {
        setSelectedUserId(userId);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`/api/users/${selectedUserId}`);
            message.success('Сотрудник успешно удален');
            setIsDeleteModalOpen(false);
            fetchUsers(); // Обновляем список
        } catch (error: any) {
            message.error(error.response?.data?.detail || 'Ошибка при удалении сотрудника');
        }
    };

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const showEditModal = async (userId: number) => {
        try {
            const user = users.find(u => u.id === userId);
            if (user) {
                setEditingUser(user);
                form.setFieldsValue({
                    name: `${user.surname || ''} ${user.name || ''}`.trim(),
                    email: user.email,
                    phone: user.phone,
                    role: user.role,
                    is_admin: user.is_admin
                });
                setIsEditModalOpen(true);
            }
        } catch (error) {
            message.error('Ошибка при загрузке данных пользователя');
        }
    };

    const handleEditCancel = () => {
        setIsEditModalOpen(false);
    };

    const handleEditSave = async () => {
        try {
            const values = await form.validateFields();
            const [surname, name] = values.name.split(' ');
            const userData = {
                surname,
                name,
                email: values.email,
                phone: values.phone,
                role: values.role,
                is_admin: values.is_admin
            };
            
            await axiosInstance.put(`/api/users/${selectedUserId}`, userData);
            message.success('Данные успешно обновлены');
            setIsEditModalOpen(false);
            form.resetFields();
            fetchUsers();
        } catch (error: any) {
            message.error(error.response?.data?.detail || 'Ошибка при обновлении данных');
        }
    };

    // Обновляем data для таблицы
    const data = users.map(user => ({
        key: user.id,
        name: `${user.surname || ''} ${user.name || ''}`.trim(),
        email: user.email,
        phone: user.phone || '',
        role: user.role || 'quality',
        isAdmin: user.is_admin,
        avatarColor: token['volcano-2'],
        initialsColor: token['volcano-6'],
        initials: `${(user.surname?.[0] || '')}${(user.name?.[0] || '')}`.toUpperCase(),
    }));

    const columns = [
        {
            title: 'Сотрудник',
            key: 'employee',
            render: (record: any) => (
                <Space>
                    <Avatar style={{ backgroundColor: record.avatarColor, width: '50px', height: '50px', color: record.initialsColor }}>{record.initials}</Avatar>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.name}</Typography.Text>
                        <Space>
                            <Space>
                                <MailOutlined />
                                <Typography.Text type="secondary">{record.email}</Typography.Text>
                            </Space>
                            <Space>
                                <PhoneOutlined />
                                <Typography.Text type="secondary">{record.phone}</Typography.Text>
                            </Space>
                        </Space>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Роль сотрудника',
            key: 'role',
            width: 420,
            render: (record: any) => (
                <Select
                    defaultValue={record.role}
                    style={{ width: '90%' }}
                    options={[
                        { value: 'distributor', label: 'Дистрибьютер' },
                        { value: 'quality', label: 'Менеджер по качеству' },
                    ]}
                />
            ),
        },
        {
            title: 'Права администратора',
            key: 'isAdmin',
            width: 300,
            align: 'left' as const,
            render: (record: any) => (
                <Switch 
                    checked={record.isAdmin}
                    onChange={async (checked) => {
                        try {
                            await axiosInstance.put(`/api/users/${record.key}`, {
                                is_admin: checked
                            });
                            message.success('Права администратора обновлены');
                            fetchUsers();
                        } catch (error: any) {
                            message.error(error.response?.data?.detail || 'Ошибка при обновлении прав');
                        }
                    }}
                />
            ),
        },
        {
            title: 'Действия',
            key: 'actions',
            width: 180,
            align: 'right' as const,
            render: (record: any) => (
                <Space>
                    <Button 
                        type="text" 
                        icon={<EditOutlined style={{ color: token['blue-6'] }} />} 
                        onClick={() => {
                            setSelectedUserId(record.key);
                            showEditModal(record.key);
                        }}
                    />
                    <Button 
                        type="text" 
                        icon={<DeleteOutlined style={{ color: token['red-6'] }} />}
                        onClick={() => showDeleteModal(record.key)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <>
            <HeaderAdmin />
            <StyledContent>
                <TextParentRoot>
                    <Title level={4}>Сотрудники производства</Title>
                    <HeadingExtra>
                        <Button icon={<UserAddOutlined style={{ color: token['blue-5'] }} />} onClick={() => setIsModalOpen(true)} style={{ height: '40px' }}>
                            Добавить сотрудника
                        </Button>
                    </HeadingExtra>
                </TextParentRoot>

                <StyledTable 
                    columns={columns} 
                    dataSource={data}
                    pagination={false}
                />

                <Modal
                    title="Пригласить сотрудника"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    centered={true}
                    width={610}
                    footer={[
                        <Button 
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            style={{ width: '100%', height: '40px' }}
                        >
                            Пригласить
                        </Button>
                    ]}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        style={{ marginTop: '16px' }}
                    >
                        <FormRow>
                            <Form.Item
                                label="Фамилия и Имя"
                                name="name"
                                rules={[{ required: true, message: 'Введите фамилию и имя' }]}
                            >
                                <Input placeholder="Ивнов Иван" style={{ height: '40px', backgroundColor: '#F5F5F5' }} />
                            </Form.Item>
                            
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    { required: true, message: 'Введите email' },
                                    { type: 'email', message: 'Введите корректный email' }
                                ]}
                            >
                                <Input placeholder="example@mail.com" style={{ height: '40px', backgroundColor: '#F5F5F5' }} />
                            </Form.Item>
                            
                            <Form.Item
                                label="Пароль"
                                name="password"
                                rules={[{ required: true, message: 'Введите пароль' }]}
                            >
                                <Input.Password placeholder="••••••••" style={{ height: '40px', backgroundColor: '#F5F5F5' }} />
                            </Form.Item>
                        </FormRow>
                    </Form>
                </Modal>

                <Modal
                    title={null}
                    open={isDeleteModalOpen}
                    onCancel={handleDeleteCancel}
                    centered
                    width={571}
                    footer={null}
                    closable={false}
                >
                    <div style={{ textAlign: 'left', marginBottom: 16 }}>
                        <Space direction="vertical" size={8}>
                            <Typography.Title level={4} style={{ margin: 0 }}>
                                Вы уверены что хотите удалить сотрудника?
                            </Typography.Title>
                            <Typography.Text type="secondary">
                                Даные об удалении записи сохранятся в истории изменений
                            </Typography.Text>
                        </Space>
                    </div>
                    
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Button 
                            onClick={handleDeleteCancel}
                            style={{ width: '262px', height: 40 }}
                        >
                            Отмена
                        </Button>
                        <Button 
                            danger
                            type="primary"
                            onClick={handleDelete}
                            style={{ width: '262px', height: 40 }}
                        >
                            Да, удалить
                        </Button>
                    </Space>
                </Modal>

                <Modal
                    title="Права доступа"
                    open={isEditModalOpen}
                    onCancel={handleEditCancel}
                    footer={null}
                    width={330}
                    centered
                >
                    <div style={{ padding: '16px 0' }}>
                        <Space direction="vertical" size={16} style={{ width: '100%' }}>
                            {editingUser && (
                                <Space size={16}>
                                    <Avatar style={{ backgroundColor: token['volcano-2'], width: 50, height: 50, color: token['volcano-6'] }}>
                                        {`${(editingUser.surname?.[0] || '')}${(editingUser.name?.[0] || '')}`.toUpperCase()}
                                    </Avatar>
                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                        {`${editingUser.surname || ''} ${editingUser.name || ''}`.trim()}
                                    </Typography.Title>
                                </Space>
                            )}
                            
                            <StyledForm form={form} layout="vertical">
                                <Form.Item 
                                    label="Роль"
                                    name="role"
                                >
                                    <Select
                                        style={{ width: '100%', height: 40 }}
                                        options={[
                                            { value: 'distributor', label: 'Дистрибьютер' },
                                            { value: 'quality', label: 'Менеджер по качеству' },
                                        ]}
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="Фамилия и Имя"
                                    name="name"
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="E-mail"
                                    name="email"
                                    rules={[{ type: 'email', message: 'Введите корректный email' }]}
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="Номер телефона"
                                    name="phone"
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>
                            </StyledForm>

                            <div style={{ display: 'flex', gap: '12px' }}>
                                <Button 
                                    onClick={handleEditCancel}
                                    style={{ flex: 1, height: 40 }}
                                >
                                    Отмена
                                </Button>
                                <Button 
                                    type="primary"
                                    onClick={handleEditSave}
                                    style={{ flex: 1, height: 40 }}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </Space>
                    </div>
                </Modal>

            </StyledContent>
        </>
    );
};

export default Employers;