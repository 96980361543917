import axiosInstance from './axios';

export interface ExpirationNotification {
    id: number;
    type: string;
    name: string;
    expiration_date: string;
    days_until_expiration: number;
    laboratory_id: number;
    is_read: boolean;
}

export interface NotificationResponse {
    reagents: ExpirationNotification[];
    standards: ExpirationNotification[];
    total_count: number;
}

export const getExpirationNotifications = async (laboratoryId: string): Promise<NotificationResponse> => {
    const { data } = await axiosInstance.get<NotificationResponse>(`/api/laboratory/${laboratoryId}/notifications`);
    return data;
};

export const getAllExpirationNotifications = async (): Promise<NotificationResponse> => {
    const { data } = await axiosInstance.get<NotificationResponse>('/api/notifications/all');
    return data;
};

export const markNotificationsAsRead = async (notificationIds: number[], type: string): Promise<void> => {
    await axiosInstance.post('/api/notifications/read', {
        notification_ids: notificationIds,
        notification_type: type
    });
};

export const markAllNotificationsAsRead = async (): Promise<void> => {
    await axiosInstance.post('/api/notifications/read-all');
}; 