import React from 'react';
import { Layout, Button, Badge, Space, Typography, theme, Popover, List } from 'antd';
import { BellOutlined, LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import logo from '../../logo.svg'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { useEffect, useState } from 'react';

interface CurrentUser {
    name: string;
    role: string;
}

const { Header } = Layout;
const { Text } = Typography;
const { useToken } = theme;

const StyledHeader = styled(Header)`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  height: auto;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LogoImage = styled.img`
  height: 25.5px;
  object-fit: contain;
`;

const UserInfo = styled.div`
  text-align: right;
  line-height: 16px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;


const HeaderQualityControl: React.FC = () => {
    const { token } = useToken();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const { data } = await axiosInstance.get('/api/users/me');
                setCurrentUser({
                    name: data.name,
                    role: data.role
                });
                
                // Проверяем роль пользователя
                if (data.role !== 'quality') {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Failed to fetch current user:', error);
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };

        fetchCurrentUser();
    }, [navigate]);

    const UserName = styled(Text)`
        display: block;
        color: ${token['blue-6']};
    `;

    const handleLogout = () => {
        navigate('/auth/logout');
    };

    const userMenuContent = (
        <List>
            <List.Item 
                onClick={handleLogout}
                style={{ cursor: 'pointer', padding: '0px 8px' }}
            >
                <Space>
                    <LogoutOutlined style={{ color: token['red-6'] }} />
                    <Typography.Text>Выйти</Typography.Text>
                </Space>
            </List.Item>
        </List>
    );

    return (
        <StyledHeader>
            <Logo>
                <LogoImage alt="" src={logo} />
            </Logo>

            <RightSection>
                <Space>
                    <UserInfo>
                        <UserName>
                            Контроль качества
                        </UserName>
                    </UserInfo>
                    <Popover 
                        content={userMenuContent}
                        trigger="hover"
                        placement="bottom"
                        arrow={false}
                    >
                        <Button 
                            type="primary" 
                            shape="circle" 
                            style={{ 
                                width: "40px", 
                                height: "40px", 
                                backgroundColor: token['blue-6'],
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {loading ? '...' : currentUser?.name 
                                ? currentUser.name[0].toUpperCase()
                                : 'КК'
                            }
                        </Button>
                    </Popover>
                </Space>
            </RightSection>
        </StyledHeader>
    );
};

export default HeaderQualityControl;