import { RightOutlined } from "@ant-design/icons";
import { Card, List, theme } from "antd";
import React, { useState } from "react";
import styled from 'styled-components';

const { useToken } = theme;

interface StyledListItemProps {
  $isActive: boolean;
  $blueColor: string;
}

const StyledListItem = styled(List.Item)<StyledListItemProps>`
  && {
    background-color: ${props => props.$isActive ? "#e6f7ff" : "transparent"};
    cursor: pointer;
    padding: 8px 12px 8px 15px;
    border-radius: 4px;
    margin-bottom: 4px;
    transition: all 0.3s;
    color: ${props => props.$isActive ? props.$blueColor : 'rgba(0, 0, 0, 0.85)'};
    font-weight: ${props => props.$isActive ? 500 : 400};
    border-radius: 4px !important;
    border-bottom: none;

    &:hover {
      background-color: ${props => props.$isActive ? "#e6f7ff" : "#f5f5f5"};
    }
  }
`;

interface MSIGroupsProps {
  onSelect?: (item: string) => void;
}

const MSIGroups: React.FC<MSIGroupsProps> = ({ onSelect }) => {
  const { token } = useToken();
  const [activeItem, setActiveItem] = useState<string>('');

  const items = [
    'Аскорбиновая кислота',
    'Нефть'
  ];

  const handleItemClick = (item: string) => {
    setActiveItem(item);
    onSelect?.(item);
  };

  return (
    <Card style={{ borderColor: '#d9d9d9' }}>
      <List
        dataSource={items}
        renderItem={(item) => (
          <StyledListItem
            $isActive={activeItem === item}
            $blueColor={token['blue-5']}
            onClick={() => handleItemClick(item)}
          >
            {item}
          </StyledListItem>
        )}
      />
    </Card>
  );
};

export default MSIGroups;
