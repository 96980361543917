import { api } from './api';

export interface StandardSample {
    id: number;
    index: string;
    name: string;
    gso_number: string;
    category: string;
    parameter: string;
    measurement_unit: string;
    error_unit: string;
}

export const getStandardSamples = async (): Promise<StandardSample[]> => {
    const response = await api.get('/standard-samples/');
    return response.data;
};

export const uploadStandardSamples = async (file: File): Promise<StandardSample[]> => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post('/standard-samples/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
}; 