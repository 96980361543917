import { api } from './api';

export const getChemicalNames = async (): Promise<string[]> => {
    try {
        // Используем Promise.allSettled вместо Promise.all для обработки отдельных ошибок
        const [standardsResult, precursorsResult] = await Promise.allSettled([
            api.get('/standard-samples/'),
            api.get('/precursors/')
        ]);
        
        let standardNames: string[] = [];
        let precursorNames: string[] = [];
        
        // Обрабатываем результаты для стандартных образцов
        if (standardsResult.status === 'fulfilled') {
            standardNames = standardsResult.value.data.map((item: any) => item.name);
        }
        
        // Обрабатываем результаты для прекурсоров
        if (precursorsResult.status === 'fulfilled') {
            precursorNames = precursorsResult.value.data.map((item: any) => item.name);
        }
        
        // Объединяем и удаляем дубликаты
        return Array.from(new Set([...standardNames, ...precursorNames]));
    } catch (error) {
        console.error('Ошибка при получении списка веществ:', error);
        return []; // Возвращаем пустой массив в случае ошибки
    }
};

export const getPrecursorChemicalNames = async (): Promise<string[]> => {
    try {
        const response = await api.get('/precursors/');
        return response.data.map((item: any) => item.name);
    } catch (error) {
        console.error('Ошибка при получении списка прекурсоров:', error);
        return []; // Возвращаем пустой массив в случае ошибки
    }
}; 