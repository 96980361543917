import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Table, Checkbox, Button, message } from 'antd';
import type { TableProps } from 'antd';
import { getBatches, getBatch, Batch, CertifiedValue } from '../../api/batches';
import dayjs from 'dayjs';

interface DataType {
  key: string;
  batchCode: string;
  registrationDate: string;
  sampleIndex: string;
  soName: string;
  gsoNumber: string;
  soCategory: string;
  packaging: string;
  indicatorName: string;
  certifiedValue: number | string;
  certifiedValueUnit: string;
  error: number | string;
  errorUnit: string;
  expiryDate: string;
}

interface SimpleTableProps {
  onRow?: (record: DataType) => { onClick: () => void } & React.HTMLAttributes<HTMLElement>;
  searchValue?: string;
  onSelectedRowsChange?: (selectedRowKeys: React.Key[]) => void;
}

const SimpleTable = forwardRef<any, SimpleTableProps>((props, ref) => {
  const { onRow, searchValue, onSelectedRowsChange } = props;

  // Добавляем состояние для сортировки
  const [sortedInfo, setSortedInfo] = useState<{
    columnKey: string | null;
    order: 'ascend' | 'descend' | null;
  }>({
    columnKey: null,
    order: null,
  });

  // Добавляем состояние для выбранных строк
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  
  // Состояние для данных таблицы
  const [data, setData] = useState<DataType[]>([]);
    
  // Состояние для загрузки данных
  const [loading, setLoading] = useState(false);

  // Загрузка данных при монтировании компонента
  useEffect(() => {
    fetchBatches();
  }, []);

  // Функция для получения ID партии из ключа строки
  const getBatchIdFromKey = (key: string): number => {
    // Ключ имеет формат "id-indicatorName" или просто "id"
    const idPart = key.split('-')[0];
    return parseInt(idPart, 10);
  };

  // Функция для обработки клика по строке
  const handleRowClick = async (record: DataType) => {
    try {
      const batchId = getBatchIdFromKey(record.key);
      const batch = await getBatch(batchId);
      return batch;
    } catch (error) {
      console.error('Ошибка при получении данных партии:', error);
      message.error('Не удалось загрузить данные партии');
      return null;
    }
  };

  // Экспортируем функции через ref
  useImperativeHandle(ref, () => ({
    fetchBatches,
    handleRowClick
  }));

  // Функция для загрузки партий
  const fetchBatches = async () => {
    try {
      setLoading(true);
      const batches = await getBatches();
      
      // Преобразуем данные для таблицы
      const tableData: DataType[] = [];
      
      batches.forEach((batch) => {
        // Если у партии есть аттестованные значения
        if (batch.certified_values && batch.certified_values.length > 0) {
          batch.certified_values.forEach((cv: CertifiedValue) => {
            tableData.push({
              key: `${batch.id}-${cv.indicator_name}`,
              batchCode: batch.batch_code,
              registrationDate: dayjs(batch.registration_date).format('DD.MM.YYYY'),
              sampleIndex: batch.batch_number,
              soName: batch.so_name || '',
              gsoNumber: batch.gso_number || '',
              soCategory: batch.so_category || '',
              packaging: batch.packaging || '',
              indicatorName: cv.indicator_name,
              certifiedValue: cv.value,
              certifiedValueUnit: cv.unit,
              error: cv.error,
              errorUnit: cv.error_unit,
              expiryDate: batch.expiry_date ? dayjs(batch.expiry_date).format('DD.MM.YYYY') : ''
            });
          });
        } else {
          // Если у партии нет аттестованных значений, все равно добавляем ее в таблицу
          tableData.push({
            key: `${batch.id}`,
            batchCode: batch.batch_code,
            registrationDate: dayjs(batch.registration_date).format('DD.MM.YYYY'),
            sampleIndex: batch.batch_number,
            soName: batch.so_name || '',
            gsoNumber: batch.gso_number || '',
            soCategory: batch.so_category || '',
            packaging: batch.packaging || '',
            indicatorName: '',
            certifiedValue: '',
            certifiedValueUnit: '',
            error: '',
            errorUnit: '',
            expiryDate: batch.expiry_date ? dayjs(batch.expiry_date).format('DD.MM.YYYY') : ''
          });
        }
      });
      
      setData(tableData);
      setLoading(false);
    } catch (error) {
      console.error('Ошибка при загрузке партий:', error);
      setLoading(false);
    }
  };

  // Обработчик изменения сортировки
  const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    if (Array.isArray(sorter)) {
      setSortedInfo(sorter[0] as any);
    } else {
      setSortedInfo(sorter as any);
    }
  };

  // Обработчик выбора строк
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // Вызываем callback, если он предоставлен
    if (onSelectedRowsChange) {
      onSelectedRowsChange(newSelectedRowKeys);
    }
  };

  // Конфигурация для выбора строк
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Шифр партии',
      dataIndex: 'batchCode',
      key: 'batchCode',
      width: 150,
      sorter: (a, b) => a.batchCode.localeCompare(b.batchCode),
      sortOrder: sortedInfo.columnKey === 'batchCode' ? sortedInfo.order : null,
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      width: 200,
      sorter: (a, b) => a.registrationDate.localeCompare(b.registrationDate),
      sortOrder: sortedInfo.columnKey === 'registrationDate' ? sortedInfo.order : null,
    },
    {
      title: 'Индекс образца',
      dataIndex: 'sampleIndex',
      key: 'sampleIndex',
      width: 200,
      sorter: (a, b) => a.sampleIndex.localeCompare(b.sampleIndex),
      sortOrder: sortedInfo.columnKey === 'sampleIndex' ? sortedInfo.order : null,
    },
    {
      title: 'Наименование СО',
      dataIndex: 'soName',
      key: 'soName',
      width: 200,
      sorter: (a, b) => a.soName.localeCompare(b.soName),
      sortOrder: sortedInfo.columnKey === 'soName' ? sortedInfo.order : null,
    },
    {
      title: 'Номер ГСО',
      dataIndex: 'gsoNumber',
      key: 'gsoNumber',
      width: 200,
      sorter: (a, b) => a.gsoNumber.localeCompare(b.gsoNumber),
      sortOrder: sortedInfo.columnKey === 'gsoNumber' ? sortedInfo.order : null,
    },
    {
      title: 'Категория СО',
      dataIndex: 'soCategory',
      key: 'soCategory',
      width: 200,
      sorter: (a, b) => a.soCategory.localeCompare(b.soCategory),
      sortOrder: sortedInfo.columnKey === 'soCategory' ? sortedInfo.order : null,
    },
    {
      title: 'Фасовка',
      dataIndex: 'packaging',
      key: 'packaging',
      width: 200,
      sorter: (a, b) => a.packaging.localeCompare(b.packaging),
      sortOrder: sortedInfo.columnKey === 'packaging' ? sortedInfo.order : null,
    },
    {
      title: 'Наименование показателя',
      dataIndex: 'indicatorName',
      key: 'indicatorName',
      width: 250,
      sorter: (a, b) => a.indicatorName.localeCompare(b.indicatorName),
      sortOrder: sortedInfo.columnKey === 'indicatorName' ? sortedInfo.order : null,
    },
    {
      title: 'Аттестованное значение',
      dataIndex: 'certifiedValue',
      key: 'certifiedValue',
      width: 250,
      sorter: (a, b) => {
        const aVal = typeof a.certifiedValue === 'number' ? a.certifiedValue : parseFloat(a.certifiedValue as string) || 0;
        const bVal = typeof b.certifiedValue === 'number' ? b.certifiedValue : parseFloat(b.certifiedValue as string) || 0;
        return aVal - bVal;
      },
      sortOrder: sortedInfo.columnKey === 'certifiedValue' ? sortedInfo.order : null,
    },
    {
      title: 'Ед. изм. АЗ',
      dataIndex: 'certifiedValueUnit',
      key: 'certifiedValueUnit',
      width: 200,
      sorter: (a, b) => a.certifiedValueUnit.localeCompare(b.certifiedValueUnit),
      sortOrder: sortedInfo.columnKey === 'certifiedValueUnit' ? sortedInfo.order : null,
    },
    {
      title: 'Погрешность АЗ',
      dataIndex: 'error',
      key: 'error',
      width: 200,
      sorter: (a, b) => {
        const aVal = typeof a.error === 'number' ? a.error : parseFloat(a.error as string) || 0;
        const bVal = typeof b.error === 'number' ? b.error : parseFloat(b.error as string) || 0;
        return aVal - bVal;
      },
      sortOrder: sortedInfo.columnKey === 'error' ? sortedInfo.order : null,
    },
    {
      title: 'Ед. изм. погрешности АЗ',
      dataIndex: 'errorUnit',
      key: 'errorUnit',
      width: 250,
      sorter: (a, b) => a.errorUnit.localeCompare(b.errorUnit),
      sortOrder: sortedInfo.columnKey === 'errorUnit' ? sortedInfo.order : null,
    },
    {
      title: 'Срок годности',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      width: 200,
      sorter: (a, b) => a.expiryDate.localeCompare(b.expiryDate),
      sortOrder: sortedInfo.columnKey === 'expiryDate' ? sortedInfo.order : null,
    },
  ];

  // Фильтрация данных по поисковому запросу
  const filteredData = useMemo(() => {
    if (!searchValue) return data;
    
    return data.filter(item => 
      item.batchCode.toLowerCase().includes(searchValue.toLowerCase()) || 
      item.soName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [data, searchValue]);
  
  // Используем отфильтрованные данные для отображения
  const dataSource = filteredData;

  return (
    <div>
      <div style={{ border: '1px solid rgba(217, 217, 217, 1)', borderRadius: '8px', overflow: 'hidden' }}>
        <Table 
          columns={columns} 
          dataSource={dataSource} 
          scroll={{ x: 'max-content' }}
          pagination={{ pageSize: 100 }}
          size="middle"
          className="quality-control-table"
          rowKey="key"
          onChange={handleChange}
          rowSelection={rowSelection}
          loading={loading}
          // Увеличиваем высоту заголовка
          components={{
            header: {
              cell: (props: any) => (
                <th 
                  {...props} 
                  style={{ 
                    ...props.style, 
                    height: '100px',
                    padding: '16px 8px',
                    fontWeight: 600,
                    color: '#595959',
                    backgroundColor: '#fafafa'
                  }}
                />
              )
            }
          }}
          onRow={onRow || ((record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: 'pointer' }
          }))}
        />
      </div>
    </div>
  );
});

export default SimpleTable;