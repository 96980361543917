import React, { useEffect, useState, useMemo } from 'react';
import { Layout, Typography, List, Space, Tag, theme, Select, Button, Badge } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import HeaderAdmin from './HeaderAdmin';
import { getAllExpirationNotifications, markAllNotificationsAsRead, markNotificationsAsRead, ExpirationNotification, NotificationResponse } from '../../api/notifications';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

type FilterType = 'all' | 'read' | 'unread';

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
`;

const NotificationsContainer = styled.div`
    margin-top: 8px;
    background: white;
    border-radius: 8px;
    padding: 0px;
    width: 916px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
`;

const StyledListItem = styled(List.Item)`
    padding: 16px !important;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color:rgba(250, 250, 250, 1);
    }
`;

const NotificationItem = styled.div`
    width: 100%;
    .time {
        color: #8c8c8c;
        font-size: 12px;
    }
    .category-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
    }
    .category {
        color: #8c8c8c;
        font-size: 12px;
    }
`;

const NotificationsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #E0E0E0;
`;

const StyledSelect = styled(Select<FilterType>)`
    width: 150px;
    .ant-select-selection-item {
        color: rgba(9, 109, 217, 1) !important;
    }
`;

const HeaderButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

const ReadAllButton = styled(Button)`
    color: ${props => props.theme.token?.colorPrimary};
    font-weight: 400;
    padding: 0;
    height: auto;

    &:hover {
        background: none;
        color: ${props => props.theme.token?.colorPrimaryHover};
    }
`;

const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;

const FilterLabel = styled(Text)`
    color: rgba(0, 0, 0, 1);
`;

const SettingsButton = styled(Button)`
    padding: 4px;
    height: 45px;
    width: 45px !important;
    color: rgba(9, 109, 217, 1);
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: none;
        color: rgba(0, 0, 0, 0.65);
        border-color: #4096ff;
    }
`;

const Notifications: React.FC = () => {
    const { token } = useToken();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState<NotificationResponse | null>(null);
    const [filter, setFilter] = useState<FilterType>('all');

    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const data = await getAllExpirationNotifications();
            setNotifications(data);
        } catch (error) {
            console.error('Failed to fetch notifications:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
        // Обновляем данные каждые 5 минут
        const interval = setInterval(fetchNotifications, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    const formatNotificationType = (type: string) => {
        return type === 'reagent' ? 'Реактивы/Прекурсоры' : 'СО';
    };

    const handleFilterChange = (value: FilterType) => {
        setFilter(value);
    };

    const handleReadAll = async () => {
        try {
            setLoading(true);
            await markAllNotificationsAsRead();
            await fetchNotifications(); // Перезагружаем данные после отметки всех как прочитанных
        } catch (error) {
            console.error('Failed to mark all notifications as read:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNotificationClick = async (notification: ExpirationNotification) => {
        try {
            if (!notification.is_read) {
                await markNotificationsAsRead([notification.id], notification.type);
                // Обновляем состояние локально
                setNotifications(prev => {
                    if (!prev) return prev;
                    return {
                        ...prev,
                        reagents: notification.type === 'reagent' 
                            ? prev.reagents.map(n => n.id === notification.id ? { ...n, is_read: true } : n)
                            : prev.reagents,
                        standards: notification.type === 'standard'
                            ? prev.standards.map(n => n.id === notification.id ? { ...n, is_read: true } : n)
                            : prev.standards,
                        total_count: prev.total_count
                    };
                });
            }
            navigate(`/admin/manage-laboratory/${notification.laboratory_id}`, {
                state: { 
                    highlightId: notification.id,
                    tab: notification.type === 'reagent' ? 1 : 2
                }
            });
        } catch (error) {
            console.error('Failed to mark notification as read:', error);
        }
    };

    const filteredNotifications = useMemo(() => {
        if (!notifications) return [];
        
        switch (filter) {
            case 'read':
                return notifications.reagents.filter((n: ExpirationNotification) => n.is_read)
                    .concat(notifications.standards.filter((n: ExpirationNotification) => n.is_read));
            case 'unread':
                return notifications.reagents.filter((n: ExpirationNotification) => !n.is_read)
                    .concat(notifications.standards.filter((n: ExpirationNotification) => !n.is_read));
            default:
                return notifications.reagents.concat(notifications.standards);
        }
    }, [notifications, filter]);

    const unreadCount = useMemo(() => {
        if (!notifications) return 0;
        
        return notifications.reagents.filter((n: ExpirationNotification) => !n.is_read).length + 
               notifications.standards.filter((n: ExpirationNotification) => !n.is_read).length;
    }, [notifications]);

    const handleSettingsClick = () => {
        if (notifications && notifications.reagents.length > 0) {
            navigate(`/admin/settings-laboratory/${notifications.reagents[0].laboratory_id}`);
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderAdmin />
            <StyledContent>
                <TextParentRoot>
                    <Space size={8}>
                        <Title level={3}>Уведомления</Title>
                        <Badge 
                            count={unreadCount} 
                            style={{ 
                                backgroundColor: '#ff4d4f',
                                marginTop: -8
                            }}
                        />
                    </Space>
                </TextParentRoot>

                <NotificationsContainer>
                    <NotificationsHeader>
                        <FilterContainer>
                            <FilterLabel>Показать:</FilterLabel>
                            <StyledSelect
                                variant="borderless"
                                suffixIcon={null}
                                value={filter}
                                onChange={handleFilterChange}
                                options={[
                                    { value: 'all', label: 'Все' },
                                    { value: 'read', label: 'Прочитанные' },
                                    { value: 'unread', label: 'Непрочитанные' }
                                ]}
                            />
                        </FilterContainer>
                        <HeaderButtons>
                            <ReadAllButton 
                                type="link"
                                onClick={handleReadAll}
                                disabled={unreadCount === 0}
                            >
                                Прочитать все
                            </ReadAllButton>
                            <SettingsButton 
                                type="text" 
                                icon={<SettingOutlined style={{ width: 14, height: 14 }} />}
                                onClick={handleSettingsClick}
                            />
                        </HeaderButtons>
                    </NotificationsHeader>
                    <List
                        loading={loading}
                        dataSource={filteredNotifications}
                        renderItem={(item: ExpirationNotification) => (
                            <StyledListItem 
                                onClick={() => handleNotificationClick(item)}
                                style={{ 
                                    backgroundColor: item.is_read ? 'rgba(0, 0, 0, 0.02)' : 'white'
                                }}
                            >
                                <NotificationItem>
                                    <div className="category-row">
                                        <span className="category" style={{ fontWeight: 500, fontSize: 14 }}>{formatNotificationType(item.type)}</span>
                                        <span className="time" style={{ fontWeight: 500, fontSize: 14 }}>
                                            {new Date(item.expiration_date).toLocaleDateString('ru-RU')}
                                        </span>
                                    </div>
                                    <div>
                                        <Text style={{ display: 'block', fontWeight: 500, fontSize: 24 }}>
                                            {item.days_until_expiration} дней до окончания срока годности
                                        </Text>
                                    </div>
                                    <Text style={{ display: 'block', fontWeight: 500, fontSize: 20 }}>
                                        {item.name}
                                    </Text>
                                </NotificationItem>
                            </StyledListItem>
                        )}
                    />
                </NotificationsContainer>
            </StyledContent>
        </Layout>
    );
};

export default Notifications; 