import { FunctionComponent } from 'react';
import styled from "styled-components";
import HeaderAdmin from './HeaderAdmin';
import { Typography, Layout, Space, Select, theme } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

const { Title } = Typography;
const { Content } = Layout;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    color: #262626;
    font-family: Roboto;
    cursor: pointer;
`;

const BackButton = styled(Space)`
    &:hover {
        opacity: 0.8;
    }
    align-items: center;
`;

const StyledArrow = styled(ArrowLeftOutlined)`
    color: #262626;
    font-size: 16px;
    display: flex;
    align-items: center;
`;

const SettingsBlock = styled.div`
  width: 690px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  overflow: hidden;
`;

const SettingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  .setting-label {
    padding: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 22px;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .ant-select {
    width: 271px;
    height: 54px;

    .ant-select-selector {
      border-radius: 4px !important;
    }
  }
`;

const SettingsLaboratory: FunctionComponent = () => {
    const navigate = useNavigate();
    const { token } = useToken();
    const { id } = useParams<{ id: string }>();

    const handleBack = () => {
        if (id) {
            navigate(`/admin/manage-laboratory/${id}`);
        } else {
            navigate('/admin/laboratories');
        }
    };

    const notificationOptions = [
        { value: '1month', label: 'За 1 месяц' },
        { value: '2months', label: 'За 2 месяца' },
        { value: '3months', label: 'За 3 месяца' },
    ];

    const displayOptions = [
        { value: '1month', label: '1 месяц' },
        { value: '2months', label: '2 месяца' },
        { value: '3months', label: '3 месяца' },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderAdmin />
            <StyledContent>
                <TextParentRoot onClick={handleBack} style={{ marginBottom: 22 }}>
                    <BackButton align="center">
                        <StyledArrow />
                        <Title level={4} style={{ margin: 0, paddingLeft: 16, fontWeight: 500 }}>Назад</Title>
                    </BackButton>
                </TextParentRoot>

                <Title level={4}>Настройки</Title>

                <SettingsBlock>
                    <SettingRow>
                        <div className="setting-label">
                            Уведомление об окончании срока годности
                        </div>
                        <Select
                            defaultValue="1month"
                            options={notificationOptions}
                        />
                    </SettingRow>

                    <SettingRow>
                        <div className="setting-label">
                            Уведомление о поверке оборудование
                        </div>
                        <Select
                            defaultValue="1month"
                            options={notificationOptions}
                        />
                    </SettingRow>

                    <SettingRow>
                        <div className="setting-label">
                            Уведомление о заканчивающихся реактивах
                        </div>
                        <Select
                            defaultValue="1month"
                            options={notificationOptions}
                        />
                    </SettingRow>

                    <SettingRow>
                        <div className="setting-label">
                            Уведомление о заканчивающихся СО
                        </div>
                        <Select
                            defaultValue="1month"
                            options={notificationOptions}
                        />
                    </SettingRow>

                    <SettingRow>
                        <div className="setting-label">
                            Отображать в таблице после исчерпания остатков
                        </div>
                        <Select
                            defaultValue="1month"
                            options={displayOptions}
                        />
                    </SettingRow>
                </SettingsBlock>
            </StyledContent>
        </Layout>
    );
};

export default SettingsLaboratory;