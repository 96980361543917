import { FunctionComponent, useState } from 'react';
import styled from "styled-components";
import HeaderAdmin from './HeaderAdmin';
import { Typography, Layout, Space, Button, theme, Segmented } from 'antd';
import { FilterOutlined, ExportOutlined, SearchOutlined, PlusOutlined, CalendarOutlined, ImportOutlined, ScanOutlined } from '@ant-design/icons';
import ReagentsTable from '../tables/Reagents';
import { useParams } from 'react-router-dom';

const { Title } = Typography;
const { Content } = Layout;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const ControlsBlock = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Chemicals: FunctionComponent = () => {
    const { token } = useToken();
    const [activeSegment, setActiveSegment] = useState<string | number>('Реактивы');
    const { laboratoryId } = useParams();

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderAdmin />
            <StyledContent>
                <TextParentRoot>
                    <Title level={4}>База химических веществ</Title>
                </TextParentRoot>

                <ControlsBlock style={{ marginBottom: 24 }}>
                    <Segmented 
                        size="large" 
                        options={['Реактивы', 'Стандартные образцы (СО)', 'Прекурсоры']} 
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                        value={activeSegment}
                        onChange={setActiveSegment}
                    />
                    <HeadingExtra>
                        <Button 
                            icon={<ExportOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px', width: '45px' }} 
                        />
                        <Button 
                            icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px' }}
                        >
                            Добавить запись
                        </Button>
                    </HeadingExtra>
                </ControlsBlock>

                <ReagentsTable laboratoryId={laboratoryId!} />
            </StyledContent>
        </Layout>
    );
};

export default Chemicals; 