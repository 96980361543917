import React, { useState, useEffect } from 'react';
import { Button, Col, Input, Row, Select, Typography, DatePicker, Modal } from "antd";
import styled from "styled-components";
import dayjs from 'dayjs';
import { CheckboxField } from './CheckboxField';
import { message } from 'antd';
import type { StandardType } from '../pages/tables/StandartSamples';
import type { Dayjs } from 'dayjs';
import type { DatePickerProps } from 'antd';
import { theme } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { updateStandard, deleteStandard } from '../api/chemicalRegistry';
import { useLaboratoryId } from '../hooks/useLaboratoryId';

const { Text } = Typography;

// Используем те же стили, что и в GetReagent
const ScreenContainer = styled.div`
  width: 400px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const BottomButtons = styled.div`
  padding: 16px;
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  &[type='number'] {
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  justify-content: center;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
  }

  .cancel-button {
    flex: 1;
    max-width: 178px;
  }

  .action-button {
    flex: 1;
    max-width: 178px;
  }
`;

// Добавляем опции
const standardTypes = [
    'ГСО 1234-56 Стандартный образец 1',
    'ОСО 7890-12 Стандартный образец 2',
    'СОП 3456-78 Стандартный образец 3',
].map(item => ({ label: item, value: item }));

const manufacturerOptions = [
    'ФГУП "ВНИИМ им. Д.И. Менделеева"',
    'ФГУП "УНИИМ"',
    'ООО "Химтест"',
].map(item => ({ label: item, value: item }));

const purposeOptions = [
    'Калибровка',
    'Поверка',
    'Контроль точности',
].map(item => ({ label: item, value: item }));

const indicatorOptions = [
    'Массовая доля железа',
    'Массовая доля углерода',
    'Массовая доля серы',
].map(item => ({ label: item, value: item }));

const notificationOptions = [
    'За месяц',
    'За 2 месяца',
    'За 3 месяца',
].map(item => ({ label: item, value: item }));

interface EditSSProps {
  onCancel: () => void;
  record: StandardType;
  onSuccess?: () => void;
}

const ScreenTitle = styled(Typography.Title)`
  margin: 16px !important;
  font-size: 16px !important;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditSS: React.FC<EditSSProps> = ({ onCancel, record, onSuccess }) => {
  const laboratoryId = useLaboratoryId();
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [editedRecord, setEditedRecord] = useState<StandardType>(record);

  // Добавляем эффект для обновления editedRecord при изменении record
  useEffect(() => {
    setEditedRecord(record);
  }, [record]);

  // Типизированные обработчики
  const handleSelectChange = (field: keyof StandardType) => (value: unknown) => {
    setEditedRecord(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleDateChange = (field: keyof StandardType) => 
    (_: unknown, dateString: string | string[]) => {
      setEditedRecord(prev => ({
        ...prev,
        [field]: typeof dateString === 'string' ? dateString || null : null
      }));
    };

  const handleSave = async () => {
    if (!laboratoryId) return;

    try {
      setLoading(true);
      await updateStandard(laboratoryId, record.key as number, {
        name: editedRecord.name,
        manufacturer: editedRecord.manufacturer,
        purpose: editedRecord.purpose,
        indicator: editedRecord.indicator,
        certifiedValue: editedRecord.certifiedValue,
        errorValue: editedRecord.errorValue,
        additionalInfo: editedRecord.additionalInfo,
        normativeDocument: editedRecord.normativeDocument,
        expirationDate: editedRecord.expirationDate,
        expirationNotification: editedRecord.expirationNotification,
        releaseDate: editedRecord.releaseDate,
        notes: editedRecord.notes,
        isPrecursor: editedRecord.isPrecursor
      });
      
      message.success('Запись успешно обновлена');
      onSuccess?.();
      onCancel();
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('Ошибка при обновлении записи');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!laboratoryId) return;

    try {
      setLoading(true);
      await deleteStandard(laboratoryId, record.key as number);
      message.success('Запись успешно удалена');
      onSuccess?.();
      onCancel();
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error('Ошибка при удалении записи');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleContainerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      const handleClickOutside = (e: MouseEvent) => {
        e.stopPropagation();
      };
      document.addEventListener('mousedown', handleClickOutside);
      setTimeout(() => {
        document.removeEventListener('mousedown', handleClickOutside);
      }, 0);
    }
  };

  return (
    <ScreenContainer onClick={handleContainerClick} onMouseDown={handleMouseDown}>
      <HeaderContainer>
        <ScreenTitle level={4} style={{ margin: 0 }}>Изменение записи</ScreenTitle>
      </HeaderContainer>

      <ScrollContent>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <StyledText>Наименование, тип, номер и категория СО</StyledText>
            <StyledSelect
              value={editedRecord.name}
              onChange={handleSelectChange('name')}
              options={standardTypes}
              style={{ width: '100%' }}
              showSearch
              allowClear
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
              dropdownStyle={{ zIndex: 1100 }}
            />
          </Col>

          <Col span={24}>
            <StyledText>Изготовитель СО</StyledText>
            <StyledSelect
              value={editedRecord.manufacturer}
              onChange={handleSelectChange('manufacturer')}
              options={manufacturerOptions}
              style={{ width: '100%' }}
              showSearch
              allowClear
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
              dropdownStyle={{ zIndex: 1100 }}
            />
          </Col>

          <Col span={24}>
            <StyledText>Назначение</StyledText>
            <StyledSelect
              value={editedRecord.purpose}
              onChange={handleSelectChange('purpose')}
              options={purposeOptions}
              style={{ width: '100%' }}
              showSearch
              allowClear
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
              dropdownStyle={{ zIndex: 1100 }}
            />
          </Col>

          <Col span={24}>
            <StyledText>Показатель</StyledText>
            <StyledSelect
              value={editedRecord.indicator}
              onChange={handleSelectChange('indicator')}
              options={indicatorOptions}
              style={{ width: '100%' }}
              showSearch
              allowClear
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
              dropdownStyle={{ zIndex: 1100 }}
            />
          </Col>

          <Col span={24}>
            <StyledText>Аттестованное значение м.д. %</StyledText>
            <StyledInput
              type="number"
              value={editedRecord.certifiedValue}
              onChange={(e) => setEditedRecord(prev => ({ ...prev, certifiedValue: Number(e.target.value) }))}
              placeholder="введите значение"
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
            />
          </Col>

          <Col span={24}>
            <StyledText>Погрешность а.з. абс. %</StyledText>
            <StyledInput
              type="number"
              value={editedRecord.errorValue}
              onChange={(e) => setEditedRecord(prev => ({ ...prev, errorValue: Number(e.target.value) }))}
              placeholder="введите значение"
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
            />
          </Col>

          <Col span={24}>
            <StyledText>Дополнительные сведения</StyledText>
            <StyledInput
              value={editedRecord.additionalInfo}
              onChange={(e) => setEditedRecord(prev => ({ ...prev, additionalInfo: e.target.value }))}
              placeholder="введите информацию"
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
            />
          </Col>

          <Col span={24}>
            <StyledText>Нормативный документ</StyledText>
            <StyledInput
              value={editedRecord.normativeDocument}
              onChange={(e) => setEditedRecord(prev => ({ ...prev, normativeDocument: e.target.value }))}
              placeholder="введите документ"
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
            />
          </Col>

          <Col span={24}>
            <StyledText>Срок годности экземпляра СО</StyledText>
            <StyledDatePicker
              value={editedRecord.expirationDate ? dayjs(editedRecord.expirationDate) : null}
              onChange={handleDateChange('expirationDate')}
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
              onOpenChange={handleOpenChange}
              popupStyle={{ zIndex: 1100 }}
              getPopupContainer={(trigger) => trigger.parentElement || document.body}
            />
          </Col>

          <Col span={24}>
            <StyledText>Уведомление об окончании срока годности</StyledText>
            <StyledSelect
              value={editedRecord.expirationNotification}
              onChange={handleSelectChange('expirationNotification')}
              options={notificationOptions}
              style={{ width: '100%' }}
              showSearch
              allowClear
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
              dropdownStyle={{ zIndex: 1100 }}
            />
          </Col>

          <Col span={24}>
            <StyledText>Дата выпуска экземпляра СО</StyledText>
            <StyledDatePicker
              value={editedRecord.releaseDate ? dayjs(editedRecord.releaseDate) : null}
              onChange={handleDateChange('releaseDate')}
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
              onOpenChange={handleOpenChange}
              popupStyle={{ zIndex: 1100 }}
              getPopupContainer={(trigger) => trigger.parentElement || document.body}
            />
          </Col>

          <Col span={24}>
            <StyledText>Примечание</StyledText>
            <StyledInput
              value={editedRecord.notes}
              onChange={(e) => setEditedRecord(prev => ({ ...prev, notes: e.target.value }))}
              placeholder="введите примечание"
              onMouseDown={handleMouseDown}
              onClick={e => e.stopPropagation()}
            />
          </Col>

          <Col span={24}>
            <StyledText>Прекурсор</StyledText>
            <CheckboxField
              title="Да"
              checked={editedRecord.isPrecursor}
              onChange={(checked) => setEditedRecord(prev => ({ ...prev, isPrecursor: checked }))}
            />
          </Col>
        </Row>
      </ScrollContent>

      <BottomButtons>
        <ButtonsRow>
          <DeleteButton 
            icon={<DeleteOutlined style={{ color: token['red-5'] }} />}
            onClick={handleDelete}
            onMouseDown={handleMouseDown}
          />
          <Button 
            className="cancel-button"
            onClick={onCancel}
            disabled={loading}
            onMouseDown={handleMouseDown}
          >
            Отмена
          </Button>
          <Button 
            className="action-button"
            type="primary"
            onClick={handleSave}
            loading={loading}
            onMouseDown={handleMouseDown}
          >
            Сохранить
          </Button>
        </ButtonsRow>
      </BottomButtons>
    </ScreenContainer>
  );
};

// Добавляем стили для кнопки удаления
const DeleteButton = styled(Button)`
  min-width: 46px !important;
  width: 46px !important;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 46px !important;
`;

export default EditSS;

