import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Table, Checkbox, Button, Tooltip, Input, Space, Popover, theme } from 'antd';
import type { TableColumnsType, InputRef } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { createStyles } from 'antd-style';
import dayjs from 'dayjs';
import { SearchOutlined, FilterOutlined, EyeInvisibleOutlined, DatabaseOutlined, ArrowUpOutlined, ArrowDownOutlined, MoreOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import ColumnManager from '../../components/ColumnManager';
import GetReagent from '../../components/GetReagent';
import { createPortal } from 'react-dom';
import { getReagents } from '../../api/chemicalRegistry';
import { useLocation } from 'react-router-dom';
import { useLaboratoryId } from '../../hooks/useLaboratoryId';
import axiosInstance from '../../api/axios';

const { useToken } = theme;

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table {
      border: 1px solid #E0E0E0;
      
      .ant-table-thead .ant-table-cell {
        color: #595959;
      }

      .ant-table-tbody {
        .ant-table-cell {
          font-weight: 500;

          .ant-select-selection-item,
          .ant-picker-input > input {
            font-weight: 500;
          }

          .ant-select .ant-select-selector {
            border-radius: 4px !important;
          }
        }
      }

      .cell-content {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
}));

export interface DataType {
  key: React.Key;
  number: number;
  receipt: {
    date: string;
    name: string;
    batch: string;
    manufacturer: string;
    supplier: string;
    frequencyClass: string;
    expiryDate: string;
    expiryNotification: string;
    containerSize: number;
    containerUnit: string;
    containerCount: number;
    documents: boolean;
    admission: boolean;
  };
  storage: {
    isPrecursor: boolean;
    location: string;
    conditions: string;
  };
  accounting: {
    openedShelfLife: string;
    used: number;
    remaining: number;
    notes: string;
  };
  custom_columns?: Record<string, any>;
}

export const testDataSource: DataType[] = [];

const manufacturerOptions = [
  'Производитель 1',
  'Производитель 2',
  'Производитель 3',
  'Другой'
].map(item => ({ label: item, value: item }));

const supplierOptions = [
  'Поставщик 1',
  'Поставщик 2',
  'Поставщик 3',
  'Другой'
].map(item => ({ label: item, value: item }));

const frequencyClassOptions = [
  'A',
  'B',
  'C',
  'D'
].map(item => ({ label: item, value: item }));

const notificationOptions = [
  'За 30 дней',
  'За 60 дней',
  'За 90 дней',
  'Другое'
].map(item => ({ label: item, value: item }));

const containerUnitOptions = [
  'мл',
  'л',
  'кг',
  'г',
  'Другое'
].map(item => ({ label: item, value: item }));

const locationOptions = [
  'Склд A',
  'Склад B',
  'Склад C',
  'Другое'
].map(item => ({ label: item, value: item }));

const TextWithTooltip: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  if (typeof text === 'string' && text.length > 24) {
    return (
      <Tooltip title={text}>
        <div className="cell-content">{text}</div>
      </Tooltip>
    );
  }
  return <div>{text}</div>;
};

const ColumnMenu = styled.div`
  width: 306px;
  padding: 12px;
  background: #F5F5F5;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const MenuButton = styled(Button)<{ $isBlue?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 6.4px 12px;
  background: ${props => props.$isBlue ? '#1890FF' : 'white'};
  color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'};
  border: 0.5px solid ${props => props.$isBlue ? '#1890FF' : '#D9D9D9'};
  border-radius: 4px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;

  .anticon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: ${props => props.$isBlue ? '#40a9ff' : '#fff'} !important;
    color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'} !important;
    border-color: ${props => props.$isBlue ? '#40a9ff' : '#d9d9d9'} !important;
  }
`;

const FilterContent = styled.div`
  height: 200px;
  overflow-y: auto;
  margin-bottom: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const FilterDropdown: React.FC<{
  close: () => void;
  confirm: (selectedValues: string[]) => void;
  values: string[];
}> = ({ close, confirm, values }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const uniqueValues = Array.from(new Set(values)).sort();

  return (
    <div style={{ padding: 8, background: 'white', borderRadius: 4 }}>
      <FilterContent>
        {uniqueValues.map((value) => (
          <div key={value} style={{ marginBottom: 4 }}>
            <Checkbox
              checked={selectedValues.includes(value)}
              onChange={(e) => {
                const newValues = e.target.checked
                  ? [...selectedValues, value]
                  : selectedValues.filter(v => v !== value);
                setSelectedValues(newValues);
              }}
            >
              {value}
            </Checkbox>
          </div>
        ))}
      </FilterContent>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm(selectedValues);
            close();
          }}
          size="small"
        >
          Применить
        </Button>
        <Button
          onClick={() => {
            setSelectedValues([]);
            confirm([]);
            close();
          }}
          size="small"
        >
          Сбросить
        </Button>
      </Space>
    </div>
  );
};

interface CustomColumnTitleProps {
  title: React.ReactNode;
  onSort?: (order: 'asc' | 'desc') => void;
  onFilter?: (values: string[]) => boolean;
  onHide?: () => void;
  onManage?: () => void;
  type?: 'number' | 'date';
  dataIndex?: string[];
  filterValues?: string[];
  getAllColumnNames: () => { title: string; key: string }[];
  hiddenColumns: string[];
  onColumnVisibilityChange: (columns: string[]) => void;
}

const CustomColumnTitle: React.FC<CustomColumnTitleProps> = ({
  title,
  onSort,
  onFilter,
  onHide,
  onManage,
  type,
  filterValues = [],
  getAllColumnNames,
  hiddenColumns,
  onColumnVisibilityChange
}) => {
  const { token } = useToken();
  const [open, setOpen] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [showColumnManager, setShowColumnManager] = useState(false);
  const labId = useLaboratoryId();

  const handleMenuClick = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    setOpen(false);
  };

  const handleFilter = () => {
    setOpen(false);
    setFilterVisible(true);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (showColumnManager) return;
    setOpen(newOpen);
  };

  const columnMenu = (
    <ColumnMenu>
      {!showColumnManager ? (
        <>
          {type && (
            <>
              <MenuButton 
                icon={<ArrowUpOutlined style={{ color: token['blue-5'] }} />}
                onClick={() => handleMenuClick(() => onSort?.('asc'))}
              >
                Сортировать по возрастанию
              </MenuButton>
              <MenuButton 
                icon={<ArrowDownOutlined style={{ color: token['blue-5'] }} />}
                onClick={() => handleMenuClick(() => onSort?.('desc'))}
              >
                Сортировать по убыванию
              </MenuButton>
            </>
          )}
          <MenuButton 
            icon={<FilterOutlined style={{ color: token['blue-5'] }} />}
            onClick={handleFilter}
          >
            Фильтры
          </MenuButton>
          <MenuButton 
            icon={<EyeInvisibleOutlined style={{ color: token['blue-5'] }} />}
            onClick={() => handleMenuClick(onHide)}
          >
            Скрыть
          </MenuButton>
          <MenuButton 
            $isBlue 
            icon={<DatabaseOutlined style={{ color: 'white' }} />}
            onClick={() => setShowColumnManager(true)}
          >
            Управление колонками
          </MenuButton>
        </>
      ) : (
        <ColumnManager
          columns={getAllColumnNames()}
          hiddenColumns={hiddenColumns}
          onColumnVisibilityChange={onColumnVisibilityChange}
          visible={true}
          onClose={() => setShowColumnManager(false)}
          registryType="reagent"
          laboratoryId={labId ? Number(labId) : 0}
        />
      )}
    </ColumnMenu>
  );

  return (
    <>
      <Popover 
        content={columnMenu} 
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'transparent', boxShadow: 'none' }}
      >
        <div style={{ cursor: 'pointer' }}>{title}</div>
      </Popover>
      <Popover
        content={
          <FilterDropdown
            close={() => setFilterVisible(false)}
            confirm={(values) => {
              if (onFilter) {
                onFilter(values);
              }
              setFilterVisible(false);
            }}
            values={filterValues}
          />
        }
        trigger="click"
        open={filterVisible}
        onOpenChange={setFilterVisible}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'white', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: filterVisible ? 'block' : 'none' }} />
      </Popover>
    </>
  );
};

interface FilterFunction {
  (value: string): boolean;
}

interface ReagentsTableProps {
    data?: DataType[];
    onSelectionChange?: (selectedRowKeys: React.Key[]) => void;
    laboratoryId?: string;
    resetSelection?: boolean;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 200px;
  right: 50px;
  z-index: 1000;
  pointer-events: auto;
`;

interface ScreenProps {
  onCancel: () => void;
  reagentNames: string[];
}

const ReagentsTable: React.FC<ReagentsTableProps> = ({ 
    data = testDataSource,
    onSelectionChange,
    laboratoryId,
    resetSelection
}) => {
  const { styles } = useStyle();
  const location = useLocation();
  const isAdmin = location.pathname.startsWith('/admin');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filteredData, setFilteredData] = useState<DataType[]>(data);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [columnManagerVisible, setColumnManagerVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(() => {
    const saved = localStorage.getItem(`reagents_hidden_columns_${laboratoryId}`);
    return saved ? JSON.parse(saved) : [];
  });
  const [sortedInfo, setSortedInfo] = useState<{ columnKey: string | null; order: 'ascend' | 'descend' | null }>({
    columnKey: null,
    order: null,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<DataType | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const labId = useLaboratoryId();
  const [customColumns, setCustomColumns] = useState<{ title: string; key: string; type: string }[]>([]);

  useEffect(() => {
    if (laboratoryId) {
      localStorage.setItem(`reagents_hidden_columns_${laboratoryId}`, JSON.stringify(hiddenColumns));
    }
  }, [hiddenColumns, laboratoryId]);

  useEffect(() => {
    if (resetSelection) {
      setSelectedRowKeys([]);
    }
  }, [resetSelection]);

  const fetchReagents = async () => {
    if (!laboratoryId) return;

    try {
      const reagentsData = await getReagents(laboratoryId);
      setFilteredData(reagentsData);
    } catch (error) {
      console.error('Error fetching reagents:', error);
    }
  };

  useEffect(() => {
    fetchReagents();
  }, [laboratoryId]);

  useEffect(() => {
    const newData = data.filter(record => {
      return Object.entries(filters).every(([key, filterValues]) => {
        if (filterValues.length === 0) return true;

        const keys = key.split('.');
        const fieldValue = keys.reduce((obj, key) => obj?.[key], record as any)?.toString();
        return filterValues.includes(fieldValue);
      });
    });

    setFilteredData(newData);
  }, [filters, data]);

  const handleCheckboxChange = (
    record: DataType,
    field: 'documents' | 'admission' | 'isPrecursor',
    category: 'receipt' | 'storage',
    checked: boolean
  ) => {
    setFilteredData(prevData => 
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [category]: {
              ...item[category],
              [field]: checked
            }
          };
        }
        return item;
      })
    );
  };

  const handleSelectChange = (
    record: DataType,
    field: string,
    category: 'receipt' | 'storage',
    value: string
  ) => {
    setFilteredData(prevData =>
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [category]: {
              ...item[category],
              [field]: value
            }
          };
        }
        return item;
      })
    );
  };

  const handleDateChange = (
    record: DataType,
    field: string,
    category: 'receipt',
    value: dayjs.Dayjs | null
  ) => {
    setFilteredData(prevData =>
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [category]: {
              ...item[category],
              [field]: value ? value.format('YYYY-MM-DD') : null
            }
          };
        }
        return item;
      })
    );
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string[],
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex.join('.'));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string[]) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Поиск"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex.join('.'));
            }}
          >
            Фильтр
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value: any, record: DataType) => {
      const fieldValue = dataIndex.reduce((obj, key) => obj?.[key], record as any);
      return fieldValue?.toString().toLowerCase().includes((value as string).toLowerCase());
    },
    filterDropdownProps: {
      onOpenChange(open: boolean) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text: string) =>
      searchedColumn === dataIndex.join('.') ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <TextWithTooltip text={text} />
      ),
  });

  const handleHideColumn = (dataIndex: string[]) => {
    const columnKey = dataIndex.join('.');
    setHiddenColumns(prev => [...prev, columnKey]);
  };

  const handleSort = (columnKey: string, order: 'asc' | 'desc') => {
    const antdOrder = order === 'asc' ? 'ascend' : 'descend';
    setSortedInfo({ columnKey, order: antdOrder });

    setFilteredData(prevData => {
      const newData = [...prevData];
      newData.sort((a, b) => {
        const aValue = columnKey.split('.').reduce((obj, key) => obj?.[key], a as any);
        const bValue = columnKey.split('.').reduce((obj, key) => obj?.[key], b as any);

        if (typeof aValue === 'string') {
          return order === 'asc' 
            ? aValue.localeCompare(bValue) 
            : bValue.localeCompare(aValue);
        }

        return order === 'asc' 
          ? (aValue > bValue ? 1 : -1)
          : (bValue > aValue ? 1 : -1);
      });
      return newData;
    });
  };

  const handleFilter = (columnKey: string, values: string[]): boolean => {
    if (values.length === 0) {
      const newFilters = { ...filters };
      delete newFilters[columnKey];
      setFilters(newFilters);
    } else {
      setFilters(prev => ({
        ...prev,
        [columnKey]: values
      }));
    }
    return true;
  };

  const getUniqueValues = (dataIndex: string[]) => {
    return Array.from(new Set(
      filteredData.map(item => {
        const value = dataIndex.reduce((obj, key) => obj?.[key], item as any);
        return value?.toString() || '';
      }).filter(Boolean)
    ));
  };

  const columnTitles: { [key: string]: string } = {
    'number': 'Номер',
    'receipt.date': 'Дата поступления',
    'receipt.name': 'Наименование',
    'receipt.batch': 'Номер партии/серии',
    'receipt.manufacturer': 'Производитель',
    'receipt.supplier': 'Поставщик',
    'receipt.frequencyClass': 'Класс частоты',
    'receipt.expiryDate': 'Срок годности',
    'receipt.expiryNotification': 'Уведомление об окончании срока годности',
    'receipt.containerSize': 'Размер тары',
    'receipt.containerUnit': 'Единица измерения тары',
    'receipt.containerCount': 'Количество тарных мест',
    'receipt.documents': 'Комплект документов получен',
    'receipt.admission': 'Допуск',
    'storage.isPrecursor': 'Прекурсор',
    'storage.location': 'Место хранения',
    'storage.conditions': 'Условия хранения',
    'accounting.openedShelfLife': 'Срок хранения после вскрытия',
    'accounting.used': 'Использовано',
    'accounting.remaining': 'Остаток',
    'accounting.notes': 'Примечание'
  };

  const getColumnKeyByTitle = (title: string): string => {
    for (const [key, value] of Object.entries(columnTitles)) {
      if (value === title) {
        return key;
      }
    }
    return title;
  };

  const getAllColumnNames = () => {
    const columnData: { title: string; key: string }[] = [];
    
    const extractNames = (cols: TableColumnsType<DataType>) => {
      cols.forEach(col => {
        if ('children' in col) {
          extractNames(col.children || []);
        } else if (col.key) {
          const key = Array.isArray(col.dataIndex) 
            ? col.dataIndex.join('.')
            : col.dataIndex?.toString() || col.key.toString();
          columnData.push({
            title: columnTitles[key] || key,
            key: key
          });
        }
      });
    };
    
    extractNames(columns);
    
    // Не добавляем пользовательские колонки здесь, так как они будут добавлены в компоненте ColumnManager
    // customColumns.forEach(col => {
    //   columnData.push({
    //     title: col.title,
    //     key: col.key
    //   });
    // });
    
    return columnData;
  };

  const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    
    .column-menu-icon {
      opacity: 1;
    }
  `;

  const TitleText = styled.div`
    flex: 1;
  `;

  const MenuIcon = styled.div`
    opacity: 0;
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 4px;
    transition: all 0.2s;
    
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    
    .anticon {
      font-size: 16px;
      color: #8C8C8C;
    }
  `;

  const createColumnTitle = (title: string, dataIndex?: string[], isGroupTitle?: boolean, type?: 'number' | 'date') => {
    if (isGroupTitle) {
      return <TextWithTooltip text={title} />;
    }

    return (
      <CustomColumnTitle 
        title={
          <TitleContainer>
            <TitleText>
              <TextWithTooltip text={title} />
            </TitleText>
            <MenuIcon className="column-menu-icon">
              <MoreOutlined />
            </MenuIcon>
          </TitleContainer>
        }
        onSort={dataIndex && type ? (order) => handleSort(dataIndex.join('.'), order) : undefined}
        onFilter={dataIndex ? (values) => handleFilter(dataIndex.join('.'), values) : undefined}
        onHide={() => dataIndex && handleHideColumn(dataIndex)}
        onManage={() => setColumnManagerVisible(true)}
        type={type}
        dataIndex={dataIndex}
        filterValues={dataIndex ? getUniqueValues(dataIndex) : []}
        getAllColumnNames={getAllColumnNames}
        hiddenColumns={hiddenColumns}
        onColumnVisibilityChange={setHiddenColumns}
      />
    );
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: createColumnTitle('№ п/п', ['number'], false, 'number'),
      dataIndex: 'number',
      key: 'number',
      width: 86,
      // fixed: 'left',
      sortOrder: sortedInfo.columnKey === 'number' ? sortedInfo.order : null,
      hidden: hiddenColumns.includes('number'),
    },
    {
      title: createColumnTitle('Поступление', undefined, true),
      children: [
        {
          title: createColumnTitle('Дата', ['receipt', 'date'], false, 'date'),
          dataIndex: ['receipt', 'date'],
          key: 'date',
          width: 232,
          hidden: hiddenColumns.includes('receipt.date'),
          render: (text: string) => <TextWithTooltip text={text ? dayjs(text).format('DD.MM.YYYY') : ''} />,
        },
        {
          title: createColumnTitle('Наименование', ['receipt', 'name']),
          dataIndex: ['receipt', 'name'],
          key: 'name',
          width: 232,
          hidden: hiddenColumns.includes('receipt.name'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
          title: createColumnTitle('№ партии/серии', ['receipt', 'batch']),
          dataIndex: ['receipt', 'batch'],
          key: 'batch',
          width: 232,
          render: (text: string) => <TextWithTooltip text={text} />,
          hidden: hiddenColumns.includes('receipt.batch'),
        },
        {
          title: createColumnTitle('Производитель', ['receipt', 'manufacturer']),
          dataIndex: ['receipt', 'manufacturer'],
          key: 'manufacturer',
          width: 232,
          hidden: hiddenColumns.includes('receipt.manufacturer'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
          title: createColumnTitle('Поставщик', ['receipt', 'supplier']),
          dataIndex: ['receipt', 'supplier'],
          key: 'supplier',
          width: 232,
          hidden: hiddenColumns.includes('receipt.supplier'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
          title: createColumnTitle('Класс частоты', ['receipt', 'frequencyClass']),
          dataIndex: ['receipt', 'frequencyClass'],
          key: 'frequencyClass',
          width: 232,
          hidden: hiddenColumns.includes('receipt.frequencyClass'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
          title: createColumnTitle('Срок годности', ['receipt', 'expiryDate']),
          dataIndex: ['receipt', 'expiryDate'],
          key: 'expiryDate',
          width: 232,
          hidden: hiddenColumns.includes('receipt.expiryDate'),
          render: (text: string) => <TextWithTooltip text={text ? dayjs(text).format('DD.MM.YYYY') : ''} />,
        },
        // {
        //   title: createColumnTitle('Уведомление об окончании срока годности', ['receipt', 'expiryNotification']),
        //   dataIndex: ['receipt', 'expiryNotification'],
        //   key: 'expiryNotification',
        //   width: 232,
        //   hidden: hiddenColumns.includes('receipt.expiryNotification'),
        //   render: (text: string) => <TextWithTooltip text={text} />,
        // },
        {
          title: createColumnTitle('Размер тары', ['receipt', 'containerSize'], false, 'number'),
          dataIndex: ['receipt', 'containerSize'],
          key: 'containerSize',
          width: 232,
          hidden: hiddenColumns.includes('receipt.containerSize'),
          render: (text: number) => <TextWithTooltip text={text.toString()} />,
        },
        {
          title: createColumnTitle('Ед. изм. размера тары', ['receipt', 'containerUnit']),
          dataIndex: ['receipt', 'containerUnit'],
          key: 'containerUnit',
          width: 232,
          hidden: hiddenColumns.includes('receipt.containerUnit'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
          title: createColumnTitle('Количество тарных мест', ['receipt', 'containerCount'], false, 'number'),
          dataIndex: ['receipt', 'containerCount'],
          key: 'containerCount',
          width: 232,
          hidden: hiddenColumns.includes('receipt.containerCount'),
          render: (text: number) => <TextWithTooltip text={text.toString()} />,
        },
        {
          title: createColumnTitle('Комплект документов получен', ['receipt', 'documents']),
          dataIndex: ['receipt', 'documents'],
          key: 'documents',
          width: 232,
          hidden: hiddenColumns.includes('receipt.documents'),
          render: (value: boolean) => <TextWithTooltip text={value ? 'Да' : 'Нет'} />,
        },
        {
          title: createColumnTitle('Допуск', ['receipt', 'admission']),
          dataIndex: ['receipt', 'admission'],
          key: 'admission',
          width: 232,
          hidden: hiddenColumns.includes('receipt.admission'),
          render: (value: boolean) => <TextWithTooltip text={value ? 'Да' : 'Нет'} />,
        },
      ],
    },
    {
      title: createColumnTitle('Хранение', undefined, true),
      children: [
        {
          title: createColumnTitle('Прекурсор', ['storage', 'isPrecursor']),
          dataIndex: ['storage', 'isPrecursor'],
          key: 'isPrecursor',
          width: 232,
          hidden: hiddenColumns.includes('storage.isPrecursor'),
          render: (value: boolean) => <TextWithTooltip text={value ? 'Да' : 'Нет'} />,
        },
        {
          title: createColumnTitle('Место хранения', ['storage', 'location']),
          dataIndex: ['storage', 'location'],
          key: 'location',
          width: 232,
          hidden: hiddenColumns.includes('storage.location'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
          title: createColumnTitle('Условия хранения', ['storage', 'conditions']),
          dataIndex: ['storage', 'conditions'],
          key: 'conditions',
          width: 232,
          hidden: hiddenColumns.includes('storage.conditions'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
      ],
    },
    {
      title: createColumnTitle('Учет расхода', undefined, true),
      children: [
        {
          title: createColumnTitle('Срок хранения после вскрытия тары', ['accounting', 'openedShelfLife']),
          dataIndex: ['accounting', 'openedShelfLife'],
          key: 'openedShelfLife',
          width: 232,
          hidden: hiddenColumns.includes('accounting.openedShelfLife'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
        {
          title: createColumnTitle('Использовано', ['accounting', 'used']),
          dataIndex: ['accounting', 'used'],
          key: 'used',
          width: 232,
          hidden: hiddenColumns.includes('accounting.used'),
          render: (text: number) => <TextWithTooltip text={text.toString()} />,
        },
        {
          title: createColumnTitle('Остаток', ['accounting', 'remaining']),
          dataIndex: ['accounting', 'remaining'],
          key: 'remaining',
          width: 232,
          hidden: hiddenColumns.includes('accounting.remaining'),
          render: (text: number) => <TextWithTooltip text={text.toString()} />,
        },
        {
          title: createColumnTitle('Примечание', ['accounting', 'notes']),
          dataIndex: ['accounting', 'notes'],
          key: 'notes',
          width: 232,
          hidden: hiddenColumns.includes('accounting.notes'),
          render: (text: string) => <TextWithTooltip text={text} />,
        },
      ],
    },
  ];

  const getVisibleColumns = (cols: TableColumnsType<DataType>): TableColumnsType<DataType> => {
    return cols.map(col => {
      if ('children' in col) {
        return {
          ...col,
          children: getVisibleColumns(col.children || []).filter(child => !child.hidden)
        };
      }
      return col;
    }).filter(col => {
      if ('children' in col) {
        return col.children && col.children.length > 0;
      }
      return !col.hidden;
    });
  };

  // Создаем динамические колонки для пользовательских полей
  const customColumnsConfig = useMemo(() => {
    return customColumns.map(col => ({
      title: createColumnTitle(col.title, [col.key]),
      dataIndex: ['custom_columns', col.key],
      key: col.key,
      width: 150,
      hidden: hiddenColumns.includes(col.key),
      render: (text: any) => {
        if (!text) return <TextWithTooltip text="-" />;
        
        switch (col.type) {
          case 'date':
            return <TextWithTooltip text={text ? dayjs(text).format('DD.MM.YYYY') : '-'} />;
          case 'boolean':
            return (
              <Checkbox
                checked={Boolean(text)}
                disabled
              />
            );
          case 'int':
            return <TextWithTooltip text={text.toString()} />;
          default:
            return <TextWithTooltip text={text} />;
        }
      }
    }));
  }, [customColumns, hiddenColumns]);

  // Объединяем стандартные и пользовательские колонки
  const allColumns = useMemo(() => {
    return [...columns, ...customColumnsConfig];
  }, [columns, customColumnsConfig]);

  // Используем allColumns вместо columns
  const visibleColumns = getVisibleColumns(allColumns);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      onSelectionChange?.(newSelectedRowKeys);
    },
    columnWidth: 48,
    fixed: true
  };

  const handleRowClick = (event: React.MouseEvent<HTMLElement>, record: DataType) => {
    if (isAdmin) return; // Отключаем клик для админов
    
    // Проверяем, был ли клик по элементам управления
    const target = event.target as HTMLElement;
    const isControlElement = target.closest('.ant-select') || 
                           target.closest('.ant-select-selector') ||
                           target.closest('.ant-select-dropdown') ||
                           target.closest('.ant-select-item') ||
                           target.closest('.ant-picker') || 
                           target.closest('.ant-checkbox-wrapper') ||
                           target.closest('.ant-btn');
    
    // Если клик был по элементам управления, не открываем GetReagent
    if (isControlElement) {
      event.stopPropagation();
      return;
    }

    setSelectedRow(record);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
    setSelectedRow(null);
  };

  const uniqueReagentNames = Array.from(new Set(
    data.map(item => item.receipt.name)
  ));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handlePopoverClose();
      }
    };

    if (isPopoverOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopoverOpen]);

  const handleGetReagentSuccess = () => {
    fetchReagents(); // Обновляем данные после успешного действия
  };

  const renderGetReagent = () => {
    if (!isPopoverOpen) return null;
    
    return createPortal(
      <ModalOverlay ref={modalRef}>
        <GetReagent 
          onCancel={handlePopoverClose} 
          reagentNames={uniqueReagentNames}
          selectedRecord={selectedRow}
          onSuccess={handleGetReagentSuccess}  // Добавляем обработчик
        />
      </ModalOverlay>,
      document.body
    );
  };

  // Функция для загрузки пользовательских колонок
  const loadCustomColumns = useCallback(async () => {
    if (!labId) return;
    
    try {
      const response = await axiosInstance.get(`/api/custom-columns/reagent`, {
        params: {
          laboratory_id: labId
        }
      });
      
      if (response.status === 200) {
        setCustomColumns(response.data.map((col: any) => ({ 
          title: col.title, 
          key: col.key,
          type: col.type
        })));
      }
    } catch (error) {
      console.error('Ошибка при загрузке пользовательских колонок:', error);
    }
  }, [labId]);

  // Загружаем пользовательские колонки при монтировании компонента
  useEffect(() => {
    loadCustomColumns();
  }, [loadCustomColumns]);

  const handleColumnVisibilityChange = (newHiddenColumns: string[]) => {
    setHiddenColumns(newHiddenColumns);
    if (laboratoryId) {
      localStorage.setItem(`reagents_hidden_columns_${laboratoryId}`, JSON.stringify(newHiddenColumns));
    }
  };

  // Добавляем функцию проверки срока годности
  const isExpiryDateNearExpiration = (expiryDate: string | undefined): boolean => {
    if (!expiryDate) return false;
    const today = dayjs();
    const expiry = dayjs(expiryDate);
    const monthsLeft = expiry.diff(today, 'month');
    return monthsLeft <= 1 && expiry.isAfter(today);
  };

  // Добавляем функцию проверки малого остатка
  const isLowRemaining = (remaining: number): boolean => {
    return remaining < 10;
  };

  return (
    <>
      <Table<DataType>
        className={styles.customTable}
        columns={visibleColumns}
        dataSource={filteredData}
        rowSelection={rowSelection}
        bordered
        size="middle"
        scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
        onRow={(record) => ({
          onClick: (e) => handleRowClick(e, record),
          style: { 
            cursor: isAdmin ? 'default' : 'pointer',
            backgroundColor: isExpiryDateNearExpiration(record.receipt.expiryDate) 
              ? 'rgba(255, 0, 0, 0.07)' 
              : isLowRemaining(record.accounting.remaining)
                ? 'rgba(255, 255, 0, 0.15)'
                : undefined
          },
          onMouseDown: (e) => {
            const target = e.target as HTMLElement;
            if (target.closest('.ant-select') || 
                target.closest('.ant-select-selector') ||
                target.closest('.ant-select-dropdown') ||
                target.closest('.ant-select-item') ||
                target.closest('.ant-picker') || 
                target.closest('.ant-checkbox-wrapper') ||
                target.closest('.ant-btn')) {
              e.stopPropagation();
            }
          }
        })}
      />

      {!isAdmin && renderGetReagent()}

      {columnManagerVisible && (
        <ColumnManager
          columns={getAllColumnNames()}
          hiddenColumns={hiddenColumns}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          visible={true}
          onClose={() => setColumnManagerVisible(false)}
          registryType="reagent"
          laboratoryId={labId ? Number(labId) : 0}
        />
      )}
    </>
  );
};

export default ReagentsTable;