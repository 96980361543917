import { ScanOutlined } from "@ant-design/icons";
import { Button, Col, Row, Modal, Typography } from "antd";
import React from "react";

const { Text } = Typography;

interface ScanProps {
    open: boolean;
    onCancel: () => void;
}

const Scan: React.FC<ScanProps> = ({ open, onCancel }): JSX.Element => {
    return (
        <Modal
            open={open}
            footer={null}
            closable={false}
            width={434}
            height={439}
            centered
        >
            <Row justify="space-between" align="top">
                <Col>
                    <Text style={{ fontSize: 16, fontWeight: 500 }}>Сканирование</Text>
                </Col>
            </Row>

            <Row
                justify="center"
                align="middle"
                style={{ marginTop: 72, marginBottom: 16 }}
            >
                <Col>
                    <ScanOutlined style={{ fontSize: 122, color: '#40a9ff' }} />
                </Col>
            </Row>

            <Row justify="center" align="middle">
                <Col>
                    <Text strong style={{ fontSize: 16, textAlign: "center", display: 'block', width: 154 }}>
                        Отсканируйте код этикетки реактива
                    </Text>
                </Col>
            </Row>

            <Row justify="center" align="middle" style={{ marginTop: 72 }}>
                <Col span={24}>
                    <Button block onClick={onCancel} style={{ height: 40, borderRadius: 4 }}>
                        Отмена
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default Scan;
