import React, { useState, useEffect, useRef } from 'react';
import { Layout, Segmented, Button, theme, Typography, Input, message, Space } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import HeaderQualityControl from './Header';
import styled from 'styled-components';
import { ExportOutlined, QrcodeOutlined } from '@ant-design/icons';
import SimpleTable from './SimpleTable';
import AddBatchModal from './AddBatchModal';
import { Batch, generateBatchLabels } from '../../api/batches';

const { Content } = Layout;
const { Title } = Typography;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const SearchInput = styled(Input)`
  width: 45px;
  height: 40px;
  border-color: #d9d9d9;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
  padding: 0;
  
  &.expanded {
    width: 240px;
    padding: 4px 11px;
    border-color: #d9d9d9;
  }

  &:hover {
    border-color: #d9d9d9;
  }

  &:focus {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .ant-input {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .ant-input-prefix {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  input {
    line-height: normal !important;
    height: 100% !important;
  }

  &:not(.expanded) {
    .ant-input-prefix {
      margin-right: 0;
    }
    
    .ant-input {
      width: 0;
      padding: 0;
    }
  }

  &.expanded .ant-input-prefix {
    width: auto;
    margin-right: 8px;
  }
`;

// Определяем интерфейс для данных строки таблицы
interface DataType {
  key: string;
  batchCode: string;
  registrationDate: string;
  sampleIndex: string;
  soName: string;
  gsoNumber: string;
  soCategory: string;
  packaging: string;
  indicatorName: string;
  certifiedValue: number | string;
  certifiedValueUnit: string;
  error: number | string;
  errorUnit: string;
  expiryDate: string;
}

// Определяем интерфейс для ref таблицы
interface TableRef {
    fetchBatches: () => Promise<void>;
    handleRowClick: (record: DataType) => Promise<Batch | null>;
}

const MainPage: React.FC = () => {
    const { token } = useToken();

    const tableRef = useRef<TableRef>(null);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editBatch, setEditBatch] = useState<Batch | undefined>(undefined);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    const showAddModal = () => {
        setIsAddModalVisible(true);
    };

    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const handleAddSuccess = () => {
        // Обновляем данные таблицы
        if (tableRef.current) {
            tableRef.current.fetchBatches();
            message.success('Партия успешно добавлена');
        }
    };

    // Функция для обработки клика по строке таблицы
    const handleRowClick = async (record: DataType) => {
        if (tableRef.current) {
            const batch = await tableRef.current.handleRowClick(record);
            if (batch) {
                setEditBatch(batch);
                setIsEditModalVisible(true);
            }
        }
    };

    // Функция для закрытия модального окна редактирования
    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
        setEditBatch(undefined);
    };

    // Функция для обработки успешного редактирования
    const handleEditSuccess = () => {
        // Обновляем данные таблицы
        if (tableRef.current) {
            tableRef.current.fetchBatches();
            message.success('Партия успешно обновлена');
        }
    };

    // Функция для обработки удаления партии
    const handleDeleteBatch = () => {
        // Обновляем данные таблицы
        if (tableRef.current) {
            tableRef.current.fetchBatches();
            // Сообщение об успешном удалении уже отображается в AddBatchModal
        }
    };

    // Функция для получения уникальных ID партий из выбранных строк
    const getUniqueBatchIds = (): number[] => {
        // Получаем уникальные ID партий (первая часть ключа до дефиса)
        const uniqueBatchIds = new Set<number>();
        
        selectedRowKeys.forEach((key) => {
            const idPart = (key as string).split('-')[0];
            uniqueBatchIds.add(parseInt(idPart, 10));
        });
        
        return Array.from(uniqueBatchIds);
    };

    // Обработчик изменения выбранных строк
    const handleSelectedRowsChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // Обработчик генерации QR-кодов и этикеток
    const handleGenerateQRAndLabels = () => {
        const uniqueBatchIds = getUniqueBatchIds();
        if (uniqueBatchIds.length === 0) {
            message.warning('Не выбрано ни одной партии');
            return;
        }

        console.log('Генерация QR-кодов и этикеток для партий:', uniqueBatchIds);
        message.loading({ content: `Генерация QR-кодов и этикеток для ${uniqueBatchIds.length} партий...`, key: 'generateLabels' });
        
        generateBatchLabels(uniqueBatchIds)
            .then(blob => {
                console.log('Получен ответ от сервера, размер:', blob.size);
                // Создаем ссылку для скачивания файла
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'batch_labels.zip';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                
                message.success({ content: 'QR-коды и этикетки успешно сгенерированы', key: 'generateLabels' });
            })
            .catch(error => {
                console.error('Ошибка при генерации QR-кодов и этикеток:', error);
                message.error({ content: 'Ошибка при генерации QR-кодов и этикеток', key: 'generateLabels' });
            });
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderQualityControl />
            <StyledContent>
                <TextParentRoot style={{ marginBottom: 16 }}>
                    <Title level={4}>Реестр выпущенных партий</Title>
                    <Space>
                        <SearchInput
                            className={isSearchExpanded ? "expanded" : ""}
                            placeholder={isSearchExpanded ? "Поиск" : ""}
                            prefix={<SearchOutlined style={{ color: token['blue-6'] }} />}
                            onFocus={() => setIsSearchExpanded(true)}
                            onChange={(e) => handleSearch(e.target.value)}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    setIsSearchExpanded(false);
                                }
                            }}
                            style={{ height: '40px' }}
                            value={searchValue}
                        />
                        <Button 
                            icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px' }}
                            onClick={showAddModal}
                        >
                            Добавить запись
                        </Button>
                        {getUniqueBatchIds().length > 0 && (
                            <Button 
                                type="primary"
                                icon={<QrcodeOutlined />} 
                                style={{ height: '40px' }}
                                onClick={handleGenerateQRAndLabels}
                            >
                                Сгенерировать QR и этикетку ({getUniqueBatchIds().length})
                            </Button>
                        )}
                    </Space>
                </TextParentRoot>
                
                <SimpleTable 
                    ref={tableRef} 
                    onRow={(record: DataType) => ({
                        onClick: () => handleRowClick(record),
                        style: { cursor: 'pointer' }
                    })}
                    searchValue={searchValue}
                    onSelectedRowsChange={handleSelectedRowsChange}
                />
                
                {/* Модальное окно для добавления партии */}
                <AddBatchModal
                    open={isAddModalVisible}
                    onCancel={handleAddModalCancel}
                    onSuccess={handleAddSuccess}
                />
                
                {/* Модальное окно для редактирования партии */}
                <AddBatchModal
                    open={isEditModalVisible}
                    onCancel={handleEditModalCancel}
                    onSuccess={handleEditSuccess}
                    editBatch={editBatch}
                    onDelete={handleDeleteBatch}
                />
            </StyledContent>
        </Layout>
    );
};

export default MainPage;
