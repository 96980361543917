import { Button, Col, DatePicker, Form, Input, Row, Select, Typography, message } from "antd";
import React, { useState, FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import dayjs from 'dayjs';
import { msiApi } from '../api/msi';
import { MSIYearPlanData } from '../api/msi';

const { Text } = Typography;

const ScreenContainer = styled.div`
  width: 400px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const ScreenTitle = styled(Typography.Title)`
  margin: 16px !important;
  font-size: 16px !important;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;
  width: 100%;
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  padding: 16px;
  display: flex;
  justify-content: center;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
    width: 178px;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }
` as typeof Form;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0 !important;
    
    > label {
      font-size: 12px;
      color: #8c8c8c;
      font-weight: 500;
      height: auto;
      margin-bottom: 0px !important;
      line-height: 0 !important;
      
      &::before {
        display: none !important;
      }
      
      &::after {
        display: none;
      }
    }
  }
  
  .ant-form-item-control {
    margin-top: 0px;
  }
  
  .ant-form-item-explain {
    min-height: 0;
    margin-top: 0px;
    font-size: 12px;
  }
`;

interface AdminAddMSIProps {
  onCancel: () => void;
  onSuccess: () => void;
  editData?: MSIYearPlanData & { id: number };
}

const groups = [
  'Нефть и нефтепродукты',
  'Вода питьевая, природная, сточная',
  'Газ природный и сжиженный',
  'Физические факторы и факторы трудового процесса',
  'Воздух атмосферный, рабочей зоны, промышленные выбросы в атмосферу',
  'Почва',
  'Отходы производства и потребления'
];

const rounds = ['Раунд 1', 'Раунд 2', 'Раунд 3', 'Раунд 4'];

const AdminAddMSI: FunctionComponent<AdminAddMSIProps> = ({ onCancel, onSuccess, editData }) => {
    const [form] = Form.useForm<MSIYearPlanData>();

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData);
        }
    }, [editData, form]);

    const handleSubmit = async (values: MSIYearPlanData) => {
        try {
            if (editData) {
                await msiApi.updateMSIYearPlan(editData.id, values);
                message.success('Запись успешно обновлена');
            } else {
                await msiApi.createMSIYearPlan(values);
                message.success('Запись успешно создана');
            }
            onSuccess();
            onCancel();
        } catch (error) {
            console.error('Error:', error);
            message.error('Ошибка при сохранении данных');
        }
    };

    const handleDelete = async () => {
        if (!editData) return;

        try {
            await msiApi.deleteMSIYearPlan(editData.id);
            message.success('Запись успешно удалена');
            onSuccess();
            onCancel();
        } catch (error) {
            console.error('Error:', error);
            message.error('Ошибка при удалении записи');
        }
    };

    return (
        <ScreenContainer>
            <ScreenTitle level={4}>{editData ? 'Редактирование записи' : 'Новая запись'}</ScreenTitle>
            
            <StyledForm
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <ScrollContent>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <StyledFormItem
                                name="group"
                                label="Группа"
                                rules={[{ required: true, message: 'Выберите группу' }]}
                            >
                                <StyledSelect
                                    placeholder="выберите группу"
                                    options={groups.map(group => ({ label: group, value: group }))}
                                    showSearch
                                />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="object"
                                label="Объект"
                                rules={[{ required: true, message: 'Введите объект' }]}
                            >
                                <StyledInput placeholder="введите объект" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="round"
                                label="Раунд"
                                rules={[{ required: true, message: 'Выберите раунд' }]}
                            >
                                <StyledSelect
                                    placeholder="выберите раунд"
                                    options={rounds.map(round => ({ label: round, value: round }))}
                                />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="round_dates"
                                label="Сроки проведения раунда"
                                rules={[{ required: true, message: 'Введите сроки проведения' }]}
                            >
                                <StyledInput placeholder="введите сроки проведения" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="application_deadline"
                                label="Прием заявок до"
                                rules={[{ required: true, message: 'Введите срок приема заявок' }]}
                            >
                                <StyledInput placeholder="введите срок приема заявок" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="pickup_dates"
                                label="Сроки самовывоза"
                                rules={[{ required: true, message: 'Введите сроки самовывоза' }]}
                            >
                                <StyledInput placeholder="введите сроки самовывоза" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="result_submission"
                                label="Предоставление результата"
                                rules={[{ required: true, message: 'Введите срок предоставления результата' }]}
                            >
                                <StyledInput placeholder="введите срок предоставления результата" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="conclusion_dates"
                                label="Предоставление заключений"
                                rules={[{ required: true, message: 'Введите срок предоставления заключений' }]}
                            >
                                <StyledInput placeholder="введите срок предоставления заключений" />
                            </StyledFormItem>
                        </Col>

                        <Col span={24}>
                            <StyledFormItem
                                name="program_type"
                                label="Тип программы"
                                rules={[{ required: true, message: 'Введите тип программы' }]}
                            >
                                <StyledInput placeholder="введите тип программы" />
                            </StyledFormItem>
                        </Col>
                    </Row>
                </ScrollContent>

                <ButtonsRow>
                    {editData ? (
                        <Button danger onClick={handleDelete}>Удалить</Button>
                    ) : (
                        <Button onClick={onCancel}>Отмена</Button>
                    )}
                    <Button type="primary" htmlType="submit">
                        {editData ? 'Сохранить' : 'Создать'}
                    </Button>
                </ButtonsRow>
            </StyledForm>
        </ScreenContainer>
    );
};

export default AdminAddMSI;

