import React, { useEffect, useState } from 'react';
import { Modal, Table, Space, Button, message } from 'antd';
import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import axiosInstance from '../api/axios';
import ExportTypes, { ExportFormat } from './ExportTypes';
import ExportProcess from './ExportProcess';

interface BaseItem {
    number: number;
    name: string;
    type: string;
    note: string;
}

interface VerificationItem extends BaseItem {
    verificationType: string;
}

interface PurchaseItem extends BaseItem {
    quantity: string;
}

type RequestItem = VerificationItem | PurchaseItem;

const StyledTable = styled(Table<RequestItem>)`
    .ant-table-tbody > tr > td {
        height: 55px;
        padding: 8px;
    }
    
    // .ant-table-body {
    //     height: 260px !important;
    //     overflow-y: auto !important;
    // }
`;

const ButtonsRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
`;

interface RequestDetailsProps {
    open: boolean;
    onCancel: () => void;
    requestId: number;
    requestType: string;
}

const RequestDetails: React.FC<RequestDetailsProps> = ({ open, onCancel, requestId, requestType }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<RequestItem[]>([]);
    const [isExportVisible, setIsExportVisible] = useState(false);
    const [isExportProcessVisible, setIsExportProcessVisible] = useState(false);

    const fetchRequestDetails = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const endpoint = requestType === 'verification' 
                ? '/api/verification-requests'
                : '/api/purchase-requests';
            
            const response = await axiosInstance.get(`${endpoint}/${requestId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            
            if (response.data) {
                setData(response.data.items || []);
            }
        } catch (error) {
            console.error('Error fetching request details:', error);
            message.error('Ошибка при загрузке данных заявки');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open && requestId) {
            fetchRequestDetails();
        }
    }, [open, requestId]);

    const verificationColumns: ColumnsType<RequestItem> = [
        {
            title: "№ п/п",
            dataIndex: "number",
            key: "number",
            width: 87,
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            width: 232,
        },
        {
            title: "Тип оборудования",
            dataIndex: "type",
            key: "type",
            width: 232,
        },
        {
            title: "Тип поверки",
            dataIndex: "verificationType",
            key: "verificationType",
            width: 232,
            render: (_, record) => 'verificationType' in record ? record.verificationType : '',
        },
        {
            title: "Примечание",
            dataIndex: "note",
            key: "note",
            width: 232,
        },
    ];

    const purchaseColumns: ColumnsType<RequestItem> = [
        {
            title: "№ п/п",
            dataIndex: "number",
            key: "number",
            width: 87,
        },
        {
            title: "Тип",
            dataIndex: "type",
            key: "type",
            width: 232,
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            width: 232,
        },
        {
            title: "Количество",
            dataIndex: "quantity",
            key: "quantity",
            width: 232,
            render: (_, record) => 'quantity' in record ? record.quantity : '',
        },
        {
            title: "Примечание",
            dataIndex: "note",
            key: "note",
            width: 232,
        },
    ];

    const handleExport = async (format: ExportFormat) => {
        setIsExportVisible(false);
        setIsExportProcessVisible(true);
        
        try {
            const endpoint = requestType === 'verification' 
                ? '/api/verification-requests/export'
                : '/api/purchase-requests/export';
            
            const response = await axiosInstance.post(
                `${endpoint}?format=${format}`,
                { items: data },
                {
                    responseType: 'blob',
                    headers: {
                        'Accept': '*/*',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            const blob = new Blob([response.data], { 
                type: response.headers['content-type'] 
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition
                ? contentDisposition.split('filename=')[1].replace(/"/g, '')
                : `export.${format.toLowerCase()}`;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Ошибка при экспорте:', error);
            message.error('Ошибка при экспорте данных');
        } finally {
            setIsExportProcessVisible(false);
        }
    };

    return (
        <Modal
            title={requestType === 'verification' ? 'Заявка на поверку' : 'Заявка на закупку'}
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
            centered
        >
            <StyledTable
                columns={requestType === 'verification' ? verificationColumns : purchaseColumns}
                dataSource={data}
                pagination={false}
                bordered
                loading={loading}
                style={{ marginTop: 16 }}
                scroll={{ y: 300 }}
            />
            {/* <ButtonsRow>
                <Button onClick={onCancel}>Назад</Button>
                <Button 
                    icon={<FileOutlined />}
                    onClick={() => setIsExportVisible(true)}
                >
                    Экспортировать
                </Button>
            </ButtonsRow>
            <ExportTypes 
                open={isExportVisible}
                onCancel={() => setIsExportVisible(false)}
                onExport={handleExport}
                selectedRows={data.map((item, index) => index)}
            />
            <ExportProcess 
                open={isExportProcessVisible}
            /> */}
        </Modal>
    );
};

export default RequestDetails; 