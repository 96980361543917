import { api } from './api';

export interface Precursor {
    id: number;
    name: string;
    group: string;
}

export const getPrecursors = async (): Promise<Precursor[]> => {
    const response = await api.get('/precursors/');
    return response.data;
};

export const createPrecursor = async (precursor: Omit<Precursor, 'id'>): Promise<Precursor> => {
    const response = await api.post('/precursors/', precursor);
    return response.data;
};

export const uploadPrecursors = async (file: File): Promise<Precursor[]> => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post('/precursors/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
}; 