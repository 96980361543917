import { ScanOutlined } from "@ant-design/icons";
import { Button, Col, Row, Modal, Typography, Input, message } from "antd";
import React, { useState, useEffect, useRef } from "react";
import type { InputRef } from 'antd';
import ScanInstructions from "../components/ScanInstructions";

const { Text, Link } = Typography;

interface ScanProps {
    open: boolean;
    onCancel: () => void;
    onScan?: (scannedData: string) => void;
}

const Scan: React.FC<ScanProps> = ({ open, onCancel, onScan }): JSX.Element => {
    const [scannedData, setScannedData] = useState<string>("");
    const inputRef = useRef<InputRef>(null);
    const [instructionsVisible, setInstructionsVisible] = useState<boolean>(false);
    
    // Автофокус на поле ввода при открытии
    useEffect(() => {
        if (open) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
        }
    }, [open]);

    // Очищаем данные при закрытии модального окна
    useEffect(() => {
        if (!open) {
            setScannedData("");
        }
    }, [open]);

    // Обработчик ввода данных
    const handleDataInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Удаляем символы переноса строки, которые может добавлять сканер
        setScannedData(value.replace(/[\r\n]/g, ''));
    };

    const handleScan = () => {
        if (!scannedData) {
            message.error("Пожалуйста, введите или отсканируйте данные");
            return;
        }

        // Удаляем лишние пробелы
        const cleanData = scannedData.trim();
        
        onScan?.(cleanData);
        message.success("Данные успешно отсканированы");
        onCancel();
    };

    // Обработчик нажатия клавиш
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // Enter от сканера или клавиатуры
        if (e.key === 'Enter') {
            e.preventDefault();
            handleScan();
        }
    };

    const showInstructions = () => {
        setInstructionsVisible(true);
    };

    const hideInstructions = () => {
        setInstructionsVisible(false);
    };

    return (
        <>
            <Modal
                open={open && !instructionsVisible}
                footer={null}
                closable={false}
                width={434}
                centered
            >
                <Row justify="space-between" align="top">
                    <Col>
                        <Text style={{ fontSize: 16, fontWeight: 500 }}>Сканирование</Text>
                    </Col>
                    <Col>
                        <Link onClick={showInstructions}>Показать инструкцию</Link>
                    </Col>
                </Row>

                <Row
                    justify="center"
                    align="middle"
                    style={{ marginTop: 32, marginBottom: 16 }}
                >
                    <Col>
                        <ScanOutlined style={{ fontSize: 122, color: '#40a9ff' }} />
                    </Col>
                </Row>

                <Row justify="center" align="middle">
                    <Col>
                        <Text strong style={{ fontSize: 16, textAlign: "center", display: 'block', width: 154 }}>
                            Отсканируйте код этикетки реактива
                        </Text>
                    </Col>
                </Row>

                <Row justify="center" align="middle" style={{ marginTop: 24 }}>
                    <Col span={24}>
                        <Input
                            ref={inputRef}
                            placeholder="Введите или отсканируйте код"
                            value={scannedData}
                            onChange={handleDataInput}
                            onKeyPress={handleKeyPress}
                            style={{ height: 40 }}
                            autoFocus
                        />
                    </Col>
                </Row>

                <Row justify="center" align="middle" style={{ marginTop: 24 }}>
                    <Col span={24}>
                        {/* <Button 
                            type="primary" 
                            block 
                            onClick={handleScan}
                            style={{ height: 40, marginBottom: 8 }}
                        >
                            Подтвердить
                        </Button> */}
                        <Button 
                            block 
                            onClick={onCancel} 
                            style={{ height: 40 }}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Modal>
            
            <ScanInstructions 
                open={open && instructionsVisible} 
                onClose={hideInstructions} 
            />
        </>
    );
};

export default Scan;
