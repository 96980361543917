import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Row, Typography, Modal } from "antd";
import React from "react";

const { Text } = Typography;

interface SuccessRequestProps {
    open: boolean;
    onClose: () => void;
}

const SuccessRequest: React.FC<SuccessRequestProps> = ({ open, onClose }): JSX.Element => {
    return (
        <Modal
            open={open}
            footer={null}
            closable={false}
            width={600}
            centered
        >
            <Row justify="center" style={{ marginTop: "16px" }}>
                <CheckCircleOutlined style={{ fontSize: "80px", color: "#52C41A" }} />
            </Row>
            <Row justify="center" style={{ marginTop: "16px" }}>
                <Text style={{ fontSize: "24px", textAlign: "center" }}>
                    Заявка в Спектрохим на закупку отправлена
                </Text>
            </Row>
            <Row justify="center" style={{ marginTop: "16px", marginBottom: "16px" }}>
                <Button onClick={onClose} style={{ height: 40, borderRadius: 4 }}>
                    Закрыть
                </Button>
            </Row>
        </Modal>
    );
};

export default SuccessRequest;