import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Row, Typography, Modal } from "antd";
import React, { useEffect } from "react";

const { Text } = Typography;

interface SuccessRequestProps {
    open: boolean;
    onClose: () => void;
}

const SuccessRequest: React.FC<SuccessRequestProps> = ({ open, onClose }): JSX.Element => {
    useEffect(() => {
        return () => {
            if (open) {
                onClose();
            }
        };
    }, [open, onClose]);

    return (
        <Modal
            open={open}
            footer={null}
            closable={true}
            width={600}
            centered
            onCancel={onClose}
            maskClosable={true}
            keyboard={true}
            destroyOnClose={true}
            zIndex={1500}
        >
            <Row justify="center" style={{ marginTop: "16px" }}>
                <CheckCircleOutlined style={{ fontSize: "80px", color: "#52C41A" }} />
            </Row>
            <Row justify="center" style={{ marginTop: "16px" }}>
                <Text style={{ fontSize: "24px", textAlign: "center" }}>
                    Заявка отправлена
                </Text>
            </Row>
            <Row justify="center" style={{ marginTop: "16px", marginBottom: "16px" }}>
                <Button onClick={onClose} type="primary" style={{ height: 40, borderRadius: 4 }}>
                    Закрыть
                </Button>
            </Row>
        </Modal>
    );
};

export default SuccessRequest;