import axiosInstance from './axios';

export interface CustomColumn {
  title: string;
  key: string;
  type: string;
  required: boolean;
}

/**
 * Получить список кастомных колонок для указанного типа реестра
 * @param registryType Тип реестра (reagent, standard, precursor, measuring_instrument, testing_instrument, helping_instrument)
 * @param laboratoryId ID лаборатории
 * @returns Массив кастомных колонок
 */
export const getCustomColumns = async (registryType: string, laboratoryId: number): Promise<CustomColumn[]> => {
  try {
    const response = await axiosInstance.get(`/api/custom-columns/${registryType}`, {
      params: {
        laboratory_id: laboratoryId
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении кастомных колонок:', error);
    return [];
  }
};

/**
 * Добавить кастомную колонку для указанного типа реестра
 * @param registryType Тип реестра (reagent, standard, precursor, measuring_instrument, testing_instrument, helping_instrument)
 * @param laboratoryId ID лаборатории
 * @param column Данные колонки
 * @returns Добавленная колонка
 */
export const addCustomColumn = async (
  registryType: string, 
  laboratoryId: number, 
  column: { title: string; type: string; required?: boolean }
): Promise<CustomColumn> => {
  const response = await axiosInstance.post(`/api/custom-columns/${registryType}`, column, {
    params: {
      laboratory_id: laboratoryId
    }
  });
  return response.data;
};

/**
 * Удалить кастомную колонку для указанного типа реестра
 * @param registryType Тип реестра (reagent, standard, precursor)
 * @param laboratoryId ID лаборатории
 * @param columnKey Ключ колонки
 * @returns Сообщение об успешном удалении
 */
export const deleteCustomColumn = async (
  registryType: string, 
  laboratoryId: number, 
  columnKey: string
): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(`/api/custom-columns/${registryType}/${columnKey}`, {
    params: {
      laboratory_id: laboratoryId
    }
  });
  return response.data;
}; 