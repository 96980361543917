import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Table, Checkbox, Button, Tooltip, Input, Space, Popover, theme, message } from 'antd';
import type { TableColumnsType, InputRef } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { createStyles } from 'antd-style';
import dayjs from 'dayjs';
import { SearchOutlined, FilterOutlined, EyeInvisibleOutlined, DatabaseOutlined, ArrowUpOutlined, ArrowDownOutlined, MoreOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import { getStandards } from '../../api/chemicalRegistry';
import { useLaboratoryId } from '../../hooks/useLaboratoryId';
import EditSS from '../../components/EditSS';
import ColumnManager from '../../components/ColumnManager';
import type { ColumnType } from 'antd/es/table';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../api/axios';

const { useToken } = theme;

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table {
      border: 1px solid #E0E0E0;
      
      .ant-table-thead .ant-table-cell {
        color: #595959;
      }

      .ant-table-tbody {
        .ant-table-cell {
          font-weight: 500;

          .ant-select-selection-item,
          .ant-picker-input > input {
            font-weight: 500;
          }

          .ant-select .ant-select-selector {
            border-radius: 4px !important;
          }
        }
      }

      .cell-content {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
}));

export interface StandardType {
    key: React.Key;
    number: number;
    name: string;
    manufacturer: string;
    purpose: string;
    batchCode: string;
    packaging: string;
    indicator: string;
    certifiedValue: number;
    certifiedValueUnit: string;
    errorValue: number;
    errorValueUnit: string;
    additionalInfo: string;
    normativeDocument: string;
    expirationDate: string;
    expirationNotification: string;
    releaseDate: string;
    notes: string;
    isPrecursor: boolean;
    custom_columns?: Record<string, any>;
}

const standardTypes = [
    'ГСО 1234-56 Стандартный образец 1',
    'ОСО 7890-12 Стандартный образец 2',
    'СОП 3456-78 Стандартный образец 3',
].map(item => ({ label: item, value: item }));

const manufacturerOptions = [
    'ФГУП "ВНИИМ им. Д.И. Менделеева"',
    'ФГУП "УНИИМ"',
    'ООО "Химтест"',
].map(item => ({ label: item, value: item }));

const purposeOptions = [
    'Калибровка',
    'Поверка',
    'Контроль точности',
].map(item => ({ label: item, value: item }));

const indicatorOptions = [
    'Массовая доля железа',
    'Массовая доля углерода',
    'Массовая доля серы',
].map(item => ({ label: item, value: item }));

const notificationOptions = [
    'За месяц',
    'За 2 месяца',
    'За 3 месяца',
].map(item => ({ label: item, value: item }));

const TextWithTooltip: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  if (text === null || text === undefined) {
    return <div>-</div>;
  }
  
  if (typeof text === 'string' && text.length > 24) {
    return (
      <Tooltip title={text}>
        <div className="cell-content">{text}</div>
      </Tooltip>
    );
  }
  return <div>{text}</div>;
};

const ColumnMenu = styled.div`
  width: 306px;
  padding: 12px;
  background: #F5F5F5;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const MenuButton = styled(Button)<{ $isBlue?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 6.4px 12px;
  background: ${props => props.$isBlue ? '#1890FF' : 'white'};
  color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'};
  border: 0.5px solid ${props => props.$isBlue ? '#1890FF' : '#D9D9D9'};
  border-radius: 4px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;

  .anticon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: ${props => props.$isBlue ? '#40a9ff' : '#fff'} !important;
    color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'} !important;
    border-color: ${props => props.$isBlue ? '#40a9ff' : '#d9d9d9'} !important;
  }
`;

const FilterContent = styled.div`
  height: 200px;
  overflow-y: auto;
  margin-bottom: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const FilterDropdown: React.FC<{
  close: () => void;
  confirm: (selectedValues: string[]) => void;
  values: string[];
}> = ({ close, confirm, values }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const uniqueValues = Array.from(new Set(values)).sort();

  return (
    <div style={{ padding: 8, background: 'white', borderRadius: 4 }}>
      <FilterContent>
        {uniqueValues.map((value) => (
          <div key={value} style={{ marginBottom: 4 }}>
            <Checkbox
              checked={selectedValues.includes(value)}
              onChange={(e) => {
                const newValues = e.target.checked
                  ? [...selectedValues, value]
                  : selectedValues.filter(v => v !== value);
                setSelectedValues(newValues);
              }}
            >
              {value}
            </Checkbox>
          </div>
        ))}
      </FilterContent>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm(selectedValues);
            close();
          }}
          size="small"
        >
          Применить
        </Button>
        <Button
          onClick={() => {
            setSelectedValues([]);
            confirm([]);
            close();
          }}
          size="small"
        >
          Сбросить
        </Button>
      </Space>
    </div>
  );
};

interface CustomColumnTitleProps {
  title: React.ReactNode;
  onSort?: (order: 'asc' | 'desc') => void;
  onFilter?: (values: string[]) => boolean;
  onHide?: () => void;
  onManage?: () => void;
  type?: 'number' | 'date';
  dataIndex?: string[];
  filterValues?: string[];
  getAllColumnNames: () => { title: string; key: string }[];
  hiddenColumns: string[];
  onColumnVisibilityChange: (columns: string[]) => void;
}

const CustomColumnTitle: React.FC<CustomColumnTitleProps> = ({
  title,
  onSort,
  onFilter,
  onHide,
  onManage,
  type,
  filterValues = [],
  getAllColumnNames,
  hiddenColumns,
  onColumnVisibilityChange
}) => {
  const { token } = useToken();
  const [open, setOpen] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [showColumnManager, setShowColumnManager] = useState(false);
  const labId = useLaboratoryId();

  const handleMenuClick = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    setOpen(false);
  };

  const handleFilter = () => {
    setOpen(false);
    setFilterVisible(true);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (showColumnManager) return;
    setOpen(newOpen);
  };

  const columnMenu = (
    <ColumnMenu>
      {!showColumnManager ? (
        <>
          {type && (
            <>
              <MenuButton 
                icon={<ArrowUpOutlined style={{ color: token['blue-5'] }} />}
                onClick={() => handleMenuClick(() => onSort?.('asc'))}
              >
                Сортировать по возрастанию
              </MenuButton>
              <MenuButton 
                icon={<ArrowDownOutlined style={{ color: token['blue-5'] }} />}
                onClick={() => handleMenuClick(() => onSort?.('desc'))}
              >
                Сортировать по убыванию
              </MenuButton>
            </>
          )}
          <MenuButton 
            icon={<FilterOutlined style={{ color: token['blue-5'] }} />}
            onClick={handleFilter}
          >
            Фильтры
          </MenuButton>
          <MenuButton 
            icon={<EyeInvisibleOutlined style={{ color: token['blue-5'] }} />}
            onClick={() => handleMenuClick(onHide)}
          >
            Скрыть
          </MenuButton>
          <MenuButton 
            $isBlue 
            icon={<DatabaseOutlined style={{ color: 'white' }} />}
            onClick={() => setShowColumnManager(true)}
          >
            Управление колонками
          </MenuButton>
        </>
      ) : (
        <ColumnManager
          columns={getAllColumnNames()}
          hiddenColumns={hiddenColumns}
          onColumnVisibilityChange={onColumnVisibilityChange}
          visible={true}
          onClose={() => setShowColumnManager(false)}
          registryType="standard"
          laboratoryId={labId ? Number(labId) : 0}
        />
      )}
    </ColumnMenu>
  );

  return (
    <>
      <Popover 
        content={columnMenu} 
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'transparent', boxShadow: 'none' }}
      >
        <div style={{ cursor: 'pointer' }}>{title}</div>
      </Popover>
      <Popover
        content={
          <FilterDropdown
            close={() => setFilterVisible(false)}
            confirm={(values) => {
              if (onFilter) {
                onFilter(values);
              }
              setFilterVisible(false);
            }}
            values={filterValues}
          />
        }
        trigger="click"
        open={filterVisible}
        onOpenChange={setFilterVisible}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'white', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: filterVisible ? 'block' : 'none' }} />
      </Popover>
    </>
  );
};

interface FilterFunction {
  (value: string): boolean;
}

interface TableProps {
  data?: StandardType[];
  onSelectionChange?: (selectedRowKeys: React.Key[]) => void;
  loading?: boolean;
  resetSelection?: boolean;
}

const isColumnGroup = (column: any): column is ColumnType<StandardType> & { children: any[] } => {
  return 'children' in column;
};

const getColumnKey = (column: any): string => {
  if (isColumnGroup(column)) {
    return '';
  }
  if (!column.dataIndex) {
    return '';
  }
  return Array.isArray(column.dataIndex) ? column.dataIndex.join('.') : column.dataIndex.toString();
};

const getColumnTitle = (column: any): string => {
  if (typeof column.title === 'string') {
    return column.title;
  }
  
  // Если title - это React-компонент, попробуем получить текст из свойств
  if (column.title && typeof column.title === 'object') {
    // Проверяем, есть ли у компонента свойство props.text
    if (column.title.props && column.title.props.text) {
      if (typeof column.title.props.text === 'string') {
        return column.title.props.text;
      }
    }
    
    // Проверяем, есть ли у компонента свойство props.title
    if (column.title.props && column.title.props.title) {
      if (typeof column.title.props.title === 'string') {
        return column.title.props.title;
      }
      
      // Если title.props.title - это компонент TextWithTooltip
      if (column.title.props.title.props && column.title.props.title.props.text) {
        if (typeof column.title.props.title.props.text === 'string') {
          return column.title.props.title.props.text;
        }
      }
    }
    
    // Проверяем, есть ли у компонента свойство props.children
    if (column.title.props && column.title.props.children) {
      // Если children - это массив, ищем TextWithTooltip
      if (Array.isArray(column.title.props.children)) {
        for (const child of column.title.props.children) {
          if (child && child.props && child.props.text) {
            if (typeof child.props.text === 'string') {
              return child.props.text;
            }
          }
        }
      }
    }
  }
  
  // Если не удалось получить текст, используем dataIndex или key
  if (column.dataIndex) {
    const dataIndex = Array.isArray(column.dataIndex) ? column.dataIndex.join('.') : column.dataIndex;
    // Используем словарь для преобразования dataIndex в читаемое название
    const columnLabels: Record<string, string> = {
      'number': 'Номер',
      'name': 'Наименование',
      'manufacturer': 'Производитель',
      'purpose': 'Назначение',
      'batchCode': 'Шифр партии',
      'packaging': 'Фасовка',
      'indicator': 'Показатель',
      'certifiedValue': 'Аттестованное значение',
      'certifiedValueUnit': 'Единица измерения атт. значения',
      'errorValue': 'Погрешность',
      'errorValueUnit': 'Единица измерения погрешности',
      'additionalInfo': 'Дополнительная информация',
      'normativeDocument': 'Нормативный документ',
      'expirationDate': 'Срок годности',
      'expirationNotification': 'Уведомление об окончании',
      'releaseDate': 'Дата выпуска',
      'notes': 'Примечание',
      'isPrecursor': 'Прекурсор'
    };
    
    return columnLabels[dataIndex] || dataIndex;
  }
  
  return column.key || '';
};

const StandartSamplesTable: React.FC<TableProps> = ({ 
  data = [], 
  onSelectionChange,
  loading = false,
  resetSelection
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(() => {
    const saved = localStorage.getItem('standardSamplesHiddenColumns');
    return saved ? JSON.parse(saved) : [];
  });
  const [sortedInfo, setSortedInfo] = useState<{ columnKey: string | null; order: 'ascend' | 'descend' | null }>({
    columnKey: null,
    order: null,
  });
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [filteredData, setFilteredData] = useState<StandardType[]>(data);
  const { styles } = useStyle();
  const { token } = useToken();
  const [selectedRecord, setSelectedRecord] = useState<StandardType | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const laboratoryId = useLaboratoryId();
  const [isColumnManagerVisible, setIsColumnManagerVisible] = useState(false);
  const location = useLocation();
  const isAdmin = location.pathname.startsWith('/admin');
  const [customColumns, setCustomColumns] = useState<{ title: string; key: string; type?: string }[]>([]);

  useEffect(() => {
    localStorage.setItem('standardSamplesHiddenColumns', JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  useEffect(() => {
    if (resetSelection) {
      setSelectedRowKeys([]);
    }
  }, [resetSelection]);

  const fetchStandards = useCallback(async () => {
    try {
      if (!laboratoryId) return;
      
      console.log('Fetching standards for laboratory:', laboratoryId);
      const standardsData = await getStandards(Number(laboratoryId));
      console.log('Fetched standards:', standardsData);
      
      setFilteredData(standardsData);
    } catch (error) {
      console.error('Error fetching standards:', error);
      message.error('Ошибка при загрузке стандартных образцов');
    }
  }, [laboratoryId]);

  useEffect(() => {
    fetchStandards();
  }, [fetchStandards]);

  const handleSuccess = useCallback(() => {
    fetchStandards();
    if (onSelectionChange) {
      onSelectionChange([]);
    }
  }, [fetchStandards, onSelectionChange]);

  // Update filteredData when data prop changes
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // Update filteredData when filters change
  useEffect(() => {
    const newData = data.filter(record => {
      return Object.entries(filters).every(([key, filterValues]) => {
        if (filterValues.length === 0) return true;

        const keys = key.split('.');
        const fieldValue = keys.reduce((obj, key) => obj?.[key], record as any)?.toString();
        return filterValues.includes(fieldValue);
      });
    });

    setFilteredData(newData);
  }, [filters, data]);

  const handleCheckboxChange = (
    record: StandardType,
    field: 'isPrecursor',
    checked: boolean
  ) => {
    setFilteredData(prevData => 
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: checked
          };
        }
        return item;
      })
    );
  };

  const handleSelectChange = (
    record: StandardType,
    field: string,
    value: string
  ) => {
    setFilteredData(prevData =>
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })
    );
  };

  const handleDateChange = (
    record: StandardType,
    field: string,
    value: dayjs.Dayjs | null
  ) => {
    setFilteredData(prevData =>
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: value ? value.format('YYYY-MM-DD') : null
          };
        }
        return item;
      })
    );
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string[],
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex.join('.'));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string[]) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Поиск"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex.join('.'));
            }}
          >
            Фильтр
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value: any, record: StandardType) => {
      const fieldValue = dataIndex.reduce((obj, key) => obj?.[key], record as any);
      return fieldValue?.toString().toLowerCase().includes((value as string).toLowerCase());
    },
    filterDropdownProps: {
      onOpenChange(open: boolean) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text: string) =>
      searchedColumn === dataIndex.join('.') ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <TextWithTooltip text={text} />
      ),
  });

  const handleHideColumn = (dataIndex: string[]) => {
    const columnKey = dataIndex.join('.');
    setHiddenColumns(prev => {
      const newHiddenColumns = [...prev, columnKey];
      localStorage.setItem('standardSamplesHiddenColumns', JSON.stringify(newHiddenColumns));
      return newHiddenColumns;
    });
  };

  const handleShowColumn = (columnKey: string) => {
    setHiddenColumns(prev => {
      const newHiddenColumns = prev.filter(key => key !== columnKey);
      localStorage.setItem('standardSamplesHiddenColumns', JSON.stringify(newHiddenColumns));
      return newHiddenColumns;
    });
  };

  const handleSort = (columnKey: string, order: 'asc' | 'desc') => {
    const antdOrder = order === 'asc' ? 'ascend' : 'descend';
    setSortedInfo({ columnKey, order: antdOrder });

    setFilteredData(prevData => {
      const newData = [...prevData];
      newData.sort((a, b) => {
        const aValue = columnKey.split('.').reduce((obj, key) => obj?.[key], a as any);
        const bValue = columnKey.split('.').reduce((obj, key) => obj?.[key], b as any);

        if (typeof aValue === 'string') {
          return order === 'asc' 
            ? aValue.localeCompare(bValue) 
            : bValue.localeCompare(aValue);
        }

        return order === 'asc' 
          ? (aValue > bValue ? 1 : -1)
          : (bValue > aValue ? 1 : -1);
      });
      return newData;
    });
  };

  const handleFilter = (columnKey: string, values: string[]): boolean => {
    if (values.length === 0) {
      const newFilters = { ...filters };
      delete newFilters[columnKey];
      setFilters(newFilters);
    } else {
      setFilters(prev => ({
        ...prev,
        [columnKey]: values
      }));
    }
    return true;
  };

  const getUniqueValues = (dataIndex: string[]) => {
    return Array.from(new Set(
      data.map(item => {
        const value = dataIndex.reduce((obj, key) => obj?.[key], item as any);
        return value?.toString() || '';
      }).filter(Boolean)
    ));
  };

  const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    
    .column-menu-icon {
      opacity: 1;
    }
  `;

  const TitleText = styled.div`
    flex: 1;
  `;

  const MenuIcon = styled.div`
    opacity: 0;
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 4px;
    transition: all 0.2s;
    
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    
    .anticon {
      font-size: 16px;
      color: #8C8C8C;
    }
  `;

  const columnTitles: { [key: string]: string } = {
    'number': '№ п/п',
    'name': 'Наименование, тип, номер и категория СО',
    'manufacturer': 'Изготовитель СО',
    'purpose': 'Назначение',
    'batchCode': 'Шифр партии',
    'packaging': 'Фасовка',
    'indicator': 'Показатель',
    'certifiedValue': 'Аттестов. значение м.д. %',
    'certifiedValueUnit': 'Единица измерения атт. значения',
    'errorValue': 'Погрешность а.з. абс. %',
    'errorValueUnit': 'Единица измерения погрешности',
    'additionalInfo': 'Доп сведения',
    'normativeDocument': 'Нормативный документ',
    'expirationDate': 'Срок годности экземпляра СО',
    'expirationNotification': 'Уведомление об окончании',
    'releaseDate': 'Дата выпуска',
    'notes': 'Примечание',
    'isPrecursor': 'Прекурсор'
  };

  const getAllColumnNames = () => {
    const result: { title: string; key: string }[] = [];
    
    const extractNames = (cols: TableColumnsType<StandardType>) => {
      cols.forEach(col => {
        if ('children' in col && col.children) {
          extractNames(col.children);
        } else if (col.title) {
          const dataIndex = (col as ColumnType<StandardType>).dataIndex;
          if (dataIndex) {
            const key = Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex.toString();
            const titleStr = typeof col.title === 'string' 
              ? col.title 
              : getColumnTitle(col);
            
            result.push({ title: titleStr, key });
          } else if (col.key) {
            const titleStr = typeof col.title === 'string' 
              ? col.title 
              : getColumnTitle(col);
              
            result.push({ title: titleStr, key: col.key.toString() });
          }
        }
      });
    };
    
    extractNames(columns);
    
    // Не добавляем пользовательские колонки здесь, так как они будут добавлены в компоненте ColumnManager
    // customColumns.forEach(col => {
    //   result.push(col);
    // });
    
    return result;
  };

  const createColumnTitle = (title: string, dataIndex?: string[], isGroupTitle?: boolean, type?: 'number' | 'date') => {
    if (isGroupTitle) {
      return <TextWithTooltip text={title} />;
    }

    return (
      <CustomColumnTitle 
        title={
          <TitleContainer>
            <TitleText>
              <TextWithTooltip text={title} />
            </TitleText>
            <MenuIcon className="column-menu-icon">
              <MoreOutlined />
            </MenuIcon>
          </TitleContainer>
        }
        onSort={dataIndex && type ? (order) => handleSort(dataIndex.join('.'), order) : undefined}
        onFilter={dataIndex ? (values) => handleFilter(dataIndex.join('.'), values) : undefined}
        onHide={() => dataIndex && handleHideColumn(dataIndex)}
        onManage={() => setIsColumnManagerVisible(true)}
        type={type}
        dataIndex={dataIndex}
        filterValues={dataIndex ? getUniqueValues(dataIndex) : []}
        getAllColumnNames={getAllColumnNames}
        hiddenColumns={hiddenColumns}
        onColumnVisibilityChange={setHiddenColumns}
      />
    );
  };

  const columns: TableColumnsType<StandardType> = [
    {
        title: createColumnTitle('№ п/п', ['number'], false, 'number'),
        dataIndex: 'number',
        key: 'number',
        width: 86,
        // fixed: 'left',
        sortOrder: sortedInfo.columnKey === 'number' ? sortedInfo.order : null,
        hidden: hiddenColumns.includes('number'),
    },
    {
        title: createColumnTitle('Наименование, тип, номер и категория СО', ['name']),
        dataIndex: 'name',
        key: 'name',
        width: 232,
        hidden: hiddenColumns.includes('name'),
        render: (text: string) => <TextWithTooltip text={text} />,
    },
    {
        title: createColumnTitle('Изготовитель СО', ['manufacturer']),
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: 232,
        hidden: hiddenColumns.includes('manufacturer'),
        render: (text: string) => <TextWithTooltip text={text} />,
    },
    {
        title: createColumnTitle('Назначение', ['purpose']),
        dataIndex: 'purpose',
        key: 'purpose',
        width: 232,
        hidden: hiddenColumns.includes('purpose'),
        render: (text: string) => <TextWithTooltip text={text} />,
    },
    {
        title: createColumnTitle('Шифр партии', ['batchCode']),
        dataIndex: 'batchCode',
        key: 'batchCode',
        width: 232,
        hidden: hiddenColumns.includes('batchCode'),
        render: (text: string) => <TextWithTooltip text={text} />,
    },
    {
        title: createColumnTitle('Фасовка', ['packaging']),
        dataIndex: 'packaging',
        key: 'packaging',
        width: 232,
        hidden: hiddenColumns.includes('packaging'),
        render: (text: string) => <TextWithTooltip text={text} />,
    },
    {
        title: createColumnTitle('Метрологические характеристики', undefined, true),
        children: [
            {
                title: createColumnTitle('Показатель', ['indicator']),
                dataIndex: 'indicator',
                key: 'indicator',
                width: 232,
                hidden: hiddenColumns.includes('indicator'),
                render: (text: string) => <TextWithTooltip text={text} />,
            },
            {
                title: createColumnTitle('Аттестов. значение м.д. %', ['certifiedValue'], false, 'number'),
                dataIndex: 'certifiedValue',
                key: 'certifiedValue',
                width: 232,
                hidden: hiddenColumns.includes('certifiedValue'),
                render: (text: any) => {
                  if (typeof text === 'number') {
                    return <TextWithTooltip text={text !== null && text !== undefined ? text.toString() : '-'} />;
                  }
                  return <TextWithTooltip text={text} />;
                },
            },
            {
                title: createColumnTitle('Единица измерения атт. значения', ['certifiedValueUnit']),
                dataIndex: 'certifiedValueUnit',
                key: 'certifiedValueUnit',
                width: 232,
                hidden: hiddenColumns.includes('certifiedValueUnit'),
                render: (text: string) => <TextWithTooltip text={text} />,
            },
            {
                title: createColumnTitle('Погрешность а.з. абс. %', ['errorValue'], false, 'number'),
                dataIndex: 'errorValue',
                key: 'errorValue',
                width: 232,
                hidden: hiddenColumns.includes('errorValue'),
                render: (text: any) => {
                  if (typeof text === 'number') {
                    return <TextWithTooltip text={text !== null && text !== undefined ? text.toString() : '-'} />;
                  }
                  return <TextWithTooltip text={text} />;
                },
            },
            {
                title: createColumnTitle('Единица измерения погрешности', ['errorValueUnit']),
                dataIndex: 'errorValueUnit',
                key: 'errorValueUnit',
                width: 232,
                hidden: hiddenColumns.includes('errorValueUnit'),
                render: (text: string) => <TextWithTooltip text={text} />,
            },
        ],
    },
    {
        title: createColumnTitle('Доп сведения', ['additionalInfo']),
        dataIndex: 'additionalInfo',
        key: 'additionalInfo',
        width: 232,
        hidden: hiddenColumns.includes('additionalInfo'),
        render: (text: string) => <TextWithTooltip text={text} />,
    },
    {
        title: createColumnTitle('Нормативный документ', ['normativeDocument']),
        dataIndex: 'normativeDocument',
        key: 'normativeDocument',
        width: 232,
        hidden: hiddenColumns.includes('normativeDocument'),
        render: (text: string) => <TextWithTooltip text={text} />,
    },
    {
        title: createColumnTitle('Срок годности экземпляра СО', ['expirationDate'], false, 'date'),
        dataIndex: 'expirationDate',
        key: 'expirationDate',
        width: 232,
        hidden: hiddenColumns.includes('expirationDate'),
        render: (text: string) => <TextWithTooltip text={text ? dayjs(text).format('DD.MM.YYYY') : ''} />,
    },
    // {
    //     title: createColumnTitle('Уведомление об окончании срока годности', ['expirationNotification']),
    //     dataIndex: 'expirationNotification',
    //     key: 'expirationNotification',
    //     width: 232,
    //     hidden: hiddenColumns.includes('expirationNotification'),
    //     render: (text: string) => <TextWithTooltip text={text} />,
    // },
    {
        title: createColumnTitle('Дата выпуска экземпляра СО', ['releaseDate'], false, 'date'),
        dataIndex: 'releaseDate',
        key: 'releaseDate',
        width: 232,
        hidden: hiddenColumns.includes('releaseDate'),
        render: (text: string) => <TextWithTooltip text={text ? dayjs(text).format('DD.MM.YYYY') : ''} />,
    },
    {
        title: createColumnTitle('Примечание', ['notes']),
        dataIndex: 'notes',
        key: 'notes',
        width: 232,
        hidden: hiddenColumns.includes('notes'),
        render: (text: string) => <TextWithTooltip text={text} />,
    },
    {
        title: createColumnTitle('Прекурсор', ['isPrecursor']),
        dataIndex: 'isPrecursor',
        key: 'isPrecursor',
        width: 232,
        hidden: hiddenColumns.includes('isPrecursor'),
        render: (value: boolean) => <TextWithTooltip text={value ? 'Да' : 'Нет'} />,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      onSelectionChange?.(newSelectedRowKeys);
    },
    columnWidth: 48,
    fixed: true
  };

  const getVisibleColumns = (cols: TableColumnsType<StandardType>): TableColumnsType<StandardType> => {
    return cols.filter(col => {
      if (isColumnGroup(col)) {
        col.children = getVisibleColumns(col.children);
        return col.children.length > 0;
      }
      return !col.hidden;
    });
  };

  // Создаем динамические колонки для пользовательских полей
  const customColumnsConfig = useMemo(() => {
    return customColumns.map(col => ({
      title: createColumnTitle(col.title, [col.key]),
      dataIndex: ['custom_columns', col.key],
      key: col.key,
      width: 150,
      hidden: hiddenColumns.includes(col.key),
      render: (text: any) => {
        if (!text) return <TextWithTooltip text="-" />;
        
        // Определяем тип колонки по ключу, если информация о типе не доступна
        const colType = col.type || 'text';
        
        switch (colType) {
          case 'date':
            return <TextWithTooltip text={text ? dayjs(text).format('DD.MM.YYYY') : '-'} />;
          case 'boolean':
            return (
              <Checkbox
                checked={Boolean(text)}
                disabled
              />
            );
          case 'int':
            return <TextWithTooltip text={text !== null && text !== undefined ? text.toString() : '-'} />;
          default:
            return <TextWithTooltip text={text} />;
        }
      }
    }));
  }, [customColumns, hiddenColumns]);

  // Объединяем стандартные и пользовательские колонки
  const allColumns = useMemo(() => {
    return [...columns, ...customColumnsConfig];
  }, [columns, customColumnsConfig]);
  
  // Используем allColumns вместо columns для отображения
  const finalVisibleColumns = useMemo(() => {
    return getVisibleColumns(allColumns);
  }, [allColumns]);

  const handleRowClick = (record: StandardType) => {
    if (isAdmin) return;
    console.log('Row clicked:', record);
    setSelectedRecord(record);
    setIsEditModalVisible(true);
  };

  // Добавляем функцию проверки срока годности
  const isExpiryDateNearExpiration = (expiryDate: string | undefined): boolean => {
    if (!expiryDate) return false;
    const today = dayjs();
    const expiry = dayjs(expiryDate);
    const monthsLeft = expiry.diff(today, 'month');
    return monthsLeft <= 1 && expiry.isAfter(today);
  };

  // Добавляем функцию проверки малого остатка
  const isLowRemaining = (certifiedValue: number): boolean => {
    return certifiedValue < 10;
  };

  // Функция для загрузки пользовательских колонок
  const loadCustomColumns = useCallback(async () => {
    if (!laboratoryId) return;
    
    try {
      const response = await axiosInstance.get(`/api/custom-columns/standard`, {
        params: {
          laboratory_id: laboratoryId
        }
      });
      
      if (response.status === 200) {
        setCustomColumns(response.data.map((col: any) => ({ 
          title: col.title, 
          key: col.key,
          type: col.type || 'text' 
        })));
      }
    } catch (error) {
      console.error('Ошибка при загрузке пользовательских колонок:', error);
    }
  }, [laboratoryId]);

  // Загружаем пользовательские колонки при монтировании компонента
  useEffect(() => {
    loadCustomColumns();
  }, [loadCustomColumns]);

  return (
    <div className={styles.customTable}>
      <Table<StandardType>
        columns={finalVisibleColumns}
        dataSource={filteredData}
        rowSelection={rowSelection}
        loading={loading}
        bordered
        size="middle"
        scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          style: { 
            cursor: isAdmin ? 'default' : 'pointer',
            backgroundColor: isExpiryDateNearExpiration(record.expirationDate)
              ? 'rgba(255, 0, 0, 0.07)'
              : isLowRemaining(record.certifiedValue)
                ? 'rgba(255, 255, 0, 0.15)'
                : undefined
          }
        })}
      />

      {!isAdmin && (
        <Popover 
          content={selectedRecord && (
            <EditSS
              onCancel={() => {
                setIsEditModalVisible(false);
                setSelectedRecord(null);
              }}
              record={selectedRecord}
              onSuccess={handleSuccess}
            />
          )}
          trigger="click"
          open={isEditModalVisible}
          onOpenChange={(visible) => {
            setIsEditModalVisible(visible);
            if (!visible) {
              setSelectedRecord(null);
            }
          }}
          placement="bottomRight"
          overlayStyle={{ 
            padding: 0,
            zIndex: 1100
          }}
          overlayInnerStyle={{ 
            padding: 0
          }}
          arrow={false}
          getPopupContainer={(trigger) => trigger.parentElement || document.body}
        >
          <div style={{ position: 'absolute', right: '45px', top: '195px', width: 0, height: 0 }} />
        </Popover>
      )}

      <ColumnManager
        visible={isColumnManagerVisible}
        columns={getAllColumnNames()}
        hiddenColumns={hiddenColumns}
        onColumnVisibilityChange={setHiddenColumns}
        onClose={() => setIsColumnManagerVisible(false)}
        registryType="standard"
        laboratoryId={Number(laboratoryId || 0)}
        onColumnAdded={loadCustomColumns}
      />
    </div>
  );
};

export default StandartSamplesTable;