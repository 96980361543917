import React, { useState, useEffect } from 'react';
import { Button, Input, Typography, Layout, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import styled from 'styled-components';
import { useAuth } from '../../hooks/useAuth';
import { User } from '../../types/user';

const { Title, Text } = Typography;

const PageWrapper = styled.div`
  width: 100%;
  height: 867px;
  background-color: #f5f5f5;
  position: relative;
  font-family: 'Roboto', sans-serif;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: calc(50% - 245px);
  left: calc(50% - 236px);
  width: 472px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

const FormWrapper = styled.div`
  width: 376px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 4px !important;
`;

const InputLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #8c8c8c;
`;

const StyledInput = styled(Input)`
  background: #fafafa;
  border: 0.5px solid #d9d9d9;
  height: 40px;
  
  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
    font-weight: 400;
    font-size: 14px;
  }
`;

const StyledPasswordInput = styled(Input.Password)`
  background: #fafafa;
  border: 0.5px solid #d9d9d9;
  height: 40px;
  
  input::placeholder {
    color: rgba(0, 0, 0, 0.25);
    font-weight: 400;
    font-size: 14px;
  }

  .ant-input {
    background: #fafafa;
    font-weight: 400;
  }
`;

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userStr = localStorage.getItem('user');
    if (isAuthenticated && userStr) {
        const userData = JSON.parse(userStr) as User;

        if (userData.is_admin || userData.role === "admin") {
            navigate('/admin/laboratories');
        } else if (userData.role === 'quality') {
            navigate('/quality-control');
        } else if (userData.role === 'distributor') {
            navigate('/distributor');
        } else if (userData.laboratory_id) {
            navigate(`/laboratory/${userData.laboratory_id}/chemicals`);
        }
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    try {
        if (!username || !password) {
            message.error('Пожалуйста, введите логин и пароль');
            return;
        }

        setLoading(true);
        
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);

        console.log('Login params:', { username, password });
        
        try {
            const response = await axiosInstance.post<{
                access_token: string;
                token_type: string;
                user: User;
            }>('/api/auth/login/access-token', params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            
            console.log('Login response:', response.data);

            if (response.data.access_token && response.data.user) {
                const userData = response.data.user;
                console.log('Детали авторизации:', {
                    userData,
                    isAdmin: userData.is_admin,
                    role: userData.role,
                    adminByRole: userData.role === "admin",
                    adminByFlag: userData.is_admin,
                    redirectPath: userData.is_admin || userData.role === "admin" 
                        ? '/admin/laboratories' 
                        : userData.role === 'quality' 
                            ? '/quality-control' 
                            : userData.role === 'distributor' 
                                ? '/distributor' 
                                : userData.laboratory_id 
                                    ? `/laboratory/${userData.laboratory_id}/chemicals` 
                                    : '/auth/login'
                });
                
                login(userData, response.data.access_token);
                message.success('Успешная авторизация');

                if (userData.is_admin || userData.role === "admin") {
                    navigate('/admin/laboratories');
                } else if (userData.role === 'quality') {
                    navigate('/quality-control');
                } else if (userData.role === 'distributor') {
                    navigate('/distributor');
                } else if (userData.laboratory_id) {
                    navigate(`/laboratory/${userData.laboratory_id}/chemicals`);
                }
            }
        } catch (axiosError: any) {
            console.error('Axios error details:', {
                message: axiosError.message,
                code: axiosError.code,
                response: axiosError.response,
                request: axiosError.request
            });
            
            if (axiosError.code === 'ERR_NETWORK') {
                message.error('Ошибка сети. Проверьте соединение и URL API.');
            } else if (axiosError.response) {
                message.error(`Ошибка сервера: ${axiosError.response.status} - ${axiosError.response.data.detail || 'Неизвестная ошибка'}`);
            } else {
                message.error(`Ошибка: ${axiosError.message}`);
            }
        }
    } catch (error: any) {
        console.error('Login error:', error);
        let errorMessage = 'Ошибка авторизации';
        
        if (error.response?.data?.detail && typeof error.response.data.detail === 'string') {
            errorMessage = error.response.data.detail;
        } else if (error.message && typeof error.message === 'string') {
            errorMessage = error.message;
        }
        
        message.error(errorMessage);
    } finally {
        setLoading(false);
    }
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <Title
          level={2}
          style={{
            margin: 0,
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '40px'
          }}
        >
          Личный кабинет
        </Title>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
          <FormWrapper>
            <Title
              level={5}
              style={{
                margin: 0,
                color: 'rgba(54, 49, 38, 0.9)',
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 700
              }}
            >
              Вход
            </Title>

            <InputWrapper>
              <InputLabel>Логин</InputLabel>
              <StyledInput 
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="введите логин" 
                style={{ borderRadius: 4 }} 
              />
            </InputWrapper>

            <InputWrapper>
              <InputLabel>Пароль</InputLabel>
              <StyledPasswordInput 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="введите пароль" 
                style={{ borderRadius: 4 }} 
              />
            </InputWrapper>

            <Button
              onClick={handleLogin}
              type="primary"
              loading={loading}
              block
              style={{
                height: 48,
                fontSize: 14,
                fontWeight: 300,
                lineHeight: '28px'
              }}
            >
              Войти
            </Button>
          </FormWrapper>

          <Text
            onClick={() => navigate('/auth/forgot-password')}
            style={{
              fontSize: 14,
              lineHeight: '22px',
              color: '#8a8a92',
              fontWeight: 300
            }}
          >
            Забыли пароль?
          </Text>

          {/* <Text
            onClick={() => navigate('/')}
            style={{
              fontSize: 14,
              lineHeight: '22px',
              color: '#2b2b2f',
              cursor: 'pointer'
            }}
          >
            &lt; Назад
          </Text> */}
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default LoginForm;