import axiosInstance from './axios';

/**
 * Получить заголовки из Excel файла
 * @param file Файл Excel
 * @param headerRows Количество строк заголовка
 * @returns Массив заголовков
 */
export const extractExcelHeaders = async (file: File, headerRows: number): Promise<string[]> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('header_rows', headerRows.toString());

    const response = await axiosInstance.post('/import-export/extract-headers', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.headers;
  } catch (error) {
    console.error('Ошибка при получении заголовков из Excel:', error);
    throw error;
  }
};

/**
 * Получить количество строк данных в Excel файле
 * @param file Файл Excel
 * @param headerRows Количество строк заголовка
 * @returns Количество строк данных
 */
export const extractExcelRowsCount = async (file: File, headerRows: number): Promise<number> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('header_rows', headerRows.toString());

    const response = await axiosInstance.post('/import-export/extract-rows-count', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.rows_count;
  } catch (error) {
    console.error('Ошибка при получении количества строк из Excel:', error);
    throw error;
  }
};

/**
 * Импортировать реагенты из Excel файла
 * @param file Файл Excel
 * @param mapping Маппинг колонок
 * @param headerRows Количество строк заголовка
 * @param laboratoryId ID лаборатории
 * @returns Сообщение об успешном импорте
 */
export const importReagents = async (
  file: File,
  mapping: Record<string, string>,
  headerRows: number,
  laboratoryId: number
): Promise<{ message: string }> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mapping', JSON.stringify(mapping));
    formData.append('header_rows', headerRows.toString());
    formData.append('laboratory_id', laboratoryId.toString());

    const response = await axiosInstance.post('/import-export/import-reagents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Ошибка при импорте реагентов:', error);
    throw error;
  }
};

/**
 * Импортировать стандартные образцы из Excel файла
 * @param file Файл Excel
 * @param mapping Маппинг колонок
 * @param headerRows Количество строк заголовка
 * @param laboratoryId ID лаборатории
 * @returns Сообщение об успешном импорте
 */
export const importStandards = async (
  file: File,
  mapping: Record<string, string>,
  headerRows: number,
  laboratoryId: number
): Promise<{ message: string }> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mapping', JSON.stringify(mapping));
    formData.append('header_rows', headerRows.toString());
    formData.append('laboratory_id', laboratoryId.toString());

    const response = await axiosInstance.post('/import-export/import-standards', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Ошибка при импорте стандартных образцов:', error);
    throw error;
  }
};

/**
 * Извлечь данные из Excel файла на основе маппинга колонок
 * @param file Файл Excel
 * @param mapping Маппинг колонок
 * @param headerRows Количество строк заголовка
 * @returns Данные из Excel файла
 */
export const extractExcelData = async (
  file: File,
  mapping: Record<string, string>,
  headerRows: number
): Promise<any[]> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mapping', JSON.stringify(mapping));
    formData.append('header_rows', headerRows.toString());

    const response = await axiosInstance.post('/import-export/extract-data', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.data || [];
  } catch (error) {
    console.error('Ошибка при извлечении данных из Excel:', error);
    throw error;
  }
}; 