import React from "react";
import styled from "styled-components";
import { Checkbox } from "antd";

const Container = styled.div`
  align-items: center;
  background-color: #fafafa;
  border: 0.5px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  padding: 4px 12px 4px 0px;
  position: relative;
  height: 40px;
`;

const SelectWrapper = styled.div`
  align-items: flex-start;
  border-radius: 2px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 32px;
  padding: 4px 12px;
  position: relative;
`;

const SelectionItem = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  padding: 1px 0px;
  position: relative;
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;

interface CheckboxFieldProps {
  title: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  onMouseDown?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({ 
  title, 
  checked = false,
  onChange 
}) => {
  return (
    <Container>
      <SelectWrapper>
        <SelectionItem>
          <Title>{title}</Title>
        </SelectionItem>
      </SelectWrapper>

      <Checkbox
        checked={checked}
        onChange={e => onChange?.(e.target.checked)}
      />
    </Container>
  );
}; 