import { CloseOutlined, DeleteOutlined, FileOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Row, Select, Table, Modal, Space, message } from "antd";
import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import ExportTypes, { ExportFormat } from './ExportTypes';
import ExportProcess from './ExportProcess';
import SuccessRequest from './SuccessRequest';
import axiosInstance from '../api/axios';

const { Option } = Select;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table-tbody > tr > td {
    height: 55px;
    padding: 8px;
  }
  
  .ant-table-body {
    height: 260px !important;
    overflow-y: auto !important;
  }
`;

interface DataType {
    key: React.Key;
    number: number;
    name: string;
    type: string;
    quantity: string;
    note: string;
}

interface SelectedReagent {
    key: React.Key;
    number: number;
    name: string;
}

interface PurchaseRequestProps {
    onCancel: () => void;
    open: boolean;
    selectedReagents: SelectedReagent[];
    onSuccess?: () => void;
}

const PurchaseRequest: React.FC<PurchaseRequestProps> = ({ onCancel, open, selectedReagents, onSuccess }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [isExportVisible, setIsExportVisible] = useState(false);
    const [isExportProcessVisible, setIsExportProcessVisible] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);

    useEffect(() => {
        setDataSource(selectedReagents.map(reagent => ({
            key: reagent.key,
            number: reagent.number,
            name: reagent.name,
            type: 'Реактив',
            quantity: '',
            note: ''
        })));
    }, [selectedReagents]);

    const handleDelete = (key: React.Key) => {
        setDataSource(prev => prev.filter(item => item.key !== key));
    };

    const handleAdd = () => {
        const newItem: DataType = {
            key: Date.now(),
            number: dataSource.length + 1,
            name: '',
            type: 'Реактив',
            quantity: '',
            note: ''
        };
        setDataSource([...dataSource, newItem]);
    };

    const handleExport = async (format: ExportFormat) => {
        setIsExportVisible(false);
        setIsExportProcessVisible(true);
        
        try {
            console.log('Exporting data:', dataSource);

            const requestData = {
                items: dataSource.map(item => ({
                    number: item.number,
                    type: item.type,
                    name: item.name,
                    quantity: item.quantity,
                    note: item.note
                }))
            };

            console.log('Request data:', requestData);

            const response = await axiosInstance.post(
                `/api/purchase-requests/export?format=${format}`,
                requestData,
                {
                    responseType: 'blob',
                    headers: {
                        'Accept': '*/*'
                    }
                }
            );

            const blob = new Blob([response.data], { 
                type: response.headers['content-type'] 
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition
                ? contentDisposition.split('filename=')[1].replace(/"/g, '')
                : `export.${format.toLowerCase()}`;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Ошибка при экспорте:', error);
            message.error('Ошибка при экспорте данных');
        } finally {
            setIsExportProcessVisible(false);
        }
    };

    const handleTypeChange = (value: string, record: DataType) => {
        setDataSource(prev => 
            prev.map(item => 
                item.key === record.key ? { ...item, type: value } : item
            )
        );
    };

    const handleNameChange = (value: string, record: DataType) => {
        setDataSource(prev => 
            prev.map(item => 
                item.key === record.key ? { ...item, name: value } : item
            )
        );
    };

    const handleQuantityChange = (value: string, record: DataType) => {
        setDataSource(prev => 
            prev.map(item => 
                item.key === record.key ? { ...item, quantity: value } : item
            )
        );
    };

    const handleNoteChange = (value: string, record: DataType) => {
        setDataSource(prev => 
            prev.map(item => 
                item.key === record.key ? { ...item, note: value } : item
            )
        );
    };

    const handleSubmit = async () => {
        try {
            const requestData = {
                items: dataSource.map(item => ({
                    number: item.number,
                    type: item.type,
                    name: item.name,
                    quantity: item.quantity,
                    note: item.note
                }))
            };

            const response = await axiosInstance.post('/api/purchase-requests/submit', requestData);
            
            if (response.status === 200) {
                setIsSuccessVisible(true);
            } else {
                throw new Error('Ошибка при отправке заявки');
            }
        } catch (error) {
            console.error('Ошибка при отправке заявки:', error);
            message.error('Ошибка при отправке заявки');
        }
    };

    const handleSuccessClose = () => {
        setIsSuccessVisible(false);
        setDataSource([]);
        if (onSuccess) {
            onSuccess();
        }
        setTimeout(() => {
            onCancel();
        }, 100);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "№ п/п",
            dataIndex: "number",
            key: "number",
            width: 87,
        },
        {
            title: "Тип",
            dataIndex: "type",
            key: "type",
            width: 232,
            render: (_, record) => (
                <Select 
                    value={record.type} 
                    style={{ width: "100%" }}
                    onChange={(value) => handleTypeChange(value, record)}
                >
                    <Option value="Реактив">Реактив</Option>
                    <Option value="Прекурсор">Прекурсор</Option>
                    <Option value="Стандартный образец">Стандартный образец</Option>
                </Select>
            ),
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            width: 232,
            render: (text: string, record: DataType) => (
                <Input 
                    value={text} 
                    placeholder="введите название"
                    onChange={(e) => handleNameChange(e.target.value, record)}
                />
            ),
        },
        {
            title: "Количество",
            dataIndex: "quantity",
            key: "quantity",
            width: 232,
            render: (text: string, record: DataType) => (
                <Input 
                    value={text} 
                    placeholder="введите количество"
                    onChange={(e) => handleQuantityChange(e.target.value, record)}
                />
            ),
        },
        {
            title: "Примечание",
            dataIndex: "note",
            key: "note",
            width: 232,
            render: (text: string, record: DataType) => (
                <Input 
                    value={text} 
                    placeholder="описание"
                    onChange={(e) => handleNoteChange(e.target.value, record)}
                />
            ),
        },
        {
            key: "action",
            width: 48,
            render: (_: unknown, record: DataType) => (
                <StyledButton 
                    type="text" 
                    icon={<DeleteOutlined style={{ color: '#f5222d' }} />}
                    style={{ width: '32px', padding: 0 }}
                    onClick={() => handleDelete(record.key)}
                />
            ),
        },
    ];

    return (
        <Modal
            title="Заявка на закупку"
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
            centered
        >
            <StyledTable
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                bordered
                style={{ marginTop: 16 }}
                scroll={{ y: 300 }}
            />
            <ButtonsRow justify="space-between" style={{ marginTop: 16 }}>
                <Space size={8}>
                    <StyledButton onClick={onCancel}>Назад</StyledButton>
                    <StyledButton 
                        icon={<PlusOutlined />}
                        onClick={handleAdd}
                    >
                        Добавить
                    </StyledButton>
                </Space>
                <Space size={8}>
                    <StyledButton 
                        icon={<FileOutlined />}
                        onClick={() => setIsExportVisible(true)}
                    >
                        Экспортировать
                    </StyledButton>
                    <StyledButton 
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Оформить заявку в СпектроХим
                    </StyledButton>
                </Space>
            </ButtonsRow>
            <ExportTypes 
                open={isExportVisible}
                onCancel={() => setIsExportVisible(false)}
                onExport={handleExport}
                selectedRows={dataSource.map(item => item.key)}
            />
            <ExportProcess 
                open={isExportProcessVisible}
            />
            <SuccessRequest 
                open={isSuccessVisible}
                onClose={handleSuccessClose}
            />
        </Modal>
    );
};

export default PurchaseRequest;
