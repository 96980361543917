import { EditOutlined, PlusOutlined, DatabaseOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Row, Typography, theme, Modal, Input, Select, message } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../api/axios";

const { Text } = Typography;
const { useToken } = theme;
const { Option } = Select;

interface ColumnManagerProps {
  columns: { title: string; key: string }[];
  hiddenColumns: string[];
  onColumnVisibilityChange: (columns: string[]) => void;
  visible?: boolean;
  onClose?: () => void;
  registryType?: string;
  laboratoryId?: number;
  onColumnAdded?: () => void;
}

interface ColumnItem {
  title: string;
  key: string;
  isCustom?: boolean;
  type?: string;
}

const ColumnManager: React.FC<ColumnManagerProps> = ({
  columns,
  hiddenColumns,
  onColumnVisibilityChange,
  visible = false,
  onClose,
  registryType,
  laboratoryId,
  onColumnAdded,
}): JSX.Element | null => {
  if (!visible) return null;
  
  const { token } = useToken();
  const [isAddColumnVisible, setIsAddColumnVisible] = useState(false);
  const [newColumnTitle, setNewColumnTitle] = useState('');
  const [newColumnType, setNewColumnType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [customColumns, setCustomColumns] = useState<ColumnItem[]>([]);
  const [allColumns, setAllColumns] = useState<ColumnItem[]>(columns);

  // Функция для загрузки пользовательских колонок
  const loadCustomColumns = useCallback(async () => {
    if (!registryType || !laboratoryId) return;
    
    try {
      const response = await axiosInstance.get(`/api/custom-columns/${registryType}`, {
        params: {
          laboratory_id: laboratoryId
        }
      });
      
      if (response.status === 200) {
        const customCols = response.data.map((col: any) => ({ 
          title: col.title, 
          key: col.key,
          type: col.type || 'text',
          isCustom: true
        }));
        setCustomColumns(customCols);
        
        // Объединяем стандартные и пользовательские колонки
        setAllColumns([...columns, ...customCols]);
      }
    } catch (error) {
      console.error('Ошибка при загрузке пользовательских колонок:', error);
    }
  }, [registryType, laboratoryId, columns]);

  // Загружаем пользовательские колонки при монтировании компонента
  useEffect(() => {
    loadCustomColumns();
  }, [loadCustomColumns]);

  const handleVisibilityChange = (columnKey: string, checked: boolean) => {
    const newHiddenColumns = checked
      ? hiddenColumns.filter(col => col !== columnKey)
      : [...hiddenColumns, columnKey];
    
    onColumnVisibilityChange(newHiddenColumns);
    
    // Сохраняем настройки видимости колонок с учетом типа реестра и ID лаборатории
    if (registryType && laboratoryId) {
      const storageKey = `${registryType}_${laboratoryId}_hiddenColumns`;
      localStorage.setItem(storageKey, JSON.stringify(newHiddenColumns));
    }
  };

  const toggleAddColumn = () => {
    setIsAddColumnVisible(!isAddColumnVisible);
    if (!isAddColumnVisible) {
      setNewColumnTitle('');
      setNewColumnType('');
    }
  };

  const handleAddColumn = async () => {
    if (newColumnTitle && newColumnType) {
      try {
        setIsLoading(true);
        
        // Проверяем, что registryType и laboratoryId заданы
        if (!registryType || !laboratoryId) {
          message.error('Не указан тип реестра или ID лаборатории');
          return;
        }
        
        // Отправляем запрос на создание новой колонки
        const response = await axiosInstance.post(`/api/custom-columns/${registryType}`, {
          title: newColumnTitle,
          type: newColumnType,
          required: false
        }, {
          params: {
            laboratory_id: laboratoryId
          }
        });
        
        if (response.status === 200 || response.status === 201) {
          // Обновляем список колонок
          const newColumn = {
            title: response.data.title,
            key: response.data.key,
            type: newColumnType,
            isCustom: true
          };
          
          // Добавляем новую колонку в список
          setCustomColumns(prev => [...prev, newColumn]);
          setAllColumns(prev => [...prev, newColumn]);
          
          // Закрываем форму добавления
          toggleAddColumn();
          
          // Показываем сообщение об успехе
          message.success(`Колонка "${newColumnTitle}" успешно добавлена`);
          
          // Вызываем обработчик для обновления таблицы
          if (onColumnAdded) {
            onColumnAdded();
          }
        } else {
          // Показываем сообщение об ошибке
          message.error('Не удалось добавить колонку');
        }
      } catch (error) {
        console.error('Ошибка при добавлении колонки:', error);
        message.error('Не удалось добавить колонку');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Row justify="center" align="middle" style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Button type="primary" icon={<DatabaseOutlined />} block style={{ height: 40 }}>
            Управление колонками
          </Button>
          <Button 
            icon={<PlusOutlined />} 
            block 
            style={{ height: 40, marginTop: 10 }}
            onClick={toggleAddColumn}
          >
            Добавить колонку
          </Button>
        </Col>
      </Row>

      {isAddColumnVisible && (
        <div style={{ marginBottom: 20, padding: '0px 0' }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <label style={{ color: '#8c8c8c', fontSize: 12 }}>Название</label>
              <Input 
                placeholder="введите название" 
                style={{ height: 40, borderRadius: 4, backgroundColor: '#fafafa' }}
                value={newColumnTitle}
                onChange={(e) => setNewColumnTitle(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <label style={{ color: '#8c8c8c', fontSize: 12 }}>Тип данных</label>
              <Select 
                placeholder="выберите тип" 
                suffixIcon={<DownOutlined />}
                style={{ height: 40, width: '100%', borderRadius: 4 }}
                value={newColumnType || undefined}
                onChange={(value) => setNewColumnType(value)}
              >
                <Option value="text">Текст</Option>
                <Option value="int">Число</Option>
                <Option value="date">Дата</Option>
                <Option value="boolean">Да/Нет</Option>
              </Select>
            </Col>
            <Col span={24}>
              <Row gutter={12}>
                <Col span={12}>
                  <Button 
                    block 
                    style={{ height: 40, borderRadius: 4 }} 
                    onClick={toggleAddColumn}
                  >
                    Отмена
                  </Button>
                </Col>
                <Col span={12}>
                  <Button 
                    type="primary" 
                    block 
                    style={{ height: 40, borderRadius: 4 }}
                    onClick={handleAddColumn}
                    disabled={!newColumnTitle || !newColumnType}
                    loading={isLoading}
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}

      <div style={{ height: 343, overflowY: "auto", marginBottom: 16 }}>
        {allColumns.map(({ title, key, isCustom }, index) => (
          <Row key={index} align="middle" style={{ marginBottom: 8, height: 40, backgroundColor: "#ffffff", borderRadius: 4, border: `1px solid #d9d9d9`}}>
            <Col flex="1 1 0" style={{ marginLeft: 14, minWidth: 0 }}>
              <Text ellipsis style={{ maxWidth: '100%' }}>
                {isCustom && <span style={{ color: '#1890ff', marginRight: 5 }}>•</span>}
                {title}
              </Text>
            </Col>
            <Col flex="0 0 auto" style={{ marginRight: 14 }}>
              <Checkbox 
                checked={!hiddenColumns.includes(key)}
                onChange={(e) => handleVisibilityChange(key, e.target.checked)}
              />
            </Col>
          </Row>
        ))}
      </div>

      <Row gutter={16}>
        <Col flex="auto">
          <Button block onClick={onClose} style={{ height: 40 }}>
            Отмена
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ColumnManager;
