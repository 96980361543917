import { EditOutlined, PlusOutlined, DatabaseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Row, Typography, theme, Modal } from "antd";
import React, { useState, useEffect } from "react";
import AddColumn from './AddColumn';

const { Text } = Typography;
const { useToken } = theme;

interface ColumnManagerProps {
  columns: { title: string; key: string }[];
  hiddenColumns: string[];
  onColumnVisibilityChange: (columns: string[]) => void;
  visible?: boolean;
  onClose?: () => void;
}

const ColumnManager: React.FC<ColumnManagerProps> = ({
  columns,
  hiddenColumns,
  onColumnVisibilityChange,
  visible = false,
  onClose,
}): JSX.Element | null => {
  if (!visible) return null;
  
  const { token } = useToken();
  const [isAddColumnVisible, setIsAddColumnVisible] = useState(false);

  const handleVisibilityChange = (columnKey: string, checked: boolean) => {
    const newHiddenColumns = checked
      ? hiddenColumns.filter(col => col !== columnKey)
      : [...hiddenColumns, columnKey];
    
    onColumnVisibilityChange(newHiddenColumns);
    localStorage.setItem('standardSamplesHiddenColumns', JSON.stringify(newHiddenColumns));
  };

  return (
    <div>
      <Row justify="center" align="middle" style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Button type="primary" icon={<DatabaseOutlined />} block style={{ height: 40 }}>
            Управление колонками
          </Button>
        </Col>
      </Row>

      <div style={{ height: 343, overflowY: "auto", marginBottom: 16 }}>
        {columns.map(({ title, key }, index) => (
          <Row key={index} align="middle" style={{ marginBottom: 8, height: 40, backgroundColor: "#ffffff", borderRadius: 4, border: `1px solid #d9d9d9`}}>
            <Col flex="1 1 0" style={{ marginLeft: 14, minWidth: 0 }}>
              <Text ellipsis style={{ maxWidth: '100%' }}>{title}</Text>
            </Col>
            <Col flex="0 0 auto" style={{ marginRight: 14 }}>
              <Checkbox 
                checked={!hiddenColumns.includes(key)}
                onChange={(e) => handleVisibilityChange(key, e.target.checked)}
              />
            </Col>
          </Row>
        ))}
      </div>

      <Row gutter={16}>
        <Col flex="auto">
          <Button block onClick={onClose} style={{ height: 40 }}>
            Отмена
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ColumnManager;
