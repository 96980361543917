import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import HeaderAdmin from './HeaderAdmin';
import { Button, Space, Layout, Typography, theme, Table, Avatar, Switch, Select, Modal, Input, Form, Tag, Checkbox, message } from 'antd';
import { SearchOutlined, UserAddOutlined, MailOutlined, PhoneOutlined, EditOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';


const { Content } = Layout;
const { Title } = Typography;
const { useToken } = theme;


const HeadingExtra = styled(Space)`
    height: 40px;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    height: 100vh;
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: #fafafa;
    color: #595959 !important;
  }
    padding: 16px 0;
`;


const FormRow = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    
    .ant-form-item {
        flex: 1;
        margin-bottom: 0;
    }

    .ant-form-item-label > label {
        color: #8C8C8C;
        font-size: 12px;
    }
`;


const StyledForm = styled(Form)`
    .ant-form-item-label {
        padding: 0 0 4px !important;
    }
`;


const ScrollableContent = styled.div`
    max-height: 400px;
    overflow-y: auto;
    padding-right: 6px;
    
    /* Стили для скроллбара */
    &::-webkit-scrollbar {
        width: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
`;


const StyledArrow = styled(ArrowLeftOutlined)`
    color: #262626;
    font-size: 16px;
    display: flex;
    align-items: center;
`;

const BackButton = styled(Space)`
    &:hover {
        opacity: 0.8;
    }
    align-items: center;
`;

interface Employee {
    id: number;
    user_id: number;
    laboratory_id: number;
    name: string;
    email: string;
    phone: string | null;
    role: string;
    is_admin: boolean;
}

const EmployersLaboratory: FunctionComponent = () => {
    const { id } = useParams<{ id: string }>();
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(null);
    const [editEmployeeData, setEditEmployeeData] = useState<any>(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { token } = useToken();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const fetchEmployees = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axiosInstance.get(`/api/laboratories/${id}/employees`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setEmployees(response.data);
        } catch (error: any) {
            message.error('Ошибка при загрузке списка сотрудников');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchEmployees();
        }
    }, [id]);

    const handleOk = async () => {
        if (!id) {
            message.error('ID лаборатории не найден');
            return;
        }

        try {
            const values = await form.validateFields();
            await axiosInstance.post(
                `/api/laboratories/${id}/employees`,
                {
                    ...values,
                    role: 'quality',
                    is_admin: false
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            message.success('Сотрудник успешно добавлен');
            setIsModalOpen(false);
            form.resetFields();
            fetchEmployees();
        } catch (error: any) {
            const errorMessage = error.response?.data?.detail || 'Ошибка при добавлении сотрудника';
            message.error(errorMessage);
            console.error('Error:', error);
        }
    };

    const handleDelete = async (employeeId: number) => {
        try {
            const token = localStorage.getItem('token');
            await axiosInstance.delete(`/api/laboratories/employees/${employeeId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            message.success('Сотрудник успешно удален');
            fetchEmployees();
        } catch (error: any) {
            message.error('Ошибка при удалении сотрудника');
            console.error('Error:', error);
        }
    };

    const handleEditSave = async (employeeId: number, values: any) => {
        try {
            const token = localStorage.getItem('token');
            await axiosInstance.put(
                `/api/laboratories/employees/${employeeId}`,
                values,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            message.success('Данные сотрудника обновлены');
            fetchEmployees();
        } catch (error: any) {
            message.error('Ошибка при обновлении данных сотрудника');
            console.error('Error:', error);
        }
    };

    const data = employees.map(employee => ({
        key: employee.id,
        name: employee.name,
        email: employee.email,
        phone: employee.phone || '',
        avatarColor: token['volcano-2'],
        initialsColor: token['volcano-6'],
        initials: employee.name.split(' ').map(n => n[0]).join(''),
    }));

    const columns = [
        {
            title: 'Сотрудник',
            key: 'employee',
            render: (record: any) => (
                <Space>
                    <Avatar style={{ backgroundColor: record.avatarColor, width: '50px', height: '50px', color: record.initialsColor }}>{record.initials}</Avatar>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.name}</Typography.Text>
                        <Space>
                            <Space>
                                <MailOutlined />
                                <Typography.Text type="secondary">{record.email}</Typography.Text>
                            </Space>
                            <Space>
                                <PhoneOutlined />
                                <Typography.Text type="secondary">{record.phone}</Typography.Text>
                            </Space>
                        </Space>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Действия',
            key: 'actions',
            width: 300,
            align: 'left' as const,
            render: (record: any) => (
                <Space>
                    <Button 
                        type="text" 
                        icon={<EditOutlined style={{ color: token['blue-6'] }} />}
                        onClick={() => showEditModal(record)}
                    />
                    <Button 
                        type="text" 
                        icon={<DeleteOutlined style={{ color: token['red-6'] }} />}
                        onClick={() => showDeleteModal(record.key)}
                    />
                </Space>
            ),
        },
    ];

    const handleBack = () => {
        navigate(`/admin/manage-laboratory/${id}`);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const showDeleteModal = (employeeId: number) => {
        setSelectedEmployeeId(employeeId);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteCancel = () => {
        setSelectedEmployeeId(null);
        setIsDeleteModalOpen(false);
    };

    const handleDeleteConfirm = async () => {
        if (selectedEmployeeId === null) return;
        
        try {
            await handleDelete(selectedEmployeeId);
            setIsDeleteModalOpen(false);
        } finally {
            setSelectedEmployeeId(null);
        }
    };

    const showEditModal = (employee: any) => {
        setEditEmployeeData(employee);
        setSelectedEmployeeId(employee.key);
        form.setFieldsValue({
            name: employee.name,
            email: employee.email,
            phone: employee.phone
        });
        setIsEditModalOpen(true);
    };

    const handleEditCancel = () => {
        setSelectedEmployeeId(null);
        setEditEmployeeData(null);
        setIsEditModalOpen(false);
    };

    const handleEditConfirm = async () => {
        try {
            const values = await form.validateFields();
            if (selectedEmployeeId === null) return;

            await axiosInstance.put(
                `/api/laboratories/employees/${selectedEmployeeId}`,
                values,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            message.success('Данные сотрудника обновлены');
            setIsEditModalOpen(false);
            form.resetFields();
            fetchEmployees();
        } catch (error: any) {
            message.error(error.response?.data?.detail || 'Ошибка при обновлении данных');
        }
    };

    return (
        <>
            <HeaderAdmin />
            <StyledContent>
                <TextParentRoot onClick={handleBack}>
                    <BackButton align="center" style={{ marginBottom: 22 }}>
                        <StyledArrow />
                        <Title level={4} style={{ margin: 0, paddingLeft: 16, fontWeight: 500 }}>Назад</Title>
                    </BackButton>
                </TextParentRoot>
                <TextParentRoot>
                    <Title level={4}>Сотрудники лаборатории</Title>
                    <HeadingExtra>
                        <Button icon={<UserAddOutlined style={{ color: token['blue-5'] }} />} onClick={() => setIsModalOpen(true)} style={{ height: '40px' }}>
                            Добавить сотрудника
                        </Button>
                    </HeadingExtra>
                </TextParentRoot>

                <StyledTable 
                    columns={columns} 
                    dataSource={data}
                    pagination={false}
                />

                <Modal
                    title="Пригласить сотрудника"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    centered={true}
                    width={610}
                    footer={[
                        <Button 
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            style={{ width: '100%', height: '40px' }}
                        >
                            Пригласить
                        </Button>
                    ]}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        style={{ marginTop: '16px' }}
                    >
                        <FormRow>
                        <Form.Item
                            label="Фамилия и Имя"
                            name="name"
                        >
                            <Input placeholder="введите данные" style={{ height: '40px', width: '178px', backgroundColor: '#F5F5F5' }} />
                        </Form.Item>
                        
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[
                                { type: 'email', message: 'Пожалуйста, введите корректный email' }
                            ]}
                        >
                            <Input placeholder="введите данные" style={{ height: '40px', width: '178px', backgroundColor: '#F5F5F5' }} />
                        </Form.Item>
                        
                        <Form.Item
                            label="Пароль"
                            name="password"
                        >
                            <Input.Password placeholder="введите данные" style={{ height: '40px', width: '178px', backgroundColor: '#F5F5F5' }} />
                        </Form.Item>
                        </FormRow>
                    </Form>
                </Modal>

                <Modal
                    title={null}
                    open={isDeleteModalOpen}
                    onCancel={handleDeleteCancel}
                    centered
                    width={571}
                    footer={null}
                    closable={false}
                >
                    <div style={{ textAlign: 'left', marginBottom: 16 }}>
                        <Space direction="vertical" size={8}>
                            <Typography.Title level={4} style={{ margin: 0 }}>
                                Вы уверены что хотите удалить сотрудника?
                            </Typography.Title>
                            <Typography.Text type="secondary">
                                Данные об удалении записи сохранятся в истории изменений
                            </Typography.Text>
                        </Space>
                    </div>
                    
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Button 
                            onClick={handleDeleteCancel}
                            style={{ width: '262px', height: 40 }}
                        >
                            Отмена
                        </Button>
                        <Button 
                            danger
                            type="primary"
                            onClick={() => {
                                if (selectedEmployeeId !== null) {
                                    handleDelete(selectedEmployeeId);
                                }
                                setIsDeleteModalOpen(false);
                            }}
                            style={{ width: '262px', height: 40 }}
                        >
                            Да, удалить
                        </Button>
                    </Space>
                </Modal>

                <Modal
                    title="Редактирование сотрудника"
                    open={isEditModalOpen}
                    onCancel={handleEditCancel}
                    footer={null}
                    width={330}
                    centered
                >
                    <div style={{ padding: '16px 0' }}>
                        <Space direction="vertical" size={16} style={{ width: '100%' }}>
                            {editEmployeeData && (
                                <Space size={16}>
                                    <Avatar style={{ backgroundColor: token['volcano-2'], width: 50, height: 50, color: token['volcano-6'] }}>
                                        {editEmployeeData.initials}
                                    </Avatar>
                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                        {editEmployeeData.name}
                                    </Typography.Title>
                                </Space>
                            )}
                            <StyledForm form={form} layout="vertical">
                                <Form.Item 
                                    label="Фамилия и Имя"
                                    name="name"
                                    rules={[{ required: true, message: 'Введите имя' }]}
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="E-mail"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Введите email' },
                                        { type: 'email', message: 'Введите корректный email' }
                                    ]}
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="Номер телефона"
                                    name="phone"
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>
                            </StyledForm>

                            <div style={{ display: 'flex', gap: '12px' }}>
                                <Button 
                                    onClick={handleEditCancel}
                                    style={{ flex: 1, height: 40 }}
                                >
                                    Отмена
                                </Button>
                                <Button 
                                    type="primary"
                                    onClick={handleEditConfirm}
                                    style={{ flex: 1, height: 40 }}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </Space>
                    </div>
                </Modal>

            </StyledContent>
        </>
    );
};

export default EmployersLaboratory;