import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Row, Select, Typography, DatePicker, Checkbox } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import dayjs from 'dayjs';
import { CheckboxField } from '../../components/CheckboxField';
import { message } from 'antd';
import { createReagent } from '../../api/chemicalRegistry';
import { useLaboratoryId } from '../../hooks/useLaboratoryId';

const { Option } = Select;
const { Text } = Typography;

const ScreenContainer = styled.div`
  width: 400px;
  height: 500px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

const ScreenTitle = styled(Typography.Title)`
  margin: 16px !important;
  font-size: 16px !important;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const BottomButtons = styled.div`
  padding: 16px;
`;

const StyledInput = styled(Input)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  /* Скрываем стрелки для числовых полей */
  &[type='number'] {
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    padding: 4px 11px !important;
    background-color: #fafafa !important;
    border-radius: 4px !important;
    
    .ant-select-selection-search-input {
      height: 38px !important;
    }
    
    .ant-select-selection-item {
      line-height: 32px !important;
    }
  }

  // Центрируем иконки по вертикали
  .ant-select-arrow,
  .ant-select-clear {
    top: 50% !important;
    margin-top: -3px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  background-color: #fafafa;
  border-radius: 4px;

  .ant-picker-input > input {
    background-color: #fafafa;
  }
`;

const StyledText = styled(Text)`
  color: #8c8c8c;
  font-weight: 500;
  font-size: 12px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  justify-content: center;
  gap: 8px;
  
  .ant-btn {
    height: 40px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(Typography.Link)`
  color: #8c8c8c !important;
  font-size: 14px;
  text-decoration: underline !important;
  margin-right: 20px;
`;

// Добавляем пропсы для компонента
interface ScreenProps {
  onCancel: () => void;
  onSuccess?: () => void;
}

// Опции из Reagents.tsx
const manufacturerOptions = [
  'Поизводитель 1',
  'Производитель 2',
  'Производитель 3',
  'Другой'
].map(item => ({ label: item, value: item }));

const supplierOptions = [
  'Поставщик 1',
  'Поставщик 2',
  'Поставщик 3',
  'Другой'
].map(item => ({ label: item, value: item }));

const frequencyClassOptions = [
  'A',
  'B',
  'C',
  'D'
].map(item => ({ label: item, value: item }));

const notificationOptions = [
  'За 30 дней',
  'За 60 дней',
  'За 90 дней',
  'Другое'
].map(item => ({ label: item, value: item }));

const containerUnitOptions = [
  'мл',
  'л',
  'кг',
  'г',
  'Другое'
].map(item => ({ label: item, value: item }));

const locationOptions = [
  'Склад A',
  'Склад B',
  'Склад C',
  'Другое'
].map(item => ({ label: item, value: item }));

const Screen: React.FC<ScreenProps> = ({ onCancel, onSuccess }) => {
  const laboratoryId = useLaboratoryId();
  const [loading, setLoading] = useState(false);
  
  // Состояния для полей формы
  const [name, setName] = useState('');
  const [batch, setBatch] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [supplier, setSupplier] = useState('');
  const [receiptDate, setReceiptDate] = useState<dayjs.Dayjs | null>(null);
  const [expiryDate, setExpiryDate] = useState<dayjs.Dayjs | null>(null);
  const [location, setLocation] = useState('');
  const [conditions, setConditions] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('');
  const [openedShelfLife, setOpenedShelfLife] = useState('');
  const [frequencyClass, setFrequencyClass] = useState('');
  const [notes, setNotes] = useState('');
  const [documentsReceived, setDocumentsReceived] = useState(false);
  const [accessGranted, setAccessGranted] = useState(false);
  const [isPrecursor, setIsPrecursor] = useState(false);

  // Обработчики изменений с правильной типизацией
  const handleDateChange = (date: unknown, dateString: string | string[]) => {
    setReceiptDate(date as dayjs.Dayjs);
  };

  const handleExpiryDateChange = (date: unknown, dateString: string | string[]) => {
    setExpiryDate(date as dayjs.Dayjs);
  };

  const handleManufacturerChange = (value: unknown, option: any) => {
    setManufacturer(value as string);
  };

  const handleSupplierChange = (value: unknown, option: any) => {
    setSupplier(value as string);
  };

  const handleLocationChange = (value: unknown, option: any) => {
    setLocation(value as string);
  };

  const handleUnitChange = (value: unknown, option: any) => {
    setUnit(value as string);
  };

  const handleFrequencyClassChange = (value: unknown, option: any) => {
    setFrequencyClass(value as string);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isNaN(Number(value))) {
      setQuantity(value);
    }
  };

  const handleConditionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConditions(e.target.value);
  };

  const handleOpenedShelfLifeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpenedShelfLife(e.target.value);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  const handleSave = async () => {
    if (!laboratoryId) return;
    
    // Валидация обязательных полей
    if (!name.trim()) {
        message.error('Введите название реагента');
        return;
    }

    // Проверяем что количество введено и это число
    if (!quantity || isNaN(Number(quantity))) {
        message.error('Введите корректное количество');
        return;
    }

    try {
        setLoading(true);
        await createReagent(laboratoryId, {
            name,
            batch,
            manufacturer,
            supplier,
            receipt_date: receiptDate?.toISOString(),
            expiration_date: expiryDate?.toISOString(),
            storage_location: location,
            storage_conditions: conditions,
            quantity: quantity,
            unit,
            opened_shelf_life: openedShelfLife || null,
            frequency_class: frequencyClass,
            has_documents: documentsReceived,
            has_admission: accessGranted,
            is_precursor: isPrecursor,
            notes: notes || null
        });
        
        message.success('Реагент успешно добавлен');
        onSuccess?.();
        onCancel();
    } catch (error) {
        if (error instanceof Error) {
            message.error(error.message);
        } else {
            message.error('Ошибка при добавлении реагента');
        }
        console.error('Error:', error);
    } finally {
        setLoading(false);
    }
  };

  return (
    <ScreenContainer>
      <HeaderContainer>
        <ScreenTitle level={4} style={{ margin: 0 }}>Новая запись</ScreenTitle>
        <StyledLink>Добавить множество позиций</StyledLink>
      </HeaderContainer>
      
      <ScrollContent>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <StyledText>Дата</StyledText>
            <StyledDatePicker 
              value={receiptDate}
              onChange={handleDateChange}
              style={{ width: '100%' }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
            />
          </Col>

          <Col span={24}>
            <StyledText>Наименование</StyledText>
            <StyledInput 
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="введите название" 
            />
          </Col>

          <Col span={24}>
            <StyledText>№ Партии/серии</StyledText>
            <StyledInput 
              value={batch}
              onChange={e => setBatch(e.target.value)}
              placeholder="введите номер" 
            />
          </Col>

          <Col span={24}>
            <StyledText>Производитель</StyledText>
            <StyledSelect 
              value={manufacturer}
              onChange={handleManufacturerChange}
              placeholder="выберите из списка" 
              style={{ width: "100%" }}
              options={manufacturerOptions}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Поставщик</StyledText>
            <StyledSelect 
              value={supplier}
              onChange={handleSupplierChange}
              placeholder="выберите из списка" 
              style={{ width: "100%" }}
              options={supplierOptions}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Класс чистоты</StyledText>
            <StyledSelect 
              value={frequencyClass}
              onChange={handleFrequencyClassChange}
              placeholder="выберите из списка" 
              style={{ width: "100%" }}
              options={frequencyClassOptions}
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Срок годности</StyledText>
            <StyledDatePicker 
              value={expiryDate}
              onChange={handleExpiryDateChange}
              style={{ width: '100%' }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
            />
          </Col>

          <Col span={24}>
            <StyledText>Уведомление об окончании срока годности</StyledText>
            <StyledSelect 
              placeholder="выберите из списка" 
              style={{ width: "100%" }}
              options={notificationOptions}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Размер тары</StyledText>
            <StyledInput type="number" placeholder="введите значение" />
          </Col>

          <Col span={24}>
            <StyledText>Ед.измерения тары</StyledText>
            <StyledSelect 
              value={unit}
              onChange={handleUnitChange}
              placeholder="выберите из списка" 
              style={{ width: "100%" }}
              options={containerUnitOptions}
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Количество тарных мест</StyledText>
            <StyledInput 
              type="number"
              placeholder="введите значение"
              value={quantity || ''}
              onChange={handleQuantityChange}
            />
          </Col>

          <Col span={24}>
            <StyledText>Комплект документов</StyledText>
            <CheckboxField 
              title="Получен" 
              checked={documentsReceived}
              onChange={setDocumentsReceived}
            />
          </Col>

          <Col span={24}>
            <StyledText>Доступ</StyledText>
            <CheckboxField 
              title="Получен" 
              checked={accessGranted}
              onChange={setAccessGranted}
            />
          </Col>

          <Col span={24}>
            <StyledText>Прекурсор</StyledText>
            <CheckboxField 
              title="Да" 
              checked={isPrecursor}
              onChange={setIsPrecursor}
            />
          </Col>

          <Col span={24}>
            <StyledText>Место хранения</StyledText>
            <StyledSelect 
              value={location}
              onChange={handleLocationChange}
              placeholder="выберите из списка" 
              style={{ width: "100%" }}
              options={locationOptions}
              showSearch
              allowClear
            />
          </Col>

          <Col span={24}>
            <StyledText>Условия хранения</StyledText>
            <StyledInput 
              value={conditions}
              onChange={handleConditionsChange}
              placeholder="введите значение" 
            />
          </Col>

          <Col span={24}>
            <StyledText>Срок хранения после вскрытия тары</StyledText>
            <StyledInput placeholder="введите значение" />
          </Col>

          <Col span={24}>
            <StyledText>Использовано</StyledText>
            <StyledInput type="number" placeholder="введите значение" />
          </Col>

          <Col span={24}>
            <StyledText>Остаток</StyledText>
            <StyledInput type="number" placeholder="введите значение" />
          </Col>

          <Col span={24}>
            <StyledText>Примечание</StyledText>
            <StyledInput 
              value={notes}
              onChange={handleNotesChange}
              placeholder="введите текст" 
            />
          </Col>
        </Row>
      </ScrollContent>

      <BottomButtons>
        <ButtonsRow>
          <Button block style={{ width: 178 }} onClick={onCancel}>Отмена</Button>
          <Button 
            type="primary"
            block style={{ width: 178 }}
            onClick={handleSave}
            loading={loading}
          >
            Сохранить
          </Button>
        </ButtonsRow>
      </BottomButtons>
    </ScreenContainer>
  );
};

export default Screen;

