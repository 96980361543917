import axiosInstance from './axios';

export interface MSIData {
  id: number;
  laboratory_id: number;
  key: React.Key;
  number: number;
  measurement_method: string;
  measurement_methodology: string;
  research_object: string;
  characteristic: string;
  employee: string;
  provider: string;
  deadline: string;
  completed: boolean;
}

export interface MSICreateData {
  laboratory_id: number;
  number: number;
  measurement_method: string;
  measurement_methodology: string;
  research_object: string;
  characteristic: string;
  employee: string;
  provider: string;
  deadline: string;
  completed?: boolean;
}

export interface MSIYearPlanData {
  group: string;
  object: string;
  round: string;
  round_dates: string;
  application_deadline: string;
  pickup_dates: string;
  result_submission: string;
  conclusion_dates: string;
  program_type: string;
}

export const msiApi = {
  getLaboratoryMSI: (laboratoryId: number) => 
    axiosInstance.get<MSIData[]>(`/api/laboratory/${laboratoryId}/msi`),
  
  createMSI: (data: MSICreateData) => 
    axiosInstance.post<MSIData>('/api/msi', data),
  
  updateMSI: (id: number, data: Partial<MSIData>) => 
    axiosInstance.put<MSIData>(`/api/msi/${id}`, data),
  
  deleteMSI: (id: number) => 
    axiosInstance.delete(`/api/msi/${id}`),
  
  exportMSI: (format: string, msiIds: number[]) =>
    axiosInstance.post(
      `/api/msi/export/${format}`,
      msiIds,
      { responseType: 'blob' }
    ),

  createMSIYearPlan: (data: MSIYearPlanData) =>
    axiosInstance.post('/api/msi/year-plan', data),
    
  getMSIYearPlan: (group: string) =>
    axiosInstance.get<MSIYearPlanData[]>(`/api/msi/year-plan/${encodeURIComponent(group)}`),

  updateMSIYearPlan: (id: number, data: MSIYearPlanData) =>
    axiosInstance.put<MSIYearPlanData>(`/api/msi/year-plan/${id}`, data),

  deleteMSIYearPlan: (id: number) =>
    axiosInstance.delete(`/api/msi/year-plan/${id}`),
};