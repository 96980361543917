import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useLaboratoryId = () => {
    const { laboratoryId: urlLaboratoryId } = useParams();
    const [laboratoryId, setLaboratoryId] = useState<string | null>(null);

    useEffect(() => {
        // Если есть ID в URL, используем его (для админа)
        if (urlLaboratoryId) {
            setLaboratoryId(urlLaboratoryId);
        } else {
            // Иначе берем ID из localStorage (для сотрудника лаборатории)
            const storedId = localStorage.getItem('laboratoryId');
            if (storedId) {
                setLaboratoryId(storedId);
            }
        }
    }, [urlLaboratoryId]);

    return laboratoryId;
}; 