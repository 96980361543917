import { FC, useState } from 'react';
import { Modal, Typography, Input, Row, Col, Button, Upload, message } from 'antd';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import styled from 'styled-components';
import ImportTableReagents from './ImportTableReagents';
import ImportTableStandartSamples from './ImportTableStandartSamples';

const { Title, Text, Link } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 16px;
  }
  
`;

const StyledText = styled(Text)`
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  text-decoration: underline !important;
  text-underline-offset: 2px;
`;

const StyledInput = styled(Input)`
  height: 40px;
  margin-top: 0px !important;
  
  // Убираем стрелочки для числового поля
  &[type='number'] {
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  :where(.css-dev-only-do-not-override-csmlco).ant-input-group >.ant-input:first-child, :where(.css-dev-only-do-not-override-csmlco).ant-input-group .ant-input-group-addon:first-child {
    height: 40px;
    }
  
  .ant-input-group-addon {
    height: 40px;
  }
`;

interface ImportUploadFileProps {
  open: boolean;
  onCancel: () => void;
  onImport: (file: File, headerRows: number) => void;
  tableType: 'reagents' | 'standartSamples';
}

const ImportUploadFile: FC<ImportUploadFileProps> = ({ open, onCancel, onImport, tableType }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [headerRows, setHeaderRows] = useState<string>('1');
  const [isImportTableVisible, setIsImportTableVisible] = useState(false);
  const [headerRowsNum, setHeaderRowsNum] = useState<number>(1);

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isValidFormat = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'application/vnd.oasis.opendocument.spreadsheet'
      ].includes(file.type);

      if (!isValidFormat) {
        message.error('Пожалуйста, загрузите файл в формате XLS, XLSX, CSV или ODS!');
        return false;
      }

      setSelectedFile(file);
      return false;
    },
    showUploadList: false,
  };

  const handleImport = () => {
    if (!selectedFile) {
      message.error('Пожалуйста, выберите файл для импорта');
      return;
    }

    const parsedHeaderRows = parseInt(headerRows);
    if (isNaN(parsedHeaderRows) || parsedHeaderRows < 1) {
      message.error('Пожалуйста, введите корректное количество строк заголовка');
      return;
    }

    setHeaderRowsNum(parsedHeaderRows);
    setIsImportTableVisible(true);
    onImport(selectedFile, parsedHeaderRows);
  };

  const handleImportTableClose = () => {
    setIsImportTableVisible(false);
    onCancel();
  };

  return (
    <>
      <StyledModal
        open={open}
        onCancel={onCancel}
        footer={null}
        width={400}
        closable={false}
        centered
      >
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Title level={5} style={{ margin: 0 }}>Импорт данных из Excel</Title>
          <ExclamationCircleOutlined style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.45)' }} />
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <StyledText>Импортировать данные из</StyledText>
            <Upload {...uploadProps}>
              <StyledInput
                placeholder="загрузить файл (XLS, XLSX, CSV, ODS)"
                style={{ marginTop: 8 }}
                value={selectedFile?.name || ''}
                readOnly
                addonAfter={<UploadOutlined />}
              />
            </Upload>
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <StyledText>Заголовками считать столько строк сверху:</StyledText>
            <StyledInput
              placeholder="1"
              style={{ marginTop: 8 }}
              value={headerRows}
              onChange={(e) => setHeaderRows(e.target.value)}
              type="number"
              min={1}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <StyledLink href="#" target="_blank">Скачать пример</StyledLink>
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <StyledLink href="#" target="_blank" style={{ color: "#8c8c8c" }}>Нужна помощь с импортом?</StyledLink>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Button block onClick={onCancel} style={{ height: 40 }}>
              Отмена
            </Button>
          </Col>
          <Col span={12}>
            <Button type="primary" block onClick={handleImport} style={{ height: 40 }}>
              Импортировать
            </Button>
          </Col>
        </Row>
      </StyledModal>
      
      {tableType === 'reagents' ? (
        <ImportTableReagents 
          open={isImportTableVisible}
          onCancel={handleImportTableClose}
          selectedReagents={[]}
          fileName={selectedFile?.name}
          file={selectedFile}
          headerRows={headerRowsNum}
        />
      ) : (
        <ImportTableStandartSamples 
          open={isImportTableVisible}
          onCancel={handleImportTableClose}
          selectedReagents={[]}
          fileName={selectedFile?.name}
          file={selectedFile}
          headerRows={headerRowsNum}
        />
      )}
    </>
  );
};

export default ImportUploadFile;
